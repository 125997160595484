import { Playlist } from '@repo/types'
import { Container, Description, Heading, Panel, Spinner } from '@repo/ui'
import { observer } from 'mobx-react-lite'
import React from 'react'

import { Button } from '@/components/Button'

import { PlaylistEditor } from './PlaylistEditor'

interface PlaylistEditorContainerProps {
  baseUrl?: string
  error: Error | null
  isLoading: boolean
  onSubmit: (values: Playlist) => void
  playlist?: Playlist
}

export const PlaylistEditorContainer = observer(
  function PlaylistEditorContainer({
    baseUrl = '/',
    error,
    isLoading,
    onSubmit,
    playlist,
  }: PlaylistEditorContainerProps): React.ReactElement {
    if (isLoading || !playlist) return <Spinner />

    if (error) {
      return (
        <Container className='mt-8 p-6' size='small'>
          <Panel className='flex flex-col gap-8'>
            <Heading>Something went wrong</Heading>
            <Description className='mt-2'>{error.message}</Description>
            <Button text='Back' to='/' variant='anchor' />
          </Panel>
        </Container>
      )
    }

    return (
      <PlaylistEditor
        baseUrl={baseUrl}
        playlist={playlist}
        onSubmit={onSubmit}
      />
    )
  }
)
