import { observer } from 'mobx-react-lite'
import React, { useCallback } from 'react'
import styled, { css } from 'styled-components'

import { focusOutlineStyles } from '../StyledComponents'

export interface TabProps {
  id: string
  isActive: boolean
  isDisabled?: boolean
  onClick: (id: string) => void
  title: string | React.ReactElement
}

export const Tab = observer(function Tab({
  id,
  isActive,
  isDisabled,
  onClick,
  title,
}: TabProps): React.ReactElement {
  const openMenuWrapper = useCallback(() => {
    onClick(id)
  }, [id, onClick])

  return (
    <TabButton
      active={isActive}
      disabled={isDisabled}
      id={id}
      type='button'
      onClick={openMenuWrapper}
    >
      {title}
    </TabButton>
  )
})

interface ITabThemeProps {
  active?: boolean
}

const tab = ({ active }: ITabThemeProps) => css`
  color: var(--text-muted);
  border-bottom: 4px solid var(--bg-01);
  display: flex;
  padding: 0 calc(var(--spacing-base) / 2);
  overflow: hidden;
  font-size: 0.875em;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  background: transparent;
  height: var(--tab-height, 64px);
  min-width: var(--tab-width, 160px);
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  transition: all 0.3s ease-in-out;

  > span {
    margin-right: 10px;
  }

  --border-width: 4px;
  ${focusOutlineStyles}

  &:not(:disabled) {
    &:hover {
      background-color: var(--bg-01);
      cursor: pointer;
    }
  }

  ${active &&
  css`
    border-bottom-color: var(--primary-500);
    background: var(--bg-00);
    color: var(--text-hover);

    &:hover {
      background-color: var(--bg-04);
    }
  `}

  &:disabled {
    opacity: 0.66;

    &:hover {
      cursor: default;
    }
  }
`

const TabButton = styled.button`
  ${tab}
`
