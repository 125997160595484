import { ChevronLeftIcon, CloseIcon, SettingsIcon } from '@repo/ui'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { IconButton, IconButtonProps } from '../IconButton'
import { LocationState } from '../NavigationLink'

export const BackIcon = observer(function BackIcon(): React.ReactElement {
  return (
    <div className='flex items-center justify-center' style={{ marginLeft: -4 }}>
      <ChevronLeftIcon />
    </div>
  )
})

export const BackButton = observer(function BackButton(
  props: Partial<IconButtonProps>
): React.ReactElement {
  const history = useHistory()
  const location = useLocation<LocationState>()
  const from = location.state?.from

  const handleClick = () => {
    if (from) {
      history.push(from)
    } else {
      history.push('/')
    }
  }

  const onClick = props.url || props.onClick ? undefined : handleClick

  return <IconButton icon={<BackIcon />} title='Back' onClick={onClick} {...props} />
})

export const CloseButton = observer(function CloseButton(
  props: Partial<IconButtonProps>
): React.ReactElement {
  return <IconButton icon={<CloseIcon />} title='Close' {...props} />
})

export const SettingsButton = observer(function SettingsButton(
  props: Partial<IconButtonProps>
): React.ReactElement {
  return <IconButton icon={<SettingsIcon />} title='Settings' {...props} />
})
