import {
  ColourPalette,
  PlaybackOption,
  PlaylistType,
  SongKeys,
  SongMode,
} from '@repo/types'
import { z } from 'zod'

const ColourPaletteSchema = z.nativeEnum(ColourPalette)

const PlaybackOptionSchema = z.array(z.nativeEnum(PlaybackOption))

const VideoSchema = z.object({ url: z.string() })

const SongKeysSchema = z.nativeEnum(SongKeys)

const SongModeSchema = z.nativeEnum(SongMode)

const ISpotifyTrackMinimal = z.object({
  id: z.string(),
  uri: z.string(),
})

const ISpotifyFeatures = z.object({
  acousticness: z.number(),
  danceability: z.number(),
  energy: z.number(),
  id: z.string(),
  instrumentalness: z.number(),
  key: SongKeysSchema,
  loudness: z.number(),
  mode: SongModeSchema,
  tempo: z.number(),
  time_signature: z.number(),
  valence: z.number(),
})

const ISpotifyMeta = z.object({
  duration: z.number(),
  features: ISpotifyFeatures.optional(),
  startPosition: z.number().catch(0),
  track: ISpotifyTrackMinimal,
})

const UserSongMetaSchema = z.object({
  key: SongKeysSchema.optional(),
  notes: z.string().optional(),
  tempo: z.number().optional(),
})

export const SongSchema = z.object({
  album: z.string().optional(),
  artist: z.string().min(1, 'Artist is required'),
  id: z.number(),
  recentlyPlayed: z.boolean().optional(),
  spotifyMeta: ISpotifyMeta.optional(),
  title: z.string().min(1, 'Title is required'),
  userMeta: UserSongMetaSchema.optional(),
  video: VideoSchema.optional(),
})

export const SongSpreadsheetInputSchema = z.array(
  SongSchema.pick({
    artist: true,
    id: true,
    title: true,
  }).extend({
    artist: z.preprocess(
      (val) => String(val).trim(),
      z.string().min(1, 'Artist is required')
    ),
    title: z.preprocess(
      (val) => String(val).trim(),
      z.string().min(1, 'Title is required')
    ),
  })
)

const PlaylistTypeSchema = z.nativeEnum(PlaylistType)

export const PlaylistSchema = z.object({
  colour: ColourPaletteSchema.optional(),
  disableShuffle: z.boolean().optional(),
  image: z
    .object({
      height: z.number().catch(640),
      url: z.string(),
      width: z.number().catch(640),
    })
    .optional(),
  playbackOptions: PlaybackOptionSchema,
  songs: z.array(SongSchema),
  title: z.string(),
  type: PlaylistTypeSchema,
  url: z.string().optional(),
  video: VideoSchema.optional(),
  videoPreGame: VideoSchema.optional(),
})

export type PlaylistInput = z.infer<typeof PlaylistSchema>
