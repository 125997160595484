import { Description, Heading } from '@repo/ui'
import { observer } from 'mobx-react-lite'
import React from 'react'

import { Button } from '@/components/Button'
import { List } from '@/components/List'
import { Timestamp } from '@/firebase/firebase'

import { useGameContext } from '../pages/game/context/game-context'
import { useGameControlsContext } from '../pages/game/context/game-controls-context'

interface ICalledBingoProps {
  hideControls?: boolean
}

export const CalledBingos = observer(function CalledBingos({
  hideControls,
}: ICalledBingoProps): React.ReactElement | null {
  const { gameData, sortedCalledBingos } = useGameContext()
  const { handleVerifyBingoClick, isVerifying } = useGameControlsContext()

  if (!gameData || !sortedCalledBingos.length) return null

  return (
    <div className='rounded-lg bg-orange-500 p-6 text-black'>
      <Heading>Bingo Called By:</Heading>

      <List className='mt-4 overflow-hidden bg-transparent dark:bg-transparent'>
        {sortedCalledBingos.map(({ playerId, playerName, timestamp }) => (
          <List.Item
            actions={
              !hideControls && (
                <Button
                  className='min-w-[140px]'
                  disabled={isVerifying}
                  text='Verify Bingo'
                  onClick={() => handleVerifyBingoClick(playerId)}
                />
              )
            }
            className='flex border-transparent bg-transparent hover:bg-orange-600 dark:border-transparent dark:bg-transparent dark:hover:bg-orange-600'
            key={playerId}
          >
            <div>
              <Heading>{playerName}</Heading>

              <Description className='text-orange-900'>
                {formatTimestamp(timestamp)}
              </Description>
            </div>
          </List.Item>
        ))}
      </List>
    </div>
  )
})

function formatTimestamp(timestamp: Timestamp) {
  const string = timestamp.toDate().toLocaleDateString('en-US', {
    hour: '2-digit',
    minute: 'numeric',
    second: 'numeric',
  })

  const parts = string.split(', ')
  const [, time] = parts

  return time
}
