import { Game } from '@repo/types'
import { Badge, Heading } from '@repo/ui'
import { observer } from 'mobx-react-lite'
import React from 'react'

import { groupBy } from '@/utils/group-by'

import { GameListByHost } from './game-list-by-host'

interface GamesListByDateProps {
  games: Game[]
  showHostHeader?: boolean
}

export const GameListByDate = observer(function GameListByDate({
  games,
  showHostHeader = true,
}: GamesListByDateProps): React.ReactElement {
  const gamesWithDay = games.map((game) => {
    const gameDateDay = new Date(game.timestamp.toMillis()).setHours(0, 0, 0, 0)
    return {
      ...game,
      gameDateDay,
    }
  })

  const groupedByTimestamp = groupBy<Game>('gameDateDay', gamesWithDay)

  const gamesByDate = Object.entries(groupedByTimestamp).sort((a, b) =>
    a < b ? 1 : -1
  )

  if (gamesByDate.length === 0) return <></>

  const options = {
    day: 'numeric',
    month: 'short',
    weekday: 'short',
    year: 'numeric',
  } as Intl.DateTimeFormatOptions

  const formatDate = (date: string) =>
    new Intl.DateTimeFormat('en-us', options).format(new Date(parseInt(date)))

  return (
    <>
      {gamesByDate.map(([date, games], index) => (
        <div className='flex flex-col gap-4' key={date}>
          <Heading className='mt-8 flex gap-2'>
            <Badge colour='blue'>{games.length}</Badge>
            <span>{formatDate(date)}</span>
          </Heading>

          <GameListByHost
            games={games}
            key={index}
            showHostHeader={showHostHeader}
          />
        </div>
      ))}
    </>
  )
})
