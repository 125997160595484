import { Collections, Game, GameStatus } from '@repo/types'
import { Spinner, Container } from '@repo/ui'
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useEffect, useState } from 'react'

import { databaseRef } from '@/firebase/firebase'

import { RecentGames } from './components/recent-games'
import { AppHeader } from '../components/AppHeader'
import { Main } from '../components/Main'
import { Page } from '../components/Page'
import { PageHeading } from '../components/PageHeading'
import { transformGameData } from '../store/game/GameStore'

export const AdminBrowseGamesRoute = observer(
  function AdminBrowseGamesRoute(): React.ReactElement {
    const [loading, setLoading] = useState(false)
    const [activeGamesList, setActiveGamesList] = useState<Game[]>([])

    const fetchGames = useCallback(() => {
      setLoading(true)

      const hoursInMilliseconds = 8 * 60 * 60 * 1000
      const startDate = Date.now() - hoursInMilliseconds
      const startDateObject = new Date(startDate)

      const gamesRef = collection(databaseRef, Collections.GAMES)
      const docsQuery = query(
        gamesRef,
        where('lastActivity', '>=', startDateObject),
        orderBy('lastActivity', 'desc')
      )

      onSnapshot(docsQuery, (snapshot) => {
        if (snapshot.empty) {
          console.log('No active games found')
          setActiveGamesList([])
        } else {
          const gamesList = snapshot.docs.map((gameDoc) =>
            transformGameData(gameDoc.data() as Game)
          )

          setActiveGamesList(
            gamesList
              .sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1))
              .filter(({ playerCount }) => playerCount > 1)
          )
        }

        setLoading(false)
      })
    }, [])

    useEffect(() => {
      void fetchGames()
    }, [fetchGames])

    if (loading) return <Spinner />

    const activeGamesListDisplay = activeGamesList.filter(
      (game) =>
        Date.now() - game.lastActivity.toMillis() <= 900000 &&
        game.gameStatus !== GameStatus.GAME_COMPLETED
    )

    const recentlyActiveGamesListDisplay = activeGamesList.filter(
      (game) =>
        Date.now() - game.lastActivity.toMillis() >= 900000 &&
        game.gameStatus !== GameStatus.GAME_COMPLETED
    )

    const recentlyCompletedGamesListDisplay = activeGamesList.filter(
      (game) => game.gameStatus === GameStatus.GAME_COMPLETED
    )

    return (
      <Page title='Active Games'>
        <AppHeader header={<PageHeading>Active Games</PageHeading>} />

        <Main>
          <Container size='large'>
            <RecentGames
              activeGamesListDisplay={activeGamesListDisplay}
              recentlyActiveGamesListDisplay={recentlyActiveGamesListDisplay}
              recentlyCompletedGamesListDisplay={
                recentlyCompletedGamesListDisplay
              }
            />
          </Container>
        </Main>
      </Page>
    )
  }
)
