import { Game } from '@repo/types'
import { ToggleSwitch } from '@repo/ui'
import { observer } from 'mobx-react-lite'
import React from 'react'

import { useGameContext } from '@/pages/game/context/game-context'
import { updateGameSetting } from '@/store/game/updateGameDocument'

import { SettingsRow } from '../SettingsRow'

const SETTING_NAME = 'QR Code'
const SETTING_ID = 'jumbotron.enableQRCode' as const

export const EnableJumbotronQRCode = observer(
  function EnableJumbotronQRCode(): React.ReactElement {
    const { gameData, gameId, tracker } = useGameContext()

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target

      const update: Pick<Game, typeof SETTING_ID> = {
        [SETTING_ID]: checked,
      }

      void updateGameSetting(gameId, update)
      tracker.changeToggle(`Jumbotron ${SETTING_NAME}`, checked)
    }

    return (
      <SettingsRow
        description='Show a QR code linking to the join game screen.'
        heading={SETTING_NAME}
      >
        <ToggleSwitch
          id={SETTING_ID}
          isChecked={gameData.jumbotron.enableQRCode}
          onChange={handleChange}
        />
      </SettingsRow>
    )
  }
)
