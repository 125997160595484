import { observer } from 'mobx-react-lite'
import React from 'react'
import { Redirect, Route } from 'react-router-dom'

import StoreService from '../store/StoreService'

export interface IProtectedRouteProps {
  component: React.ElementType
  exact?: boolean
  path: string | string[]
  shouldRedirectToNoAccount?: boolean
}

export const ProtectedRoute = observer(function ProtectedRoute({
  component: Component,
  shouldRedirectToNoAccount = true,
  ...rest
}: IProtectedRouteProps): React.ReactElement {
  const { user } = StoreService.getStore()
  const { hasAccount, isAnonymous, isLoggedIn } = user

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isLoggedIn || isAnonymous) {
          return (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          )
        }

        if (!hasAccount && shouldRedirectToNoAccount) {
          return (
            <Redirect
              to={{
                pathname: '/no-account',
                state: { from: props.location },
              }}
            />
          )
        }

        return <Component {...props} />
      }}
    />
  )
})
