import { observer } from 'mobx-react-lite'
import React from 'react'

import { CreatePlaylistView } from './create-playlist-view'

export const CreatePlaylistRoute = observer(
  function CreatePlaylistRoute(): React.ReactElement {
    return <CreatePlaylistView />
  }
)

export const CreatePlaylistRouteAdmin = observer(
  function CreatePlaylistRouteAdmin(): React.ReactElement {
    return <CreatePlaylistView baseUrl='/admin/' />
  }
)
