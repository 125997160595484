import { Song } from '@repo/types'

import { isSongWithAlbum } from './isSongWithAlbum'
import { normalizedCompare } from './normalizedCompare'

export function isAlbumMatch(song: Song, playlistSong: Song) {
  const hasAlbumData = isSongWithAlbum(song) && isSongWithAlbum(playlistSong)

  if (!hasAlbumData) return false

  return normalizedCompare(song.album, playlistSong.album)
}
