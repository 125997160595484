import { useQuery } from 'react-query'

import { useGameContext } from '@/pages/game/context/game-context'

import { useSpotify } from '../useSpotify'

export function useSyncExternalSpotifyPlayback() {
  const { gameData } = useGameContext()
  const { devices, playback } = useSpotify()

  const { activeDevice, isInternalPlayerInAnotherTab } = devices

  const { fn, key } = playback.query

  const getIsEnabled = () => {
    if (!activeDevice) return false

    if (isInternalPlayerInAnotherTab) return true

    return activeDevice.isExternal
  }

  const getTimeSinceLastActivity = () => {
    if (!gameData.lastActivity) return 0

    return Date.now() - gameData.lastActivity.toMillis()
  }

  return useQuery(key, fn, {
    enabled: getIsEnabled(),
    refetchInterval(data) {
      if (!gameData.gameStarted) {
        return false
      }

      // If the data indicates that music is currently playing, set a longer interval
      if (data?.isPlaying) {
        return 5000
      }

      // Calculate the time difference between now and the last activity in milliseconds
      const timeSinceLastActivity = getTimeSinceLastActivity()

      // If the last activity was within the last 5 seconds, set a shorter refetch interval
      if (timeSinceLastActivity <= 5000) {
        return 1000
      }

      // Otherwise, disable automatic refetching
      return false
    },
    refetchOnWindowFocus: true,
  })
}
