import { Song } from '@repo/types'

export function isDuplicateBySpotifyId(song: Song, playlistSongs: Song[]) {
  const trackId = song.spotifyMeta?.track.id

  if (!trackId) return false

  return playlistSongs.some(
    ({ spotifyMeta }) => spotifyMeta?.track.id === trackId
  )
}
