import { observer } from 'mobx-react-lite'
import React from 'react'

import {
  InvalidPasswordReset,
  VerifyPasswordResetForm,
} from '@/components/forms/VerifyPasswordResetForm'
import { useSearchParams } from '@/utils/use-search-params'


export const VerifyPasswordRoute = observer(
  function VerifyPasswordRoute(): React.ReactElement {
    const query = useSearchParams()
    const code = query.get('oobCode')

    if (!code) return <InvalidPasswordReset />

    return <VerifyPasswordResetForm code={code} />
  }
)
