import { Pattern } from '@repo/types'
import { TargetDisplay } from '@repo/ui'
import { Dialog } from '@repo/ui/client'

interface BingoTargetDialogProps {
  currentTarget: Pattern
}

export function BingoTargetDialog({ currentTarget }: BingoTargetDialogProps) {
  return (
    <Dialog size='small'>
      <Dialog.Body>
        <div className='text-center'>
          <p>
            Updating guests about the new Bingo Target. This message will
            dismiss in a moment.
          </p>
          <div className='flex flex-col justify-center'>
            <TargetDisplay pattern={currentTarget} />
          </div>
        </div>
      </Dialog.Body>
    </Dialog>
  )
}
