import { isErrorWithCode } from '@repo/lib'

export function getFirebaseAuthErrorMessage(error: unknown): string {
  const defaultMessage = 'Sorry, something went wrong'

  if (!isErrorWithCode(error)) return defaultMessage

  const { code } = error

  if (code === 'auth/email-already-in-use') {
    return 'Sorry, an account using this email already exists'
  }

  if (code === 'auth/wrong-password') {
    return 'Sorry, that password is incorrect'
  }

  if (code === 'auth/user-not-found') {
    return "Sorry, we couldn't find an account with that email address. Did you mean to create a new account?"
  }

  return defaultMessage
}
