import { Playlist } from '@repo/types'

import { apiClient } from '@/api/api-client'
import StoreService from '@/store/StoreService'

import { useUpdatePlaylist } from '../useUpdatePlaylist'

export function useUpdateUserPlaylist({ playlistId }: { playlistId: string }) {
  const { playlistService } = StoreService.getStore()

  const { queryFn: itemQueryFn, queryKey: itemQueryKey } =
    playlistService.queries.userPlaylist

  const { queryFn: listQueryFn, queryKey: listQueryKey } =
    playlistService.queries.userPlaylists

  const mutationFn = (playlist: Playlist) =>
    apiClient.userUpdatePlaylist(playlist)

  return useUpdatePlaylist(
    mutationFn,
    itemQueryKey(playlistId),
    () => itemQueryFn(playlistId),
    listQueryKey,
    listQueryFn
  )
}
