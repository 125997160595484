import { PlaybackOption } from '@repo/types'
import { Container, Panel } from '@repo/ui'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { AppHeader } from '@/components/AppHeader'
import { Button } from '@/components/Button'
import { BackButton, CloseButton } from '@/components/icon-buttons/IconButtons'
import { List } from '@/components/List'
import { Main } from '@/components/Main'
import { Nav } from '@/components/Nav'
import { Page } from '@/components/Page'
import { PageHeading } from '@/components/PageHeading'
import { Title } from '@/components/Title'
import { useGameContext } from '@/pages/game/context/game-context'
import { ColourSettings } from '@/pages/settings/components/ColourSettings'
import { JumbotronLink } from '@/pages/settings/components/JumbotronLink'
import { SidekicksLink } from '@/pages/settings/components/SidekicksLink'

export const GameSettingsRoute = observer(
  function GameSettingsRoute(): React.ReactElement {
    return <GameSettingsPage />
  }
)

interface SettingsParams {
  setup: string
}

const GameSettingsPage = observer(function GameSettings(): React.ReactElement {
  const { gameId } = useGameContext()
  const history = useHistory()
  const { setup } = useParams<SettingsParams>()

  const handleBackClick = () => {
    if (gameId) {
      history.push(`/game`)
    } else {
      history.push(`/`)
    }
  }

  return (
    <Page title='Game Settings'>
      <AppHeader
        actionsLeft={<BackButton onClick={handleBackClick} />}
        actionsRight={<CloseButton onClick={handleBackClick} />}
        header={<PageHeading>Game Settings</PageHeading>}
      />

      <Main>
        <Container className='flex flex-col gap-8' size='medium'>
          <GameSettingsToggles />
        </Container>

        <Container className='my-8' size='medium'>
          <Button
            text={setup ? 'Continue' : 'Done'}
            onClick={
              setup
                ? () => history.push(`/game/lobby/setup`)
                : () => history.push(`/game`)
            }
          />
        </Container>
      </Main>
    </Page>
  )
})

export const GameSettingsToggles = observer(function GameSettingsToggles() {
  const { gameData } = useGameContext()

  return (
    <>
      <Panel className='bg-shade-0' disablePadding>
        <SidekicksLink />
        <JumbotronLink />
      </Panel>

      <Nav>
        <List>
          {gameData.type === 'music' &&
            gameData.playbackSource !== PlaybackOption.NONE && (
              <List.Item to='/game/settings/playback' showChevron>
                <Title
                  description='Manage your connection to an external music playback service.'
                  heading='Playback'
                />
              </List.Item>
            )}

          <List.Item to='/game/settings/gameplay' showChevron>
            <Title
              description='Manage how guests will experience the game.'
              heading='Gameplay'
            />
          </List.Item>

          <>
            <List.Item to='/game/settings/jumbotron' showChevron>
              <Title
                description='Customize a page intended as a large video screen display.'
                heading='Jumbotron'
              />
            </List.Item>

            <List.Item to='/game/settings/branding' showChevron>
              <Title
                description='Customize branding elements for this specific game.'
                heading='Branding'
              />
            </List.Item>

            <List.Item to='/game/settings/venue' showChevron>
              <Title
                description="Add information about the location you're hosting this game at."
                heading='Venue'
              />
            </List.Item>

            <List.Item to='/game/settings/account' showChevron>
              <Title
                description='Manage your account details.'
                heading='Account'
              />
            </List.Item>
          </>
        </List>
      </Nav>

      <ColourSettings />
    </>
  )
})
