import { CommonProps } from '@repo/types'
import { observer } from 'mobx-react-lite'

import { NetworkConnectivityWarning } from './NetworkConnectivityWarning'
import { useConnectivity } from '../hooks/useConnectivity'

export const WithNetworkConnectivity = observer(
  function WithNetworkConnectivity({ children }: CommonProps) {
    const { isOnline } = useConnectivity()

    if (!isOnline) return <NetworkConnectivityWarning />

    return children
  }
)
