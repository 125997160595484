import { Timestamp } from 'firebase/firestore'

export function formatDate(timestamp: Timestamp): string {
  const dateTimestamp = timestamp.toDate()
  const date = new Date(dateTimestamp)

  const options = {
    dateStyle: 'full',
    timeStyle: 'short',
  } as Intl.DateTimeFormatOptions

  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date)

  return formattedDate
}
