import { observer } from 'mobx-react-lite'
import React from 'react'

interface VimeoEmbedProps {
  url: string
}

export const VimeoEmbed = observer(function VimeoEmbed({
  url,
}: VimeoEmbedProps): React.ReactElement | null {
  const getVideoId = (url: string): string => {
    const regExp = /(vimeo(pro)?\.com)\/(?:[^\d]+)?(\d+)\??(.*)?$/
    const match = regExp.exec(url)

    if (match && match[3]) {
      return match[3]
    } else {
      return ''
    }
  }

  const videoId = getVideoId(url)

  if (!videoId) return null

  return (
    <iframe
      src={`https://player.vimeo.com/video/${videoId}?&autoplay=1&muted=1&autopause=0&loop=1`}
    />
  )
})
