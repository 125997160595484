import { Playlist } from '@repo/types'
import { FastField, FieldProps } from 'formik'
import { observer } from 'mobx-react-lite'
import React from 'react'
import MaskedInput from 'react-text-mask'

export function formatTime(val?: number | string): string {
  if (!val) return ''
  if (typeof val === 'string') return val

  const min = Math.floor(val / 60000)
  const sec = Math.floor((val / 1000) % 60)
  return `${min}:${`0${sec}`.slice(-2)}`
}

interface StartPositionFieldProps {
  duration: number
}

export const StartPositionField = React.memo(
  observer(function StartPositionField({ duration }: StartPositionFieldProps) {
    function parse(val: unknown): number | string {
      if (typeof val !== 'string') return 0
      if (!/[0-5]?\d:[0-5]\d/.test(val)) return val

      const [min, sec] = val.split(':').map((n) => parseInt(n) || 0)
      return (min * 60 + (sec || 0)) * 1000
    }

    function getMask(input: string): (RegExp | string)[] {
      if (input[1] === ':' || input[0] > '5') return [/\d/, ':', /[0-5]/, /\d/]
      if (/[0-5]\d/.test(input)) return [/[0-5]/, /\d/, ':', /[0-5]/, /\d/]
      return [/\d/, /\d/]
    }

    function validate(val: number | string): string | void {
      if (!val) return
      if (typeof val === 'string') return 'Enter a valid start position (mm:ss)'
      if (duration && duration < val)
        return `Start position exceeds song duration (${formatTime(duration)})`
    }

    return (
      <FastField<number | string>
        format={formatTime}
        name={`spotifyMeta.startPosition`}
        parse={parse}
        validate={validate}
      >
        {/* @ts-expect-error Formik */}
        {({
          field,
          meta: { error, touched },
        }: FieldProps<string, Playlist>) => (
          <>
            <MaskedInput
              {...field}
              mask={getMask}
              placeholder='00:00'
              placeholderChar=' '
            />
            {touched && error && (
              <div className='rounded-sm bg-red-500 p-2 text-xs text-white'>
                {error}
              </div>
            )}
          </>
        )}
      </FastField>
    )
  })
)
