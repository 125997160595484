import { ListItemProps, ListProps, List as WrappedList } from '@repo/ui'

import { NavigationLink } from './NavigationLink'

interface LinkAdapter {
  children: React.ReactNode
  href: string
}

function LinkAdapter({ children, ...props }: LinkAdapter) {
  return (
    <NavigationLink {...props} to={props.href}>
      {children}
    </NavigationLink>
  )
}

export function List(props: ListProps) {
  return <WrappedList {...props} />
}

List.Item = function ListItem(props: ListItemProps) {
  return <WrappedList.Item LinkComponent={LinkAdapter} {...props} />
}
