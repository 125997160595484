import { Container, Description, Spinner } from '@repo/ui'
import { observer } from 'mobx-react-lite'
import React from 'react'

import { CardGridCategory } from '@/components/CardGridCategory'
import { MINIMUM_ITEM_COUNT } from '@/utils/constants'

import { ConnectSpotifyCard } from './ConnectSpotifyCard'
import { SpotifyProfileBadge } from './SpotifyProfileBadge'
import { useQuerySpotifyPlaylists } from '../queries/useQuerySpotifyPlaylists'
import { useSpotify } from '../useSpotify'

interface ISpotifyCardGridProps {
  handleClick: (playlistId: string) => void
}

export const SpotifyCardGrid = observer(function SpotifyCardGrid({
  handleClick,
}: ISpotifyCardGridProps): React.ReactElement | null {
  const { auth } = useSpotify()

  const { data = [], isLoading } = useQuerySpotifyPlaylists()

  const { isAuthenticated } = auth

  if (!isAuthenticated) {
    return (
      <Container className='mt-8' size='small'>
        <ConnectSpotifyCard />
      </Container>
    )
  }

  if (isLoading) return <Spinner label='Loading your Spotify playlists...' />

  return (
    <CardGridCategory
      description={
        <div className='flex justify-between gap-4'>
          <Description>
            Playlists in your Spotify library with{' '}
            <strong>at least {MINIMUM_ITEM_COUNT}</strong> songs.
          </Description>
        </div>
      }
      handleClick={handleClick}
      heading='Your Spotify Playlists'
      headingRight={<SpotifyProfileBadge className='items-end' size='small' />}
      items={data}
    />
  )
})
