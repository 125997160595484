import { Playlist } from '@repo/types'
import { Button, Spinner } from '@repo/ui'
import { Dialog } from '@repo/ui/client'

import { PlaylistPreview } from '../components/PlaylistPreview'

interface PlaylistPreviewModalProps {
  isLoading: boolean
  isVisible: boolean
  onClickCreateGame: () => void
  onClose: () => void
  playlist: Playlist | null
}

export function PlaylistPreviewModal({
  isLoading,
  isVisible,
  onClickCreateGame,
  onClose,
  playlist,
}: PlaylistPreviewModalProps) {
  return (
    <Dialog className='max-h-[clamp(240px,90vh,680px)]' isVisible={isVisible}>
      <Dialog.Header>
        <Dialog.Title>Preview: {playlist?.title}</Dialog.Title>
        <Dialog.CloseButton onClick={onClose} />
      </Dialog.Header>
      <Dialog.Separator />
      <Dialog.Body className='pt-0'>
        {isLoading && <Spinner label='Loading playlist...' />}

        {!isLoading && playlist && <PlaylistPreview playlist={playlist} />}
      </Dialog.Body>
      <Dialog.Footer>
        <Button
          disabled={isLoading}
          text='Create Game'
          onClick={() => void onClickCreateGame()}
        />
      </Dialog.Footer>
    </Dialog>
  )
}
