import { useQuery } from 'react-query'

import StoreService from '@/store/StoreService'

export function useQueryUserPlaylistPublic(
  hostIdOrCustomUrl: string,
  playlistId: string
) {
  const { playlistService } = StoreService.getStore()
  const { queryFn, queryKey } = playlistService.queries.userPlaylistPublic

  return useQuery(queryKey(hostIdOrCustomUrl, playlistId), () =>
    queryFn(hostIdOrCustomUrl, playlistId)
  )
}
