import { Timestamp } from './firebase'
import { UserSettings } from './host'
import { PlaybackOption, Playlist, Song } from './playlist'

export enum Column {
  'B',
  'I',
  'N',
  'G',
  'O',
}

export interface Card {
  [Column.B]: Square[]
  [Column.I]: Square[]
  [Column.N]: Square[]
  [Column.G]: Square[]
  [Column.O]: Square[]
}

export interface SquareBase {
  correct?: boolean
  correctMissed?: boolean
  daubed?: boolean
  id: number
  incorrect?: boolean
}

export interface SquareNumbers extends SquareBase {
  type: 'numbers'
}

export interface SquareMusic extends SquareBase {
  artist: string
  disableArtist?: boolean
  title: string
  type: 'music'
}

export interface SquareFree extends SquareBase {
  daubed: true
  id: -1
  title: 'FREE'
  type: 'free'
}

export type Square = SquareMusic | SquareNumbers | SquareFree

export enum BingoResult {
  CONFIRMED = 'confirmed',
  DENIED = 'denied',
}

export interface ConfirmedBingo extends CalledBingo {
  [key: string]: unknown
  correctLines: { [key: number]: Square[] }
  pattern: Pattern
}

export interface DeniedBingo extends CalledBingo {
  pattern: Pattern
}

export interface CalledBingo {
  playerId: string
  playerName: string
  timestamp: Timestamp
}

export interface DrawnItem {
  column: Column
  id: number
}

export interface DrawnSong extends DrawnItem {
  song: Song
}

export type GameType = 'music' | 'numbers'

export interface BaseOptions {
  type: GameType
}

export interface MusicOptions extends BaseOptions {
  playlist: Playlist
  type: 'music'
}

export interface NumbersOptions extends BaseOptions {
  type: 'numbers'
}

export type CreateGameOptions = MusicOptions | NumbersOptions

export interface GameBase {
  [key: string]: unknown
  bingoCount: number
  currentItemIndex: number | null
  currentTarget: Pattern
  disableSystemBingoVerification: boolean
  enableScoreboard: boolean
  gameCode: string
  gameId: string
  gameStarted: Timestamp | null
  gameStatus: GameStatus
  highlightColumns: boolean
  hostEmail: string
  hostId: string
  jumbotron: Jumbotron
  lastActivity: Timestamp
  playerCount: number
  playerCountFinal: number
  requestContactDetails: boolean
  timestamp: Timestamp
  userSettings?: UserSettings
  venueId?: string
  venueName?: string
}

export interface GameMusicProperties {
  disableArtist: boolean
  drawnItems: DrawnSong[]
  playbackSource: PlaybackOption | null
  possibleSongs: Song[]
  sourcePlaylistMeta: Playlist
  type: 'music'
}

export interface GameMusic extends GameMusicProperties, GameBase {}

export interface GameNumbersProperties {
  drawnItems: DrawnItem[]
  type: 'numbers'
}

export interface GameNumbers extends GameNumbersProperties, GameBase {}

export type Game = GameMusic | GameNumbers

export interface Jumbotron {
  enableBrandImage: boolean
  enableCurrentLetter: boolean
  enableGameCode: boolean
  enablePreviousSongs: boolean
  enableQRCode: boolean
  enableQRCodeFullscreen: boolean
}

export enum GameStatus {
  GAME_CREATED = 'game_created',
  GAME_STARTED = 'game_started',
  GAME_COMPLETED = 'game_completed',
}

export enum MessageType {
  BINGO_TARGET = 'bingo_target',
  CONFIRMED_BINGO = 'confirmed_bingo',
  CONTACT_DETAILS_REQUEST = 'contact_details_request',
  DENIED_BINGO = 'denied_bingo',
  SCOREBOARD = 'scoreboard',
}

export interface MessageEvent {
  dismissed: boolean
  message: Message
  messageId: string
  timestamp: Timestamp
}

export type Message =
  | { data: Player; type: MessageType.CONFIRMED_BINGO }
  | { data: Player; type: MessageType.DENIED_BINGO }
  | { data: Scoreboard; type: MessageType.SCOREBOARD }
  | { data: Pattern; type: MessageType.BINGO_TARGET }
  | { data: Player; type: MessageType.CONTACT_DETAILS_REQUEST }

export enum Pattern {
  ONE_LINE = 'one_line',
  TWO_LINES = 'two_lines',
  THREE_LINES = 'three_lines',
  FOUR_LINES = 'four_lines',
  FULL_CARD = 'full_card',
  CORNERS = 'corners',
  EDGES = 'edges',
  X_SHAPE = 'x_shape',
}

interface PlayerBase {
  contactDetails?: 'submitted' | 'declined'
  hasLeftGame?: boolean
  inLobby?: boolean
  isRemoved?: boolean
  joinedLobby?: Timestamp
  playerCard?: Card
  playerId: string
  playerName: string
}

export interface PlayerInLobby extends PlayerBase {
  hasLeftGame?: never
  inLobby: true
  isRemoved?: never
  joinedLobby: Timestamp
  playerCard?: undefined
}

export interface PlayerInGame extends PlayerBase {
  inLobby: false
  playerCard: Card
}

export interface PlayerLeftGame extends PlayerBase {
  hasLeftGame: true
  inLobby: false
  playerCard?: Card
}

export interface PlayerRemoved extends PlayerBase {
  isRemoved: true
  playerCard: Card
}

export interface PlayerWithCorrectCount extends PlayerInGame {
  correctCount: number
}

export type Player =
  | PlayerInGame
  | PlayerInLobby
  | PlayerLeftGame
  | PlayerRemoved

export function isPlayerInGame(player: Player): player is PlayerInGame {
  if (!player.playerCard) return false
  return !player.inLobby && !player.hasLeftGame && !player.isRemoved
}

export interface PlayerScore {
  [key: string]: string | number | boolean
  bingoCount: number
  playerId: string
  playerName: string
}

export interface PlayerScoreRanked extends PlayerScore {
  rank: number
  rankTied: boolean
}

export interface Scoreboard {
  currentScores: PlayerScoreRanked[]
  previousScores: PlayerScoreRanked[]
  timestamp: Timestamp
}
