import { Container } from '@repo/ui'
import { observer } from 'mobx-react-lite'
import React from 'react'

interface IAppHeaderProps {
  actionsLeft?: React.ReactElement
  actionsRight?: React.ReactElement
  header?: React.ReactElement
  secondaryHeader?: React.ReactElement
  setRef?: (node: HTMLElement | null) => void
}

export const AppHeader = observer(function AppHeader({
  actionsLeft,
  actionsRight,
  header,
  secondaryHeader,
  setRef,
}: IAppHeaderProps): React.ReactElement {
  const showActions = actionsLeft || actionsRight

  return (
    <header
      className='sticky top-0 z-50 flex items-center border-b border-shade-3 bg-shade-1'
      ref={setRef}
    >
      <Container className='px-4 py-0' size='extra-large'>
        <div className='flex h-16 w-full items-center justify-center gap-1'>
          {showActions && (
            <div className='flex items-center justify-start'>{actionsLeft}</div>
          )}

          <div className='flex h-16 flex-1 items-center overflow-hidden md:absolute md:w-2/3 md:max-w-sm md:justify-center md:text-center'>
            {header}
          </div>

          {showActions && (
            <div className='ml-auto flex items-center justify-end justify-items-end gap-1'>
              {actionsRight}
            </div>
          )}
        </div>

        {secondaryHeader}
      </Container>
    </header>
  )
})
