import { Song } from '@repo/types'

import { isAlbumMatch } from './isAlbumMatch'
import { normalizedCompare } from './normalizedCompare'

export function isDuplicateByMetadata(song: Song, playlistSong: Song) {
  const isTitleMatch = normalizedCompare(song.title, playlistSong.title)
  const isArtistMatch = normalizedCompare(song.artist, playlistSong.artist)

  return {
    isAlbumMatch: isAlbumMatch(song, playlistSong),
    isTitleAndArtistMatch: isTitleMatch && isArtistMatch,
  }
}
