import { ButtonVariant, CheckIcon } from '@repo/ui'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import React, { SyntheticEvent, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { Button } from '@/components/Button'

interface CopyLinkProps {
  buttonVariant?: ButtonVariant
  hideInput?: boolean
  link: string
  onClickCopy?: () => void
  text: string
}

export const CopyLink = observer(function CopyLink({
  buttonVariant = 'filled',
  hideInput = false,
  link,
  onClickCopy,
  text
}: CopyLinkProps): React.ReactElement {
  const [copySuccess, setCopySuccess] = useState(false)
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null)

  const copyToClipboard = (event: SyntheticEvent) => {
    textAreaRef?.current?.select()
    document.execCommand('copy')
    ;(event.target as HTMLTextAreaElement).focus()
  }

  const handleClickCopy = (event: SyntheticEvent) => {
    copyToClipboard(event)
    setCopySuccess(true)

    onClickCopy?.()
  }

  useEffect(() => {
    let timer: number

    if (copySuccess) {
      timer = window.setTimeout(() => {
        setCopySuccess(false)
      }, 1500)
    }

    return () => {
      if (timer) {
        clearTimeout(timer)
      }
    }
  }, [copySuccess])

  return (
    <CopyLinkStyled className={classNames({ 'hide-input': hideInput })}>
      {/* eslint-disable-next-line tailwindcss/no-custom-classname*/}
      <div className='input-container'>
        <form>
          <textarea className='input' ref={textAreaRef} value={link} readOnly />
        </form>
      </div>
      {document.queryCommandSupported('copy') && (
        // eslint-disable-next-line tailwindcss/no-custom-classname
        <div className='copy-success-container'>
          {!copySuccess && <Button text={text} variant={buttonVariant} onClick={handleClickCopy} />}
          {copySuccess && (
            <CopySuccessStyled>
              <CheckIcon />
              Copied!
            </CopySuccessStyled>
          )}
        </div>
      )}
    </CopyLinkStyled>
  )
})

const CopyLinkStyled = styled.div`
  &:not(.hide-input) {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    grid-gap: 5px;
    max-width: 100%;
  }

  overflow: hidden;
  color: #fff;

  &.hide-input {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 180px;

    .input-container {
      opacity: 0;
      width: 0;
      height: 0;
      position: absolute;
    }

    .copy-success-container {
      width: 100%;
    }
  }

  form {
    width: 100%;
    max-width: 100%;
    min-height: 48px;
  }

  textarea {
    width: 100%;
    min-height: 48px;
    white-space: nowrap;
    padding: 11px;
    resize: none;
    height: 48px;
    padding: 16px;
    line-height: 1;
    overflow: hidden;
  }
`

const CopySuccessStyled = styled.div`
  text-transform: uppercase;
  font-size: 0.75em;
  font-weight: 800;
  letter-spacing: 0.66px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--green-500);
  padding: 10px;
  height: 48px;
  border-radius: 4px;
  width: 100%;

  svg {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }
`
