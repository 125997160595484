import { Panel, Heading, Container, Description } from '@repo/ui'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { Redirect, useHistory } from 'react-router-dom'

import { Button } from '@/components/Button'
import { LogoHeader } from '@/components/LogoHeader'
import { Main } from '@/components/Main'
import { Page } from '@/components/Page'
import { TermsOfService } from '@/components/TermsOfService'
import StoreService from '@/store/StoreService'
import { useSearchParams } from '@/utils/use-search-params'

export const NoAccountRoute = observer(
  function NoAccountRoute(): React.ReactElement {
    const { user } = StoreService.getStore()
    const history = useHistory()
    const query = useSearchParams()
    const email = query.get('email') ?? user?.email
    const provider = query.get('provider') ?? user?.signInProvider
    const hasEmail = Boolean(email)

    if (user.hasAccount) return <Redirect to='/' />

    return (
      <Page title='Account Setup Incomplete'>
        <Main>
          <LogoHeader />

          <Container size='small'>
            <Panel className='flex flex-col gap-8'>
              <div className='flex flex-col gap-6'>
                <div>
                  <Heading size='large'>Account Setup Incomplete</Heading>

                  {hasEmail && (
                    <div className='mt-6'>
                      <p className='text-muted'>
                        You{`'`}re logged in with this email:
                      </p>
                      <p className='mt-0 text-lg'>
                        {email} <br />
                        <span className='text-sm text-muted'>
                          via {provider}
                        </span>
                      </p>
                      <p className='mt-6 text-muted'>
                        You may have meant to use a different account, or you
                        haven{`'`}t finished the setup process.
                      </p>
                    </div>
                  )}

                  {!hasEmail && (
                    <p className='text-muted'>
                      Sorry, we couldn{`'`}t find an account for you in our
                      system.
                    </p>
                  )}
                </div>

                <Button
                  text='Back to Login'
                  variant='anchor'
                  onClick={async () => {
                    await user.signOut()
                    history.push('/')
                  }}
                />
              </div>

              <div className='flex flex-col gap-6 border-t border-t-shade-3 pt-6'>
                <div>
                  <Heading>New to Rockstar Bingo?</Heading>
                  <Description className='mt-2 text-base'>
                    Welcome! Click below to finish setting up a new account with
                    this email address.
                  </Description>
                </div>
                <CreateAccountButton />
              </div>
            </Panel>
          </Container>

          <TermsOfService />
        </Main>
      </Page>
    )
  }
)

const CreateAccountButton = observer(function CreateAccountButton() {
  const history = useHistory()
  const { user } = StoreService.getStore()

  const handleClick = () => {
    if (user.isLoggedIn) {
      history.push('/setup-account')
      return
    }

    history.push('/create-account')
  }

  return <Button text='Create Account' onClick={handleClick} />
})
