import { Collections } from '@repo/types'
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore'

import { databaseRef } from '@/firebase/firebase'

export interface Subscription {
  id?: string
  metadata: {
    [name: string]: string
  }
  quantity: number
  role: string | null
  status:
    | 'active'
    | 'canceled'
    | 'incomplete'
    | 'incomplete_expired'
    | 'past_due'
    | 'trialing'
    | 'unpaid'
  stripeLink: string
}

export async function getSubscriptions(
  userId: string
): Promise<Subscription[]> {
  const subscriptionsCollection = collection(
    databaseRef,
    Collections.CUSTOMERS,
    userId,
    Collections.SUBSCRIPTIONS
  )

  const subscriptionsQuery = query(
    subscriptionsCollection,
    where('status', 'not-in', ['canceled', 'incomplete_expired']),
    orderBy('status', 'desc'),
    orderBy('created', 'desc')
  )

  const subscriptionsSnapshot = await getDocs(subscriptionsQuery)

  if (subscriptionsSnapshot.empty) return []

  return subscriptionsSnapshot.docs.map(
    (subscriptionDoc) => subscriptionDoc.data() as Subscription
  )
}
