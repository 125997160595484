import { ISpotifyPlaybackState } from '../types'

export interface PlaybackState {
  isPlaying: boolean
}

export function convertPlaybackState(
  spotifyPlayback: ISpotifyPlaybackState
): PlaybackState {
  return {
    isPlaying: spotifyPlayback.is_playing,
  }
}
