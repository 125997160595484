import { Collections } from '@repo/types'
import { Container, Description, Heading, Panel, Spinner } from '@repo/ui'
import { useSuccessButton } from '@repo/ui/client'
import { doc, updateDoc } from 'firebase/firestore'
import { observer } from 'mobx-react-lite'
import React, { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'

import { AppHeader } from '@/components/AppHeader'
import { Button } from '@/components/Button'
import { BackButton, CloseButton } from '@/components/icon-buttons/IconButtons'
import { Main } from '@/components/Main'
import { Page } from '@/components/Page'
import { PageHeading } from '@/components/PageHeading'
import { Select } from '@/components/shared/Select'
import { Title } from '@/components/Title'
import { databaseRef } from '@/firebase/firebase'
import { useGameContext } from '@/pages/game/context/game-context'
import {
  VenueForm,
  VenueFormValues,
} from '@/pages/settings/components/VenueForm'
import { createVenue } from '@/pages/settings/pages/venue-create'
import {
  fetchVenues,
  Venue,
  VENUES_QUERY_KEY,
} from '@/pages/settings/pages/venue-list'
import StoreService from '@/store/StoreService'

async function updateGameDocument(
  gameId: string,
  venueId: string,
  venueName: string
) {
  const docRef = doc(databaseRef, Collections.GAMES, gameId)

  return updateDoc(docRef, { venueId, venueName })
}

export const VenueSettingsRoute = observer(
  function VenueSettingsRoute(): React.ReactElement {
    return <VenueSettingsPage />
  }
)

const VenueSettingsPage = observer(
  function VenueSettingsPage(): React.ReactElement {
    const history = useHistory()

    const handleClickBack = () => {
      history.goBack()
    }

    const handleClickClose = () => {
      history.push('/game')
    }

    return (
      <Page title='Venue Settings'>
        <AppHeader
          actionsLeft={<BackButton onClick={handleClickBack} />}
          actionsRight={<CloseButton onClick={handleClickClose} />}
          header={<PageHeading>Venue Settings</PageHeading>}
        />

        <Main>
          <Container className='flex flex-col gap-8' size='medium'>
            <Panel>
              <VenueListContainer />
            </Panel>
          </Container>
        </Main>
      </Page>
    )
  }
)

const VenueListContainer = observer(function VenueListContainer() {
  const { user } = StoreService.getStore()

  const { data, isError, isLoading } = useQuery<Venue[], Error>(
    [VENUES_QUERY_KEY],
    () => fetchVenues(user.userId)
  )

  if (isLoading) return <Spinner />

  if (isError || !data)
    return <Panel>There was a problem loading your venues.</Panel>

  return <VenuesPage venues={data} />
})

interface VenueSelectorProps {
  onChange: (venueId: string) => void
  value?: string
  venues: Venue[]
}

const VenueSelector = observer(function VenueSelector({
  onChange,
  value,
  venues,
}: VenueSelectorProps) {
  const options = useMemo(() => {
    return venues.map((venue) => ({
      id: venue.id,
      name: venue.name,
      value: venue.id,
    }))
  }, [venues])

  return (
    <Select
      options={options}
      placeholder='Select a venue...'
      value={value}
      onChange={onChange}
    />
  )
})

interface VenuesPageProps {
  venues: Venue[]
}

const VenuesPage = observer(function VenuesPage({ venues }: VenuesPageProps) {
  const { user } = StoreService.getStore()
  const history = useHistory()

  const { gameData, gameId, tracker } = useGameContext()
  const [showCreateForm, setShowCreateForm] = useState(!venues.length)

  const { showSuccess, showSuccessButtonState } = useSuccessButton()

  const [venueId, setVenueId] = useState<string | undefined>(gameData.venueId)

  const handleClickSave = async () => {
    if (!venueId) return

    const venue = venues.find((venue) => venue.id === venueId)

    if (!venue) return

    await updateGameDocument(gameId, venueId, venue.name)
    tracker.changeVenue(venueId, venue.name)

    await showSuccessButtonState()

    history.push('/game/settings')
  }

  const handleSubmit = async (values: VenueFormValues) => {
    const docRef = await createVenue(user.userId, values)
    const venueId = docRef.id

    await updateGameDocument(gameId, venueId, values.name)
    tracker.createVenue(venueId, values)

    return venueId
  }

  const handleSuccess = () => {
    history.goBack()
  }

  return (
    <>
      {!showCreateForm && (
        <>
          <div className='flex flex-col gap-8'>
            <div>
              <Heading>Venue Details</Heading>
              <Description className='mt-2'>
                Choose from your saved locations, or add a new location.
              </Description>
            </div>

            <div className='flex flex-col gap-6'>
              <Button
                text='Create New Venue'
                variant='outlined'
                onClick={() => setShowCreateForm(true)}
              />

              <VenueSelector
                value={venueId}
                venues={venues}
                onChange={setVenueId}
              />
            </div>

            <Button
              showSuccess={showSuccess}
              text='Save'
              onClick={handleClickSave}
            />
          </div>
        </>
      )}

      {showCreateForm && (
        <>
          <Title
            description="Add details about the venue you're hosting this game at. When
            you create games in the future, this venue will appear as an option
            in your saved venues list."
            heading='Venue Details'
          />

          <div className='mt-8'>
            <VenueForm onSubmit={handleSubmit} onSuccess={handleSuccess} />
          </div>
        </>
      )}
    </>
  )
})
