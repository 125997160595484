import { PlaybackOption } from '@repo/types'
import { observer } from 'mobx-react-lite'
import React from 'react'

import { SpotifyPlaybackControls } from '@/store/integrations/spotify/components/playback-controls/SpotifyPlaybackControls'

import { useGameContext } from './../context/game-context'
import { SongControlsNoPlayback } from './SongControlsNoPlayback'

export const SongControls = observer(
  function SongControls(): React.ReactElement | null {
    const { gameData } = useGameContext()

    if (gameData.type !== 'music') return null

    if (gameData.playbackSource === PlaybackOption.SPOTIFY) {
      return <SpotifyPlaybackControls />
    }

    return <SongControlsNoPlayback />
  }
)
