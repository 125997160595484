import { CommonProps } from '@repo/types'
import { merge } from '@repo/ui'
import { observer } from 'mobx-react-lite'

export const AppFooter = observer(function AppFooter({
  children,
  className,
}: CommonProps) {
  return (
    <footer
      className={merge(
        'sticky w-full bottom-0 self-end py-6 bg-shade-0',
        className
      )}
    >
      {children}
    </footer>
  )
})
