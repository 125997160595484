import { Playlist } from '@repo/types'
import { Panel, Heading, Container, Description } from '@repo/ui'
import { observer } from 'mobx-react-lite'
import React from 'react'

import { AppHeader } from '@/components/AppHeader'
import { Button } from '@/components/Button'
import { BackButton } from '@/components/icon-buttons/IconButtons'
import { Main } from '@/components/Main'
import { Page } from '@/components/Page'
import { PageHeading } from '@/components/PageHeading'
import { TermsOfService } from '@/components/TermsOfService'
import { TipBadge } from '@/components/TipBadge'
import { PlaylistSelector } from '@/store/integrations/spotify/components/PlaylistSelector'
import { MINIMUM_ITEM_COUNT } from '@/utils/constants'

interface ImportSpotifyPlaylistLibraryViewProps {
  baseUrl?: string
  canSubmit: boolean
  handleChange: (playlistId: string) => void
  isLoading: boolean
  onCancel: () => void
  onSubmit: () => Promise<void>
  playlists: readonly Playlist[]
  selectedPlaylistId: string
}

export const ImportSpotifyPlaylistLibraryView = observer(
  function ImportSpotifyPlaylistLibraryView({
    baseUrl = '/',
    canSubmit,
    handleChange,
    isLoading,
    onCancel,
    onSubmit,
    playlists,
    selectedPlaylistId,
  }: ImportSpotifyPlaylistLibraryViewProps): React.ReactElement {
    return (
      <Page title='Import from Spotify'>
        <AppHeader
          actionsLeft={
            <BackButton url={`${baseUrl}playlists/create/import-spotify`} />
          }
          header={<PageHeading>Import from Spotify</PageHeading>}
        />

        <Main>
          <Container className='mb-12' size='medium'>
            <Panel>
              <TipBadge />
              <Description className='mt-2'>
                Playlists must have a minimum of {MINIMUM_ITEM_COUNT} songs. If
                you don{`'`}t see your playlist, try adding more songs to it on
                Spotify.
              </Description>
            </Panel>
          </Container>

          <Container size='medium'>
            <div>
              <Heading className='mb-2 mt-6'>
                Select from your Spotify library
              </Heading>

              <PlaylistSelector
                handleChange={handleChange}
                playlists={playlists}
                selectedPlaylistId={selectedPlaylistId}
              />

              {selectedPlaylistId && (
                <Button
                  className='mt-4'
                  text='Cancel'
                  variant='anchor'
                  onClick={onCancel}
                />
              )}
            </div>

            <Button
              className='mt-8'
              disabled={!selectedPlaylistId || !canSubmit || isLoading}
              isLoading={isLoading}
              text='Next'
              onClick={onSubmit}
            />
          </Container>

          <TermsOfService />
        </Main>
      </Page>
    )
  }
)
