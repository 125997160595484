import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

export const focusOutlineStyles = css`
  &:focus,
  &:active {
    outline: 0;
  }

  &:focus-visible {
    box-shadow: inset 0px 0px 0px 4px var(--purple-500);
  }
`

const listRowStyles = css`
  display: flex;
  background: var(--bg-01);
  align-items: center;
  transition: background 0.3s ease-in-out, color 0.3s ease-in-out;
  padding: 10px 24px;
  overflow: hidden;

  a {
    display: flex;
    color: var(--text);
    text-decoration: none;
  }

  &:hover {
    background: var(--bg-02);
  }
`

export const ListRowStyled = styled.li`
  ${listRowStyles}
`

export const ListItemActionsStyled = styled.div`
  display: flex;

  > *:not(:last-child) {
    margin-right: 10px;
  }

  button {
    &.icon {
      color: var(--text);
    }
  }

  &.actions-muted {
    button {
      opacity: 0.3;
      transition: all 0.3s ease-in-out;

      &:hover {
        opacity: 1;
      }
    }
  }
`

export const ListRowLinkStyled = styled.li`
  ${listRowStyles}
  padding: 0;

  > a {
    flex: 1;
    align-items: center;
    padding: 10px 24px;

    ${focusOutlineStyles}
  }

  ${ListItemActionsStyled} {
    padding-right: 10px;
  }
`

export const AnimatedListRowStyled = styled(motion.li)`
  ${listRowStyles}
`

export const DraggableListRowStyled = styled.li`
  ${listRowStyles}
`

const listStyles = css`
  list-style: none;
  margin: 0;
  padding: 0;
`

export const ListStyled = styled.ul`
  ${listStyles}

  display: grid;
  grid-gap: 5px;
  overflow: hidden;
  max-width: 100%;

  &:not(.compact) {
    ${ListRowStyled} {
      min-height: 4em;
    }
  }

  &.compact {
    ${ListRowStyled} {
      padding: 5px 10px;
    }
  }
`

export const ListItemTitleStyled = styled.div`
  font-weight: 800;
  font-size: 1.2em;
  width: 100%;
  max-width: 100%;
  padding-right: 1em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export const InputStyled = styled.input`
  width: 100%;
  position: relative;
  z-index: 2;

  &.has-error {
    border-color: var(--red-500);
  }

  &:disabled {
    opacity: 0.75;
  }
`

export const linkStyles = css`
  .light & {
    --link: var(--primary-500);
    --link-hover: var(--primary-700);
  }

  .dark & {
    --link: var(--primary-400);
    --link-hover: var(--primary-200);
  }

  color: var(--link);
  font-weight: 800;
  transition: color 300ms ease-in-out;
  white-space: nowrap;
  border-radius: 4px;

  &:hover {
    color: var(--link-hover);
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    box-shadow: 0 0 0 2px var(--purple-500);
  }
`

export const LinkStyled = styled(Link)`
  ${linkStyles}
`

export const ExternalLinkStyled = styled.a`
  ${linkStyles}

  svg {
    width: 10px;
    height: 10px;
    margin-left: 5px;
  }
`

export const FormErrorStyled = styled.div`
  color: var(--red-500);
  background: hsl(0deg 100% 86%);
  padding: 10px;
  font-weight: 600;
  border-radius: 4px;
`

export const CardGridStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-gap: 24px;
`

export const CardGridItemStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const GridContainerStyled = styled.div`
  display: grid;
  grid-gap: 24px;
`

export const FormControlStyled = styled.div`
  display: grid;
  grid-gap: 12px;
  grid-auto-rows: min-content;

  label {
    color: var(--text-muted);
    line-height: 1;
    margin: 0;
    min-height: 1em;
  }

  input {
    height: 48px;
  }

  .info {
    font-size: 16px;
    background: transparent;
    border-color: transparent;
    color: var(--blue-400);
  }
`
