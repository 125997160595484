import { BingoResult, Game, Player, PlayerInGame } from '@repo/types'
import { BingoCard, markPlayerData } from '@repo/ui'
import { Dialog } from '@repo/ui/client'

interface BingoCardDialogProps {
  activePlayers: PlayerInGame[]
  gameData: Game
  messageData: Player
  result: BingoResult
}

export function BingoCardDialog({
  activePlayers,
  gameData,
  messageData,
  result
}: BingoCardDialogProps) {
  const [playerData] = activePlayers.filter(player => player.playerId === messageData.playerId)

  return (
    <Dialog>
      <Dialog.Body>
        <h2 className='justify-center'>
          {result === BingoResult.DENIED && `Bingo DENIED!`}
          {result === BingoResult.CONFIRMED && `Bingo CONFIRMED!`}
        </h2>

        <h2 className='justify-center'>{playerData.playerName} </h2>

        <BingoCard
          currentPlayerHasCalledBingo={false}
          gameData={gameData}
          playerData={markPlayerData(gameData, playerData)}
          onSquareClick={() => undefined}
        />
      </Dialog.Body>
    </Dialog>
  )
}
