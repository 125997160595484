import { DrawnItem } from '@repo/types'
import { ScrollContainer } from '@repo/ui/client'

import { useScrollIntoView } from '@/hooks/useScrollIntoView'

import { PreviousDrawnItem } from './PreviousDrawnItem'

interface PreviousDrawnItemsCalledProps {
  previousDrawnItemsCalled: DrawnItem[]
}

export function PreviousDrawnItemsCalled({
  previousDrawnItemsCalled,
}: PreviousDrawnItemsCalledProps) {
  const lastItem = previousDrawnItemsCalled[previousDrawnItemsCalled.length - 1]

  const { setRef } = useScrollIntoView({
    items: previousDrawnItemsCalled,
    targetId: lastItem?.id,
  })

  return (
    <ScrollContainer className='flex w-full' direction='row' hideScrollbar>
      <div className='ml-auto grid grid-flow-col gap-2'>
        {previousDrawnItemsCalled.map((drawnItem) => (
          <PreviousDrawnItem
            drawnItem={drawnItem}
            key={drawnItem.id}
            ref={(ref) => setRef(drawnItem.id, ref)}
          />
        ))}
      </div>
    </ScrollContainer>
  )
}
