import { z } from 'zod'

import { type IntegrationProfile } from '../..'
import { ISpotifyProfile } from '../types'

const ProfileSchema = z.object({
  display_name: z.string(),
  email: z.string(),
  id: z.string(),
  images: z
    .array(
      z.object({
        url: z.string(),
      })
    )
    .catch(() => []),
})

export function convertProfile(
  spotifyProfile: ISpotifyProfile
): IntegrationProfile {
  const parsedProfile = ProfileSchema.parse(spotifyProfile)

  return {
    displayName: parsedProfile.display_name,
    email: parsedProfile.email,
    id: parsedProfile.id,
    imageUrl: parsedProfile.images?.[0]?.url,
  }
}
