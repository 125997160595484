import type { Playlist } from '@repo/types'
import { Heading, merge } from '@repo/ui'
import { observer } from 'mobx-react-lite'
import React from 'react'

import { CardGrid } from './CardGrid'

interface ICardGridCategory {
  createCard?: React.ReactElement
  description?: React.ReactNode
  handleClick: (playlistId: string) => void
  heading: string
  headingAction?: React.ReactNode
  headingRight?: React.ReactNode
  items: readonly Playlist[]
}

export const CardGridCategory = observer(function CardGridCategory({
  createCard,
  description,
  handleClick,
  heading,
  headingAction,
  headingRight,
  items = [],
}: ICardGridCategory): React.ReactElement {
  return (
    <div>
      <div className='flex justify-between gap-4 pb-4'>
        <div className='flex flex-col gap-2'>
          <div
            className={merge(
              'flex flex-1 gap-4 items-center justify-between sm:justify-normal'
            )}
          >
            <Heading className='m-0'>{heading}</Heading>

            {headingAction && <div>{headingAction}</div>}
          </div>

          {description}
        </div>

        {headingRight && (
          <div className='flex justify-end overflow-hidden'>{headingRight}</div>
        )}
      </div>

      <CardGrid
        createCard={createCard}
        handleClick={handleClick}
        items={items}
      />
    </div>
  )
})
