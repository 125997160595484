import { Collections, Scoreboard } from '@repo/types'
import {
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
} from 'firebase/firestore'

import { databaseRef } from '@/firebase/firebase'

import SnapshotListener from './AbstractSnapshotListener'
import { GameStore } from '../GameStore'

class ScoreboardListener extends SnapshotListener {
  public subscribe(game: GameStore): void {
    const scoreboardCollection = collection(
      databaseRef,
      Collections.GAMES,
      game.gameId,
      Collections.SCOREBOARD
    )

    const scoreboardQuery = query(
      scoreboardCollection,
      orderBy('timestamp', 'desc'),
      limit(1)
    )

    this._unsubscribe = onSnapshot(scoreboardQuery, (snapshot) => {
      const [scores] = snapshot.docs.map((doc) => doc.data() as Scoreboard)
      game.apply({ scores })
    })
  }
}

export const scoreboardListener = new ScoreboardListener()
