import { Badge } from '@repo/ui'
import { observer } from 'mobx-react-lite'

export const TipBadge = observer(function TipBadge() {
  return (
    <Badge
      className='mr-auto w-auto text-2xs text-muted sm:text-2xs'
      size='small'
    >
      Tip
    </Badge>
  )
})
