import { Timestamp } from '@/firebase/firebase'

type ComparisonResult<T> = {
  [K in keyof T]: T[K] extends object ? ComparisonResult<T[K]> : boolean
}

function isObject<T>(value: T): value is Extract<T, object> {
  return typeof value === 'object' && value !== null
}

function compareObjects<T extends object>(
  obj1: T,
  obj2: T
): ComparisonResult<T> {
  const result = {} as ComparisonResult<T>

  for (const key in obj1) {
    if (
      Object.prototype.hasOwnProperty.call(obj1, key) &&
      Object.prototype.hasOwnProperty.call(obj2, key)
    ) {
      const keyTyped = key as keyof T
      const obj1Prop = obj1[keyTyped]
      const obj2Prop = obj2[keyTyped]

      if (isObject(obj1Prop) && isObject(obj2Prop)) {
        Reflect.set(result, keyTyped, compareObjects(obj1Prop, obj2Prop))
      } else {
        Reflect.set(result, keyTyped, obj1Prop !== obj2Prop)
      }
    }
  }

  return result
}

function flattenObject<T extends object>(
  obj: T,
  prefix = ''
): Record<string, unknown> {
  const flatObj: Record<string, unknown> = {}

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const newKey = prefix ? `${prefix}_${key}` : key
      const value = obj[key as keyof T]

      if (isObject(value)) {
        const nestedFlatObj = flattenObject(value, newKey)
        Object.assign(flatObj, nestedFlatObj)
      } else {
        flatObj[newKey] = value
      }
    }
  }

  const flatObjWithTitleCaseKeys = convertKeysToTitleCase(flatObj)
  return flatObjWithTitleCaseKeys
}

function toTitleCase(str: string): string {
  return str
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/_/g, ' ')
    .replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    )
}

function convertKeysToTitleCase(
  obj: Record<string, unknown>
): Record<string, unknown> {
  const newObj: Record<string, unknown> = {}

  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const newKey = toTitleCase(key)

      if (isObject(obj[key])) {
        newObj[newKey] = convertKeysToTitleCase(
          obj[key] as Record<string, unknown>
        )
      } else {
        newObj[newKey] = obj[key]
      }
    }
  }

  return newObj
}

function addSuffix(obj: Record<string, unknown>, suffix = 'Changed') {
  const output = {}

  for (const key in obj) {
    Reflect.set(output, `${key} ${suffix}`, obj[key])
  }

  return output
}

export function compareChanges<T extends object>(
  obj1: T,
  obj2: T
): Record<string, unknown> {
  const changes = compareObjects(obj1, obj2)
  const flattend = flattenObject(changes)

  return addSuffix(flattend)
}

export function formatTimestamp(
  timestamp: Timestamp | null
): string | Timestamp | null {
  try {
    return (timestamp as Timestamp).toDate().toISOString()
  } catch {
    return timestamp
  }
}
