import { Game } from '@repo/types'
import { Panel, Badge, Heading } from '@repo/ui'
import { observer } from 'mobx-react-lite'
import React from 'react'

import { groupBy } from '@/utils/group-by'

import { GameList } from './game-list'

interface GamesListByHostProps {
  games: Game[]
  showHostHeader?: boolean
}

export const GameListByHost = observer(function GameListByHost({
  games,
  showHostHeader = true,
}: GamesListByHostProps): React.ReactElement {
  const gamesByHost = Object.entries(groupBy<Game>('hostEmail', games))

  return (
    <>
      {gamesByHost.map(([hostEmail, games]) => (
        <div className='flex flex-col gap-2' key={hostEmail}>
          {showHostHeader && (
            <Heading className='flex gap-2' size='small'>
              {games.length > 1 && <Badge>{games.length}</Badge>}
              <span className='mr-5'>{hostEmail}</span>{' '}
            </Heading>
          )}

          <GameList
            games={games.sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1))}
            key={hostEmail}
          />
        </div>
      ))}

      {gamesByHost.length === 0 && (
        <Panel className='text-muted'>No games were found</Panel>
      )}
    </>
  )
})
