import { autorun, makeAutoObservable } from 'mobx'

import { IntegrationAuthClient } from '@/store/integrations'
import {
  ISpotifyErrorResponse,
  ISpotifyTokenResponse,
} from '@/store/integrations/spotify/types'
import { applyProperties } from '@/store/utils'
import { apiUrl } from '@/utils/constants'
import { generateRandomString } from '@/utils/generate-random-string'
import { isNonNullish } from '@/utils/type-guards'

import { KEYS, getItem, removeItem, setItem } from './storage'
import {
  FetchMethod,
  FirebaseTokenResponse,
  SpotifyTokenError,
  isProperty,
} from './types'

const SPOTIFY_API_URL = 'https://api.spotify.com/v1'

export class SpotifyAuthClient implements IntegrationAuthClient {
  private isRefreshingToken = false
  private tokenRefreshPromise?: Promise<unknown>

  public accessToken?: string
  public refreshToken?: string

  public state?: string
  public authRedirectUrl?: string

  public email?: string

  public isConnecting = false

  constructor() {
    makeAutoObservable(this)

    this.applyTokens()

    autorun(() => {
      Object.entries(KEYS).forEach(([property, storageKey]) => {
        if (!isProperty(property, this)) return

        const value = this[property]

        if (isNonNullish(value)) {
          setItem(storageKey, value.toString())
        } else {
          removeItem(storageKey)
        }
      })
    })

    addEventListener('storage', (event) => {
      const match = Object.entries(KEYS).find(([, key]) => key === event.key)
      if (!match) return

      this.applyTokens()
    })
  }

  public get isAuthenticated(): boolean {
    return Boolean(this.accessToken)
  }

  applyTokens() {
    this.apply({
      accessToken: getItem(KEYS.accessToken),
      authRedirectUrl: getItem(KEYS.authRedirectUrl),
      email: getItem(KEYS.email),
      refreshToken: getItem(KEYS.refreshToken),
      state: getItem(KEYS.state),
    })
  }

  public connect(options?: {
    currentUrl?: string
    showDialog?: boolean
  }): void {
    const { currentUrl, showDialog } = options ?? {}

    const state = generateRandomString(16)
    const authRedirectUrl = currentUrl !== '/login' ? currentUrl : ''

    this.apply({
      authRedirectUrl,
      isConnecting: true,
      state,
    })

    const url = new URL(`${apiUrl}/authorize-spotify`)

    url.searchParams.append('state', state)
    if (showDialog) url.searchParams.append('showDialog', String(showDialog))

    window.location.href = url.toString()
  }

  public disconnect(): void {
    this.apply({
      accessToken: undefined,
      authRedirectUrl: undefined,
      email: undefined,
      isConnecting: false,
      refreshToken: undefined,
      state: undefined,
    })
  }

  private apply(values: Partial<SpotifyAuthClient>) {
    applyProperties(this, values)
  }

  private storeToken(response: ISpotifyTokenResponse): void {
    const { access_token, profile, refresh_token } = response

    this.apply({
      accessToken: access_token,
      ...(refresh_token && { refreshToken: refresh_token }),
      email: profile?.email,
      isConnecting: false,
    })
  }

  public async refreshAccessToken() {
    if (!this.isRefreshingToken) {
      this.isRefreshingToken = true

      this.tokenRefreshPromise = this.getRefreshedToken()
        .then((response) => {
          if (response.access_token) {
            this.storeToken(response)
          } else {
            this.disconnect()
            throw new Error('Could not refresh token. Disconnecting Spotify.')
          }
        })
        .catch((error) => {
          throw error
        })
        .finally(() => {
          this.isRefreshingToken = false
        })
    }

    return this.tokenRefreshPromise
  }

  private async getRefreshedToken(): Promise<ISpotifyTokenResponse> {
    const url = new URL(`${apiUrl}/refresh-spotify-token`)
    url.searchParams.append('refresh_token', this.refreshToken || '')

    const response = await fetch(url.toString(), { credentials: 'include' })
    return response.json() as Promise<ISpotifyTokenResponse>
  }

  public async apiRequest<T>(
    endPoint: string,
    method: FetchMethod = FetchMethod.GET,
    body?: string
  ): Promise<T> {
    const sendRequest = async (): Promise<Response> => {
      if (!this.accessToken) throw new Error('Invalid Spotify access token')
      return await this.performFetchRequest(endPoint, method, body)
    }

    const response = await sendRequest()

    if (!response.ok) {
      const data = (await response.json()) as unknown

      if ((data as ISpotifyErrorResponse).error) {
        console.error((data as ISpotifyErrorResponse).error?.message)
        throw new Error((data as ISpotifyErrorResponse).error.message)
      } else {
        throw new Error(response.statusText)
      }
    }

    if (response.status !== 200 || method === FetchMethod.PUT) {
      return {} as T
    }

    return response.json() as Promise<T>
  }

  private async performFetchRequest(
    endPoint: string,
    method: FetchMethod,
    body?: string
  ): Promise<Response> {
    const response = await fetch(`${SPOTIFY_API_URL}${endPoint}`, {
      body,
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
        'Content-Type': 'application/json',
      },
      method,
    })

    if (response.status === 401) {
      try {
        await this.refreshAccessToken()
        return this.performFetchRequest(endPoint, method, body)
      } catch (error) {
        this.disconnect()
        console.error(error)
      }
    }

    return response
  }

  public async handleAuthCallback(): Promise<void | FirebaseTokenResponse> {
    const params = new URLSearchParams(window.location.search)
    const code = params.get('code')
    const error = params.get('error')

    const stateParam = params.get('state')

    if (!stateParam || stateParam !== this.state || Boolean(error) || !code) {
      this.disconnect()
      throw new Error('Could not authenticate to Spotify')
    }

    const response = await this.getToken(code, stateParam)

    if (response.error) {
      this.disconnect()
      throw new SpotifyTokenError(response.error, response.email)
    }

    if (response.hasMigrated && response.user) {
      return response
    }

    this.apply({
      state: undefined,
    })

    this.storeToken(response.spotify)
  }

  private async getToken(
    code: string,
    state: string
  ): Promise<FirebaseTokenResponse> {
    const url = new URL(`${apiUrl}/spotify-token`)
    url.searchParams.append('code', code)
    url.searchParams.append('state', state)

    const response = await fetch(url.toString(), { credentials: 'include' })
    return response.json() as Promise<FirebaseTokenResponse>
  }
}
