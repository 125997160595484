import { merge } from '@repo/ui'

import { Image } from '@/components/Image'

export function Avatar({
  className,
  imageUrl,
}: {
  className?: string
  imageUrl?: string
}) {
  return (
    <div
      className={merge(
        'h-8 w-8 rounded-full overflow-hidden shrink-0',
        className
      )}
    >
      <Image alt='Spotify Avatar' src={imageUrl} />{' '}
    </div>
  )
}
