import { useEffect, useRef } from 'react'

import { MixpanelService } from '../mixpanel'

export function useTrackEvent(
  eventName: string,
  eventProperties: Record<string, unknown>
) {
  const hasSent = useRef(false)

  useEffect(() => {
    if (!hasSent.current) {
      MixpanelService.track(eventName, eventProperties)
      hasSent.current = true
    }
  }, [eventName, eventProperties])
}
