import { GameStore } from './GameStore'
import {
  calledBingosListener,
  gameListener,
  messageQueueListener,
  playersListener,
  scoreboardListener,
} from './snapshot-listeners'
import StoreService from '../StoreService'

class GameStoreService {
  private _game?: GameStore

  private get game() {
    const store = StoreService.getStore()
    this._game = store.currentGame

    if (!this._game) throw new Error('Game has not been initialized.')
    return this._game
  }

  public subscribe(): void {
    const { game } = this

    calledBingosListener.subscribe(game)
    gameListener.subscribe(game)
    messageQueueListener.subscribe(game)
    playersListener.subscribe(game)
    scoreboardListener.subscribe(game)
  }

  public unsubscribe(): void {
    calledBingosListener.unsubscribe()
    gameListener.unsubscribe()
    messageQueueListener.unsubscribe()
    playersListener.unsubscribe()
    scoreboardListener.unsubscribe()
  }
}

export default new GameStoreService()
