import { observer } from 'mobx-react-lite'

import { ExternalLink } from '@/components/ExternalLink'

const URL =
  'https://help.rockstar.bingo/en/article/troubleshooting-spotify-connections-du6y3p/'

interface SpotifyTroubleshootingLinkProps {
  className?: string
}

export const SpotifyTroubleshootingLink = observer(
  function SpotifyTroubleshootingLink({
    className,
  }: SpotifyTroubleshootingLinkProps) {
    return (
      <ExternalLink className={className} href={URL}>
        Troubleshoot Spotify Connection
      </ExternalLink>
    )
  }
)
