import { Timestamp } from './firebase'
import { ISpotifyMeta, SongKeys } from './spotify'

export enum PlaylistType {
  SYSTEM_PLAYLISTS = 'system_playlists',
  USER_CUSTOM_PLAYLISTS = 'user_custom_playlists',
  USER_SPOTIFY_LIBRARY = 'user_spotify_library',
  USER_SPOTIFY_URL = 'user_spotify_url'
}

export interface Playlist {
  [key: string]: unknown
  colour?: ColourPalette
  disableShuffle?: boolean
  id: string
  image?: { height?: number; url: string, width?: number; }
  order?: number
  playbackOptions: PlaybackOption[]
  songs: Song[]
  systemMeta?: {
    isPaidOnly?: boolean
  }
  timestamp?: Timestamp
  title: string
  type: PlaylistType
  url?: string
  video?: Video
  videoPreGame?: Video
}

export interface Song {
  album?: string
  artist: string
  id: number
  recentlyPlayed?: boolean
  spotifyMeta?: ISpotifyMeta
  title: string
  userMeta?: UserSongMeta
  video?: Video
}

export interface Video {
  url: string
}

export enum PlaybackOption {
  NONE = 'none',
  SPOTIFY = 'spotify'
}

export enum ColourPalette {
  BLUE = 'blue',
  GREEN = 'green',
  INDIGO = 'indigo',
  ORANGE = 'orange',
  PINK = 'pink',
  PURPLE = 'purple',
  RED = 'red',
  TEAL = 'teal',
  YELLOW = 'yellow'
}

export interface UserSongMeta {
  key?: SongKeys
  notes?: string,
  tempo?: number
}
