import { merge, Heading, HeadingProps } from '@repo/ui'
import { observer } from 'mobx-react-lite'

export const PageTitle = observer(function PageTitle({
  children,
  className,
  size = 'large',
}: HeadingProps) {
  return (
    <Heading
      className={merge('md:justify-center text-center mt-4 mb-6', className)}
      size={size}
      disableTruncation
    >
      {children}
    </Heading>
  )
})
