import { observer } from 'mobx-react-lite'
import React from 'react'

import { useGameContext } from '@/pages/game/context/game-context'

import { VimeoEmbed } from './VimeoEmbed'
import { YoutubeEmbed } from './YouTubeEmbed'

export const JumbotronVideo = observer(
  function JumbotronVideo(): React.ReactElement | null {
    const { gameData, isCreatedGame } = useGameContext()

    if (gameData.type !== 'music') return null

    const { currentItemIndex, drawnItems, sourcePlaylistMeta } = gameData

    const getVideoUrl = () => {
      if (isCreatedGame) {
        return sourcePlaylistMeta?.videoPreGame?.url
      }

      if (currentItemIndex === null) return

      const playlistVideo = sourcePlaylistMeta?.video?.url
      const songVideo = drawnItems[currentItemIndex].song?.video?.url

      return songVideo || playlistVideo
    }

    const url = getVideoUrl()
    if (!url) return null

    if (isYoutubeUrl(url)) return <YoutubeEmbed url={url} />
    if (isVimeoUrl(url)) return <VimeoEmbed url={url} />

    return null
  }
)

function isVimeoUrl(url: string) {
  const exp = new RegExp(/(vimeo\.com)/)
  return exp.test(url)
}

function isYoutubeUrl(url: string) {
  const exp = new RegExp(/(youtu\.be|youtube\.com)/)
  return exp.test(url)
}
