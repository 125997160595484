import { Column, DrawnItem } from '@repo/types'
import { forwardRef } from 'react'

interface PreviousDrawnItemProps {
  drawnItem: DrawnItem
}

export const PreviousDrawnItem = forwardRef<HTMLDivElement, PreviousDrawnItemProps>(
  ({ drawnItem }: PreviousDrawnItemProps, ref) => {
    return (
      <div
        className='flex size-16 shrink-0 flex-col items-center justify-center md:size-24'
        ref={ref}
      >
        <div className='flex w-full flex-1 items-center justify-center rounded-t-lg border-2 border-orange-500 bg-shade-1 text-xl font-bold'>
          <span className='mt-[-2.5px]'>{Column[drawnItem.column]}</span>
        </div>
        <div className='flex w-full flex-1 items-center justify-center rounded-b-lg bg-shade-2 text-xl font-bold'>
          <span className='-mt-1'>{drawnItem.id}</span>
        </div>
      </div>
    )
  }
)

PreviousDrawnItem.displayName = 'PreviousDrawnItem'
