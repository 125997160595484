import { observer } from 'mobx-react-lite'
import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'

import { Popover, PopoverPlacement, usePopover } from '../popover'

export interface TooltipProps {
  children?: React.ReactNode
  className?: string
  disableOffset?: boolean
  placement?: PopoverPlacement
  refObject: React.MutableRefObject<HTMLElement | null>
  showTooltip?: boolean
  title?: React.ReactNode
}

export const Tooltip = observer(function Tooltip({
  children,
  className,
  disableOffset,
  placement = 'auto',
  refObject,
  showTooltip = false,
  title,
}: TooltipProps): React.ReactElement {
  const popoverOptions = {
    disableOffset,
    enableBackdrop: false,
    placement,
    showOnHover: true,
  }

  const { setShowPopover, ...popoverProps } = usePopover(
    refObject,
    popoverOptions
  )

  useEffect(() => {
    setShowPopover(showTooltip)
  }, [setShowPopover, showTooltip])

  const handleReferenceElementClick = useCallback(() => {
    setShowPopover(false)
  }, [setShowPopover])

  useEffect(() => {
    const element = refObject.current

    if (!element) return

    element.addEventListener('click', handleReferenceElementClick)

    return () => {
      element.removeEventListener('click', handleReferenceElementClick)
    }
  }, [refObject, handleReferenceElementClick])

  return (
    <Popover {...popoverProps}>
      <TooltipStyled className={className}>
        {title && <h3>{title}</h3>}
        {children}
      </TooltipStyled>
    </Popover>
  )
})

const TooltipStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1em;
  max-width: 320px;

  h3 {
    margin: 0;
  }
`
