import { Collections, WinnerContactDetails } from '@repo/types'
import { Heading, Panel } from '@repo/ui'
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useEffect, useState } from 'react'

import { List } from '@/components/List'
import { databaseRef } from '@/firebase/firebase'

import { formatTime } from './format-time'
import { useGameContext } from '../../game/context/game-context'

export const WinnerContactDetailsList = observer(
  function WinnerContactDetailsList(): React.ReactElement {
    const { gameId } = useGameContext()
    const [winnersList, setWinnersList] = useState<WinnerContactDetails[]>([])

    const fetchWinners = useCallback(() => {
      const winnerCollection = collection(
        databaseRef,
        Collections.GAMES,
        gameId,
        Collections.WINNER_CONTACT_DETAILS
      )
      const winnerQuery = query(winnerCollection, orderBy('timestamp', 'asc'))

      onSnapshot(winnerQuery, (snapshot) => {
        if (snapshot.empty) {
          console.log('No winners found')
        } else {
          const winnersList = snapshot.docs.map(
            (winnerContactDetailsDoc) =>
              winnerContactDetailsDoc.data() as WinnerContactDetails
          )
          setWinnersList(winnersList)
        }
      })
    }, [gameId])

    useEffect(() => {
      void fetchWinners()
    }, [fetchWinners])

    return (
      <>
        <Heading className='mb-2'>Winner Contact Details</Heading>

        {Boolean(winnersList.length) && (
          <List>
            {winnersList.map(({ email, name, phone, playerId, timestamp }) => (
              <List.Item key={playerId}>
                <div>
                  <Heading>{name}</Heading>
                  <div>{email}</div>
                  {phone && <div>{phone}</div>}
                  <div className='mt-5 text-muted'>{formatTime(timestamp)}</div>
                </div>
              </List.Item>
            ))}
          </List>
        )}

        {!winnersList.length && (
          <Panel className='text-muted'>
            No winner contact details were found.
          </Panel>
        )}
      </>
    )
  }
)
