import { Container, Panel } from '@repo/ui'
import * as Sentry from '@sentry/react'
import { observer } from 'mobx-react-lite'
import React, { ChangeEvent, useRef, useState } from 'react'
import { isError } from 'react-query'
import { useHistory } from 'react-router-dom'

import { AppHeader } from '@/components/AppHeader'
import { Button } from '@/components/Button'
import { FieldError } from '@/components/forms/FieldError'
import { BackButton } from '@/components/icon-buttons/IconButtons'
import { Main } from '@/components/Main'
import { Page } from '@/components/Page'
import { PageHeading } from '@/components/PageHeading'
import { PageTitle } from '@/components/PageTitle'
import { TermsOfService } from '@/components/TermsOfService'

import { PlaylistInput, PlaylistSchema } from './playlist-schema'
import { MixpanelService } from '../../../mixpanel'
import { useCreateSystemPlaylist } from '../playlist-queries/system-playlists/useCreateSystemPlaylist'
import { useCreateUserPlaylist } from '../playlist-queries/user-playlists/useCreateUserPlaylist'

function readFile(file: File): Promise<PlaylistInput> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = (event) => {
      if (typeof event.target?.result !== 'string') return

      try {
        const parsed = PlaylistSchema.parse(JSON.parse(event.target.result))
        resolve(parsed)
      } catch (error) {
        console.error('Could not parse JSON file:', error)
        Sentry.captureException(error)

        reject(error)
      }
    }

    reader.readAsText(file)
  })
}

export const ImportJsonPlaylistRoute = observer(
  function ImportJsonPlaylistRoute(): React.ReactElement {
    const history = useHistory()
    const { mutateAsync: createUserPlaylist } = useCreateUserPlaylist()

    const createPlaylist = async (playlistInput: PlaylistInput) => {
      const { playlistId } = await createUserPlaylist({
        ...playlistInput,
        id: '',
      })

      MixpanelService.importPlaylistSpreadsheet(
        playlistId,
        playlistInput.songs.length
      )

      history.push(`/playlists/edit/${playlistId}`)
    }

    return <ImportJsonPlaylist createPlaylist={createPlaylist} />
  }
)

export const ImportJsonPlaylistRouteAdmin = observer(
  function ImportJsonPlaylistRouteAdmin(): React.ReactElement {
    const history = useHistory()
    const { mutateAsync: createSystemPlaylist } = useCreateSystemPlaylist()

    const baseUrl = '/admin/'

    const createPlaylist = async (playlistInput: PlaylistInput) => {
      const { playlistId } = await createSystemPlaylist({
        ...playlistInput,
        id: '',
      })

      history.push(`${baseUrl}playlists/edit/${playlistId}`)
    }

    return (
      <ImportJsonPlaylist baseUrl={baseUrl} createPlaylist={createPlaylist} />
    )
  }
)

interface ImportJsonPlaylist {
  baseUrl?: string
  createPlaylist: (playlistInput: PlaylistInput) => Promise<void>
}

const ImportJsonPlaylist = observer(function ImportJsonPlaylist({
  baseUrl = '/',
  createPlaylist,
}: ImportJsonPlaylist): React.ReactElement {
  const [isCreatingPlaylist, setIsCreatingPlaylist] = useState(false)
  const [error, setError] = useState<Error>()
  const inputRef = useRef<HTMLInputElement | null>(null)

  const handleClick = () => {
    inputRef.current?.click()
  }

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    setError(undefined)

    const file = event.target.files?.[0]

    if (!file || file.type !== 'application/json') {
      setError(new Error('Invalid file type'))
      return
    }

    setIsCreatingPlaylist(true)

    try {
      const playlistInput = await readFile(file)

      const updatedPlaylist = {
        ...playlistInput,
        songs: playlistInput.songs.map((song, index) => ({
          ...song,
          id: index,
        })),
      }

      await createPlaylist(updatedPlaylist)
    } catch (error) {
      if (isError(error)) setError(error)
      Sentry.captureException(error)
    }

    setIsCreatingPlaylist(false)
  }

  return (
    <Page title='Import from JSON file'>
      <AppHeader
        actionsLeft={<BackButton url={`${baseUrl}playlists/create`} />}
        header={<PageHeading>Import from JSON file</PageHeading>}
      />

      <Main>
        <Container size='medium'>
          <PageTitle>Upload a JSON file</PageTitle>
        </Container>

        <Container size='small'>
          <Panel>
            <>
              <div className='flex flex-col gap-6'>
                <div className='flex flex-col gap-2'>
                  <p className='mt-2 text-sm text-muted'>
                    Select a file to upload that you have previously exported
                    from the Rockstar Bingo playlist editor in JSON format.
                  </p>
                </div>

                <div>
                  <input
                    accept='.json'
                    className='hidden'
                    ref={inputRef}
                    type='file'
                    onChange={handleFileChange}
                  />

                  <Button
                    isLoading={isCreatingPlaylist}
                    text='Choose a File'
                    onClick={handleClick}
                  />
                </div>

                {Boolean(error) && (
                  <FieldError className='rounded-t-md'>
                    <p className='mb-2 text-sm'>Error:</p>
                    {error?.message}
                  </FieldError>
                )}
              </div>
            </>
          </Panel>
        </Container>

        <TermsOfService />
      </Main>
    </Page>
  )
})
