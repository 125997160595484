import { Song } from '@repo/types'
import { Badge, IconButton, TrashIcon, YoutubeIcon } from '@repo/ui'
import { observer } from 'mobx-react-lite'
import React from 'react'

import {
  AudioInfoBadge,
  KeyBadge,
  SpotifyTrackLink,
  TempoBadge,
} from '@/components/RowAudioFeatures'

import { NotesBadge } from './NotesBadge'
import { SongMetadata } from './SongMetadata'

interface IPlaylistTrackProps {
  canReorder?: boolean
  index?: number
  isCompact?: boolean
  onClick?: (id: number) => void
  onClickDelete: (id: number) => void
  song: Song
}

export const PlaylistTrack = observer(function PlaylistTrack({
  canReorder = false,
  index,
  onClick,
  onClickDelete,
  song,
}: IPlaylistTrackProps): React.ReactElement {
  const { id } = song
  const displayBadge = canReorder && index !== undefined

  return (
    <div
      className='flex w-full gap-3 overflow-hidden'
      onClick={() => onClick?.(id)}
    >
      {displayBadge && (
        <div className='flex items-center'>
          <Badge className='w-8 pb-1' size='small'>
            {index + 1}
          </Badge>
        </div>
      )}

      <SongMetadata song={song} />

      <div className='hidden gap-4 sm:flex'>
        <div className='flex items-center justify-end gap-2 xs:hidden md:flex'>
          {song.userMeta?.notes && <NotesBadge notes={song.userMeta.notes} />}

          {song.video && (
            <Badge className='w-8 p-0' colour='red' size='small'>
              <YoutubeIcon className='size-4' />
            </Badge>
          )}

          <TempoBadge song={song} />

          <KeyBadge song={song} />

          <AudioInfoBadge song={song} />

          {song.spotifyMeta?.track.id && (
            <SpotifyTrackLink trackId={song.spotifyMeta.track.id} />
          )}
        </div>

        <div className='flex items-center'>
          <IconButton
            className='transition-opacity group-hover:opacity-100 md:opacity-0'
            size='small'
            title='Remove Track'
            onClick={() => onClickDelete(id)}
          >
            <TrashIcon />
          </IconButton>
        </div>
      </div>
    </div>
  )
})
