import { observer } from 'mobx-react-lite'

import { useGameContext } from '@/pages/game/context/game-context'

interface ActiveDeviceWarningProps {
  setShowDeviceSelectorModal: (value: boolean) => void
}
export const ActiveDeviceWarning = observer(function ActiveDeviceWarning({
  setShowDeviceSelectorModal,
}: ActiveDeviceWarningProps) {
  const { tracker } = useGameContext()

  return (
    <div
      className='flex flex-col items-center justify-center gap-2 rounded-lg bg-red-500 p-4 text-white hover:cursor-pointer'
      onClick={() => {
        tracker.spotifyFixDeviceClicked()
        setShowDeviceSelectorModal(true)
      }}
    >
      <span className='text-center'>
        Your Spotify device is not responding.
      </span>{' '}
      <span className='text-sm font-semibold uppercase tracking-wide'>
        Fix Now
      </span>
    </div>
  )
})
