import { Song } from '@repo/types'

export function SongMetadata({ song }: { song: Song }) {
  return (
    <div className='flex w-full flex-col justify-center gap-0.5 overflow-hidden'>
      <span className='truncate leading-tight'>
        {song.title}
      </span>
      <span className='truncate text-sm font-semibold uppercase leading-tight tracking-tight text-muted'>
        {song.artist}
      </span>
      <span className='truncate text-xs leading-tight tracking-wide text-muted'>
        {song?.album}
      </span>
    </div>
  )
}
