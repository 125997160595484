import { merge } from '@repo/ui'
import { observer } from 'mobx-react-lite'
import React from 'react'

interface MainProps {
  children: React.ReactNode
  className?: string
}

export const Main = observer(function Main({ children, className }: MainProps) {
  return (
    <main className={merge('pt-6 lg:pt-12 pb-12 flex-1', className)}>
      {children}
    </main>
  )
})
