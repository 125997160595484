import { Playlist } from '@repo/types'
import { Description, Heading, List, Panel } from '@repo/ui'

import { checkPlaylist } from './duplicate-checker/checkPlaylist'
import { PlaylistTrack } from './PlaylistTrack'

interface PlaylistIssuesProps {
  onClickDelete: (id: number) => void
  playlist: Playlist
}

export function PlaylistIssues({
  onClickDelete,
  playlist,
}: PlaylistIssuesProps) {
  const { errors, warnings } = checkPlaylist(playlist)

  const hasIssues = Boolean(errors.length || warnings.length)

  if (!hasIssues) return null

  return (
    <Panel className='flex flex-col gap-6'>
      <div>
        <Heading size='large'>Some items need your attention</Heading>
        <Description className='mt-2'>
          Before you can play a game using this playlist, please correct the
          issues below.
        </Description>
      </div>

      {Boolean(errors.length) && (
        <div className='flex flex-col gap-4'>
          <div className='flex flex-col gap-2'>
            <Heading>Duplicates</Heading>
            <Description>
              For best results, please delete the extra versions of each song.
            </Description>
          </div>

          <List>
            {errors.map((song) => (
              <List.Item key={song.id}>
                <PlaylistTrack song={song} onClickDelete={onClickDelete} />
              </List.Item>
            ))}
          </List>
        </div>
      )}

      {Boolean(warnings.length) && (
        <div className='flex flex-col gap-4'>
          <div className='flex flex-col gap-2'>
            <Heading>Warnings</Heading>
            <Description>
              The following songs may be too similar. Please consider revising.
            </Description>
          </div>

          <List>
            {warnings.map((song) => (
              <List.Item key={song.id}>
                <PlaylistTrack song={song} onClickDelete={onClickDelete} />
              </List.Item>
            ))}
          </List>
        </div>
      )}
    </Panel>
  )
}
