import { PlanType, playerLimits } from '@repo/types'

import { SubscriptionData, parseSubscriptionData } from './parseSubscriptionData'

interface ParsedStripeRole {
  maxPlayers: number
  planType: PlanType
}

type GetProSubscriptionData = (userId: string) => Promise<SubscriptionData | undefined>

const DEFAULT_PLAN = {
  maxPlayers: playerLimits[PlanType.FREE],
  planType: PlanType.FREE
}

export async function parseStripeRole(
  userId: string,
  role: string | null,
  getProSubscriptionData: GetProSubscriptionData
): Promise<ParsedStripeRole> {
  if (!role) return DEFAULT_PLAN

  try {
    return parseStripeRoleAsJson(role)
  } catch {
    return parseStripeRoleAsString(userId, role, getProSubscriptionData)
  }
}

function parseStripeRoleAsJson(jsonRole: string): ParsedStripeRole {
  const parsed = JSON.parse(jsonRole) as { [key: string]: unknown }
  parsed.maxPlayers = parseInt(parsed.maxPlayers as string, 10)
  return parsed as unknown as ParsedStripeRole
}

async function parseStripeRoleAsString(
  userId: string,
  role: string,
  getProSubscriptionData: GetProSubscriptionData
): Promise<ParsedStripeRole> {
  if (role === PlanType.PRO) {
    const subscriptionData = await getProSubscriptionData(userId)
    const maxPlayersFromSubscription = parseSubscriptionData(subscriptionData)

    return {
      maxPlayers: maxPlayersFromSubscription || playerLimits[PlanType.PRO],
      planType: PlanType.PRO
    }
  }

  if (role === PlanType.BASIC) {
    return {
      maxPlayers: playerLimits[PlanType.BASIC],
      planType: PlanType.BASIC
    }
  }

  if (role === PlanType.PREMIUM) {
    return {
      maxPlayers: playerLimits[PlanType.PREMIUM],
      planType: PlanType.PREMIUM
    }
  }

  return DEFAULT_PLAN
}
