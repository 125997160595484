import { Game } from '@repo/types'
import { Panel } from '@repo/ui'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useLocation } from 'react-router-dom'

import { List } from '@/components/List'
import { NavigationLink } from '@/components/NavigationLink'

import { GameInfo } from './game-info'

interface GamesListProps {
  games: Game[]
}

export const GameList = observer(function GameList({ games }: GamesListProps): React.ReactElement {
  const { pathname } = useLocation()

  return (
    <>
      {games.length !== 0 && (
        <List style={{ margin: 0, padding: 0 }}>
          {games.map((game: Game) => (
            <List.Item key={game.gameId}>
              <NavigationLink className='w-full' to={`${pathname}/${game.gameId}`}>
                <GameInfo game={game} />
              </NavigationLink>
            </List.Item>
          ))}
        </List>
      )}

      {games.length === 0 && <Panel className='text-center'>No games were found</Panel>}
    </>
  )
})
