import { PlaybackOption, Playlist, PlaylistType, Song } from '@repo/types'
import {
  Badge,
  ButtonGroup,
  Container,
  Description,
  EditIcon,
  Heading,
  Label,
  MINIMUM_SONG_COUNT,
  Panel,
  ShuffleIcon,
  ToggleSwitch
} from '@repo/ui'
import { Dialog, useSuccessButton } from '@repo/ui/client'
import * as Sentry from '@sentry/react'
import { Field, Form, Formik, useFieldValue, useSetFieldValue } from 'formik'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'

import { AppFooter } from '@/components/AppFooter'
import { Button } from '@/components/Button'
import { ColourPaletteField } from '@/components/ColourPaletteSelector'
import { ExternalLink } from '@/components/ExternalLink'
import { IconButton } from '@/components/IconButton'
import { Main } from '@/components/Main'
import { Page } from '@/components/Page'
import { Title } from '@/components/Title'
import { WarningBadge } from '@/components/WarningBadge'
import { useDialogContext } from '@/dialogs/DialogContext'
import { MixpanelService } from '@/mixpanel'
import { ImageUpload } from '@/pages/settings/components/ImageUpload'
import { SettingsRow } from '@/pages/settings/components/SettingsRow'
import { shuffle } from '@/utils/shuffle'

import { AddMoreSongs } from './AddMoreSongs'
import { CopyPlaylistLink } from './CopyPlaylistLink'
import { PlaylistIssues } from './PlaylistIssues'
import { PlaylistTrackForm } from './PlaylistTrackForm'
import { PlaylistTracks } from './PlaylistTracks'

interface PlaylistFormProps {
  onSubmit: (values: Playlist) => void
  playlist: Playlist
}

export const PlaylistForm = observer(function PlaylistForm({
  onSubmit,
  playlist
}: PlaylistFormProps): React.ReactElement {
  const canDelete = playlist.songs.length > MINIMUM_SONG_COUNT

  const [selectedId, setSelectedId] = useState<number>()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const { showSuccess, showSuccessButtonState } = useSuccessButton()

  const [showEditDetailsModal, setShowEditDetailsModal] = useState(false)

  const { setConfirmPrompt, setErrorDialog } = useDialogContext()

  const handleClickPlaylistTrack = (id: number) => {
    setSelectedId(id)
  }

  const handleDismissModal = () => setSelectedId(undefined)

  const showPlaylistTrackModal = selectedId !== undefined

  const handleSubmitPlaylistTrackForm = async (values: Song) => {
    const songs = playlist.songs.map(item => (item.id === values.id ? values : item))

    const updatedPlaylist = { ...playlist, songs }

    await saveForm(updatedPlaylist)

    handleDismissModal()
  }

  const handleToggleShuffle = async () => {
    const updatedPlaylist = {
      ...playlist,
      disableShuffle: !playlist.disableShuffle
    }

    await saveForm(updatedPlaylist)

    MixpanelService.track('Playlist Editor: Setlist Mode Changed')
  }

  const saveForm = async (playlist: Playlist) => {
    setIsSubmitting(true)

    const updatedPlaylist = {
      ...playlist,
      songs: playlist.songs.map((song, index) => ({
        ...song,
        id: index
      }))
    }

    await onSubmit(updatedPlaylist)

    try {
      MixpanelService.track('Playlist Editor: Playlist Saved')
    } catch (error) {
      console.error(error)
      Sentry.captureException(error)
    }

    setIsSubmitting(false)

    await showSuccessButtonState()
  }

  const handleClickSave = () => {
    void saveForm(playlist)
  }

  const remapSongs = (updatedSongs: { id: number }[]) => {
    return updatedSongs.map((updatedSong, index) => {
      const song = playlist.songs.find(playlistSong => playlistSong.id === updatedSong.id)

      if (!song) throw new Error(`Could not find song '${updatedSong.id}'`)

      return {
        ...song,
        id: index
      }
    })
  }

  async function handleReorder(values: { id: number }[]) {
    const updatedPlaylist = {
      ...playlist,
      songs: remapSongs(values)
    }

    await saveForm(updatedPlaylist)
  }

  const handleShufflePlaylist = async () => {
    const shuffledPlaylist = shuffle([...playlist.songs])

    const updatedPlaylist = {
      ...playlist,
      songs: remapSongs(shuffledPlaylist)
    }

    await saveForm(updatedPlaylist)

    MixpanelService.track('Playlist Editor: Setlist Shuffled')
  }

  const getSelectedSong = (id: number): Song => {
    const song = playlist.songs.find(song => song.id === id)
    if (!song) throw new Error(`Could not find song '${id}'`)
    return song
  }

  const deleteSong = async (id: number) => {
    const updatedPlaylist = {
      ...playlist,
      songs: remapSongs(playlist.songs.filter(song => song.id !== id))
    }

    MixpanelService.track('Playlist Editor: Song Removed')

    await saveForm(updatedPlaylist)
  }

  const handleClickDeletePlaylistTrack = async (id: number) => {
    if (!canDelete) {
      setErrorDialog({
        error:
          'A playlist must have at least 75 songs. Please add more songs before removing this one.',
        title: 'Minimum Songs'
      })
      return
    }

    setConfirmPrompt({
      description: 'This action cannot be undone',
      heading: 'Are you sure you want to remove this song?',
      onConfirm: () => deleteSong(id)
    })
  }

  const handleAddTrack = (song: Song) => {
    const updatedPlaylist = {
      ...playlist,
      songs: [...playlist.songs, { ...song, id: playlist.songs.length }]
    }

    MixpanelService.track('Playlist Editor: Song Added')

    void saveForm(updatedPlaylist)
  }

  return (
    <Page title='Playlist Editor'>
      {!playlist?.playbackOptions?.length && (
        <Container className='mt-6' size='extra-large'>
          <Panel>
            <WarningBadge />
            <Description className='mt-2 max-w-none'>
              This playlist is not compatible with automatic playback. It will need to be managed
              manually.
            </Description>
          </Panel>
        </Container>
      )}

      <Main>
        <Container className='flex justify-between' size='extra-large'>
          <div
            className='group flex w-full flex-col gap-2 overflow-hidden hover:cursor-pointer'
            role='button'
            onClick={() => setShowEditDetailsModal(true)}
          >
            {playlist.disableShuffle && (
              <Badge className='mr-auto w-auto' colour='teal' size='small'>
                Setlist
              </Badge>
            )}

            <div className='flex gap-4 overflow-hidden'>
              <div className='flex flex-col gap-2 overflow-hidden'>
                <Heading className='overflow-hidden whitespace-nowrap' size='large'>
                  {playlist.title}
                </Heading>
                <Description className='flex gap-2'>
                  <span>{playlist.songs.length} songs</span>
                </Description>
                <Button text='Edit Details' variant='anchor' />
              </div>

              <div className='hidden opacity-0 group-hover:opacity-100 md:flex'>
                <IconButton
                  icon={<EditIcon />}
                  size='small'
                  title='Edit Details'
                  onClick={() => setShowEditDetailsModal(true)}
                />
              </div>
            </div>
          </div>

          <div className='hidden flex-col justify-center md:flex'>
            <CopyPlaylistLink playlistId={playlist.id} />
          </div>
        </Container>

        <Container className='mt-6 flex flex-col gap-6' size='extra-large'>
          <PlaylistIssues playlist={playlist} onClickDelete={handleClickDeletePlaylistTrack} />

          {playlist.playbackOptions.includes(PlaybackOption.SPOTIFY) && (
            <AddMoreSongs playlist={playlist} onClickAdd={handleAddTrack} />
          )}

          <div>
            <div className='flex h-16 items-center justify-between gap-4'>
              <Heading>Songs</Heading>

              {playlist.disableShuffle && (
                <div className='flex items-center'>
                  <IconButton
                    icon={<ShuffleIcon />}
                    title='Shuffle'
                    onClick={handleShufflePlaylist}
                  />
                </div>
              )}
            </div>

            <PlaylistTracks
              canReorder={playlist.disableShuffle}
              isCompact={true}
              playlist={playlist}
              onClick={handleClickPlaylistTrack}
              onClickDelete={handleClickDeletePlaylistTrack}
              onUpdate={handleReorder}
            />
          </div>
        </Container>
      </Main>

      <AppFooter>
        <Container size='medium'>
          <ButtonGroup direction='row'>
            <Button
              disabled={isSubmitting}
              isLoading={isSubmitting}
              loadingText='Saving...'
              showSuccess={showSuccess}
              text='Save Changes'
              onClick={handleClickSave}
            />
          </ButtonGroup>
        </Container>
      </AppFooter>

      <Dialog isVisible={showEditDetailsModal}>
        <Dialog.Header>
          <Dialog.Title>Edit Details</Dialog.Title>
          <Dialog.CloseButton onClick={() => setShowEditDetailsModal(false)} />
        </Dialog.Header>

        <Formik
          initialValues={{
            colour: playlist.colour,
            title: playlist.title,
            video: { url: playlist?.video?.url ?? '' },
            videoPreGame: { url: playlist?.videoPreGame?.url ?? '' },
            ...(playlist.type === PlaylistType.SYSTEM_PLAYLISTS && {
              isPaidOnly: playlist.systemMeta?.isPaidOnly,
              order: playlist?.order
            }),
            image: {
              url: playlist.image?.url ?? ''
            }
          }}
          onSubmit={async ({ colour, image, isPaidOnly, order, title, video, videoPreGame }) => {
            const updatedPlaylist = {
              ...playlist,
              colour,
              title,
              video,
              videoPreGame,
              ...(playlist.type === PlaylistType.SYSTEM_PLAYLISTS && {
                order,
                systemMeta: {
                  ...playlist.systemMeta,
                  isPaidOnly
                }
              }),
              image
            }

            await saveForm(updatedPlaylist)
            setShowEditDetailsModal(false)
          }}
        >
          {({ setFieldValue }) => (
            <Form
              className='flex flex-col gap-8'
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              <Dialog.Body className='overflow-visible'>
                <div className='flex flex-col gap-6'>
                  <div className='flex flex-col gap-2'>
                    <Label>Title</Label>
                    <Field name='title' />
                  </div>

                  <div className='flex flex-col gap-2'>
                    <Label>Colour</Label>
                    <ColourPaletteField />
                  </div>

                  {playlist.type === PlaylistType.SYSTEM_PLAYLISTS && (
                    <div className='flex flex-col gap-2'>
                      <Label>Order</Label>
                      <Field name='order' type='number' />
                    </div>
                  )}

                  {playlist.type === PlaylistType.SYSTEM_PLAYLISTS && (
                    <SettingsRow
                      className='px-0 md:px-0 lg:px-0'
                      description='Only paid subscribers can use this playlist to create a game.'
                      heading='Paid Only'
                    >
                      <TogglePaidOnly />
                    </SettingsRow>
                  )}

                  {playlist.type === PlaylistType.SYSTEM_PLAYLISTS && (
                    <ImageUpload
                      storageFolder='system_playlist_images'
                      onRemoveImage={() => {
                        setFieldValue('image.url', '')
                      }}
                      onUploadSuccess={({ url }) => {
                        setFieldValue('image.url', url)
                      }}
                    />
                  )}

                  <SettingsRow
                    className='px-0 md:px-0 lg:px-0'
                    description='By default, playlists are shuffled when creating a game. To maintain the order, enable this option.'
                    heading='Setlist Mode'
                  >
                    <ToggleSwitch
                      id='setlist-mode'
                      isChecked={Boolean(playlist.disableShuffle)}
                      onChange={handleToggleShuffle}
                    />
                  </SettingsRow>

                  <div className='flex flex-col gap-4'>
                    <Title
                      description={
                        <>
                          You can provide Youtube or Vimeo URLs to play on a video screen.{' '}
                          <ExternalLink href='https://go.rockstar.bingo/jumbotron'>
                            Learn more
                          </ExternalLink>
                        </>
                      }
                      heading='Jumbotron Videos'
                    />

                    <div className='flex flex-col gap-6'>
                      <div className='flex flex-col gap-2'>
                        <Label>Default Video</Label>
                        <Field name='video.url' />
                      </div>

                      <div className='flex flex-col gap-2'>
                        <Label>Pre-game Video</Label>
                        <Field name='videoPreGame.url' />
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Body>
              <Dialog.Footer>
                <Button
                  isLoading={isSubmitting}
                  showSuccess={showSuccess}
                  text='Save'
                  type='submit'
                />
              </Dialog.Footer>
            </Form>
          )}
        </Formik>
      </Dialog>

      <PlaylistTrackForm
        isLoading={isSubmitting}
        isVisible={showPlaylistTrackModal}
        showSuccess={showSuccess}
        song={selectedId !== undefined ? getSelectedSong(selectedId) : undefined}
        onClose={handleDismissModal}
        onSubmit={handleSubmitPlaylistTrackForm}
      />
    </Page>
  )
})

function TogglePaidOnly() {
  const setFieldValue = useSetFieldValue()
  const [fieldValue] = useFieldValue('isPaidOnly')

  return (
    <ToggleSwitch
      id='paid-only'
      isChecked={Boolean(fieldValue)}
      onChange={event => setFieldValue('isPaidOnly', event.target.checked)}
    />
  )
}
