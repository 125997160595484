import { Collections, MessageEvent } from '@repo/types'
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
} from 'firebase/firestore'

import { databaseRef } from '@/firebase/firebase'

import SnapshotListener from './AbstractSnapshotListener'
import { GameStore } from '../GameStore'

class MessageQueueListener extends SnapshotListener {
  public subscribe(game: GameStore): void {
    const messageQueueCollection = collection(
      databaseRef,
      Collections.GAMES,
      game.gameId,
      Collections.MESSAGE_QUEUE
    )

    const messageQueueQuery = query(
      messageQueueCollection,
      where('dismissed', '==', false),
      orderBy('timestamp', 'asc')
    )

    this._unsubscribe = onSnapshot(
      messageQueueQuery,
      (docSnapshot) => {
        const [currentMessage] = docSnapshot.docs.map(
          (doc) => doc.data() as MessageEvent
        )

        if (docSnapshot.empty) {
          game.apply({ currentMessage: null })
        } else {
          game.apply({ currentMessage })
        }
      },
      (err) => {
        console.log(`Encountered error: ${err.message}`)
      }
    )
  }
}

export const messageQueueListener = new MessageQueueListener()
