import { Song } from '@repo/types'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { MixpanelService } from '@/mixpanel'
import { useQuerySpotifyPlaylists } from '@/store/integrations/spotify/queries/useQuerySpotifyPlaylists'
import { useSpotify } from '@/store/integrations/spotify/useSpotify'

import { ImportSpotifyPlaylistLibraryView } from './import-spotify-library-view'
import { SpotifyConnectTextPanel } from '../SpotifyConnectTextPanel'

interface ImportSpotifyPlaylistLibraryContainerProps {
  baseUrl?: string
  onSubmit: (
    playlistTitle: string,
    playlistTracks: Song[]
  ) => Promise<{ playlistId: string }>
}

export const ImportSpotifyPlaylistLibraryContainer = observer(
  function ImportSpotifyPlaylistLibraryContainer({
    baseUrl = '/',
    onSubmit,
  }: ImportSpotifyPlaylistLibraryContainerProps): React.ReactElement {
    const [selectedPlaylistId, setSelectedPlaylistId] = useState('')

    const { auth, playlistService } = useSpotify()

    const { isAuthenticated } = auth
    const history = useHistory()
    const [isLoadingPlaylist, setIsLoadingPlaylist] = useState(false)

    const { data = [] } = useQuerySpotifyPlaylists()

    const handleSubmit = useCallback(async () => {
      setIsLoadingPlaylist(true)

      const playlist =
        await playlistService.queryPlaylistById(selectedPlaylistId)

      if (!playlist) return

      const { songs, title } = playlist

      const { playlistId } = await onSubmit(title, songs)
      MixpanelService.importPlaylistSpotifyLibrary(
        playlistId,
        title,
        songs.length
      )

      setIsLoadingPlaylist(false)

      history.push(`${baseUrl}playlists/edit/${playlistId}`)
    }, [history, baseUrl, onSubmit, selectedPlaylistId, playlistService])

    const handleChange = (playlistId: string) => {
      setSelectedPlaylistId(playlistId)
    }

    if (!isAuthenticated) {
      return <SpotifyConnectTextPanel />
    }

    return (
      <ImportSpotifyPlaylistLibraryView
        canSubmit={Boolean(selectedPlaylistId)}
        handleChange={handleChange}
        isLoading={isLoadingPlaylist}
        playlists={data}
        selectedPlaylistId={selectedPlaylistId}
        onCancel={() => setSelectedPlaylistId('')}
        onSubmit={handleSubmit}
      />
    )
  }
)
