export enum BillingInterval {
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export interface Feature {
  description: string
  id: string
  isProOnly?: boolean
  title: string
  videoUrl?: string
}

export interface PlanSelection {
  interval?: BillingInterval
  planType: PlanTypeV2 | 'free'
  quantity?: number
}

export enum PlanType {
  FREE = 'free',
  BASIC = 'basic',
  PREMIUM = 'premium',
  PRO = 'pro',
}

export const PLANTYPES = {
  basic: {
    monthly: {
      priceId: {
        local: 'price_1MgLBABesJw40VXFML1fixzj',
        production: 'price_1MtMTABesJw40VXFyDsoFEEw',
        staging: 'price_1MgLBABesJw40VXFML1fixzj',
      },
      rate: 1400,
    },
    name: 'Basic',
    yearly: {
      priceId: {
        local: 'price_1MgLBABesJw40VXFoTvMpN1g',
        production: 'price_1MtMTABesJw40VXFDj7w39Gb',
        staging: 'price_1MgLBABesJw40VXFoTvMpN1g',
      },
      rate: 14000,
    },
  },
  free: {
    name: 'Free',
  },
  pro: {
    monthly: {
      priceId: {
        local: 'price_1QEbmYBesJw40VXFg3zNeBS8',
        production: 'price_1QEc9wBesJw40VXFn8ETy3tu',
        staging: 'price_1QEbmYBesJw40VXFg3zNeBS8',
      },
    },
    name: 'Pro',
    quantity: {
      maximum: 500,
      minimum: 20,
    },
    yearly: {
      priceId: {
        local: 'price_1QEbw2BesJw40VXFhnuumrdo',
        production: 'price_1QEcAwBesJw40VXFiqML0nqw',
        staging: 'price_1QEbw2BesJw40VXFhnuumrdo',
      },
    },
  },
} as const

export type PlanTypeV2 = keyof typeof PLANTYPES

export const playerLimits: { [key in PlanType]: number } = {
  [PlanType.FREE]: 5,
  [PlanType.BASIC]: 10,
  [PlanType.PREMIUM]: 30,
  [PlanType.PRO]: 20,
}

export const isBillingInterval = (
  input?: string | null
): input is BillingInterval => {
  if (!input) return false

  return [BillingInterval.MONTHLY, BillingInterval.YEARLY].includes(
    input as BillingInterval
  )
}

export const isPlanTypeV2 = (input?: string | null): input is PlanTypeV2 => {
  if (!input) return false

  return Object.keys(PLANTYPES).includes(input)
}
