import { observer } from 'mobx-react-lite'
import React from 'react'

import { useQuerySystemPlaylists } from '@/pages/playlists/playlist-queries/system-playlists/useQuerySystemPlaylists'

import { ManagePlaylistsContainer } from './manage-playlists-container'
import { useDeleteSystemPlaylist } from '../playlist-queries/system-playlists/useDeleteSystemPlaylist'

export const ManagePlaylistsRouteAdmin = observer(
  function ManagePlaylistsRouteAdmin(): React.ReactElement {
    const { data = [], isLoading: isLoadingPlaylists } =
      useQuerySystemPlaylists()
    const { isLoading: isMutating, mutate } = useDeleteSystemPlaylist()

    const isLoading = isLoadingPlaylists || isMutating

    const handleDeletePlaylist = (playlistId: string) => {
      mutate(playlistId)
    }

    return (
      <ManagePlaylistsContainer
        baseUrl='/admin/'
        isLoading={isLoading}
        playlists={data}
        onDeletePlaylist={handleDeletePlaylist}
      />
    )
  }
)
