import { Device } from '@repo/types'
import * as Sentry from '@sentry/react'
import { makeAutoObservable, reaction, when } from 'mobx'

import { queryClient } from '@/queryClient'
import {
  fetchIntegrationSettings,
  SpotifySettingsListener,
} from '@/store/integrations/spotify/listeners/SpotifySettingsListener'
import { Store } from '@/store/Store'
import StoreService from '@/store/StoreService'
import { applyProperties } from '@/store/utils'
import { backoff } from '@/utils/backoff'

import { applyActiveDevice } from './queries/useQuerySpotifyDevices'
import { SpotifyIntegration } from './SpotifyIntegrationModel'
import { IntegrationDeviceService } from '..'

export class SpotifyDeviceService implements IntegrationDeviceService {
  private spotify: SpotifyIntegration
  public settingsListener

  public isReady = false
  public isActivatingDevice = false

  public constructor(spotify: SpotifyIntegration) {
    makeAutoObservable(this)

    this.spotify = spotify
    this.settingsListener = new SpotifySettingsListener()
  }

  private get store(): Store {
    return StoreService.getStore()
  }

  get query() {
    return {
      fn: () => this.spotify.client.getDevices(),
      key: ['spotify-devices', this.store.user.userId, this.spotify.auth.email],
    } as const
  }

  get isSessionMatch() {
    const { storedDeviceIdLocal, storedDeviceIdRemote } = this.spotify.devices
    return storedDeviceIdLocal === storedDeviceIdRemote
  }

  public async init() {
    try {
      await when(
        () => this.store.user.isLoggedIn && this.spotify.auth.isAuthenticated
      )

      await fetchIntegrationSettings()

      const queryResult = await this.queryDevices()
      applyActiveDevice(this.spotify.devices, queryResult)

      const { enableExternalDevices } = this.spotify.devices

      const shouldEnableInternalDevice =
        !enableExternalDevices &&
        (this.isSessionMatch || !this.spotify.devices.activeDevice)

      if (shouldEnableInternalDevice) {
        console.log('Enabling internal device...')
        this.spotify.playerService.apply({ isEnabled: true })
        this.spotify.playerService.init()
        await when(() => this.spotify.playerService.isReady)

        await this.activateInternalDevice()
      } else {
        console.log('Internal device disabled. Skipping...')
      }

      this.settingsListener.subscribe(this.store.user.userId, this.spotify.id)

      reaction(
        () => ({
          deviceId: this.spotify.devices.storedDeviceIdRemote,
          timestamp: this.spotify.devices.storedDeviceIdRemoteTimestamp,
        }),
        async ({ deviceId }) => {
          console.log('Remote device change detected. Refreshing devices...')
          const queryDevices = await this.queryDevices()
          applyActiveDevice(this.spotify.devices, queryDevices)

          if (
            deviceId !== this.spotify.player.deviceId &&
            this.spotify.playerService.isEnabled
          ) {
            console.log(
              'A different internal player is active in another tab. Disconnecting internal player...'
            )
            this.spotify.playerService.apply({ isEnabled: false })
            this.spotify.player.playerElement.disconnect()
          }
        }
      )

      document.addEventListener('visibilitychange', async () => {
        if (
          document.visibilityState === 'visible' &&
          this.spotify.playerService.isEnabled &&
          !this.spotify.devices.activeDevice
        ) {
          console.log(
            'Document has become visible. Internal device inactive. Reactivating...'
          )
          await this.activateInternalDevice()
        }
      })

      reaction(
        () => !this.spotify.devices.activeDevice,
        async () => {
          if (this.spotify.playerService.isEnabled) {
            console.log('Internal device inactive. Reactivating...')
            await this.activateInternalDevice()
          }
        }
      )
    } catch (error) {
      console.error(error)
      Sentry.captureException(error)
    } finally {
      this.apply({ isReady: true })
    }
  }

  public async enableInternalDevice() {
    if (this.spotify.playerService.isEnabled) {
      console.log('Internal device already enabled. Skipping...')
      await when(() => this.spotify.playerService.isReady)
      return
    }

    console.log('Enabling internal device...')
    this.spotify.playerService.apply({ isEnabled: true })
    this.spotify.playerService.init()
    await when(() => this.spotify.playerService.isReady)
  }

  public async activateInternalDevice(retryCount = 0, maxRetries = 8) {
    try {
      this.apply({ isActivatingDevice: true })

      const { deviceId } = this.spotify.player

      if (!deviceId) {
        throw new Error(
          'Could not activate internal device. Device ID not found'
        )
      }

      const devices = await this.queryDevices()
      const activeDevice = devices.find((device) => device.isActive)

      if (activeDevice?.id === deviceId) {
        console.log('Device already active. Skipping...')
        return
      }

      await this.spotify.player.activateElement()

      console.log('Transferring playback to internal device', deviceId)

      await this.transferPlayback(deviceId)

      const internalDevice = await this.queryActiveDevice(deviceId, () =>
        this.queryDevices()
      )

      if (!internalDevice) {
        console.log('Internal Device not found. Reconnecting player...')
        await this.reconnectPlayerElement()
        return
      }

      this.spotify.devices.setActiveDevice(internalDevice)
    } catch (error) {
      console.error(
        'Error activating internal device. Attempting to reconnect...',
        error
      )

      try {
        await this.reconnectPlayerElement()
      } catch (reconnectError) {
        console.error('Failed to reconnect player element:', reconnectError)
      }

      if (retryCount < maxRetries) {
        try {
          await backoff(retryCount)
          console.log(`Retrying activation... Attempt: ${retryCount + 1}`)
          await this.activateInternalDevice(retryCount + 1, maxRetries)
        } catch (retryError) {
          console.error('Error during retry:', retryError)
        }
      } else {
        console.log('Max retry attempts reached. Aborting...')
      }
    } finally {
      this.apply({ isActivatingDevice: false })
    }
  }

  public async activateExternalDevice(
    deviceId: string,
    retryCount = 0,
    maxRetries = 8
  ) {
    try {
      this.apply({ isActivatingDevice: true })

      if (!deviceId) {
        throw new Error(
          'Could not activate external device. Device ID not found'
        )
      }

      const devices = await this.queryDevices()
      const activeDevice = devices.find((device) => device.isActive)

      if (activeDevice?.id === deviceId) {
        console.log('Device already active. Skipping...')
        return
      }

      console.log('Transferring playback to external device', deviceId)
      await this.transferPlayback(deviceId)

      const externalDevice = await this.queryActiveDevice(deviceId, () =>
        this.queryDevices()
      )

      if (!externalDevice) {
        console.log('External device not found.')
        return
      }

      this.spotify.devices.setActiveDevice(externalDevice)
    } catch (error) {
      if (retryCount < maxRetries) {
        await backoff(retryCount)

        console.log(`Retrying activation... Attempt: ${retryCount + 1}`)
        await this.activateExternalDevice(deviceId, retryCount + 1, maxRetries)
      } else {
        console.log('Max retry attempts reached. Aborting...')
      }
    } finally {
      this.apply({ isActivatingDevice: false })
    }
  }

  private async reconnectPlayerElement() {
    await this.spotify.playerService.disconnect()
    console.log('Reconnecting player element...')
    return this.spotify.playerService.connect()
  }

  public transferPlayback(deviceId: string) {
    return this.spotify.client.transferPlayback(deviceId)
  }

  public queryDevices() {
    if (!this.spotify.auth.isAuthenticated) {
      console.log('Spotify is not authenticated. Skipping devices query...')
      return Promise.resolve([])
    }

    console.log('Querying devices...')
    return queryClient.fetchQuery(this.query.key, this.query.fn)
  }

  async queryActiveDevice(
    deviceId: string,
    queryDevices: () => Promise<Device[]>
  ) {
    const maxRetryAttempts = 8
    let attempt = 0

    while (attempt < maxRetryAttempts) {
      attempt++

      try {
        console.log(`Querying devices. Attempt: ${attempt}`)

        const devices = await queryDevices()
        console.log('Devices query result: ', devices)

        const device = devices.find((device) => device.id === deviceId)

        if (!device) {
          console.log('Device not found')
        } else if (!device.isActive) {
          console.log('Device found but is still inactive')
        } else {
          console.log('Device is active')
          return device
        }

        if (attempt === maxRetryAttempts) {
          throw new Error(
            `Device ${
              !device ? 'not found' : 'not active'
            } after maximum retry attempts`
          )
        }
      } catch (error) {
        console.error(`Error querying devices: ${error}`)
        // Throw immediately if it's the last attempt
        if (attempt === maxRetryAttempts) throw error
      }

      await backoff(attempt)
    }
  }

  apply(values: Partial<SpotifyDeviceService>) {
    applyProperties(this, values)
  }
}
