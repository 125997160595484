import { Collections } from '@repo/types'
import { Panel, Spinner, Heading, Container, Description } from '@repo/ui'
import * as Sentry from '@sentry/react'
import { doc, getDoc, setDoc } from 'firebase/firestore'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useQuery } from 'react-query'
import { useHistory, useParams } from 'react-router-dom'

import { AppHeader } from '@/components/AppHeader'
import { BackButton, CloseButton } from '@/components/icon-buttons/IconButtons'
import { Main } from '@/components/Main'
import { Page } from '@/components/Page'
import { PageHeading } from '@/components/PageHeading'
import { databaseRef } from '@/firebase/firebase'
import { MixpanelService } from '@/mixpanel'
import { compareChanges } from '@/mixpanel/utils'
import {
  VenueForm,
  VenueFormValues,
} from '@/pages/settings/components/VenueForm'
import StoreService from '@/store/StoreService'

export const VenueUpdateRoute = observer(
  function VenueUpdateRoute(): React.ReactElement {
    const { venueId } = useParams<{ venueId?: string }>()

    if (!venueId) return <Panel>Invalid venue ID.</Panel>

    return <VenueUpdatePage venueId={venueId} />
  }
)

interface VenueUpdatePageProps {
  venueId: string
}

const VenueUpdatePage = observer(function VenueUpdatePage({
  venueId,
}: VenueUpdatePageProps): React.ReactElement {
  const history = useHistory()

  const handleClickBack = () => {
    history.goBack()
  }

  const handleClickClose = () => {
    history.push('/')
  }

  return (
    <Page title='Venue Settings'>
      <AppHeader
        actionsLeft={<BackButton onClick={handleClickBack} />}
        actionsRight={<CloseButton onClick={handleClickClose} />}
        header={<PageHeading>Venue Settings</PageHeading>}
      />

      <Main>
        <Container size='medium'>
          <Heading>Update Venue</Heading>
          <Description>Edit venue details using the form below.</Description>
        </Container>

        <Container className='p-6 px-4' size='medium'>
          <Panel>
            <VenueFormContainer venueId={venueId} />
          </Panel>
        </Container>
      </Main>
    </Page>
  )
})

const QUERY_KEY = 'update-venue'

async function updateVenue(
  userId: string,
  venueId: string,
  data: VenueFormValues
) {
  const docRef = doc(
    databaseRef,
    Collections.USERS,
    userId,
    Collections.VENUES,
    venueId
  )
  return setDoc(docRef, data)
}

export async function fetchVenue(
  userId: string,
  venueId: string
): Promise<VenueFormValues> {
  const docRef = doc(
    databaseRef,
    Collections.USERS,
    userId,
    Collections.VENUES,
    venueId
  )
  const docSnapshot = await getDoc(docRef)

  if (!docSnapshot.exists()) throw new Error('Venue not found')

  return docSnapshot.data() as VenueFormValues
}

interface VenueFormContainerProps {
  venueId: string
}

const VenueFormContainer = observer(function VenueFormContainer({
  venueId,
}: VenueFormContainerProps) {
  const { user } = StoreService.getStore()

  const { data, isError, isLoading } = useQuery<VenueFormValues, Error>(
    [QUERY_KEY, venueId],
    () => fetchVenue(user.userId, venueId)
  )

  const handleSubmit = async (values: VenueFormValues) => {
    await updateVenue(user.userId, venueId, values)

    if (data) {
      try {
        MixpanelService.track('Venue Updated', compareChanges(data, values))
      } catch (error) {
        Sentry.captureException(error)
      }
    }

    return venueId
  }

  if (isLoading) return <Spinner label='Loading venue details...' />

  if (isError || !data)
    return <Panel>There was a problem loading this venue.</Panel>

  return <VenueForm initialValues={data} onSubmit={handleSubmit} />
})
