import { ButtonProps, Button as WrappedButton } from '@repo/ui'
import { forwardRef } from 'react'

import { NavigationLink } from './NavigationLink'

interface LinkAdapter {
  children: React.ReactNode
  href: string
}

function LinkAdapter({ children, ...props }: LinkAdapter) {
  return (
    <NavigationLink {...props} to={props.href}>
      {children}
    </NavigationLink>
  )
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props: ButtonProps, ref) => {
    return <WrappedButton {...props} LinkComponent={LinkAdapter} ref={ref} />
  }
)

Button.displayName = 'Button'
