import { merge, Heading, HeadingProps } from '@repo/ui'
import { observer } from 'mobx-react-lite'

export const PageHeading = observer(function PageHeading({
  children,
  className,
  size = 'small',
}: HeadingProps) {
  return (
    <Heading className={merge('md:justify-center', className)} size={size}>
      {children}
    </Heading>
  )
})
