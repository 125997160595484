import { PlanType } from './billing'
import { UserSettings } from './host'

export enum AuthProvider {
  EMAIL = 'Email',
  GOOGLE = 'Google',
  SPOTIFY = 'Spotify',
}

export interface User {
  [key: string]: unknown
  email: string
  hasAccount: boolean
  isAnonymous?: boolean
  maxPlayers: number
  name: string
  planType: PlanType
  settings?: UserSettings
  signInProvider?: string
  userId: string
}
