import { PeopleIcon } from '@repo/ui'
import { observer } from 'mobx-react-lite'
import React from 'react'
import styled from 'styled-components'

import { IconButton } from './IconButton'
import { useGameContext } from '../pages/game/context/game-context'

interface NotificationIconProps {
  url: string
}

export const LobbyIcon = observer(function LobbyIcon({
  url,
}: NotificationIconProps): React.ReactElement {
  const { playersInLobby } = useGameContext()

  const notificationCount = playersInLobby.length

  return (
    <IconButton icon={<PeopleIcon />} title='Lobby' url={url}>
      {notificationCount > 0 && (
        <BadgeStyled>
          <span>{notificationCount}</span>
        </BadgeStyled>
      )}
    </IconButton>
  )
})

const BadgeStyled = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: var(--orange-500);
  color: #181818;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 800;
  font-size: 0.9em;
  position: absolute;
  transform: translate(50%, 50%);

  span {
    position: relative;
    top: -1.5px;
    letter-spacing: -1.2px;
  }
`
