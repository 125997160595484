export function groupBy<T extends { [key: string]: unknown }>(
  prop: string,
  array: T[]
): { [key: string]: T[] } {
  return array.reduce(function (groups: { [key: string]: T[] }, item: T) {
    const val = item[prop] as string
    groups[val] = groups[val] || []
    groups[val].push(item)
    return groups
  }, {})
}
