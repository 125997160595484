import { getErrorMessage } from '@repo/lib'
import * as Sentry from '@sentry/browser'

import { useDialogContext } from '@/dialogs/DialogContext'
import { useGameContext } from '@/pages/game/context/game-context'

import { SpotifyErrorMessage } from '../types'
import { useSpotify } from '../useSpotify'

interface UseSpotifyPlaybackErrorOptions {
  onError?: () => void
  setShowDeviceSelectorModal: (show: boolean) => void
}

export function useSpotifyPlaybackError({
  onError,
  setShowDeviceSelectorModal
}: UseSpotifyPlaybackErrorOptions) {
  const { setErrorDialog } = useDialogContext()
  const { tracker } = useGameContext()
  const { deviceService } = useSpotify()

  const handlePlaybackError = (error: unknown) => {
    onError?.()

    const errorMessage = getErrorMessage(error)

    if (
      errorMessage === SpotifyErrorMessage.NOT_FOUND ||
      errorMessage === SpotifyErrorMessage.DEVICE_NOT_FOUND ||
      errorMessage === SpotifyErrorMessage.BAD_GATEWAY ||
      errorMessage === SpotifyErrorMessage.SERVER_ERROR
    ) {
      tracker.spotifyDeviceError()

      void deviceService.queryDevices()

      setShowDeviceSelectorModal(true)
      return
    }

    if (errorMessage === SpotifyErrorMessage.INVALID_AUTH_TOKEN) {
      setShowDeviceSelectorModal(true)
      return
    }

    if (errorMessage === SpotifyErrorMessage.PREMIUM_REQUIRED) {
      tracker.spotifyPremiumError()

      setErrorDialog({
        error: 'Spotify Premium account is required to control playback'
      })

      return
    }

    if (errorMessage === SpotifyErrorMessage.INVALID_TRACK) {
      tracker.spotifyTrackError()

      setErrorDialog({
        error: 'There was a problem playing this song. Please skip ahead.'
      })

      return
    }

    setErrorDialog({ error: 'There was a problem connecting to Spotify.' })
  }

  const withErrorHandling = async (action: () => Promise<void>) => {
    try {
      return await action()
    } catch (error) {
      handlePlaybackError(error)
      Sentry.captureException(error)
    }
  }

  return {
    withErrorHandling
  }
}
