import { apiClient } from '@/api/api-client'
import StoreService from '@/store/StoreService'

import { useDeletePlaylist } from '../useDeletePlaylist'

export function useDeleteSystemPlaylist() {
  const { playlistService } = StoreService.getStore()

  const mutationFn = (playlistId: string) =>
    apiClient.adminDeleteSystemPlaylist(playlistId)

  const { queries } = playlistService
  const { queryFn, queryKey } = queries.systemPlaylists

  return useDeletePlaylist(mutationFn, queryKey, queryFn)
}
