import { Playlist } from '@repo/types'
import { Badge } from '@repo/ui'
import { ScrollContainer } from '@repo/ui/client'
import { observer } from 'mobx-react-lite'

import { List } from '@/components/List'
import { SongMetadata } from '@/pages/playlists/playlist-editor/SongMetadata'
import { shuffle } from '@/utils/shuffle'

interface PlaylistPreviewProps {
  playlist: Playlist
}

export const PlaylistPreview = observer(function PlaylistPreview({
  playlist,
}: PlaylistPreviewProps) {
  const displayedPlaylist = playlist.disableShuffle
    ? playlist.songs
    : shuffle([...playlist.songs])

  return (
    <div className='flex flex-col overflow-hidden'>
      <h4 className='mb-4 mt-0 flex items-center'>
        <span className='mr-2 text-muted'>Possible Songs:</span>{' '}
        <Badge size='small'>{playlist.songs.length}</Badge>
      </h4>

      <ScrollContainer className='rounded-lg border border-shade-3 bg-shade-1'>
        <List className='border-0' tabIndex={0}>
          {displayedPlaylist.map((song) => (
            <List.Item key={song.id} disableBorder>
              <SongMetadata song={song} />
            </List.Item>
          ))}
        </List>
      </ScrollContainer>
    </div>
  )
})
