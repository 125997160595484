import { Playlist } from '@repo/types'
import { observer } from 'mobx-react-lite'
import React from 'react'

import { useDialogContext } from '@/dialogs/DialogContext'

import { ManagePlaylistsView } from './manage-playlists-view'

interface ManagePlaylistsContainerProps {
  baseUrl?: string
  isLoading: boolean
  onDeletePlaylist: (playlistId: string) => void
  playlists: readonly Playlist[]
}

export const ManagePlaylistsContainer = observer(
  function ManagePlaylistsContainer({
    baseUrl,
    isLoading,
    onDeletePlaylist,
    playlists,
  }: ManagePlaylistsContainerProps): React.ReactElement {
    const { setConfirmPrompt } = useDialogContext()

    const handleDeletePlaylistClick = (playlistId: string) => {
      setConfirmPrompt({
        description: 'This action cannot be undone',
        heading: 'Are you sure you want to delete this playlist?',
        onConfirm: () => onDeletePlaylist(playlistId),
      })
    }

    return (
      <ManagePlaylistsView
        baseUrl={baseUrl}
        isLoading={isLoading}
        playlists={playlists}
        onDeletePlaylistClick={handleDeletePlaylistClick}
      />
    )
  }
)
