import { observer } from 'mobx-react-lite'
import React from 'react'

import { CopyLink } from '@/components/CopyLink'
import StoreService from '@/store/StoreService'

import { MixpanelService } from '../../../mixpanel'

interface CopyPlaylistLinkProps {
  playlistId: string
}

export const CopyPlaylistLink = observer(function CopyPlaylistLink({
  playlistId,
}: CopyPlaylistLinkProps): React.ReactElement {
  const { user } = StoreService.getStore()

  const hostUrlSlug = user?.settings?.joinScreen?.url ?? user.userId

  const handleClick = () => {
    MixpanelService.track('Playlist Editor: Shareable Link Copied')
  }

  return (
    <CopyLink
      buttonVariant='outlined'
      link={`${window.location.origin}/${hostUrlSlug}/playlist/${playlistId}`}
      text='Copy Link'
      hideInput
      onClickCopy={handleClick}
    />
  )
})
