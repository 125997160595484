import { AuthProvider } from '@repo/types'

export function getSignInProvider(
  firebaseProvider: string | null | undefined
): AuthProvider {
  switch (firebaseProvider) {
    case 'custom': {
      return AuthProvider.SPOTIFY
    }

    case 'google.com': {
      return AuthProvider.GOOGLE
    }

    default: {
      return AuthProvider.EMAIL
    }
  }
}
