import { Button, ButtonGroup, Description } from '@repo/ui'
import { observer } from 'mobx-react-lite'

import { useTrackEvent } from '@/hooks/useTrackEvent'

import { useSpotify } from '../../useSpotify'

export const PlaybackSyncWarning = observer(function PlaybackSyncWarning() {
  const { deviceService, playback, player } = useSpotify()

  useTrackEvent('Playback Sync Warning Shown', {
    'Game Selected Track': `${playback.selectedTrack?.artist} - ${playback.selectedTrack?.title}`,
    'Player Current Track': `${player.currentTrack?.artists[0].name} - ${player.currentTrack?.name}`,
  })

  return (
    <div className='flex flex-col justify-center gap-4 text-center'>
      <Description className='mx-auto text-center'>
        Spotify is playing a song that is not in sync with the current game.
      </Description>

      <ButtonGroup>
        <Button
          text={`Play '${playback.selectedTrack.title}'`}
          onClick={async () => {
            await deviceService.activateInternalDevice()
            playback.play(playback.selectedTrack)
          }}
        />
        <Button
          text='Next Song'
          variant='outlined'
          onClick={async () => {
            await deviceService.activateInternalDevice()
            playback.next()
          }}
        />
      </ButtonGroup>
    </div>
  )
})
