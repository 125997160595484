type Options = {
  baseDelay?: number
  maxDelay?: number
}

export function backoff(
  attempt: number,
  { baseDelay = 100, maxDelay = 20000 }: Options = {}
) {
  // Exponential backoff with jitter
  const backoffTime = Math.min(
    baseDelay * Math.pow(2, attempt) + Math.random() * 100,
    maxDelay
  ) // Cap the backoff time to 20 seconds
  console.log(`Waiting ${backoffTime.toFixed(0)} ms before next retry.`)
  return new Promise((resolve) => setTimeout(resolve, backoffTime))
}
