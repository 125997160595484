import { Collections, Playlist } from '@repo/types'
import {
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
} from 'firebase/firestore'

import { databaseRef } from '@/firebase/firebase'
import { queryClient } from '@/queryClient'
import StoreService from '@/store/StoreService'
import { fetchHostIdByCustomUrl } from '@/store/user/fetchHostIdByCustomUrl'
import { MINIMUM_ITEM_COUNT } from '@/utils/constants'

import { PlaylistSchema } from '../playlist-import/playlist-schema'

export class InternalPlaylistService {
  get user() {
    const store = StoreService.getStore()
    return store.user
  }

  get sourceUserId() {
    if (this.user.hasParent && this.user.inherit.includes('playlists')) {
      return this.user.parentId
    }

    return this.user.userId
  }

  get queries() {
    return {
      systemPlaylist: {
        queryFn: (playlistId: string) => this.fetchSystemPlaylist(playlistId),
        queryKey: (playlistId: string) => [
          Collections.SYSTEM_PLAYLISTS,
          `playlistId:${playlistId}`,
        ],
      },

      systemPlaylists: {
        queryFn: () => this.fetchSystemPlaylists(),
        queryKey: [Collections.SYSTEM_PLAYLISTS],
      },

      userPlaylist: {
        queryFn: (playlistId: string) => this.fetchUserPlaylist(playlistId),
        queryKey: (playlistId: string) => [
          Collections.USER_PLAYLISTS,
          `userId:${this.sourceUserId}`,
          `playlistId:${playlistId}`,
        ],
      },

      userPlaylistPublic: {
        queryFn: (hostIdOrCustomUrl: string, playlistId: string) =>
          this.fetchUserPlaylistPublic(hostIdOrCustomUrl, playlistId),
        queryKey: (hostIdOrCustomUrl: string, playlistId: string) => [
          Collections.USER_PLAYLISTS,
          'public',
          `hostIdOrCustomUrl:${hostIdOrCustomUrl}`,
          `playlistId:${playlistId}`,
        ],
      },

      userPlaylists: {
        queryFn: () => this.fetchUserPlaylists(),
        queryKey: [Collections.USER_PLAYLISTS, `userId:${this.sourceUserId}`],
      },
    }
  }

  async fetchUserPlaylists(): Promise<Playlist[]> {
    const userPlaylistsCollection = collection(
      databaseRef,
      Collections.USERS,
      this.sourceUserId,
      Collections.USER_PLAYLISTS
    )

    const userPlaylistsQuery = query(
      userPlaylistsCollection,
      orderBy('timestamp', 'desc')
    )

    const userPlaylistsSnapshot = await getDocs(userPlaylistsQuery)

    if (userPlaylistsSnapshot.empty) return []

    const userPlaylists: Playlist[] = []

    userPlaylistsSnapshot.docs.forEach((doc) => {
      const playlist = doc.data() as Playlist
      playlist.id = doc.id
      userPlaylists.push(playlist)
    })

    const validUserPlaylists = userPlaylists.filter((playlist) => {
      const parsed = PlaylistSchema.safeParse(playlist)
      return parsed.success && parsed.data.songs.length >= MINIMUM_ITEM_COUNT
    })

    return validUserPlaylists
  }

  async fetchUserPlaylist(playlistId: string) {
    const documentRef = doc(
      databaseRef,
      Collections.USERS,
      this.sourceUserId,
      Collections.USER_PLAYLISTS,
      playlistId
    )

    const playlistSnapshot = await getDoc(documentRef)
    return playlistSnapshot.data() as Playlist
  }

  async fetchUserPlaylistPublic(hostIdOrCustomUrl: string, playlistId: string) {
    const hostId =
      (await fetchHostIdByCustomUrl(hostIdOrCustomUrl)) ?? hostIdOrCustomUrl

    const playlistDoc = await getDoc(
      doc(
        databaseRef,
        Collections.USERS,
        hostId,
        Collections.USER_PLAYLISTS,
        playlistId
      )
    )

    return playlistDoc.data() as Playlist
  }

  queryUserPlaylists() {
    return queryClient.fetchQuery(this.queries.userPlaylists.queryKey, () =>
      this.fetchUserPlaylists()
    )
  }

  queryUserPlaylist(playlistId: string) {
    const { queryFn, queryKey } = this.queries.userPlaylist

    return queryClient.fetchQuery(queryKey(playlistId), () =>
      queryFn(playlistId)
    )
  }

  async fetchSystemPlaylists(): Promise<Playlist[]> {
    const systemPlaylistCollection = collection(
      databaseRef,
      Collections.SYSTEM_PLAYLISTS
    )

    const systemPlaylistQuery = query(
      systemPlaylistCollection,
      orderBy('timestamp', 'desc')
    )

    const systemPlaylistsSnapshot = await getDocs(systemPlaylistQuery)

    if (systemPlaylistsSnapshot.empty) return []

    const systemPlaylistsDocs = systemPlaylistsSnapshot.docs

    const systemPlaylists: Playlist[] = []

    systemPlaylistsDocs.forEach((doc) => {
      const playlist = doc.data() as Playlist
      playlist.id = doc.id
      systemPlaylists.push(playlist)
    })

    return systemPlaylists
  }

  async fetchSystemPlaylist(playlistId: string) {
    const documentRef = doc(
      databaseRef,
      Collections.SYSTEM_PLAYLISTS,
      playlistId
    )

    const playlistSnapshot = await getDoc(documentRef)
    return playlistSnapshot.data() as Playlist
  }

  querySystemPlaylists() {
    return queryClient.fetchQuery(this.queries.systemPlaylists.queryKey, () =>
      this.fetchSystemPlaylists()
    )
  }

  querySystemPlaylist(playlistId: string) {
    const { queryFn, queryKey } = this.queries.systemPlaylist

    return queryClient.fetchQuery(queryKey(playlistId), () =>
      queryFn(playlistId)
    )
  }
}
