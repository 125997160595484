import { Description, Heading, merge } from '@repo/ui'
import { observer } from 'mobx-react-lite'
import React from 'react'

import { CopyLink } from '@/components/CopyLink'
import { useGameContext } from '@/pages/game/context/game-context'

interface SidekicksLinkProps {
  className?: string
}

export const SidekicksLink = observer(function SidekicksLink({
  className,
}: SidekicksLinkProps): React.ReactElement {
  const { hostUrlSlug, tracker } = useGameContext()

  const handleClickCopy = () => {
    tracker.copyLinkSidekick()
  }

  return (
    <div
      className={merge(
        'p-4 md:p-6 border-b last:border-b-0 border-shade-3',
        className
      )}
    >
      <div className='flex flex-col justify-between gap-4 md:flex-row'>
        <div className='flex flex-col gap-2'>
          <Heading>Sidekicks View</Heading>
          <Description>
            Share this link with co-hosts so they can see the playlist with song
            lyrics and assist with managing the lobby.
          </Description>
        </div>

        <div className='flex items-center'>
          <CopyLink
            buttonVariant='outlined'
            link={`${window.location.origin}/${hostUrlSlug}/sidekick`}
            text='Copy Link'
            hideInput
            onClickCopy={handleClickCopy}
          />
        </div>
      </div>
    </div>
  )
})
