import { Song } from '@repo/types'
import { Panel, Spinner } from '@repo/ui'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useQuery } from 'react-query'
import styled from 'styled-components'

import { apiClient } from '@/api/api-client'

const QUERY_KEY = 'lyrics'

interface LyricsProps {
  song: Song
}

export const Lyrics = observer(function Lyrics({
  song,
}: LyricsProps): React.ReactElement {
  const { data, isError, isLoading } = useQuery<string, Error>(
    [QUERY_KEY, song],
    () => apiClient.getLyrics(song.title, song.artist)
  )

  if (isLoading) {
    return <Spinner label='Loading lyrics...' />
  }

  if (isError || !data) {
    return (
      <Panel>Sorry, there was a problem finding lyrics for this song.</Panel>
    )
  }

  return (
    <LyricsStyled dangerouslySetInnerHTML={{ __html: parseLyrics(data) }} />
  )
})

function parseLyrics(lyricsHTML: string) {
  const parser = new DOMParser()
  const doc = parser.parseFromString(lyricsHTML, 'text/html')

  const content =
    doc.querySelector("[class^='Lyrics__Container']") ??
    doc.querySelector('.lyrics')

  if (!content) return ''

  const contentSansAnchors = content.innerHTML.replace(/<\/?a[^>]*>/g, '')
  return contentSansAnchors.replace(/\[(.*?)\]/g, '<h2>$1</h2>')
}

const LyricsStyled = styled.div`
  font-size: 1.66em;
  line-height: 1.7;
  padding-bottom: 100px;

  h2 {
    display: inline-block;
    font-size: 1em;
    margin: 0;
    color: var(--text-muted);
    border-bottom: 1px solid var(--text-muted);
    width: 100%;
    margin-bottom: 0.5em;
    opacity: 0.8;
  }
`
