import { ColourPalette, PlaybackOption, PlaylistType, Song } from '@repo/types'
import { observer } from 'mobx-react-lite'
import React from 'react'

import { ImportSpotifyPlaylistLibraryContainer } from './import-spotify-library-container'
import { useCreateSystemPlaylist } from '../../playlist-queries/system-playlists/useCreateSystemPlaylist'
import { useCreateUserPlaylist } from '../../playlist-queries/user-playlists/useCreateUserPlaylist'

export const ImportSpotifyPlaylistLibraryRoute = observer(
  function ImportSpotifyPlaylistLibraryRoute(): React.ReactElement {
    const { mutateAsync: createUserPlaylist } = useCreateUserPlaylist()

    const handleSubmit = (title: string, songs: Song[]) => {
      return createUserPlaylist({
        colour: ColourPalette.PURPLE,
        id: '',
        playbackOptions: [PlaybackOption.SPOTIFY],
        songs,
        title,
        type: PlaylistType.USER_CUSTOM_PLAYLISTS,
      })
    }

    return <ImportSpotifyPlaylistLibraryContainer onSubmit={handleSubmit} />
  }
)

export const ImportSpotifyPlaylistLibraryRouteAdmin = observer(
  function ImportSpotifyPlaylistLibraryRouteAdmin(): React.ReactElement {
    const { mutateAsync: createSystemPlaylist } = useCreateSystemPlaylist()

    const handleSubmit = async (
      title: string,
      songs: Song[]
    ): Promise<{ playlistId: string }> => {
      return createSystemPlaylist({
        colour: ColourPalette.PURPLE,
        id: '',
        playbackOptions: [PlaybackOption.SPOTIFY],
        songs,
        title,
        type: PlaylistType.SYSTEM_PLAYLISTS,
      })
    }

    return (
      <ImportSpotifyPlaylistLibraryContainer
        baseUrl='/admin/'
        onSubmit={handleSubmit}
      />
    )
  }
)
