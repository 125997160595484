import {
  CreateGameOptions,
  Playlist,
  PlaybackOption,
  PlaylistType,
} from '@repo/types'
import { Container, Description, Heading, HistoryIcon, Spinner } from '@repo/ui'
import * as Sentry from '@sentry/react'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'

import { AppHeader } from '@/components/AppHeader'
import { AppHeaderLogo } from '@/components/AppHeaderLogo'
import { Button } from '@/components/Button'
import {
  BackButton,
  SettingsButton,
} from '@/components/icon-buttons/IconButtons'
import { IconButton } from '@/components/IconButton'
import { Main } from '@/components/Main'
import { NavigationLink } from '@/components/NavigationLink'
import { Page } from '@/components/Page'
import { SubscriptionActionRequired } from '@/components/SubscriptionActionRequired'
import { TermsOfService } from '@/components/TermsOfService'
import { MixpanelService } from '@/mixpanel'
import { fixPlaylist } from '@/pages/playlists/playlist-editor/duplicate-checker/fixPlaylist'
import { useQuerySystemPlaylists } from '@/pages/playlists/playlist-queries/system-playlists/useQuerySystemPlaylists'
import { useQueryUserPlaylists } from '@/pages/playlists/playlist-queries/user-playlists/useQueryUserPlaylists'
import { SpotifyCardGrid } from '@/store/integrations/spotify/components/SpotifyCardGrid'
import { useQuerySpotifyPlaylists } from '@/store/integrations/spotify/queries/useQuerySpotifyPlaylists'
import { useSpotify } from '@/store/integrations/spotify/useSpotify'
import StoreService from '@/store/StoreService'

import { PlaylistErrorModal } from './PlaylistErrorModal'
import { PlaylistPreviewModal } from './PlaylistPreviewModal'
import { SystemPlaylists } from './SystemPlaylists'
import { UserPlaylists } from './UserPlaylists'
import { CreateGameError } from '../components/CreateGameError'

export const ChoosePlaylist = observer(
  function ChoosePlaylist(): React.ReactElement {
    const { createGame, playlistService, user } = StoreService.getStore()
    const spotify = useSpotify()

    const { isLoading: isLoadingSystemPlaylists } = useQuerySystemPlaylists()
    useQueryUserPlaylists()
    useQuerySpotifyPlaylists()

    const [isCreatingGame, setIsCreatingGame] = useState(false)
    const [showCreateGameError, setShowCreateGameError] = useState(false)

    const [showPlaylistErrorModal, setShowPlaylistErrorModal] = useState(false)
    const [showPlaylistPreviewModal, setShowPlaylistPreviewModal] =
      useState(false)
    const [loadingPlaylist, setLoadingPlaylist] = useState(false)
    const [selectedPlaylist, setSelectedPlaylist] = useState<Playlist | null>(
      null
    )

    const checkPlaylist = (playlist: Playlist) => {
      const result = fixPlaylist(playlist)

      if (result.status === 'error') {
        setLoadingPlaylist(false)
        setSelectedPlaylist(playlist)
        setShowPlaylistPreviewModal(false)
        setShowPlaylistErrorModal(true)
        return
      }

      if (result.status === 'success') {
        setSelectedPlaylist(result.playlist)
      }
    }

    const handleSpotifyPlaylistClick = async (playlistId: string) => {
      setLoadingPlaylist(true)
      setShowPlaylistPreviewModal(true)

      const playlist =
        await spotify.playlistService.queryPlaylistById(playlistId)

      checkPlaylist(playlist)

      setLoadingPlaylist(false)
    }

    const handleSystemPlaylistClick = async (playlistId: string) => {
      setShowPlaylistPreviewModal(true)
      setLoadingPlaylist(true)

      const playlist = await playlistService.querySystemPlaylist(playlistId)

      if (playlist) {
        setSelectedPlaylist({
          ...playlist,
          playbackOptions: [PlaybackOption.SPOTIFY],
          type: PlaylistType.SYSTEM_PLAYLISTS,
        })
        setLoadingPlaylist(false)
      }
    }

    const handleUserPlaylistClick = async (playlistId: string) => {
      setLoadingPlaylist(true)
      setShowPlaylistPreviewModal(true)

      const playlist = await playlistService.queryUserPlaylist(playlistId)

      checkPlaylist(playlist)

      setLoadingPlaylist(false)
    }

    const handleCreateGameClick = async () => {
      if (!selectedPlaylist) return
      setIsCreatingGame(true)

      try {
        const options: CreateGameOptions = {
          playlist: selectedPlaylist,
          type: 'music',
        }

        const { gameId } = await createGame(options)
        MixpanelService.createGame(gameId, options)
      } catch (error) {
        setShowCreateGameError(true)
        setIsCreatingGame(false)
        Sentry.captureException(error)
      }

      setShowPlaylistPreviewModal(false)
    }

    if (showCreateGameError) {
      return <CreateGameError />
    }

    if (isCreatingGame) {
      return <Spinner label='Creating game...' />
    }

    return (
      <Page title='Choose a Playlist'>
        <AppHeader
          actionsLeft={<BackButton url='/' />}
          actionsRight={
            <>
              <IconButton
                icon={<HistoryIcon />}
                title='Game History'
                url='/game-history'
              />

              <SettingsButton url='/settings' />
            </>
          }
          header={
            <NavigationLink className='flex h-full items-center' to='/'>
              <AppHeaderLogo />
            </NavigationLink>
          }
        />

        <Main>
          {user.isSubscriptionActionRequired && (
            <Container
              className='mb-12 flex flex-col gap-12'
              size='extra-large'
            >
              <SubscriptionActionRequired />
            </Container>
          )}

          <Container
            className='mb-6 flex flex-col gap-4 lg:mb-12'
            size='extra-large'
          >
            <div className='flex flex-col gap-2'>
              <Heading size='large'>Music Bingo</Heading>
              <Description>
                Choose a playlist below to start a game of music bingo.
              </Description>
              <Button text='Change Game Type' to='/' variant='anchor' />
            </div>
          </Container>

          <Container className='flex flex-col gap-12' size='extra-large'>
            <SystemPlaylists onPlaylistClick={handleSystemPlaylistClick} />

            {!isLoadingSystemPlaylists && (
              <>
                <UserPlaylists onPlaylistClick={handleUserPlaylistClick} />

                <SpotifyCardGrid
                  handleClick={(playlistId) =>
                    void handleSpotifyPlaylistClick(playlistId)
                  }
                />
              </>
            )}
          </Container>

          <TermsOfService />
        </Main>

        {selectedPlaylist && (
          <PlaylistErrorModal
            isVisible={showPlaylistErrorModal}
            playlist={selectedPlaylist}
            onClose={() => {
              setShowPlaylistErrorModal(false)
              setSelectedPlaylist(null)
            }}
          />
        )}

        <PlaylistPreviewModal
          isLoading={loadingPlaylist}
          isVisible={showPlaylistPreviewModal}
          playlist={selectedPlaylist}
          onClickCreateGame={handleCreateGameClick}
          onClose={() => {
            setShowPlaylistPreviewModal(false)
            setSelectedPlaylist(null)
          }}
        />
      </Page>
    )
  }
)
