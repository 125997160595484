import { ColourPalette } from '@repo/types'
import { Badge, Container, Heading, Description } from '@repo/ui'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'

import { PlayerList } from './PlayerList'
import { useGameContext } from '../pages/game/context/game-context'
import { useGameControlsContext } from '../pages/game/context/game-controls-context'
import StoreService from '../store/StoreService'

export const GameLobby = observer(function GameLobby(): React.ReactElement {
  const { user } = StoreService.getStore()
  const { maxPlayers } = user

  const { playersInLobby } = useGameContext()

  const [isAdmittingPlayer, setIsAdmittingPlayer] = useState(false)
  const [isAdmittingAllPlayers, setIsAdmittingAllPlayers] = useState(false)

  const { handleAdmitAllPlayersClick, handleAdmitPlayerClick } =
    useGameControlsContext()

  const handleClickAdmit = async (playerId: string) => {
    setIsAdmittingPlayer(true)
    await handleAdmitPlayerClick(playerId)
    setIsAdmittingPlayer(false)
  }

  const handleClickAdmitAll = async () => {
    setIsAdmittingAllPlayers(true)
    await handleAdmitAllPlayersClick(playersInLobby.length)
    setIsAdmittingAllPlayers(false)
  }

  return (
    <>
      {!user.isAnonymous && maxPlayers && (
        <Container className='px-0 py-6' size='large'>
          <MaximumPlayers />
        </Container>
      )}

      <PlayerList
        badgeColor={ColourPalette.ORANGE}
        bulkButtonProps={{
          disabled: isAdmittingAllPlayers,
          loading: isAdmittingAllPlayers,
          loadingText: 'Admitting...',
          onClick: () => void handleClickAdmitAll(),
          text: 'Admit All Guests',
        }}
        buttonProps={{
          disabled: isAdmittingPlayer,
          loading: isAdmittingPlayer,
          loadingText: 'Admitting...',
          onClick: (playerId: string) => void handleClickAdmit(playerId),
          text: 'Admit',
        }}
        description='Guests waiting for you to let them in to the game.'
        noItemsText='No guests waiting to join the game.'
        players={playersInLobby}
        title='Lobby'
      />
    </>
  )
})

const MaximumPlayers = observer(function MaximumPlayers(): React.ReactElement {
  const { remainingPlayerSlots } = useGameContext()

  return (
    <>
      <Heading className='flex items-center gap-2'>
        <Badge colour={remainingPlayerSlots === 0 ? `red` : `blue`}>
          {remainingPlayerSlots}
        </Badge>
        <span>Seats Available</span>
      </Heading>

      <Description className='mt-2'>
        Upgrade your account to host larger games.
      </Description>
    </>
  )
})
