import { Collections, Game, PlaybackOption } from '@repo/types'
import { Container, Description, Heading, Panel } from '@repo/ui'
import { RadioButtonGroup } from '@repo/ui/client'
import { doc, updateDoc } from 'firebase/firestore'
import { observer } from 'mobx-react-lite'
import React, { SyntheticEvent, useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom'

import { AppFooter } from '@/components/AppFooter'
import { AppHeader } from '@/components/AppHeader'
import { Button } from '@/components/Button'
import { ExternalLink } from '@/components/ExternalLink'
import { Main } from '@/components/Main'
import { Page } from '@/components/Page'
import { PageHeading } from '@/components/PageHeading'
import { PageTitle } from '@/components/PageTitle'
import { TermsOfService } from '@/components/TermsOfService'
import { TipBadge } from '@/components/TipBadge'
import { WarningBadge } from '@/components/WarningBadge'
import { databaseRef } from '@/firebase/firebase'
import { useGameContext } from '@/pages/game/context/game-context'

const SPOTIFY_BEST_PRACTICES_LINK =
  'https://go.rockstar.bingo/hosting-best-practices'

export const SetupPlaybackRoute = observer(
  function SetupPlaybackRoute(): React.ReactElement {
    return <SetupPlayback />
  }
)

function getPlaybackOptions(gameData: Game) {
  if (gameData.type !== 'music') return []

  return gameData.sourcePlaylistMeta.playbackOptions
}

const SetupPlayback = observer(
  function SetupPlayback(): React.ReactElement | null {
    const { gameData, gameId, tracker } = useGameContext()
    const history = useHistory()

    const playbackOptions = getPlaybackOptions(gameData)
    const supportsPlayback = Boolean(playbackOptions.length)
    const supportsSpotify = playbackOptions?.includes(PlaybackOption.SPOTIFY)

    const [enableSpotifyPlayback, setEnableSpotifyPlayback] =
      useState(supportsSpotify)

    const handleChange = (event: SyntheticEvent) => {
      const { value } = event.target as HTMLInputElement
      setEnableSpotifyPlayback(value === 'true')
    }

    const handleContinueClick = () => {
      const playbackSource = enableSpotifyPlayback
        ? PlaybackOption.SPOTIFY
        : PlaybackOption.NONE

      const gameDoc = doc(databaseRef, Collections.GAMES, gameId)
      const data = { playbackSource }

      void updateDoc(gameDoc, data)
      tracker.changePlaybackSource(playbackSource)

      if (enableSpotifyPlayback && supportsSpotify) {
        history.push(`/game/setup/devices`)
      } else {
        history.push(`/game/setup/gameplay`)
      }
    }

    if (gameData.playbackSource) {
      return <Redirect to='/game' />
    }

    return (
      <Page title='Playback Source Setup'>
        <AppHeader
          header={
            <PageHeading className='pl-4 md:pl-0'>
              Playback Source Setup
            </PageHeading>
          }
        />
        <Main>
          {!supportsPlayback && (
            <Container className='p-4 md:px-6 md:pt-8' size='medium'>
              <PageTitle className='mb-6 mt-4 text-center' size='large'>
                Playback not supported
              </PageTitle>

              <Panel>
                <WarningBadge />
                <Description className='mt-2 max-w-none'>
                  This playlist is not compatible with playback and will need to
                  be managed manually.
                </Description>
              </Panel>
            </Container>
          )}

          {supportsPlayback && (
            <>
              <Container className='p-4 md:px-6' size='medium'>
                <PageTitle className='mb-6 text-center' size='large'>
                  Would you like to use Spotify playback?
                </PageTitle>
              </Container>

              <Container size='medium'>
                <RadioButtonGroup
                  options={[
                    {
                      id: 'use-spotify',
                      label: (
                        <div className='flex flex-col gap-2'>
                          <Heading>Yes</Heading>
                          <Description>
                            I have <strong>Spotify Premium</strong> open on a
                            device and my audio setup is ready
                          </Description>
                        </div>
                      ),
                      name: 'playback-selection',
                      value: 'true',
                    },
                    {
                      id: 'no-playback',
                      label: (
                        <div className='flex flex-col gap-2'>
                          <Heading>No</Heading>
                          <Description>
                            I{"'"}ll manage playback myself using another method
                          </Description>
                        </div>
                      ),
                      name: 'playback-selection',
                      value: 'false',
                    },
                  ]}
                  value={enableSpotifyPlayback.toString()}
                  onChange={handleChange}
                />

                <Panel className='mt-8 text-muted'>
                  <div className='flex flex-col gap-2'>
                    <TipBadge />
                    <Description>
                      When connecting to Spotify, we recommend hosting games
                      from a Mac or PC rather than a mobile device.
                    </Description>

                    <Description>
                      <ExternalLink href={SPOTIFY_BEST_PRACTICES_LINK}>
                        See more
                      </ExternalLink>
                    </Description>
                  </div>
                </Panel>
              </Container>
            </>
          )}

          <Container className='mt-8 ' size='medium'>
            <Button text='Continue' onClick={handleContinueClick} />
          </Container>

          <TermsOfService />
        </Main>

        <AppFooter />
      </Page>
    )
  }
)
