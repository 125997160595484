import { useEffect, useState } from 'react'

interface UseConnectivity {
  isOnline: boolean
}

export function useConnectivity(): UseConnectivity {
  const [isOnline, setIsOnline] = useState(true)

  const updateNetwork = () => {
    try {
      setIsOnline(window.navigator.onLine)
    } catch (error) {
      console.error('Error accessing navigator.onLine', error)
    }
  }

  useEffect(() => {
    try {
      window.addEventListener('online', updateNetwork)
      window.addEventListener('offline', updateNetwork)

      return () => {
        window.removeEventListener('online', updateNetwork)
        window.removeEventListener('offline', updateNetwork)
      }
    } catch (error) {
      console.error('Error attaching event listeners', error)
    }
  }, [])

  return { isOnline }
}
