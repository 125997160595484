import { merge } from '@repo/ui'

interface ColumnNameProps {
  columnName: string
  isCurrent?: boolean
}
export function ColumnName({ columnName, isCurrent = false }: ColumnNameProps) {
  return (
    <div
      className={merge(
        'font-bold rounded-sm sm:rounded-md md:rounded-xl flex items-center justify-center duration-300 transition-all',
        'md:aspect-square bg-shade-1 border-orange-500 border-[1px] md:border-2',
        { 'bg-orange-500 text-black': isCurrent }
      )}
    >
      <span
        style={{
          fontSize: `clamp(1rem, 2.66vw, 2rem)`,
          marginTop: `calc(clamp(0.01rem, 2.66vw, 0.075rem) * -1)`,
        }}
      >
        {columnName}
      </span>
    </div>
  )
}
