import { Timestamp } from 'firebase/firestore'

export function formatTime(timestamp: Timestamp): string {
  const dateTimestamp = timestamp.toDate()
  const date = new Date(dateTimestamp)
  const options = {
    hour: 'numeric',
    hour12: true,
    minute: 'numeric',
  } as Intl.DateTimeFormatOptions

  return new Intl.DateTimeFormat('en-US', options).format(date)
}
