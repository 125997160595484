import { Spinner } from '@repo/ui'
import { observer } from 'mobx-react-lite'
import React from 'react'

import StoreService from '../store/StoreService'

interface WithAuthProps {
  children: React.ReactElement
}

export const WithAuth = observer(function WithAuth({
  children,
}: WithAuthProps): React.ReactElement {
  const { user } = StoreService.getStore()
  const { hasCheckedAuth } = user

  if (!hasCheckedAuth) return <Spinner label='Loading...' />

  return children
})
