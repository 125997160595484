import { Panel } from '@repo/ui'
import { observer } from 'mobx-react-lite'

import { Title } from '@/components/Title'

import { SpotifyAuthButton } from './SpotifyAuthButton'

export const ConnectSpotifyCard = observer(function ConnectSpotifyCard() {
  return (
    <Panel className='flex flex-col gap-6'>
      <Title
        description='Connect a Spotify Premium account to play a bingo game with your own
    playlists.'
        heading='Use your playlists on Spotify'
      />

      <SpotifyAuthButton />
    </Panel>
  )
})
