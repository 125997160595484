import { ColourPalette } from '@repo/types'
import { observer } from 'mobx-react-lite'
import React from 'react'

import { PlayerList } from './PlayerList'
import { useGameContext } from '../pages/game/context/game-context'

export const PlayersLeftGame = observer(
  function PlayersLeftGame(): React.ReactElement {
    const { playersLeftGame } = useGameContext()

    return (
      <PlayerList
        badgeColor={ColourPalette.PINK}
        description='Guests who have decided to leave the game.'
        noItemsText='No guests have left the game.'
        players={playersLeftGame}
        title='Left game'
      />
    )
  }
)
