import { Collections, Player } from '@repo/types'
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore'

import { databaseRef } from '@/firebase/firebase'

import SnapshotListener from './AbstractSnapshotListener'
import { GameStore } from '../GameStore'

class PlayersListener extends SnapshotListener {
  public subscribe(game: GameStore): void {
    const playersCollection = collection(
      databaseRef,
      Collections.GAMES,
      game.gameId,
      Collections.PLAYERS
    )

    const playersQuery = query(playersCollection, orderBy('joinedLobby', 'asc'))

    this._unsubscribe = onSnapshot(
      playersQuery,
      (snapshot) => {
        const players = snapshot.docs.map((doc) => doc.data() as Player)
        game.apply({ players })
      },
      (error) => {
        console.error(`Encountered error: ${error.message}`)
      }
    )
  }
}

export const playersListener = new PlayersListener()
