import { CalledBingo, Collections } from '@repo/types'
import { collection, onSnapshot } from 'firebase/firestore'

import { databaseRef } from '@/firebase/firebase'

import SnapshotListener from './AbstractSnapshotListener'
import { GameStore } from '../GameStore'

class CalledBingosListener extends SnapshotListener {
  public subscribe(game: GameStore): void {
    const calledBingosCollection = collection(
      databaseRef,
      Collections.GAMES,
      game.gameId,
      Collections.CALLED_BINGOS
    )

    this._unsubscribe = onSnapshot(
      calledBingosCollection,
      (snapshot) => {
        const calledBingosList = snapshot.docs.map(
          (doc) => doc.data() as CalledBingo
        )
        game.apply({ calledBingosList })
      },
      (error) => {
        console.error(`Encountered error: ${error.message}`)
      }
    )
  }
}

export const calledBingosListener = new CalledBingosListener()
