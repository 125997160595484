import { Heading, ChevronLeftIcon } from '@repo/ui'
import { observer } from 'mobx-react-lite'
import React from 'react'

import { NavigationLink } from './NavigationLink'

interface BannerGameEndedProps {
  isSidekick?: boolean
}

export const BannerGameEnded = observer(function BannerGameEnded({
  isSidekick,
}: BannerGameEndedProps): React.ReactElement {
  return (
    <div className='rounded-lg bg-red-500 p-6 text-white'>
      <Heading>This game has ended.</Heading>

      {!isSidekick && (
        <NavigationLink
          className='mt-4 flex items-center gap-2 uppercase text-white'
          to='/'
        >
          <ChevronLeftIcon className='size-4' />
          Exit Game
        </NavigationLink>
      )}

      {isSidekick && <div>Waiting for the next game to start...</div>}
    </div>
  )
})
