import { useSuccessButton } from '@repo/ui/client'
import * as Sentry from '@sentry/react'
import { Form, Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import * as yup from 'yup'

import { Button } from '@/components/Button'
import { FormError } from '@/components/forms/FormError'
import { TextField } from '@/components/forms/TextField'

export interface VenueFormValues {
  address: string
  city: string
  country: string
  name: string
  state: string
}

const validationSchema = yup.object({
  name: yup.string().required('Please enter a venue name'),
})

const DEFAULT_VALUES: VenueFormValues = {
  address: '',
  city: '',
  country: '',
  name: '',
  state: '',
}

interface VenueFormProps {
  initialValues?: VenueFormValues
  isDisabled?: boolean
  onSubmit: (values: VenueFormValues) => Promise<string>
  onSuccess?: (venueId: string) => void
}

export const VenueForm = observer(function VenueForm({
  initialValues = DEFAULT_VALUES,
  isDisabled = false,
  onSubmit,
  onSuccess,
}: VenueFormProps): React.ReactElement {
  const [formError, setFormError] = useState<string | undefined>()
  const { showSuccess, showSuccessButtonState } = useSuccessButton()

  const handleSubmit = async (values: VenueFormValues) => {
    try {
      const venueId = await onSubmit(values)
      await showSuccessButtonState()
      onSuccess?.(venueId)
    } catch (error) {
      console.error(error)
      setFormError('Sorry, something went wrong')
      Sentry.captureException(error)
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, isValid }) => (
        <Form
          className='flex flex-col gap-8'
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        >
          <div className='flex flex-col gap-6'>
            <TextField isDisabled={isDisabled} label='Name' name='name' />
            <TextField isDisabled={isDisabled} label='Address' name='address' />
            <TextField isDisabled={isDisabled} label='City' name='city' />
            <TextField
              isDisabled={isDisabled}
              label='State/Province'
              name='state'
            />
            <TextField isDisabled={isDisabled} label='Country' name='country' />
          </div>

          <Button
            disabled={isDisabled || isSubmitting || !isValid}
            showSuccess={showSuccess}
            text='Save'
            type='submit'
          />

          {formError && <FormError error={formError} />}
        </Form>
      )}
    </Formik>
  )
})
