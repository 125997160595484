import { Playlist } from '@repo/types'
import startCase from 'lodash.startcase'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getPlaylistDetails(playlist: Playlist) {
  return {
    'Playlist Default Video': playlist.video,
    'Playlist Pre-game Video': playlist.videoPreGame,
    'Playlist Title': playlist.title,
    'Playlist Type': startCase(playlist.type).replace(/_/g, ' '),
    'Playlist URL': playlist.url,
    'Setlist Mode Enabled': playlist.disableShuffle,
  }
}
