import { Song } from '@repo/types'

import { formatTime } from '../pages/playlists/playlist-editor/StartPositionField'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getSongDetails(song: Song) {
  return {
    Artist: song.artist,
    'Song Title': song.title,
    'Start Position': formatTime(song.spotifyMeta?.startPosition),
    'User Key': song.userMeta?.key,
    'User Notes': song.userMeta?.notes,
    'User Tempo': song.userMeta?.tempo,
    Video: song.video,
  }
}
