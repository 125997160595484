import { Playlist } from '@repo/types'

import { apiClient } from '@/api/api-client'
import StoreService from '@/store/StoreService'

import { useCreatePlaylist } from '../useCreatePlaylist'

export function useCreateSystemPlaylist() {
  const { playlistService } = StoreService.getStore()

  const { queryFn, queryKey } = playlistService.queries.systemPlaylists

  const mutationFn = (playlist: Playlist) =>
    apiClient.adminUpdateSystemPlaylist(playlist)

  return useCreatePlaylist(queryKey, mutationFn, queryFn)
}
