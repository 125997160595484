import { Playlist } from '@repo/types'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'

import { PlaylistEditorContainer } from './PlaylistEditorContainer'
import { useQueryUserPlaylist } from '../playlist-queries/user-playlists/useQueryUserPlaylist'
import { useUpdateUserPlaylist } from '../playlist-queries/user-playlists/useUpdateUserPlaylist'

interface IEditPlaylistRouteParams {
  playlistId: string
}

export const EditPlaylistRoute = observer(
  function EditPlaylistRoute(): React.ReactElement | null {
    const { playlistId } = useParams<IEditPlaylistRouteParams>()
    const {
      data: playlist,
      error,
      isLoading,
    } = useQueryUserPlaylist(playlistId)

    const { mutate: updateUserPlaylist } = useUpdateUserPlaylist({ playlistId })

    const handleSubmit = (values: Playlist) => {
      updateUserPlaylist(values)
    }

    return (
      <>
        <Helmet>
          <title>Edit Playlist | Rockstar Bingo</title>
        </Helmet>

        <PlaylistEditorContainer
          error={error}
          isLoading={isLoading}
          playlist={playlist}
          onSubmit={handleSubmit}
        />
      </>
    )
  }
)
