import React from 'react'
import ReactDOM from 'react-dom/client'

import App from './App'
import { MixpanelService } from './mixpanel'
import { queryClient } from './queryClient'
import { initializeSentry } from './sentry/Sentry'
import StoreService from './store/StoreService'

if (import.meta.env.VITE_ENV === 'production') {
  initializeSentry()
}

StoreService.init()
const store = StoreService.getStore()
// @ts-expect-error Add store to window
window.store = store
//@ts-expect-error Add queryClient to window
window.queryClient = queryClient

store.spotify.init()

MixpanelService.init()

const rootElement = document.getElementById('root')
if (!rootElement) throw new Error('Could not find root element')

ReactDOM.createRoot(rootElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
