import { Store } from './Store'
import { AuthListener } from './user/listeners/AuthListener'

class StoreService {
  public store?: Store

  public init(): void {
    this.store = new Store()

    new AuthListener(this.store)
  }

  public getStore(): Store {
    if (!this.store) throw new Error('Store has not been initialized')
    return this.store
  }

  /**  For tests */
  public setStore(store: Store) {
    this.store = store
  }
}

export default new StoreService()
