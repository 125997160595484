export interface ISpotifyMeta {
  duration: number
  features?: ISpotifyFeatures
  startPosition: number
  track: ISpotifyTrackMinimal
}

export interface ISpotifyTrackMinimal {
  id: string
  uri: string
}

export interface ISpotifyFeatures {
  acousticness: number
  danceability: number
  energy: number
  id: string
  instrumentalness: number
  key: SongKeys
  loudness: number
  mode: SongMode
  tempo: number
  time_signature: number
  valence: number
}

export enum SongKeys {
  'C',
  'C♯',
  'D',
  'D♯',
  'E',
  'F',
  'F♯',
  'G',
  'G♯',
  'A',
  'A♯',
  'B'
}

export enum SongMode {
  'm',
  'M'
}

export enum SongModeFull {
  'Minor',
  'Major'
}
