import { PositioningStrategy, State } from '@popperjs/core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { usePopper, Modifier } from 'react-popper'

import {
  PopoverArrowPlacement,
  PopoverPlacement,
  UsePopover,
  UsePopoverOptions,
} from '.'

function getArrowOptions(
  arrowElement: HTMLDivElement | null,
  arrowPlacement: PopoverArrowPlacement,
  placement?: PopoverPlacement
) {
  if (arrowPlacement === 'auto' || placement === 'auto')
    return {
      options: {
        element: arrowElement,
        padding: 6,
      },
    }

  return { enabled: false }
}

export function usePopover(
  refObject: React.MutableRefObject<HTMLElement | null>,
  {
    arrowPlacement = 'auto',
    disableOffset = false,
    enableArrow = true,
    enableBackdrop = true,
    matchReferenceWidth = false,
    onClose,
    placement,
    showOnHover = false,
  }: UsePopoverOptions
): UsePopover {
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  )
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  )
  const [showPopover, setShowPopover] = useState(false)

  const modifiers: Modifier<string, Record<string, unknown>>[] = useMemo(
    () => [
      {
        name: 'arrow',
        ...getArrowOptions(arrowElement, arrowPlacement, placement),
        enabled: enableArrow,
      },
      {
        enabled: enableArrow && !disableOffset,
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
      {
        effect: ({ state }: { state: State }) => {
          const reference = state.elements.reference as Element
          state.elements.popper.style.width = `${reference.clientWidth}px`
        },
        enabled: matchReferenceWidth,
        fn: ({ state }: { state: State }) => {
          const styles = state.styles['popper'] as React.CSSProperties
          styles.width = `${state.rects.reference.width}px`
        },
        name: 'matchReferenceWidth',
        phase: 'beforeWrite',
        requires: ['computeStyles'],
      },
    ],
    [
      arrowElement,
      arrowPlacement,
      enableArrow,
      disableOffset,
      matchReferenceWidth,
      placement,
    ]
  )

  const popperOptions = {
    modifiers: modifiers,
    placement,
    strategy: 'fixed' as PositioningStrategy,
  }

  const { attributes, styles, update } = usePopper(
    referenceElement,
    popperElement,
    {
      ...popperOptions,
    }
  )

  const handleClick = () => {
    if (showPopover) {
      setShowPopover(false)
    } else {
      setShowPopover(true)
    }
  }

  const handleClose = useCallback(() => {
    setShowPopover(false)
    onClose && onClose()
  }, [onClose])

  const handleBackdropClick = () => {
    handleClose()
  }

  const handleOutsideClick = useCallback(
    (event: MouseEvent) => {
      const element = referenceElement
      const popper = popperElement
      const isReferenceElementClick = element?.contains(
        event.target as HTMLDivElement
      )
      if (isReferenceElementClick) return

      const isInsideClick = popper?.contains(event.target as HTMLDivElement)
      const isInsideButtonClick = !!(event?.target as HTMLElement).closest(
        'button'
      )
      const isInsideAnchorClick = !!(event?.target as HTMLElement).closest('a')

      const shouldDismiss =
        !isInsideClick || isInsideButtonClick || isInsideAnchorClick
      if (shouldDismiss) handleClose()
    },
    [handleClose, referenceElement, popperElement]
  )

  const handleMouseEnter = useCallback(() => {
    if (!showOnHover) return
    !showPopover && setShowPopover(true)
  }, [showOnHover, showPopover])

  const handleMouseLeave = useCallback(() => {
    if (!showOnHover) return
    showPopover && setShowPopover(false)
  }, [showOnHover, showPopover])

  useEffect(() => {
    setReferenceElement(refObject?.current)
  }, [refObject, setReferenceElement])

  useEffect(() => {
    if (!showPopover) return
    document.addEventListener('click', handleOutsideClick)

    return () => {
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [showPopover, referenceElement, popperElement, handleOutsideClick])

  useEffect(() => {
    if (!showOnHover || !referenceElement) return

    referenceElement.addEventListener('mouseenter', handleMouseEnter)
    referenceElement.addEventListener('mouseleave', handleMouseLeave)

    return () => {
      referenceElement.removeEventListener('mouseenter', handleMouseEnter)
      referenceElement.removeEventListener('mouseleave', handleMouseLeave)
    }
  }, [showOnHover, handleMouseEnter, handleMouseLeave, referenceElement])

  return {
    arrowElement,
    arrowPlacement,
    attributes,
    enableArrow,
    enableBackdrop,
    handleBackdropClick,
    handleClick,
    placement,
    popperElement,
    referenceElement,
    setArrowElement,
    setPopperElement,
    setReferenceElement,
    setShowPopover,
    showPopover,
    styles,
    updatePopover: update,
  }
}
