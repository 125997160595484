import { merge } from '@repo/ui'
import React, { useState } from 'react'

interface ImageProps {
  alt: string
  className?: string
  onError?: () => void
  onLoad?: () => void
  src?: string
}

export function Image({ alt, className, onError, onLoad, src }: ImageProps) {
  const [isLoaded, setIsLoaded] = useState(false)

  const handleLoad = () => {
    setIsLoaded(true)
    onLoad?.()
  }

  return (
    <img
      alt={alt}
      className={merge(
        'transition-opacity duration-300',
        {
          'opacity-0': !isLoaded,
          'opacity-100': isLoaded,
        },
        className
      )}
      src={src}
      onError={onError}
      onLoad={handleLoad}
    />
  )
}
