import { MessageType, PlaybackOption } from '@repo/types'
import { Container } from '@repo/ui'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useHistory } from 'react-router-dom'

import { AppFooter } from '@/components/AppFooter'
import { AppHeader } from '@/components/AppHeader'
import { BannerGameEnded } from '@/components/BannerGameEnded'
import { GameHeader } from '@/components/GameHeader'
import { GameStatusButtons } from '@/components/GameStatusButtons'
import { BackIcon, SettingsButton } from '@/components/icon-buttons/IconButtons'
import { IconButton } from '@/components/IconButton'
import { LobbyIcon } from '@/components/LobbyIcon'
import { Main } from '@/components/Main'
import { Page } from '@/components/Page'
import { Playlist } from '@/components/Playlist'
import { MessageQueue } from '@/pages/game/message-queue/MessageQueue'

import { MessageBanners } from './components/MessageBanners'
import { SongControls } from './components/SongControls'
import { useGameContext } from './context/game-context'
import {
  GameControlsContextProvider,
  useGameControlsContext,
} from './context/game-controls-context'
import { NumbersBoard } from './numbers/NumbersBoard'
import { NumbersControls } from './numbers/NumbersControls'
import { SetupPlaybackRoute } from './setup/setup-playback'

export const GameControlsRoute = observer(
  function GameControlsRoute(): React.ReactElement {
    const { gameData } = useGameContext()

    if (
      gameData.type === 'music' &&
      gameData.playbackSource === PlaybackOption.SPOTIFY
    ) {
      return (
        <GameControlsContextProvider>
          <GameControls />
        </GameControlsContextProvider>
      )
    }

    return (
      <GameControlsContextProvider>
        <GameControls />
      </GameControlsContextProvider>
    )
  }
)

const GameControls = observer(function GameControls() {
  const {
    currentMessage,
    gameData,
    hasCalledBingo,
    isCompletedGame,
    isStartedGame,
  } = useGameContext()

  const { handleFinishGameClick } = useGameControlsContext()

  const history = useHistory()

  const handleBackButtonClick = async () => {
    if (isCompletedGame) {
      return history.push('/')
    }

    await handleFinishGameClick()
  }

  if (gameData.type === 'music' && !gameData.playbackSource) {
    return <SetupPlaybackRoute />
  }

  const hasCurrentMessage =
    currentMessage &&
    currentMessage.message.type !== MessageType.CONTACT_DETAILS_REQUEST

  const showMessageBanners = hasCalledBingo || hasCurrentMessage

  return (
    <Page title='Game Controls'>
      <Helmet>
        <body className='overflow-hidden' />

        <style type='text/css'>
          {`
           .overflow-hidden #root {
              overflow: hidden;
            }

            body {
              scroll-behavior: smooth;
            }
          `}
        </style>
      </Helmet>

      <MessageQueue />

      <AppHeader
        actionsLeft={
          <IconButton
            icon={<BackIcon />}
            title='Exit Game'
            onClick={() => void handleBackButtonClick()}
          />
        }
        actionsRight={
          <>
            <LobbyIcon url='/game/lobby' />
            <SettingsButton url='/game/settings' />
          </>
        }
        header={<GameHeader />}
        secondaryHeader={
          <div className='px-0 py-6'>
            <Container className='px-0' size='large'>
              {isCompletedGame && <BannerGameEnded />}
            </Container>

            <Container className='px-0' size='small'>
              <GameStatusButtons />
            </Container>
          </div>
        }
      />

      <Main className='flex flex-col overflow-hidden py-0 lg:pt-0'>
        {gameData.type === 'music' && (
          <Playlist scrollContainer='element' scrollToSong='previous' />
        )}

        {gameData.type === 'numbers' && <NumbersBoard />}
      </Main>

      {isStartedGame && (
        <AppFooter className='border-t border-shade-3 bg-shade-1'>
          <Container size='medium'>
            {!showMessageBanners && <Controls />}
            {showMessageBanners && <MessageBanners />}
          </Container>
        </AppFooter>
      )}
    </Page>
  )
})

const Controls = observer(() => {
  const { gameData } = useGameContext()

  if (gameData.type === 'numbers') {
    return <NumbersControls />
  }

  return <SongControls />
})
