import { Container } from '@repo/ui'
import { observer } from 'mobx-react-lite'
import React from 'react'

import { AppHeader } from '@/components/AppHeader'
import { BackButton } from '@/components/icon-buttons/IconButtons'
import { List } from '@/components/List'
import { Main } from '@/components/Main'
import { Nav } from '@/components/Nav'
import { Page } from '@/components/Page'
import { PageHeading } from '@/components/PageHeading'
import { PageTitle } from '@/components/PageTitle'
import { TermsOfService } from '@/components/TermsOfService'
import { Title } from '@/components/Title'

export const ImportSpotifyPlaylistRoute = observer(
  function ImportSpotifyPlaylistRoute(): React.ReactElement {
    return <ImportSpotifyPlaylist />
  }
)

export const ImportSpotifyPlaylistRouteAdmin = observer(
  function ImportSpotifyPlaylistRouteAdmin(): React.ReactElement {
    return <ImportSpotifyPlaylist baseUrl='/admin/' />
  }
)

interface ImportSpotifyPlaylistProps {
  baseUrl?: string
}

const ImportSpotifyPlaylist = observer(function ImportSpotifyPlaylist({
  baseUrl = '/',
}: ImportSpotifyPlaylistProps): React.ReactElement {
  return (
    <Page title='Import from Spotify'>
      <AppHeader
        actionsLeft={<BackButton url={`${baseUrl}playlists/create`} />}
        header={<PageHeading>Import from Spotify</PageHeading>}
      />

      <Main>
        <Container size='medium'>
          <PageTitle>Choose an Import Method</PageTitle>
        </Container>

        <Container size='medium'>
          <Nav>
            <List>
              <List.Item
                to={`${baseUrl}playlists/create/import-spotify/library`}
                showChevron
              >
                <Title
                  description='Choose from your saved Spotify playlists to tailor a custom bingo
                    game'
                  heading='Spotify Library'
                  size='small'
                />
              </List.Item>
              <List.Item
                to={`${baseUrl}playlists/create/import-spotify/url`}
                showChevron
              >
                <Title
                  description='Grab a shareable link to any playlist on Spotify to import'
                  heading='Paste a URL'
                  size='small'
                />
              </List.Item>
            </List>
          </Nav>
        </Container>

        <TermsOfService />
      </Main>
    </Page>
  )
})
