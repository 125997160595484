import { observer } from 'mobx-react-lite'
import React from 'react'

import { GameControlsContextProvider } from '@/pages/game/context/game-controls-context'
import { Lobby } from '@/pages/game/lobby'

export const SidekickLobbyRoute = observer(
  function SidekickLobbyRoute(): React.ReactElement {
    return (
      <GameControlsContextProvider>
        <Lobby backUrl='.' isSidekick />
      </GameControlsContextProvider>
    )
  }
)
