import { Heading } from '@repo/ui'
import { Dialog } from '@repo/ui/client'

import { useDialogContext } from '@/dialogs/DialogContext'
import { PlaybackSettings } from '@/pages/settings/components/PlaybackSettings'

import { useSpotify } from '../useSpotify'

interface DeviceSelectorModalProps {
  setShowDeviceSelectorModal: (show: boolean) => void
  showDeviceSelectorModal: boolean
}

export function DeviceSelectorModal({
  setShowDeviceSelectorModal,
  showDeviceSelectorModal,
}: DeviceSelectorModalProps) {
  const { devices } = useSpotify()
  const { setErrorDialog } = useDialogContext()

  return (
    <Dialog className='mb-8 max-h-none' isVisible={showDeviceSelectorModal}>
      <Dialog.Body className='overflow-visible'>
        {!devices.activeDevice && (
          <Heading className='mb-2 text-center' disableTruncation>
            Your playback device isn{"'"}t responding
          </Heading>
        )}

        <PlaybackSettings
          onError={(error) =>
            setErrorDialog({
              error: error.message,
              onContinue: () => setShowDeviceSelectorModal(false),
            })
          }
          onSubmit={() => setShowDeviceSelectorModal(false)}
        />
      </Dialog.Body>
    </Dialog>
  )
}
