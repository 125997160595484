import { observer } from 'mobx-react-lite'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { useSearchParams } from '@/utils/use-search-params'

export const AuthActionRoute = observer(
  function AuthActionRoute(): React.ReactElement | null {
    const history = useHistory()
    const query = useSearchParams()
    const mode = query.get('mode')
    const code = query.get('oobCode')

    if (mode === 'resetPassword' && code) {
      history.push(`/verify-password-reset?oobCode=${code}`)
    }

    return null
  }
)
