import { Playlist } from '@repo/types'
import { ExportIcon } from '@repo/ui'
import { observer } from 'mobx-react-lite'
import React from 'react'

import { AppHeader } from '@/components/AppHeader'
import { BackButton } from '@/components/icon-buttons/IconButtons'
import { IconButton } from '@/components/IconButton'
import { Page } from '@/components/Page'
import { PageHeading } from '@/components/PageHeading'

import { PlaylistForm } from './PlaylistForm'
import { exportPlaylist } from '../playlist-export'

interface PlaylistEditorProps {
  baseUrl?: string
  onSubmit: (values: Playlist) => void
  playlist: Playlist
}

export const PlaylistEditor = observer(function PlaylistEditor({
  baseUrl = '/',
  onSubmit,
  playlist,
}: PlaylistEditorProps): React.ReactElement {
  const handleExportClick = () => {
    exportPlaylist(playlist)
  }

  return (
    <Page title='Edit Playlist'>
      <AppHeader
        actionsLeft={<BackButton url={`${baseUrl}playlists`} />}
        actionsRight={
          <IconButton
            icon={<ExportIcon />}
            title='Export Playlist'
            onClick={handleExportClick}
          />
        }
        header={<PageHeading>Edit Playlist</PageHeading>}
      />

      <PlaylistForm playlist={playlist} onSubmit={onSubmit} />
    </Page>
  )
})
