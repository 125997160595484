import { AuthProvider } from '@repo/types'
import { ButtonGroup, Container, Heading, Panel } from '@repo/ui'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import * as yup from 'yup'

import { AuthButton, AuthType } from '@/authentication/auth-button'
import { Button } from '@/components/Button'
import {
  PasswordForm,
  PasswordFormValues,
} from '@/components/forms/PasswordForm'
import { LogoHeader } from '@/components/LogoHeader'
import { Main } from '@/components/Main'
import { Page } from '@/components/Page'
import {
  RedirectToPathname,
  useRedirectToPathname,
} from '@/components/RedirectToPathname'
import { TermsOfService } from '@/components/TermsOfService'
import { firebaseApp } from '@/firebase/firebase'
import StoreService from '@/store/StoreService'
import { useSearchParams } from '@/utils/use-search-params'

const Login = observer(function Login(): React.ReactElement {
  const { user } = StoreService.getStore()
  const { isAnonymous, isLoggedIn } = user

  const query = useSearchParams()
  const emailParam = query.get('email')
  const isEmail = Boolean(emailParam)

  const [showEmailForm, setShowEmailForm] = useState(isEmail)

  const { redirectPathname } = useRedirectToPathname()

  const handleShowEmailForm = () => {
    setShowEmailForm(!showEmailForm)
  }

  const handleSubmit = ({ email, password }: PasswordFormValues) => {
    const auth = getAuth(firebaseApp)
    return signInWithEmailAndPassword(auth, email, password)
  }

  if (isLoggedIn && !isAnonymous) {
    return <RedirectToPathname redirectPathname={redirectPathname} />
  }

  return (
    <Page title='Login'>
      <Main>
        <LogoHeader />

        <Container size='small'>
          <Panel className='flex flex-col gap-6'>
            <Heading className='text-center' size='large'>
              Welcome back!
            </Heading>

            {!showEmailForm && (
              <>
                <ButtonGroup direction='column'>
                  <AuthButton
                    authType={AuthType.LOGIN}
                    provider={AuthProvider.EMAIL}
                    onClick={handleShowEmailForm}
                  />

                  <AuthButton
                    authType={AuthType.LOGIN}
                    provider={AuthProvider.GOOGLE}
                    onClick={() => user.signInGoogle()}
                  />
                </ButtonGroup>
              </>
            )}

            {showEmailForm && (
              <>
                <PasswordForm
                  buttonText='Login with Email'
                  passwordValidationSchema={yup
                    .string()
                    .required('Please enter a password')}
                  onSubmit={handleSubmit}
                />

                <Button
                  text='Forgot password?'
                  to='/reset-password'
                  variant='anchor'
                />

                <Button
                  text='Choose another login method'
                  variant='anchor'
                  onClick={handleShowEmailForm}
                />
              </>
            )}

            <footer className='border-t border-shade-3 py-6 text-right'>
              <Heading className='m-0 justify-end' size='small'>
                New to Rockstar Bingo?
              </Heading>
              <Button
                className='mt-2 justify-end'
                text='Sign up now'
                to='/create-account'
                variant='anchor'
              />
            </footer>
          </Panel>
        </Container>

        <TermsOfService />
      </Main>
    </Page>
  )
})

export const LoginRoute = observer(function LoginRoute(): React.ReactElement {
  return <Login />
})
