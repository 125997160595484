export function applyProperties<T extends object>(
  model: T,
  updates: Record<string, unknown>
): void {
  Reflect.ownKeys(updates).forEach((key) => {
    const value = Reflect.get(updates, key)
    try {
      Reflect.set(model, key, value)
    } catch {
      // Nothing to do - MobX will not allow assignment of 'computeds'
    }
  })
}
