import { Container } from '@repo/ui'
import { observer } from 'mobx-react-lite'

import { Logo } from './Logo'

export const LogoHeader = observer(function LogoHeader() {
  return (
    <Container className='flex justify-center p-6 md:p-8 lg:p-12' size='small'>
      <Logo />
    </Container>
  )
})
