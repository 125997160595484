import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'

import { useTrackPageView } from '@/hooks/useTrackPageView'

interface PageProps {
  children: React.ReactNode
  title: string
}

export const Page = observer(function Page({ children, title }: PageProps) {
  useTrackPageView(title)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Helmet>
        <title>{title} | Rockstar Bingo</title>
      </Helmet>

      {children}
    </>
  )
})
