import { Badge, NotesIcon } from '@repo/ui'
import { observer } from 'mobx-react-lite'
import React, { useRef } from 'react'

import { Tooltip } from '@/components/shared/tooltip/Tooltip'

interface NotesProps {
  notes: string
}
export const NotesBadge = observer(function NotesBadge({
  notes,
}: NotesProps): React.ReactElement {
  const ref = useRef<HTMLElement>(null)

  return (
    <>
      <Badge className='w-8' ref={ref} size='small'>
        <NotesIcon className='size-4' />
      </Badge>

      <Tooltip refObject={ref}>{notes}</Tooltip>
    </>
  )
})
