import { Playlist, Song } from '@repo/types'
import { MINIMUM_SONG_COUNT } from '@repo/ui'

import { checkPlaylist } from './checkPlaylist'
import { createNormalizedSongTitle } from './utils/createNormalizedSongTitle'

export function fixPlaylist(
  playlist: Playlist,
  minimumSongCount = MINIMUM_SONG_COUNT
) {
  const { errors, successes, warnings } = checkPlaylist(playlist)

  const errorSongs = new Map<string, Song>()

  for (const song of errors) {
    if (!song.spotifyMeta?.track.id) continue

    if (!errorSongs.has(song.spotifyMeta?.track.id)) {
      errorSongs.set(song.spotifyMeta?.track.id, song)
    }
  }

  const fixedErrorSongs = Array.from(errorSongs.values())

  const fixedErrorAndWarningSongs = new Map<string, Song>()

  for (const song of [...fixedErrorSongs, ...warnings]) {
    const normalizedTitle = createNormalizedSongTitle(song)
    if (!fixedErrorAndWarningSongs.has(normalizedTitle)) {
      fixedErrorAndWarningSongs.set(normalizedTitle, song)
    }
  }

  const fixedSongsArray = Array.from(fixedErrorAndWarningSongs.values())

  const output: Song[] = [...fixedSongsArray, ...successes].sort(
    (a, b) => a.id - b.id
  )

  if (output.length < minimumSongCount) {
    return {
      message: `After removing duplicates, there aren't enough songs to create a game. A playlist requires at least ${minimumSongCount} unique songs.`,
      status: 'error',
    } as const
  }

  return {
    playlist: {
      ...playlist,
      songs: output.map((song, index) => ({ ...song, id: index })),
    },
    status: 'success',
  } as const
}
