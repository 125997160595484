import { Device } from '@repo/types'
import { makeAutoObservable } from 'mobx'

import { Store } from '@/store/Store'
import StoreService from '@/store/StoreService'
import { applyProperties } from '@/store/utils'

import { IntegrationDevicesStore } from '..'

export const SESSION_STORAGE_INTERNAL_PLAYER_KEY =
  'spotify_internal_player_device_id'

export class SpotifyDevicesStore implements IntegrationDevicesStore {
  private integrationId = ''
  public activeDevice?: Device

  public enableExternalDevices = false
  public supportsExternalDevices = false

  public storedDeviceIdLocal =
    sessionStorage.getItem(SESSION_STORAGE_INTERNAL_PLAYER_KEY) ?? undefined

  public storedDeviceIdRemote = ''
  public storedDeviceIdRemoteTimestamp = 0

  public constructor(
    integrationId: string,
    enableExternalDevices: boolean,
    supportsExternalDevices: boolean
  ) {
    this.integrationId = integrationId

    this.enableExternalDevices = enableExternalDevices
    this.supportsExternalDevices = supportsExternalDevices

    makeAutoObservable(this)
  }

  private get store(): Store {
    return StoreService.getStore()
  }

  private get spotify() {
    return this.store.spotify
  }

  private get player() {
    return this.store.spotify.player
  }

  public get isInternalPlayerInAnotherTab() {
    if (!this.activeDevice) return false

    return (
      !this.activeDevice?.isExternal &&
      this.player.deviceId !== this.activeDevice?.id
    )
  }

  public apply(values: Partial<SpotifyDevicesStore>) {
    applyProperties(this, values)
  }

  public setActiveDevice(device: Device | undefined): Promise<void> {
    this.apply({ activeDevice: device })

    if (!device) {
      return Promise.resolve()
    }

    return this.spotify.setPlaybackDevice(device)
  }

  public setEnableExternalDevices(enableExternal = false): Promise<void> {
    this.enableExternalDevices = enableExternal

    return this.spotify.setEnableExternalDevices(enableExternal)
  }
}
