import { Container } from '@repo/ui'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { AppHeader } from '@/components/AppHeader'
import { BackButton, CloseButton } from '@/components/icon-buttons/IconButtons'
import { Main } from '@/components/Main'
import { Page } from '@/components/Page'
import { PageHeading } from '@/components/PageHeading'
import { JumbotronSettingsToggles } from '@/pages/settings/components/jumbotron/JumbotronSettingsToggles'

export const JumbotronSettingsRoute = observer(
  function JumbotronSettingsRoute(): React.ReactElement {
    return <JumbotronSettings />
  }
)

const JumbotronSettings = observer(
  function JumbotronSettings(): React.ReactElement {
    const history = useHistory()

    const handleClickBack = () => {
      history.goBack()
    }

    const handleClickClose = () => {
      history.push('/game')
    }

    return (
      <Page title='Jumbotron Settings'>
        <AppHeader
          actionsLeft={<BackButton onClick={handleClickBack} />}
          actionsRight={<CloseButton onClick={handleClickClose} />}
          header={<PageHeading>Jumbotron Settings</PageHeading>}
        />

        <Main>
          <Container size='medium'>
            <JumbotronSettingsToggles />
          </Container>
        </Main>
      </Page>
    )
  }
)
