import { ButtonGroup } from '@repo/ui'
import { observer } from 'mobx-react-lite'
import React from 'react'

import { Button } from '@/components/Button'

import { useGameContext } from '../pages/game/context/game-context'
import { useGameControlsContext } from '../pages/game/context/game-controls-context'

export const GameStatusButtons = observer(
  function GameStatusButtons(): React.ReactElement {
    const { isCompletedGame, isStartedGame } = useGameContext()

    const {
      handleFinishGameClick,
      handleStartGameClick,
      isFinishingGame,
      isStartingGame,
    } = useGameControlsContext()

    const isStartDisabled = isStartingGame

    const isFinishDisabled = !isStartedGame || isFinishingGame

    return (
      <>
        {!isCompletedGame && (
          <ButtonGroup>
            {!isStartedGame && (
              <Button
                className='mr-5'
                disabled={isStartDisabled}
                isLoading={isStartingGame}
                loadingText='Starting...'
                text='Start Game'
                onClick={handleStartGameClick}
              />
            )}

            {isStartedGame && (
              <Button
                disabled={isFinishDisabled}
                isLoading={isFinishingGame}
                loadingText='Finishing...'
                text='Finish Game'
                variant='outlined'
                onClick={handleFinishGameClick}
              />
            )}
          </ButtonGroup>
        )}
      </>
    )
  }
)
