import { Column, DrawnItem } from '@repo/types'

interface CurrentDrawnItemProps {
  drawnItem: DrawnItem
}

export function CurrentDrawnItem({ drawnItem }: CurrentDrawnItemProps) {
  const { column, id } = drawnItem

  return (
    <div className='flex gap-2 text-4xl font-bold md:text-6xl'>
      <div className='flex size-16 items-center justify-center rounded-3xl rounded-r-none bg-orange-500 text-black transition-opacity duration-300 sm:size-24 md:size-32'>
        <span className='-mt-1'>{Column[column]}</span>
      </div>
      <div className='flex size-16 items-center justify-center rounded-3xl rounded-l-none bg-blue-500 transition-opacity duration-300 sm:size-24 md:size-32'>
        <span className='-mt-2'>{id}</span>
      </div>
    </div>
  )
}
