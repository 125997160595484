import StoreService from '@/store/StoreService'

import { SpotifyClient } from './SpotifyClient'
import { IntegrationProfile, IntegrationProfileService } from '..'

export class SpotifyProfileService implements IntegrationProfileService {
  private client: SpotifyClient

  public constructor(client: SpotifyClient) {
    this.client = client
  }

  get store() {
    return StoreService.getStore()
  }

  get user() {
    return this.store.user
  }

  get spotify() {
    return this.store.spotify
  }

  get queries() {
    return {
      profile: {
        fn: () => this.client.getProfile(),
        key: ['spotify-profile', this.user.userId, this.spotify.auth.email],
      },
    } as const
  }

  queryProfile(): Promise<IntegrationProfile> {
    return this.client.getProfile()
  }
}
