import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { Redirect, useLocation } from 'react-router-dom'

interface LocationState {
  from: {
    pathname: string
  }
}

const REDIRECT_PATHNAME_KEY = 'redirect_pathname'

function getRedirectPathname() {
  return localStorage.getItem(REDIRECT_PATHNAME_KEY)
}

function removeRedirectPathname() {
  return localStorage.removeItem(REDIRECT_PATHNAME_KEY)
}

function setRedirectPathname(pathname: string) {
  return localStorage.setItem(REDIRECT_PATHNAME_KEY, pathname)
}

export const RedirectToPathname = observer(
  ({ redirectPathname }: { redirectPathname: string }) => {
    useEffect(() => {
      removeRedirectPathname()
    }, [])
    return <Redirect to={redirectPathname} />
  }
)

export function useRedirectToPathname() {
  const { state } = useLocation<LocationState>()
  const redirectPathname = getRedirectPathname() ?? '/'

  useEffect(() => {
    try {
      if (state.from.pathname !== '/') {
        setRedirectPathname(state.from.pathname)
      }
    } catch {
      //
    }
  }, [state?.from.pathname])

  return {
    redirectPathname,
  }
}
