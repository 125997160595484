import { Heading, merge } from '@repo/ui'

import { Avatar } from './Avatar'
import { useQuerySpotifyProfile } from '../queries/useQuerySpotifyProfile'

export function SpotifyProfileBadge({
  className,
  size = 'large',
}: {
  className?: string
  size?: 'small' | 'large'
}) {
  const { data: profile, isError, isLoading } = useQuerySpotifyProfile()

  if (isLoading || isError || !profile) return null

  if (size === 'small') {
    return (
      <div
        className={merge(
          'flex flex-col gap-1 justify-center overflow-hidden',
          className
        )}
      >
        <div className='flex items-center'>
          <div className='text-2xs uppercase tracking-wider text-muted'>
            Spotify account:
          </div>
        </div>

        <div className='flex max-w-full items-center gap-2 overflow-hidden text-sm'>
          <Avatar imageUrl={profile.imageUrl} />
          <Heading size='extra-small'>{profile.displayName}</Heading>
        </div>
      </div>
    )
  }

  return (
    <div className='flex flex-col justify-center gap-3'>
      <div className='flex items-center'>
        <div className='text-2xs uppercase tracking-wider text-muted'>
          Spotify account:
        </div>
      </div>

      <div className='flex gap-3'>
        <Avatar className='size-12' imageUrl={profile.imageUrl} />

        <div className='flex flex-col'>
          <div>{profile.displayName}</div>
          <div className='text-muted'>{profile.email}</div>
        </div>
      </div>
    </div>
  )
}
