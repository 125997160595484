import { ScrollContainer } from '@repo/ui/client'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { AnimatedStaggerChild, AnimatedStaggerParent } from './AnimatedStagger'
import { focusOutlineStyles } from '../StyledComponents'

interface SelectProps<T extends string> {
  children?: never
  className?: string
  disabled?: boolean
  isOpen?: boolean
  label?: string
  onChange?: (value: T, event: unknown) => void
  onClick?: () => void
  options: SelectOption<T>[]
  placeholder?: string
  ref?: (node: HTMLElement | null) => void
  value?: T
}

export interface SelectOption<T extends string = string> {
  description?: React.ReactNode
  graphic?: React.ReactNode
  id: string
  name: string
  value: T
}

interface SelectOptionProps<T extends string> extends SelectOption<T> {
  handleChooseOption: (id: string, event: React.SyntheticEvent) => void
}

export const Select = observer(function Select<T extends string = string>({
  className,
  disabled,
  isOpen: isOpenControlled,
  label,
  onChange,
  onClick,
  options,
  placeholder,
  value,
  ...props
}: SelectProps<T>): React.ReactElement {
  const [isOpen, setIsOpen] = useState<boolean>(Boolean(isOpenControlled))
  const [selectedId, setSelectedId] = useState<string | null>(null)

  const handleOpenSelect = () => {
    if (onClick) {
      onClick()
    }
    if (disabled) return
    setIsOpen(!isOpen)
  }

  const getSelectedItem = (id: string) => {
    if (!options) return
    return options.find(item => item.id === id)
  }

  const handleChooseOption = (id: string, event: React.SyntheticEvent) => {
    setSelectedId(id)
    setIsOpen(false)
    const item = getSelectedItem(id)
    if (onChange && item) {
      onChange(item.value, event)
    }
  }

  useEffect(() => {
    const selectedId = options.find(option => option.value === value)
    if (selectedId) {
      setSelectedId(selectedId.id)
    } else {
      setSelectedId(null)
    }
  }, [value, options])

  const node = useRef<HTMLDivElement>(null)

  const handleClick = useCallback(
    (event: Event) => {
      if (node.current?.contains(event.target as Node)) {
        // click was inside component
        return
      }

      // click was outside component
      isOpenControlled === undefined && setIsOpen(false)
    },
    [isOpenControlled]
  )

  useEffect(() => {
    // called when component mounts
    document.addEventListener('mousedown', handleClick)

    // called when component unmounts
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [handleClick])

  return (
    <SelectStyled className={classNames({ 'is-open': isOpen }, className)} ref={node}>
      {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
      <div className={`select ${disabled ? `disabled` : ``}`}>
        {label && <LabelStyled>{label}</LabelStyled>}

        <button type='button' onClick={handleOpenSelect} {...props}>
          <SelectedItemTextStyled>
            {selectedId ? (
              getSelectedItem(selectedId)?.name
            ) : placeholder ? (
              <div className='text-muted'>{placeholder}</div>
            ) : (
              options[0]?.name
            )}
          </SelectedItemTextStyled>
          <SeparatorStyled></SeparatorStyled>
          <IndicatorContainerStyled>
            <svg
              /* eslint-disable-next-line tailwindcss/no-custom-classname */
              className='chevron'
              height='24'
              viewBox='0 0 24 24'
              width='24'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z' />
            </svg>
          </IndicatorContainerStyled>
        </button>
        {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
        <div className='select-options--container'>
          {isOpen && (
            <AnimatedStaggerParent>
              {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
              <div className='options is-scrollable'>
                <ScrollContainer>
                  <ul>
                    {options.map(option => (
                      <AnimatedStaggerChild key={option.id} keyId={option.id}>
                        <SelectOptionComponent
                          handleChooseOption={handleChooseOption}
                          {...option}
                        />
                      </AnimatedStaggerChild>
                    ))}
                  </ul>
                </ScrollContainer>
              </div>
            </AnimatedStaggerParent>
          )}
        </div>
      </div>
    </SelectStyled>
  )
})

const SelectOptionComponent = observer(function SelectOptionComponent<T extends string>({
  description,
  graphic,
  handleChooseOption,
  id,
  name
}: SelectOptionProps<T>): React.ReactElement {
  const handleClick = (event: React.SyntheticEvent) => {
    handleChooseOption(id, event)
  }

  return (
    <li>
      <button className={id} onClick={handleClick}>
        {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
        {graphic && <div className='graphic'>{graphic}</div>}
        {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
        <div className='text'>
          <h4>{name}</h4>
          {description && <p>{description}</p>}
        </div>
      </button>
    </li>
  )
})

const padding = `6px 12.5px`

const SelectStyled = styled.div`
  .light & {
    --input: var(--bg-03);
    --input-hover: var(--bg-04);
    --input-border: var(--input);
  }

  .dark & {
    --input: var(--bg-03);
    --input-hover: var(--bg-04);
    --input-border: var(--input);
  }

  position: relative;

  &.is-open {
    .select:not(.disabled) > button {
      background: var(--bg-00);
      border-color: var(--input);
      color: var(--text-muted);

      &:hover {
        background: var(--bg-00);
      }
    }
  }

  .select {
    button {
      width: 100%;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0;
      box-sizing: border-box;
      padding: 0 5px 0 18px;
      border: 1px solid var(--input);
      border-radius: 4px;
      background: var(--input);
      color: var(--text);
      font-weight: 700;
      font-size: 1em;
      transition: background 0.3s ease-in-out;

      .chevron {
        path {
          fill: currentColor;
        }
      }
    }

    &:not(.disabled) {
      button {
        &:hover {
          background: var(--input-hover);
          cursor: pointer;
        }

        ${focusOutlineStyles}
      }
    }

    &.disabled {
      opacity: 0.4;

      button {
        &:hover {
          cursor: default;
        }
      }
    }

    .options {
      position: absolute;
      margin-top: 4px;

      z-index: 100;
      width: 100%;
      background: var(--bg-00);
      max-height: clamp(128px, 50vh, 640px);
      border-radius: 4px;
      padding: 4px;

      display: flex;
      flex-direction: column;
      overflow: hidden;

      &:not(:hover) {
        ::-webkit-scrollbar-thumb {
          background-color: var(--bg-02);
        }

        &::-webkit-scrollbar {
          width: 0;
          background-color: transparent;
        }
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      gap: 4px;
      border-radius: 4px;

      li {
        button {
          display: flex;
          padding: ${padding};
          height: auto;
          border-radius: 4px;

          .graphic {
            flex-grow: 0;
            flex-shrink: 0;
            color: var(--primary-500);
            display: flex;
            align-items: center;
            justify-content: center;
            overflow: hidden;
          }

          .text {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex-grow: 1;
            text-align: left;
            padding: ${padding};

            h4 {
              margin: 0;
              padding: 0;
              font-size: 1em;
            }

            p {
              margin: 0;
              color: var(--text-muted);
              font-weight: 400;
              font-size: 14px;
              line-height: 1.4;
            }
          }
        }
      }
    }
  }
`

const LabelStyled = styled.div`
  font-size: 0.83333em;
  margin-bottom: 5px;
  color: var(--text);
`

const SelectedItemTextStyled = styled.span`
  display: flex;
  justify-content: flex-start;
  flex: 1;
`

const SeparatorStyled = styled.span`
  align-self: stretch;
  background-color: var(--border-04);
  margin-bottom: 8px;
  margin-top: 8px;
  width: 1px;
  box-sizing: border-box;
`

const IndicatorContainerStyled = styled.div`
  display: flex;
  padding: 8px;
`
