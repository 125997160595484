import { Device } from '@repo/types'
import {
  ComputerIcon,
  Description,
  Heading,
  PhoneIcon,
  SpeakerIcon,
  TabletIcon,
} from '@repo/ui'
import { observer } from 'mobx-react-lite'

import { Button } from '@/components/Button'
import { Select } from '@/components/shared/Select'

import { SpotifyTroubleshootingLink } from './SpotifyTroubleshootingLink'
import { SpotifyDevice } from '../types'

function getGraphic(type: string) {
  let graphic

  switch (type) {
    case SpotifyDevice.SMARTPHONE:
      graphic = <PhoneIcon />
      break
    case SpotifyDevice.COMPUTER:
      graphic = <ComputerIcon />
      break
    case SpotifyDevice.TABLET:
      graphic = <TabletIcon />
      break
    case SpotifyDevice.SPEAKER:
      graphic = <SpeakerIcon />
      break
    default:
      graphic = undefined
  }

  return graphic
}

interface DeviceSelectorProps {
  devices: readonly Device[]
  isLoading?: boolean
  onChange: (id: string) => void
  onClickRefresh: () => void
  selectedDevice?: Device
}

export const DeviceSelector = observer(function DeviceSelector({
  devices,
  isLoading = false,
  onChange,
  onClickRefresh,
  selectedDevice,
}: DeviceSelectorProps) {
  const options = [
    ...devices.map(({ id, name, type }) => {
      return {
        description: type,
        graphic: getGraphic(type),
        id,
        name,
        value: id,
      }
    }),
  ]

  return (
    <>
      <Heading>Spotify Apps</Heading>

      <Description className='mt-2'>
        Control a Spotify app running outside of Rockstar Bingo. Make sure your
        device is awake and Spotify is open. If your device still doesn{"'"}t
        show, try playing a song directly in the Spotify app for a moment to
        wake it up.
      </Description>

      <SpotifyTroubleshootingLink className='mt-2' />

      <Button
        className='mb-4 mt-6'
        isLoading={isLoading}
        text={isLoading ? 'Loading Devices...' : 'Refresh'}
        variant='outlined'
        onClick={onClickRefresh}
      />

      <Select
        className='mb-6'
        disabled={!devices.length}
        label=''
        options={options}
        placeholder={!devices.length ? 'No active devices' : 'Select a device'}
        value={selectedDevice?.id ?? ''}
        onChange={onChange}
        onClick={onClickRefresh}
      />
    </>
  )
})
