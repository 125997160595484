import { observer } from 'mobx-react-lite'
import React from 'react'
import { useHistory } from 'react-router-dom'

import { Button } from '@/components/Button'

import StoreService from '../store/StoreService'

interface LogoutProps {
  className?: string
  variant?: 'anchor' | 'outlined'
}

export const Logout = observer(function Logout({
  className,
  variant = 'outlined',
}: LogoutProps): React.ReactElement {
  const store = StoreService.getStore()
  const history = useHistory()

  const handleClick = () => {
    history.push('/login')
    store.user.signOut()
  }

  return (
    <Button
      className={className}
      text='Logout'
      variant={variant}
      onClick={handleClick}
    />
  )
})
