import { observer } from 'mobx-react-lite'
import React from 'react'

import { BingoResultsBanner } from '@/components/BingoResultsBanner'
import { CalledBingos } from '@/components/CalledBingos'

import { useGameContext } from './../context/game-context'
import { useGameControlsContext } from './../context/game-controls-context'

interface MessageBannersProps {
  isSidekick?: boolean
}

export const MessageBanners = observer(function MessageBanners({
  isSidekick = false,
}: MessageBannersProps): React.ReactElement {
  const { hasCalledBingo } = useGameContext()
  const { isVerifying } = useGameControlsContext()

  return (
    <>
      {hasCalledBingo && !isVerifying && (
        <CalledBingos hideControls={isSidekick} />
      )}

      <BingoResultsBanner />
    </>
  )
})
