import { merge, Badge, Container, Label } from '@repo/ui'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useLocation } from 'react-router-dom'

import { DisplayGameCode } from './DisplayGameCode'
import { NavigationLink } from './NavigationLink'
import { useGameContext } from '../pages/game/context/game-context'

export const GameHeader = observer(function GameHeader(): React.ReactElement {
  const { activePlayers, playersInLobby } = useGameContext()
  const { pathname } = useLocation()

  const getPathname = () => pathname.replace(/\/\s*$/, '')

  return (
    <Container className='flex justify-center gap-8' size='small'>
      <DisplayGameCode size='small' />

      <div className='hidden gap-8 sm:flex'>
        <NavigationLink
          className={merge(
            'flex flex-col items-center justify-center gap-1 w-16'
          )}
          to={`${getPathname()}/lobby`}
        >
          <Label className='text-center text-xs'>Playing</Label>
          <Badge colour='green' size='small'>
            {activePlayers.length}
          </Badge>
        </NavigationLink>

        <NavigationLink
          className={merge(
            'flex flex-col items-center justify-center gap-1 w-16'
          )}
          to={`${getPathname()}/lobby`}
        >
          <Label className='text-center text-xs'>Lobby</Label>
          <Badge colour='orange' size='small'>
            {playersInLobby.length}
          </Badge>
        </NavigationLink>
      </div>
    </Container>
  )
})
