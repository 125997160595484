import { getErrorMessage } from '@repo/lib'
import { Playlist } from '@repo/types'
import * as Sentry from '@sentry/react'
import { observer } from 'mobx-react-lite'
import React, { ChangeEvent, useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useDialogContext } from '@/dialogs/DialogContext'
import { MixpanelService } from '@/mixpanel'
import { MINIMUM_SONG_ERROR } from '@/store/integrations/spotify/SpotifyClient'
import { useSpotify } from '@/store/integrations/spotify/useSpotify'

import { ImportSpotifyPlaylistUrlView } from './import-spotify-url-view'
import { SpotifyConnectTextPanel } from '../SpotifyConnectTextPanel'

const UNKNOWN_ERROR = 'There was a problem with the URL. Please try again.'

interface ImportSpotifyUrlContainerProps {
  baseUrl?: string
  onSubmit: (playlist: Playlist) => Promise<{ playlistId: string }>
}

export const ImportSpotifyUrlContainer = observer(function ImportSpotifyUrlContainer({
  baseUrl = '/',
  onSubmit
}: ImportSpotifyUrlContainerProps): React.ReactElement {
  const { auth, playlistService } = useSpotify()
  const { isAuthenticated } = auth

  const { setErrorDialog } = useDialogContext()

  const history = useHistory()
  const [isLoadingPlaylist, setIsLoadingPlaylist] = useState(false)
  const [playlistUrl, setPlaylistUrl] = useState('')

  const handleCancel = () => {
    setPlaylistUrl('')
  }

  const handleSubmit = useCallback(async () => {
    setIsLoadingPlaylist(true)

    try {
      const playlist = await playlistService.queryPlaylistByUrl(playlistUrl)
      if (playlist) {
        const { playlistId } = await onSubmit(playlist)

        MixpanelService.importPlaylistSpotifyURL(playlistId, playlist.title, playlist.songs.length)

        setIsLoadingPlaylist(false)

        history.push(`${baseUrl}playlists/edit/${playlistId}`)
      }
    } catch (error) {
      console.error(error)
      Sentry.captureException(error)

      setIsLoadingPlaylist(false)

      const errorMessage =
        getErrorMessage(error) === MINIMUM_SONG_ERROR ? MINIMUM_SONG_ERROR : UNKNOWN_ERROR

      setErrorDialog({ error: errorMessage })
    }
  }, [baseUrl, history, onSubmit, playlistService, playlistUrl, setErrorDialog])

  const handlePlaylistUrlInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target as HTMLInputElement
    setPlaylistUrl(value)
  }

  if (!isAuthenticated) {
    return <SpotifyConnectTextPanel />
  }

  return (
    <ImportSpotifyPlaylistUrlView
      isLoading={isLoadingPlaylist}
      playlistUrl={playlistUrl}
      onCancel={handleCancel}
      onPlaylistUrlInputChange={handlePlaylistUrlInputChange}
      onSubmit={handleSubmit}
    />
  )
})
