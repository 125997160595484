import { Playlist } from '@repo/types'
import { useMutation, useQueryClient } from 'react-query'

export function useDeletePlaylist(
  mutationFn: (playlistId: string) => Promise<Response>,
  listQueryKey: string[],
  listQueryFn: () => Promise<Playlist[]>
) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn,
    onError: (err, variables, context: any) => {
      // Roll back on error
      queryClient.setQueryData(listQueryKey, context?.previousPlaylists)
    },
    onMutate: async playlistId => {
      await queryClient.cancelQueries(listQueryKey)

      const previousPlaylists = queryClient.getQueryData(listQueryKey)

      // Optimistically remove the playlist from the list
      queryClient.setQueryData(listQueryKey, (oldPlaylists: Playlist[] = []) =>
        oldPlaylists?.filter(playlist => playlist.id !== playlistId)
      )

      return { previousPlaylists }
    },
    onSettled: () => {
      queryClient.invalidateQueries(listQueryKey)
      queryClient.fetchQuery(listQueryKey, listQueryFn)
    }
  })
}
