import { PlaybackOption, Playlist } from '@repo/types'

import { checkDuplicates } from './checkDuplicates'

export function checkPlaylist(playlist: Playlist) {
  const isSpotifyCompatible = playlist.playbackOptions.includes(
    PlaybackOption.SPOTIFY
  )

  const errors = []
  const warnings = []
  const successes = []

  const playlistSongs = [...playlist.songs].sort((a, b) =>
    a.title.localeCompare(b.title)
  )

  for (const song of playlistSongs) {
    const otherSongs = playlist.songs.filter(
      (playlistSong) => playlistSong !== song
    )
    const result = checkDuplicates(song, otherSongs, {
      isSpotifyCompatible,
    })

    if (result.status === 'error') {
      errors.push(song)
    }

    if (result.status === 'warning') {
      warnings.push(song)
    }

    if (result.status === 'success') {
      successes.push(song)
    }
  }

  return {
    errors,
    successes,
    warnings,
  }
}
