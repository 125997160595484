import { Panel, Badge, Heading, Description } from '@repo/ui'
import { observer } from 'mobx-react-lite'

import { ManageSubscriptionButton } from '../pages/settings/components/AccountSettings'

export const SubscriptionActionRequired = observer(() => (
  <Panel className='flex flex-col gap-8'>
    <div className='flex flex-col gap-2'>
      <Badge className='mb-2 mr-auto w-auto text-xs' colour='red'>
        Action Required
      </Badge>
      <Heading>Your Subscription has been disabled</Heading>
      <Description>
        Paid features will be unavailable until your account is updated and
        payment has resumed.
      </Description>
    </div>
    <ManageSubscriptionButton />
  </Panel>
))
