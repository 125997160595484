import { Badge } from '@repo/ui'

export function BetaBadge() {
  return (
    <Badge
      className='absolute right-2 top-2 mr-auto w-auto text-xs sm:text-xs'
      colour='red'
    >
      Beta
    </Badge>
  )
}
