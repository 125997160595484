import { CommonProps } from '@repo/types'
import { merge } from '@repo/ui'
import { observer } from 'mobx-react-lite'
import React from 'react'

interface LogoProps extends CommonProps {
  size?: 'small' | 'large'
}

export const Logo = observer(function Logo({
  className,
  size = 'large',
}: LogoProps): React.ReactElement {
  return (
    <svg
      className={merge('h-8', { 'h-12': size === 'large' }, className)}
      viewBox='0 0 764.2 128.2'
      xmlns='http://www.w3.org/2000/svg'
    >
      <defs />
      <path
        d='M396 48.4l16 15.7 22.9-1.9-19.4 17-3 18.4L437 82.5l25.6 17.2-8.1-31 25.8-19.4-33.4-3.1-9.9-31.5-8.5 26.5-1 3.3z'
        fill='#fff'
      />
      <path
        d='M82.4 73.5c0-15.3-12.3-27.6-27.6-27.6S27.2 58.2 27.2 73.5c0 15.2 12.3 27.6 27.6 27.6s27.6-12.5 27.6-27.6zm-44.9 0c0-9.5 7.8-17.3 17.3-17.3S72.1 64 72.1 73.5s-7.8 17.2-17.3 17.2c-9.6-.1-17.3-7.7-17.3-17.2zM137.8 83.9H126c-3.1 4.2-8.2 6.8-13.8 6.8-9.5 0-17.2-7.8-17.2-17.3s7.6-17.2 17.2-17.2c5.6 0 10.7 2.7 13.8 6.8h11.8c-4-10.1-14-17.3-25.6-17.3-15.2 0-27.6 12.5-27.6 27.6 0 15.3 12.5 27.6 27.6 27.6 11.7.2 21.6-6.9 25.6-17zM282.3 45.8H263V31.7h-10.3v14.1H204.5c-9 0-16.4 7.2-16.4 16.4 0 9 7.4 16.4 16.4 16.4H227c3.4 0 6 2.8 6 6 0 3.4-2.7 6-6 6h-38.9v10.5H227c9 0 16.4-7.4 16.4-16.5 0-9-7.4-16.4-16.4-16.4h-22.5c-3.4 0-6-2.7-6-6 0-3.4 2.7-6 6-6h48.2v44.9H263v-45h8.9c2.7-4.1 6.3-7.7 10.4-10.3z'
        fill='var(--text)'
      />
      <path
        d='M299.7 45.8c-15.3 0-27.6 12.3-27.6 27.6s12.3 27.6 27.6 27.6c6.6 0 12.5-2.3 17.3-6v6h10.3V73.5c0-15.3-12.3-27.7-27.6-27.7zm0 45c-9.5 0-17.3-7.8-17.3-17.3s7.8-17.3 17.3-17.3S317 63.9 317 73.5s-7.8 17.3-17.3 17.3zM332.6 73.4V101h10.3V73.4c0-9.7 6.9-17.7 17.1-17.7v-9.9c-17.4 0-27.4 9.4-27.4 27.6zM27.4 45.8C10 45.8 0 55.2 0 73.4V101h10.3V73.4c0-9.7 6.9-17.7 17.1-17.7v-9.9zM185.2 45.7H172l-20.1 26.4V31.7h-10.3v69.4h10.3V74.5L172 101h13.2L164 73.3z'
        fill='var(--text)'
      />
      <g fill='var(--text)'>
        <path d='M569.7 73.5c0 15.1-12.2 27.3-27.3 27.3-15 0-27.3-12.2-27.3-27.3V18.8h10.4v33.4c4.6-3.8 10.6-6.1 17-6.1 15 .1 27.2 12.4 27.2 27.4zm-10.2 0c0-9.4-7.7-17-17.1-17-9.4 0-17 7.6-17 17s7.6 17.1 17 17.1 17.1-7.7 17.1-17.1zM585.2 35.8h-10.4V25.6h10.4v10.2zm-10.3 10.4h10.4v54.7h-10.4V46.2zM602 100.8h-10.2V73.5c0-15.1 12.2-27.3 27.3-27.3 15.1 0 27.3 12.2 27.3 27.3v27.3h-10.2V73.5c0-9.4-7.7-17.1-17.1-17.1-9.4 0-17.1 7.7-17.1 17.1v27.3zM695.1 100.7v-6c-4.6 3.8-10.6 6.1-17.1 6.1-15 0-27.3-12.3-27.3-27.3 0-15.1 12.3-27.3 27.3-27.3 15.1 0 27.3 12.2 27.3 27.3v27.3c0 15-12.2 27.3-27.3 27.3-12.7 0-23.4-8.8-26.4-20.6h10.7c2.7 6 8.8 10.2 15.7 10.2 9.4 0 17.1-7.6 17.1-17zm0-27.2c0-9.4-7.7-17.1-17.1-17.1-9.4 0-17 7.7-17 17.1 0 9.4 7.6 17 17 17s17.1-7.6 17.1-17zM764.2 73.5c0 15-12.2 27.3-27.3 27.3-15.1 0-27.3-12.3-27.3-27.3 0-15.1 12.2-27.3 27.3-27.3 15.1 0 27.3 12.2 27.3 27.3zm-10.3 0c0-9.4-7.7-17.1-17.1-17.1-9.4 0-17.1 7.7-17.1 17.1 0 9.4 7.7 17 17.1 17 9.4 0 17.1-7.6 17.1-17z' />
      </g>
      <path
        d='M437 25.1l1.5 4.5 3.5 10.6 3.4 10.4h26.8l-3.8 2.8-9 6.6-8.8 6.5 3.4 10.4 3.4 10.6 1.5 4.5-3.9-2.8-9-6.5-8.9-6.4-8.9 6.4-9 6.5-3.9 2.8 1.5-4.5 1.9-5.9.2-.5.4-.3 5-3.6 11.9-8.6.9-.7.9.7 8.1 5.8-3-9.5-.3-1.1.9-.7 8-5.9h-11l-.4-1.1-3.1-9.4-3.1 9.4-.4 1.1h-22.5l-.4-.3-4.9-3.6-3.8-2.8H428.9l3.4-10.4 3.5-10.6 1.2-4.4M437 0l-7.4 22.7-1.5 4.5-3.5 10.6-1.6 5h-21.2l-23.8.1L397.3 57l3.8 2.8 4.9 3.6.4.3 2.1 1.5h19l-7.9 5.7-5 3.6-.4.3-2.1 1.5-.8 2.4-.2.5-1.9 5.9-1.5 4.5-7.3 22.7 19.3-14 3.9-2.8 9-6.5 4.3-3.1 4.3 3.1 9 6.5 3.9 2.8 19.3 14-7.3-22.7-1.5-4.5-3.4-10.6-1.6-5 4.3-3.1 9-6.6 3.8-2.8L496 42.9l-23.9-.1h-21.2l-1.6-5-3.5-10.6-1.5-4.5L437 0z'
        fill='#6A24DB'
      />
    </svg>
  )
})
