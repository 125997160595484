import { observer } from 'mobx-react-lite'
import React, { createContext, useContext } from 'react'

import { GameStore } from '@/store/game/GameStore'

import { CurrentGameContextProvider } from './current-game-context-provider'
import { GameHistoryContextProvider } from '../../game-history/game-history-context-provider'

export const GameContext = createContext({} as GameStore)

export function useGameContext(): GameStore {
  const context = useContext(GameContext)

  if (context === undefined) {
    throw new Error('useGameContext must be used within a GameContextProvider')
  }

  return context
}

export interface GameContextProviderProps {
  children: React.ReactNode
  gameId?: string
}

export const GameContextProvider = observer(function GameContextProvider({
  children,
  gameId,
}: GameContextProviderProps) {
  if (gameId) {
    return (
      <GameHistoryContextProvider gameId={gameId}>
        {children}
      </GameHistoryContextProvider>
    )
  }

  return <CurrentGameContextProvider>{children}</CurrentGameContextProvider>
})
