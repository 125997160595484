import { ISpotifyFeatures, ISpotifyMeta, Song, SongKeys, SongModeFull } from '@repo/types'
import { merge, Badge, SpotifyIcon, InfoCircleIcon } from '@repo/ui'
import { observer } from 'mobx-react-lite'
import React, { useRef } from 'react'
import styled from 'styled-components'

import { Tooltip } from './shared/tooltip/Tooltip'
import { getShowFeaturesSettings } from '../pages/settings/pages/sidekick-settings'

interface AudioBadgeProps {
  className?: string
  song: Song
}

interface RowAudioFeaturesProps {
  currentItemIndex: number
  index: number
  song: Song
  spotifyMeta?: ISpotifyMeta
}

export const RowAudioFeatures = observer(function RowAudioFeatures({
  currentItemIndex,
  index,
  song
}: RowAudioFeaturesProps): React.ReactElement {
  if (!song.spotifyMeta?.features || !song.userMeta) return <></>

  const showFeatures = getShowFeaturesSettings()

  const trackId = song.spotifyMeta.track.id

  return (
    <div className='flex items-center gap-2'>
      {showFeatures.bpm && <TempoBadge song={song} />}

      {showFeatures.key && <KeyBadge song={song} />}

      {showFeatures.info && currentItemIndex === index && <AudioInfoBadge song={song} />}

      {showFeatures.spotifyTrackLink && <SpotifyTrackLink trackId={trackId} />}
    </div>
  )
})

interface SpotifyTrackLinkProps {
  trackId: string
}

export const SpotifyTrackLink = observer(function SpotifyTrackLink({
  trackId
}: SpotifyTrackLinkProps): React.ReactElement | null {
  if (!trackId) return null

  return (
    <a
      className='flex'
      href={`https://open.spotify.com/track/${trackId}`}
      rel='noreferrer'
      target='_blank'
    >
      <Badge className='h-6 w-8 border-spotify-green bg-spotify-green p-0 text-white'>
        <SpotifyIcon className='size-4' fill='#fff' />
      </Badge>
    </a>
  )
})

export const AudioInfoBadge = observer(function AudioInfoBadge({
  className,
  song
}: AudioBadgeProps): React.ReactElement {
  const badgeRef = useRef<HTMLElement>(null)
  const hasKey = song?.userMeta?.key === 0 || song?.userMeta?.key !== undefined
  if (!song.spotifyMeta?.features || !hasKey || !song.userMeta) return <></>

  return (
    <>
      <Badge className={merge('h-6 w-8 p-0', className)} colour='blue' ref={badgeRef}>
        <InfoCircleIcon className='size-4' />
      </Badge>

      <AudioFeaturesTooltip
        features={song.spotifyMeta.features}
        refObject={badgeRef}
        songKey={song.spotifyMeta.features.key}
        tempo={song.spotifyMeta.features.tempo}
      />
    </>
  )
})

interface AudioFeaturesTooltipProps {
  features: ISpotifyFeatures
  refObject: React.MutableRefObject<HTMLElement | null>
  songKey: SongKeys
  tempo: number
}

const AudioFeaturesTooltip = observer(function AudioFeaturesTooltip({
  features,
  refObject,
  songKey,
  tempo
}: AudioFeaturesTooltipProps): React.ReactElement {
  const formatPercentage = (val: number): string => `${(val * 100).toFixed(1)}%`

  return (
    <Tooltip placement='auto' refObject={refObject}>
      <AudioFeaturesListStyled className='text-lg'>
        {(songKey !== undefined || songKey === 0) && (
          <li>
            <span className='text-muted'>Key:</span> {SongKeys[songKey]}{' '}
            {SongModeFull[features.mode]}
          </li>
        )}
        {tempo && (
          <li>
            <span className='text-muted'>Tempo:</span> {Math.round(tempo)} bpm
          </li>
        )}
        <li>
          <span className='text-muted'>Acousticness:</span>{' '}
          {formatPercentage(features.acousticness)}
        </li>
        <li>
          <span className='text-muted'>Danceability:</span>{' '}
          {formatPercentage(features.danceability)}
        </li>
        <li>
          <span className='text-muted'>Energy:</span> {formatPercentage(features.energy)}
        </li>
        <li>
          <span className='text-muted'>Cheerfulness:</span> {formatPercentage(features.valence)}
        </li>
      </AudioFeaturesListStyled>
    </Tooltip>
  )
})

const AudioFeaturesListStyled = styled.ul`
  line-height: 1.4;
`

export const KeyBadge = observer(function KeyBadge({ song }: AudioBadgeProps): React.ReactElement {
  const { spotifyMeta, userMeta } = song
  const hasUserKey = userMeta?.key !== undefined
  const hasSpotifyKey = spotifyMeta?.features?.key !== undefined
  const hasKey = hasUserKey || hasSpotifyKey

  if (!hasKey || !spotifyMeta?.features) return <></>

  const keyToDisplay = hasUserKey ? userMeta.key : spotifyMeta.features.key

  if (keyToDisplay === undefined) return <></>

  return (
    <Badge className='normal-case tracking-tighter' colour='pink' size='small'>
      {SongKeys[keyToDisplay]}
      {spotifyMeta.features.mode === 0 ? 'm' : ''}
    </Badge>
  )
})

export const TempoBadge = observer(function TempoBadge({ song }: AudioBadgeProps) {
  if (!song.spotifyMeta) return <></>

  const getSpotifyTempo = () => {
    const tempo = song?.spotifyMeta?.features?.tempo
    if (!tempo) return

    return Math.round(tempo)
  }

  return (
    <Badge className='pb-1' colour='purple' size='small'>
      <span>{song?.userMeta?.tempo ?? getSpotifyTempo()}</span>
    </Badge>
  )
})
