import { BingoResult, Message, MessageType } from '@repo/types'
import { observer } from 'mobx-react-lite'
import React from 'react'

import { BingoCardDialog } from './BingoCardDialog'
import { BingoTargetDialog } from './BingoTargetDialog'
import { ScoreboardDialog } from './ScoreboardDialog'
import { getMessageType } from './utils'
import { useGameContext } from '../context/game-context'

export const MessageQueue = observer(function MessageQueue(): React.ReactElement | null {
  const { activePlayers, currentMessage, gameData } = useGameContext()
  if (!gameData || !currentMessage) return null

  const { isBingoResult, isContactRequest } = getMessageType(currentMessage)

  if (isContactRequest || isBingoResult) {
    return null
  }

  const getModal = (message: Message) => {
    switch (message.type) {
      case MessageType.DENIED_BINGO: {
        return (
          <BingoCardDialog
            activePlayers={activePlayers}
            gameData={gameData}
            messageData={message.data}
            result={BingoResult.DENIED}
          />
        )
      }

      case MessageType.CONFIRMED_BINGO: {
        return (
          <BingoCardDialog
            activePlayers={activePlayers}
            gameData={gameData}
            messageData={message.data}
            result={BingoResult.CONFIRMED}
          />
        )
      }

      case MessageType.SCOREBOARD: {
        return <ScoreboardDialog scores={message.data} />
      }

      case MessageType.BINGO_TARGET: {
        return <BingoTargetDialog currentTarget={gameData.currentTarget} />
      }

      default: {
        return null
      }
    }
  }

  return getModal(currentMessage.message)
})
