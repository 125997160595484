"use client";
import { jsxs as ve, jsx as k, Fragment as Fn } from "react/jsx-runtime";
import { B as Nn, b as ot, c as Wt, m as oe, H as Bn, d as $n, a as Wn, A as Vn, I as _n, s as Hn } from "./globals-RGQ6n7_z.js";
import jn, { Balancer as zn } from "react-wrap-balancer";
import { AnimatePresence as Kn, motion as Vt } from "framer-motion";
import * as d from "react";
import it, { useState as te, useEffect as he, useLayoutEffect as ze, useRef as _t, createContext as qn, useContext as Un, useMemo as Ht, forwardRef as jt, useCallback as zt, useImperativeHandle as Xn } from "react";
import * as Yn from "react-dom";
import { createPortal as Kt } from "react-dom";
function mi({
  children: e,
  isVisible: t = !1,
  onCancel: n,
  onClose: r,
  onConfirm: o
}) {
  const i = () => {
    o(), r();
  }, s = () => {
    n == null || n(), r();
  };
  return /* @__PURE__ */ ve(K, { isVisible: t, size: "small", children: [
    /* @__PURE__ */ k(K.Body, { className: "items-center text-center", children: /* @__PURE__ */ k(zn, { className: "mx-auto", children: e }) }),
    /* @__PURE__ */ k(K.Footer, { className: "pt-6", children: /* @__PURE__ */ ve(Nn, { direction: "column", children: [
      /* @__PURE__ */ k(ot, { text: "Yes", onClick: i }),
      /* @__PURE__ */ k(ot, { text: "No", variant: "outlined", onClick: s })
    ] }) })
  ] });
}
function K({ children: e, className: t, isVisible: n = !1, size: r = "medium" }) {
  const [o, i] = te(!1);
  return he(() => {
    i(!0);
  }, []), he(() => {
    const { documentElement: s } = document;
    return n ? s.classList.add("overflow-hidden") : s.classList.remove("overflow-hidden"), () => {
      s.classList.remove("overflow-hidden");
    };
  }, [n]), o ? Kt(
    /* @__PURE__ */ k(Kn, { children: n && /* @__PURE__ */ k(K.Backdrop, { children: /* @__PURE__ */ k(Wt, { className: "max-sm:mt-auto max-sm:px-0", size: r, children: /* @__PURE__ */ k(K.Content, { className: t, children: e }) }) }) }),
    document.body
  ) : null;
}
K.Backdrop = function({ children: t }) {
  return /* @__PURE__ */ k(
    Vt.div,
    {
      animate: { opacity: 1 },
      className: oe(
        "fixed inset-0 z-50 h-[100dvh] w-full",
        "bg-modal-backdrop",
        "overflow-auto",
        "backdrop-blur",
        "flex flex-col",
        "max-sm:pb-[env(safe-area-inset-bottom)]",
        "max-sm:justify-end"
      ),
      exit: { opacity: 0 },
      initial: { opacity: 0 },
      children: t
    }
  );
};
K.Content = function({ children: t, className: n }) {
  return /* @__PURE__ */ k(
    Vt.div,
    {
      animate: { opacity: 1, y: 0 },
      className: oe(
        "flex flex-col",
        "border-2 border-shade-2 bg-shade-0 dark:border-shade-1",
        "rounded-xl shadow-xl",
        "p-4 md:p-6",
        "mt-8",
        "lg:mt-24",
        "text-default",
        "max-sm:rounded-b-none",
        "max-h-[100dvh]",
        n
      ),
      exit: { opacity: 0, y: "-48px" },
      initial: { opacity: 0, y: "48px" },
      transition: { bounce: 0, duration: 0.5, type: "spring" },
      children: t
    }
  );
};
K.Header = function({ children: t }) {
  return /* @__PURE__ */ k("header", { className: "sticky top-0 z-50 flex items-center justify-between gap-4 bg-shade-0", children: t });
};
K.Title = function({ children: t }) {
  return /* @__PURE__ */ k("div", { className: "w-full overflow-hidden", children: /* @__PURE__ */ k(Bn, { size: "small", children: t }) });
};
K.Body = function({ children: t, className: n }) {
  return /* @__PURE__ */ k("div", { className: oe("flex flex-1 flex-col overflow-hidden py-6", n), children: t });
};
K.Separator = function({ className: t }) {
  return /* @__PURE__ */ k("hr", { className: oe("border-shade-3", "my-4 md:my-6", t) });
};
K.Footer = function({ children: t, className: n }) {
  return /* @__PURE__ */ k("footer", { className: oe("sticky bottom-0 bg-shade-0", n), children: t });
};
K.CloseButton = function({ onClick: t }) {
  return /* @__PURE__ */ k($n, { className: "ml-auto", size: "small", title: "Close", onClick: t, children: /* @__PURE__ */ k(Wn, {}) });
};
function pi({
  children: e,
  isVisible: t = !1,
  onClose: n,
  onContinue: r
}) {
  const o = () => {
    r == null || r(), n();
  };
  return /* @__PURE__ */ ve(K, { isVisible: t, size: "small", children: [
    /* @__PURE__ */ k(K.Body, { className: "items-center text-center", children: /* @__PURE__ */ k(jn, { className: "mx-auto", children: e }) }),
    /* @__PURE__ */ k(K.Footer, { className: "pt-6", children: /* @__PURE__ */ k(ot, { text: "Continue", onClick: o }) })
  ] });
}
function qt(e) {
  return Ut(e) ? (e.nodeName || "").toLowerCase() : "#document";
}
function Se(e) {
  var t;
  return (e == null || (t = e.ownerDocument) == null ? void 0 : t.defaultView) || window;
}
function Gn(e) {
  var t;
  return (t = (Ut(e) ? e.ownerDocument : e.document) || window.document) == null ? void 0 : t.documentElement;
}
function Ut(e) {
  return e instanceof Node || e instanceof Se(e).Node;
}
function q(e) {
  return e instanceof Element || e instanceof Se(e).Element;
}
function be(e) {
  return e instanceof HTMLElement || e instanceof Se(e).HTMLElement;
}
function st(e) {
  return typeof ShadowRoot > "u" ? !1 : e instanceof ShadowRoot || e instanceof Se(e).ShadowRoot;
}
function Qn(e) {
  return ["html", "body", "#document"].includes(qt(e));
}
function Zn(e) {
  return Se(e).getComputedStyle(e);
}
function Jn(e) {
  if (qt(e) === "html")
    return e;
  const t = (
    // Step into the shadow DOM of the parent of a slotted node.
    e.assignedSlot || // DOM Element detected.
    e.parentNode || // ShadowRoot detected.
    st(e) && e.host || // Fallback.
    Gn(e)
  );
  return st(t) ? t.host : t;
}
function ue(e) {
  let t = e.activeElement;
  for (; ((n = t) == null || (r = n.shadowRoot) == null ? void 0 : r.activeElement) != null; ) {
    var n, r;
    t = t.shadowRoot.activeElement;
  }
  return t;
}
function j(e, t) {
  if (!e || !t)
    return !1;
  const n = t.getRootNode && t.getRootNode();
  if (e.contains(t))
    return !0;
  if (n && st(n)) {
    let r = t;
    for (; r; ) {
      if (e === r)
        return !0;
      r = r.parentNode || r.host;
    }
  }
  return !1;
}
function er() {
  const e = navigator.userAgentData;
  return e != null && e.platform ? e.platform : navigator.platform;
}
function tr() {
  const e = navigator.userAgentData;
  return e && Array.isArray(e.brands) ? e.brands.map((t) => {
    let {
      brand: n,
      version: r
    } = t;
    return n + "/" + r;
  }).join(" ") : navigator.userAgent;
}
function nr(e) {
  if (e.mozInputSource === 0 && e.isTrusted)
    return !0;
  const t = /Android/i;
  return (t.test(er()) || t.test(tr())) && e.pointerType ? e.type === "click" && e.buttons === 1 : e.detail === 0 && !e.pointerType;
}
function rr(e) {
  return e.width === 0 && e.height === 0 || e.width === 1 && e.height === 1 && e.pressure === 0 && e.detail === 0 && e.pointerType !== "mouse" || // iOS VoiceOver returns 0.333• for width/height.
  e.width < 1 && e.height < 1 && e.pressure === 0 && e.detail === 0;
}
function or() {
  return /apple/i.test(navigator.vendor);
}
function Fe(e, t) {
  const n = ["mouse", "pen"];
  return t || n.push("", void 0), n.includes(e);
}
function ir(e) {
  return "nativeEvent" in e;
}
function sr(e) {
  return e.matches("html,body");
}
function z(e) {
  return (e == null ? void 0 : e.ownerDocument) || document;
}
function De(e, t) {
  if (t == null)
    return !1;
  if ("composedPath" in e)
    return e.composedPath().includes(t);
  const n = e;
  return n.target != null && t.contains(n.target);
}
function Xt(e) {
  return "composedPath" in e ? e.composedPath()[0] : e.target;
}
const cr = "input:not([type='hidden']):not([disabled]),[contenteditable]:not([contenteditable='false']),textarea:not([disabled])";
function Yt(e) {
  return be(e) && e.matches(cr);
}
function Qe(e) {
  e.preventDefault(), e.stopPropagation();
}
const lr = Math.min, ur = Math.max, ar = {
  left: "right",
  right: "left",
  bottom: "top",
  top: "bottom"
}, fr = {
  start: "end",
  end: "start"
};
function xt(e, t, n) {
  return ur(e, lr(t, n));
}
function Ke(e, t) {
  return typeof e == "function" ? e(t) : e;
}
function ae(e) {
  return e.split("-")[0];
}
function qe(e) {
  return e.split("-")[1];
}
function Gt(e) {
  return e === "x" ? "y" : "x";
}
function Qt(e) {
  return e === "y" ? "height" : "width";
}
function ye(e) {
  return ["top", "bottom"].includes(ae(e)) ? "y" : "x";
}
function Zt(e) {
  return Gt(ye(e));
}
function dr(e, t, n) {
  n === void 0 && (n = !1);
  const r = qe(e), o = Zt(e), i = Qt(o);
  let s = o === "x" ? r === (n ? "end" : "start") ? "right" : "left" : r === "start" ? "bottom" : "top";
  return t.reference[i] > t.floating[i] && (s = Ne(s)), [s, Ne(s)];
}
function mr(e) {
  const t = Ne(e);
  return [ct(e), t, ct(t)];
}
function ct(e) {
  return e.replace(/start|end/g, (t) => fr[t]);
}
function pr(e, t, n) {
  const r = ["left", "right"], o = ["right", "left"], i = ["top", "bottom"], s = ["bottom", "top"];
  switch (e) {
    case "top":
    case "bottom":
      return n ? t ? o : r : t ? r : o;
    case "left":
    case "right":
      return t ? i : s;
    default:
      return [];
  }
}
function gr(e, t, n, r) {
  const o = qe(e);
  let i = pr(ae(e), n === "start", r);
  return o && (i = i.map((s) => s + "-" + o), t && (i = i.concat(i.map(ct)))), i;
}
function Ne(e) {
  return e.replace(/left|right|bottom|top/g, (t) => ar[t]);
}
function vr(e) {
  return {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    ...e
  };
}
function hr(e) {
  return typeof e != "number" ? vr(e) : {
    top: e,
    right: e,
    bottom: e,
    left: e
  };
}
function Be(e) {
  const {
    x: t,
    y: n,
    width: r,
    height: o
  } = e;
  return {
    width: r,
    height: o,
    top: n,
    left: t,
    right: t + r,
    bottom: n + o,
    x: t,
    y: n
  };
}
function Et(e, t, n) {
  let {
    reference: r,
    floating: o
  } = e;
  const i = ye(t), s = Zt(t), c = Qt(s), l = ae(t), a = i === "y", m = r.x + r.width / 2 - o.width / 2, f = r.y + r.height / 2 - o.height / 2, g = r[c] / 2 - o[c] / 2;
  let u;
  switch (l) {
    case "top":
      u = {
        x: m,
        y: r.y - o.height
      };
      break;
    case "bottom":
      u = {
        x: m,
        y: r.y + r.height
      };
      break;
    case "right":
      u = {
        x: r.x + r.width,
        y: f
      };
      break;
    case "left":
      u = {
        x: r.x - o.width,
        y: f
      };
      break;
    default:
      u = {
        x: r.x,
        y: r.y
      };
  }
  switch (qe(t)) {
    case "start":
      u[s] -= g * (n && a ? -1 : 1);
      break;
    case "end":
      u[s] += g * (n && a ? -1 : 1);
      break;
  }
  return u;
}
const br = async (e, t, n) => {
  const {
    placement: r = "bottom",
    strategy: o = "absolute",
    middleware: i = [],
    platform: s
  } = n, c = i.filter(Boolean), l = await (s.isRTL == null ? void 0 : s.isRTL(t));
  let a = await s.getElementRects({
    reference: e,
    floating: t,
    strategy: o
  }), {
    x: m,
    y: f
  } = Et(a, r, l), g = r, u = {}, p = 0;
  for (let v = 0; v < c.length; v++) {
    const {
      name: y,
      fn: b
    } = c[v], {
      x: h,
      y: P,
      data: R,
      reset: T
    } = await b({
      x: m,
      y: f,
      initialPlacement: r,
      placement: g,
      strategy: o,
      middlewareData: u,
      rects: a,
      platform: s,
      elements: {
        reference: e,
        floating: t
      }
    });
    m = h ?? m, f = P ?? f, u = {
      ...u,
      [y]: {
        ...u[y],
        ...R
      }
    }, T && p <= 50 && (p++, typeof T == "object" && (T.placement && (g = T.placement), T.rects && (a = T.rects === !0 ? await s.getElementRects({
      reference: e,
      floating: t,
      strategy: o
    }) : T.rects), {
      x: m,
      y: f
    } = Et(a, g, l)), v = -1);
  }
  return {
    x: m,
    y: f,
    placement: g,
    strategy: o,
    middlewareData: u
  };
};
async function Jt(e, t) {
  var n;
  t === void 0 && (t = {});
  const {
    x: r,
    y: o,
    platform: i,
    rects: s,
    elements: c,
    strategy: l
  } = e, {
    boundary: a = "clippingAncestors",
    rootBoundary: m = "viewport",
    elementContext: f = "floating",
    altBoundary: g = !1,
    padding: u = 0
  } = Ke(t, e), p = hr(u), y = c[g ? f === "floating" ? "reference" : "floating" : f], b = Be(await i.getClippingRect({
    element: (n = await (i.isElement == null ? void 0 : i.isElement(y))) == null || n ? y : y.contextElement || await (i.getDocumentElement == null ? void 0 : i.getDocumentElement(c.floating)),
    boundary: a,
    rootBoundary: m,
    strategy: l
  })), h = f === "floating" ? {
    x: r,
    y: o,
    width: s.floating.width,
    height: s.floating.height
  } : s.reference, P = await (i.getOffsetParent == null ? void 0 : i.getOffsetParent(c.floating)), R = await (i.isElement == null ? void 0 : i.isElement(P)) ? await (i.getScale == null ? void 0 : i.getScale(P)) || {
    x: 1,
    y: 1
  } : {
    x: 1,
    y: 1
  }, T = Be(i.convertOffsetParentRelativeRectToViewportRelativeRect ? await i.convertOffsetParentRelativeRectToViewportRelativeRect({
    elements: c,
    rect: h,
    offsetParent: P,
    strategy: l
  }) : h);
  return {
    top: (b.top - T.top + p.top) / R.y,
    bottom: (T.bottom - b.bottom + p.bottom) / R.y,
    left: (b.left - T.left + p.left) / R.x,
    right: (T.right - b.right + p.right) / R.x
  };
}
const yr = function(e) {
  return e === void 0 && (e = {}), {
    name: "flip",
    options: e,
    async fn(t) {
      var n, r;
      const {
        placement: o,
        middlewareData: i,
        rects: s,
        initialPlacement: c,
        platform: l,
        elements: a
      } = t, {
        mainAxis: m = !0,
        crossAxis: f = !0,
        fallbackPlacements: g,
        fallbackStrategy: u = "bestFit",
        fallbackAxisSideDirection: p = "none",
        flipAlignment: v = !0,
        ...y
      } = Ke(e, t);
      if ((n = i.arrow) != null && n.alignmentOffset)
        return {};
      const b = ae(o), h = ye(c), P = ae(c) === c, R = await (l.isRTL == null ? void 0 : l.isRTL(a.floating)), T = g || (P || !v ? [Ne(c)] : mr(c)), E = p !== "none";
      !g && E && T.push(...gr(c, v, p, R));
      const w = [c, ...T], x = await Jt(t, y), $ = [];
      let _ = ((r = i.flip) == null ? void 0 : r.overflows) || [];
      if (m && $.push(x[b]), f) {
        const S = dr(o, s, R);
        $.push(x[S[0]], x[S[1]]);
      }
      if (_ = [..._, {
        placement: o,
        overflows: $
      }], !$.every((S) => S <= 0)) {
        var D, F;
        const S = (((D = i.flip) == null ? void 0 : D.index) || 0) + 1, A = w[S];
        if (A)
          return {
            data: {
              index: S,
              overflows: _
            },
            reset: {
              placement: A
            }
          };
        let B = (F = _.filter((M) => M.overflows[0] <= 0).sort((M, O) => M.overflows[1] - O.overflows[1])[0]) == null ? void 0 : F.placement;
        if (!B)
          switch (u) {
            case "bestFit": {
              var C;
              const M = (C = _.filter((O) => {
                if (E) {
                  const I = ye(O.placement);
                  return I === h || // Create a bias to the `y` side axis due to horizontal
                  // reading directions favoring greater width.
                  I === "y";
                }
                return !0;
              }).map((O) => [O.placement, O.overflows.filter((I) => I > 0).reduce((I, H) => I + H, 0)]).sort((O, I) => O[1] - I[1])[0]) == null ? void 0 : C[0];
              M && (B = M);
              break;
            }
            case "initialPlacement":
              B = c;
              break;
          }
        if (o !== B)
          return {
            reset: {
              placement: B
            }
          };
      }
      return {};
    }
  };
};
async function wr(e, t) {
  const {
    placement: n,
    platform: r,
    elements: o
  } = e, i = await (r.isRTL == null ? void 0 : r.isRTL(o.floating)), s = ae(n), c = qe(n), l = ye(n) === "y", a = ["left", "top"].includes(s) ? -1 : 1, m = i && l ? -1 : 1, f = Ke(t, e);
  let {
    mainAxis: g,
    crossAxis: u,
    alignmentAxis: p
  } = typeof f == "number" ? {
    mainAxis: f,
    crossAxis: 0,
    alignmentAxis: null
  } : {
    mainAxis: 0,
    crossAxis: 0,
    alignmentAxis: null,
    ...f
  };
  return c && typeof p == "number" && (u = c === "end" ? p * -1 : p), l ? {
    x: u * m,
    y: g * a
  } : {
    x: g * a,
    y: u * m
  };
}
const xr = function(e) {
  return e === void 0 && (e = 0), {
    name: "offset",
    options: e,
    async fn(t) {
      var n, r;
      const {
        x: o,
        y: i,
        placement: s,
        middlewareData: c
      } = t, l = await wr(t, e);
      return s === ((n = c.offset) == null ? void 0 : n.placement) && (r = c.arrow) != null && r.alignmentOffset ? {} : {
        x: o + l.x,
        y: i + l.y,
        data: {
          ...l,
          placement: s
        }
      };
    }
  };
}, Er = function(e) {
  return e === void 0 && (e = {}), {
    name: "shift",
    options: e,
    async fn(t) {
      const {
        x: n,
        y: r,
        placement: o
      } = t, {
        mainAxis: i = !0,
        crossAxis: s = !1,
        limiter: c = {
          fn: (y) => {
            let {
              x: b,
              y: h
            } = y;
            return {
              x: b,
              y: h
            };
          }
        },
        ...l
      } = Ke(e, t), a = {
        x: n,
        y: r
      }, m = await Jt(t, l), f = ye(ae(o)), g = Gt(f);
      let u = a[g], p = a[f];
      if (i) {
        const y = g === "y" ? "top" : "left", b = g === "y" ? "bottom" : "right", h = u + m[y], P = u - m[b];
        u = xt(h, u, P);
      }
      if (s) {
        const y = f === "y" ? "top" : "left", b = f === "y" ? "bottom" : "right", h = p + m[y], P = p - m[b];
        p = xt(h, p, P);
      }
      const v = c.fn({
        ...t,
        [g]: u,
        [f]: p
      });
      return {
        ...v,
        data: {
          x: v.x - n,
          y: v.y - r
        }
      };
    }
  };
}, lt = Math.min, pe = Math.max, $e = Math.round, Ae = Math.floor, ie = (e) => ({
  x: e,
  y: e
});
function xe(e) {
  return en(e) ? (e.nodeName || "").toLowerCase() : "#document";
}
function X(e) {
  var t;
  return (e == null || (t = e.ownerDocument) == null ? void 0 : t.defaultView) || window;
}
function ne(e) {
  var t;
  return (t = (en(e) ? e.ownerDocument : e.document) || window.document) == null ? void 0 : t.documentElement;
}
function en(e) {
  return e instanceof Node || e instanceof X(e).Node;
}
function Q(e) {
  return e instanceof Element || e instanceof X(e).Element;
}
function ee(e) {
  return e instanceof HTMLElement || e instanceof X(e).HTMLElement;
}
function Rt(e) {
  return typeof ShadowRoot > "u" ? !1 : e instanceof ShadowRoot || e instanceof X(e).ShadowRoot;
}
function Oe(e) {
  const {
    overflow: t,
    overflowX: n,
    overflowY: r,
    display: o
  } = Z(e);
  return /auto|scroll|overlay|hidden|clip/.test(t + r + n) && !["inline", "contents"].includes(o);
}
function Rr(e) {
  return ["table", "td", "th"].includes(xe(e));
}
function Ue(e) {
  return [":popover-open", ":modal"].some((t) => {
    try {
      return e.matches(t);
    } catch {
      return !1;
    }
  });
}
function mt(e) {
  const t = pt(), n = Q(e) ? Z(e) : e;
  return n.transform !== "none" || n.perspective !== "none" || (n.containerType ? n.containerType !== "normal" : !1) || !t && (n.backdropFilter ? n.backdropFilter !== "none" : !1) || !t && (n.filter ? n.filter !== "none" : !1) || ["transform", "perspective", "filter"].some((r) => (n.willChange || "").includes(r)) || ["paint", "layout", "strict", "content"].some((r) => (n.contain || "").includes(r));
}
function Tr(e) {
  let t = se(e);
  for (; ee(t) && !we(t); ) {
    if (mt(t))
      return t;
    if (Ue(t))
      return null;
    t = se(t);
  }
  return null;
}
function pt() {
  return typeof CSS > "u" || !CSS.supports ? !1 : CSS.supports("-webkit-backdrop-filter", "none");
}
function we(e) {
  return ["html", "body", "#document"].includes(xe(e));
}
function Z(e) {
  return X(e).getComputedStyle(e);
}
function Xe(e) {
  return Q(e) ? {
    scrollLeft: e.scrollLeft,
    scrollTop: e.scrollTop
  } : {
    scrollLeft: e.scrollX,
    scrollTop: e.scrollY
  };
}
function se(e) {
  if (xe(e) === "html")
    return e;
  const t = (
    // Step into the shadow DOM of the parent of a slotted node.
    e.assignedSlot || // DOM Element detected.
    e.parentNode || // ShadowRoot detected.
    Rt(e) && e.host || // Fallback.
    ne(e)
  );
  return Rt(t) ? t.host : t;
}
function tn(e) {
  const t = se(e);
  return we(t) ? e.ownerDocument ? e.ownerDocument.body : e.body : ee(t) && Oe(t) ? t : tn(t);
}
function re(e, t, n) {
  var r;
  t === void 0 && (t = []), n === void 0 && (n = !0);
  const o = tn(e), i = o === ((r = e.ownerDocument) == null ? void 0 : r.body), s = X(o);
  if (i) {
    const c = ut(s);
    return t.concat(s, s.visualViewport || [], Oe(o) ? o : [], c && n ? re(c) : []);
  }
  return t.concat(o, re(o, [], n));
}
function ut(e) {
  return e.parent && Object.getPrototypeOf(e.parent) ? e.frameElement : null;
}
function nn(e) {
  const t = Z(e);
  let n = parseFloat(t.width) || 0, r = parseFloat(t.height) || 0;
  const o = ee(e), i = o ? e.offsetWidth : n, s = o ? e.offsetHeight : r, c = $e(n) !== i || $e(r) !== s;
  return c && (n = i, r = s), {
    width: n,
    height: r,
    $: c
  };
}
function gt(e) {
  return Q(e) ? e : e.contextElement;
}
function ge(e) {
  const t = gt(e);
  if (!ee(t))
    return ie(1);
  const n = t.getBoundingClientRect(), {
    width: r,
    height: o,
    $: i
  } = nn(t);
  let s = (i ? $e(n.width) : n.width) / r, c = (i ? $e(n.height) : n.height) / o;
  return (!s || !Number.isFinite(s)) && (s = 1), (!c || !Number.isFinite(c)) && (c = 1), {
    x: s,
    y: c
  };
}
const Cr = /* @__PURE__ */ ie(0);
function rn(e) {
  const t = X(e);
  return !pt() || !t.visualViewport ? Cr : {
    x: t.visualViewport.offsetLeft,
    y: t.visualViewport.offsetTop
  };
}
function Sr(e, t, n) {
  return t === void 0 && (t = !1), !n || t && n !== X(e) ? !1 : t;
}
function fe(e, t, n, r) {
  t === void 0 && (t = !1), n === void 0 && (n = !1);
  const o = e.getBoundingClientRect(), i = gt(e);
  let s = ie(1);
  t && (r ? Q(r) && (s = ge(r)) : s = ge(e));
  const c = Sr(i, n, r) ? rn(i) : ie(0);
  let l = (o.left + c.x) / s.x, a = (o.top + c.y) / s.y, m = o.width / s.x, f = o.height / s.y;
  if (i) {
    const g = X(i), u = r && Q(r) ? X(r) : r;
    let p = g, v = ut(p);
    for (; v && r && u !== p; ) {
      const y = ge(v), b = v.getBoundingClientRect(), h = Z(v), P = b.left + (v.clientLeft + parseFloat(h.paddingLeft)) * y.x, R = b.top + (v.clientTop + parseFloat(h.paddingTop)) * y.y;
      l *= y.x, a *= y.y, m *= y.x, f *= y.y, l += P, a += R, p = X(v), v = ut(p);
    }
  }
  return Be({
    width: m,
    height: f,
    x: l,
    y: a
  });
}
function Or(e) {
  let {
    elements: t,
    rect: n,
    offsetParent: r,
    strategy: o
  } = e;
  const i = o === "fixed", s = ne(r), c = t ? Ue(t.floating) : !1;
  if (r === s || c && i)
    return n;
  let l = {
    scrollLeft: 0,
    scrollTop: 0
  }, a = ie(1);
  const m = ie(0), f = ee(r);
  if ((f || !f && !i) && ((xe(r) !== "body" || Oe(s)) && (l = Xe(r)), ee(r))) {
    const g = fe(r);
    a = ge(r), m.x = g.x + r.clientLeft, m.y = g.y + r.clientTop;
  }
  return {
    width: n.width * a.x,
    height: n.height * a.y,
    x: n.x * a.x - l.scrollLeft * a.x + m.x,
    y: n.y * a.y - l.scrollTop * a.y + m.y
  };
}
function Ar(e) {
  return Array.from(e.getClientRects());
}
function on(e) {
  return fe(ne(e)).left + Xe(e).scrollLeft;
}
function Pr(e) {
  const t = ne(e), n = Xe(e), r = e.ownerDocument.body, o = pe(t.scrollWidth, t.clientWidth, r.scrollWidth, r.clientWidth), i = pe(t.scrollHeight, t.clientHeight, r.scrollHeight, r.clientHeight);
  let s = -n.scrollLeft + on(e);
  const c = -n.scrollTop;
  return Z(r).direction === "rtl" && (s += pe(t.clientWidth, r.clientWidth) - o), {
    width: o,
    height: i,
    x: s,
    y: c
  };
}
function kr(e, t) {
  const n = X(e), r = ne(e), o = n.visualViewport;
  let i = r.clientWidth, s = r.clientHeight, c = 0, l = 0;
  if (o) {
    i = o.width, s = o.height;
    const a = pt();
    (!a || a && t === "fixed") && (c = o.offsetLeft, l = o.offsetTop);
  }
  return {
    width: i,
    height: s,
    x: c,
    y: l
  };
}
function Ir(e, t) {
  const n = fe(e, !0, t === "fixed"), r = n.top + e.clientTop, o = n.left + e.clientLeft, i = ee(e) ? ge(e) : ie(1), s = e.clientWidth * i.x, c = e.clientHeight * i.y, l = o * i.x, a = r * i.y;
  return {
    width: s,
    height: c,
    x: l,
    y: a
  };
}
function Tt(e, t, n) {
  let r;
  if (t === "viewport")
    r = kr(e, n);
  else if (t === "document")
    r = Pr(ne(e));
  else if (Q(t))
    r = Ir(t, n);
  else {
    const o = rn(e);
    r = {
      ...t,
      x: t.x - o.x,
      y: t.y - o.y
    };
  }
  return Be(r);
}
function sn(e, t) {
  const n = se(e);
  return n === t || !Q(n) || we(n) ? !1 : Z(n).position === "fixed" || sn(n, t);
}
function Dr(e, t) {
  const n = t.get(e);
  if (n)
    return n;
  let r = re(e, [], !1).filter((c) => Q(c) && xe(c) !== "body"), o = null;
  const i = Z(e).position === "fixed";
  let s = i ? se(e) : e;
  for (; Q(s) && !we(s); ) {
    const c = Z(s), l = mt(s);
    !l && c.position === "fixed" && (o = null), (i ? !l && !o : !l && c.position === "static" && !!o && ["absolute", "fixed"].includes(o.position) || Oe(s) && !l && sn(e, s)) ? r = r.filter((m) => m !== s) : o = c, s = se(s);
  }
  return t.set(e, r), r;
}
function Mr(e) {
  let {
    element: t,
    boundary: n,
    rootBoundary: r,
    strategy: o
  } = e;
  const s = [...n === "clippingAncestors" ? Ue(t) ? [] : Dr(t, this._c) : [].concat(n), r], c = s[0], l = s.reduce((a, m) => {
    const f = Tt(t, m, o);
    return a.top = pe(f.top, a.top), a.right = lt(f.right, a.right), a.bottom = lt(f.bottom, a.bottom), a.left = pe(f.left, a.left), a;
  }, Tt(t, c, o));
  return {
    width: l.right - l.left,
    height: l.bottom - l.top,
    x: l.left,
    y: l.top
  };
}
function Lr(e) {
  const {
    width: t,
    height: n
  } = nn(e);
  return {
    width: t,
    height: n
  };
}
function Fr(e, t, n) {
  const r = ee(t), o = ne(t), i = n === "fixed", s = fe(e, !0, i, t);
  let c = {
    scrollLeft: 0,
    scrollTop: 0
  };
  const l = ie(0);
  if (r || !r && !i)
    if ((xe(t) !== "body" || Oe(o)) && (c = Xe(t)), r) {
      const f = fe(t, !0, i, t);
      l.x = f.x + t.clientLeft, l.y = f.y + t.clientTop;
    } else o && (l.x = on(o));
  const a = s.left + c.scrollLeft - l.x, m = s.top + c.scrollTop - l.y;
  return {
    x: a,
    y: m,
    width: s.width,
    height: s.height
  };
}
function Ze(e) {
  return Z(e).position === "static";
}
function Ct(e, t) {
  return !ee(e) || Z(e).position === "fixed" ? null : t ? t(e) : e.offsetParent;
}
function cn(e, t) {
  const n = X(e);
  if (Ue(e))
    return n;
  if (!ee(e)) {
    let o = se(e);
    for (; o && !we(o); ) {
      if (Q(o) && !Ze(o))
        return o;
      o = se(o);
    }
    return n;
  }
  let r = Ct(e, t);
  for (; r && Rr(r) && Ze(r); )
    r = Ct(r, t);
  return r && we(r) && Ze(r) && !mt(r) ? n : r || Tr(e) || n;
}
const Nr = async function(e) {
  const t = this.getOffsetParent || cn, n = this.getDimensions, r = await n(e.floating);
  return {
    reference: Fr(e.reference, await t(e.floating), e.strategy),
    floating: {
      x: 0,
      y: 0,
      width: r.width,
      height: r.height
    }
  };
};
function Br(e) {
  return Z(e).direction === "rtl";
}
const $r = {
  convertOffsetParentRelativeRectToViewportRelativeRect: Or,
  getDocumentElement: ne,
  getClippingRect: Mr,
  getOffsetParent: cn,
  getElementRects: Nr,
  getClientRects: Ar,
  getDimensions: Lr,
  getScale: ge,
  isElement: Q,
  isRTL: Br
};
function Wr(e, t) {
  let n = null, r;
  const o = ne(e);
  function i() {
    var c;
    clearTimeout(r), (c = n) == null || c.disconnect(), n = null;
  }
  function s(c, l) {
    c === void 0 && (c = !1), l === void 0 && (l = 1), i();
    const {
      left: a,
      top: m,
      width: f,
      height: g
    } = e.getBoundingClientRect();
    if (c || t(), !f || !g)
      return;
    const u = Ae(m), p = Ae(o.clientWidth - (a + f)), v = Ae(o.clientHeight - (m + g)), y = Ae(a), h = {
      rootMargin: -u + "px " + -p + "px " + -v + "px " + -y + "px",
      threshold: pe(0, lt(1, l)) || 1
    };
    let P = !0;
    function R(T) {
      const E = T[0].intersectionRatio;
      if (E !== l) {
        if (!P)
          return s();
        E ? s(!1, E) : r = setTimeout(() => {
          s(!1, 1e-7);
        }, 1e3);
      }
      P = !1;
    }
    try {
      n = new IntersectionObserver(R, {
        ...h,
        // Handle <iframe>s
        root: o.ownerDocument
      });
    } catch {
      n = new IntersectionObserver(R, h);
    }
    n.observe(e);
  }
  return s(!0), i;
}
function ln(e, t, n, r) {
  r === void 0 && (r = {});
  const {
    ancestorScroll: o = !0,
    ancestorResize: i = !0,
    elementResize: s = typeof ResizeObserver == "function",
    layoutShift: c = typeof IntersectionObserver == "function",
    animationFrame: l = !1
  } = r, a = gt(e), m = o || i ? [...a ? re(a) : [], ...re(t)] : [];
  m.forEach((b) => {
    o && b.addEventListener("scroll", n, {
      passive: !0
    }), i && b.addEventListener("resize", n);
  });
  const f = a && c ? Wr(a, n) : null;
  let g = -1, u = null;
  s && (u = new ResizeObserver((b) => {
    let [h] = b;
    h && h.target === a && u && (u.unobserve(t), cancelAnimationFrame(g), g = requestAnimationFrame(() => {
      var P;
      (P = u) == null || P.observe(t);
    })), n();
  }), a && !l && u.observe(a), u.observe(t));
  let p, v = l ? fe(e) : null;
  l && y();
  function y() {
    const b = fe(e);
    v && (b.x !== v.x || b.y !== v.y || b.width !== v.width || b.height !== v.height) && n(), v = b, p = requestAnimationFrame(y);
  }
  return n(), () => {
    var b;
    m.forEach((h) => {
      o && h.removeEventListener("scroll", n), i && h.removeEventListener("resize", n);
    }), f == null || f(), (b = u) == null || b.disconnect(), u = null, l && cancelAnimationFrame(p);
  };
}
const Vr = xr, _r = Er, Hr = yr, jr = (e, t, n) => {
  const r = /* @__PURE__ */ new Map(), o = {
    platform: $r,
    ...n
  }, i = {
    ...o.platform,
    _c: r
  };
  return br(e, t, {
    ...o,
    platform: i
  });
};
var Me = typeof document < "u" ? ze : he;
function We(e, t) {
  if (e === t)
    return !0;
  if (typeof e != typeof t)
    return !1;
  if (typeof e == "function" && e.toString() === t.toString())
    return !0;
  let n, r, o;
  if (e && t && typeof e == "object") {
    if (Array.isArray(e)) {
      if (n = e.length, n !== t.length) return !1;
      for (r = n; r-- !== 0; )
        if (!We(e[r], t[r]))
          return !1;
      return !0;
    }
    if (o = Object.keys(e), n = o.length, n !== Object.keys(t).length)
      return !1;
    for (r = n; r-- !== 0; )
      if (!{}.hasOwnProperty.call(t, o[r]))
        return !1;
    for (r = n; r-- !== 0; ) {
      const i = o[r];
      if (!(i === "_owner" && e.$$typeof) && !We(e[i], t[i]))
        return !1;
    }
    return !0;
  }
  return e !== e && t !== t;
}
function un(e) {
  return typeof window > "u" ? 1 : (e.ownerDocument.defaultView || window).devicePixelRatio || 1;
}
function St(e, t) {
  const n = un(e);
  return Math.round(t * n) / n;
}
function Ot(e) {
  const t = d.useRef(e);
  return Me(() => {
    t.current = e;
  }), t;
}
function zr(e) {
  e === void 0 && (e = {});
  const {
    placement: t = "bottom",
    strategy: n = "absolute",
    middleware: r = [],
    platform: o,
    elements: {
      reference: i,
      floating: s
    } = {},
    transform: c = !0,
    whileElementsMounted: l,
    open: a
  } = e, [m, f] = d.useState({
    x: 0,
    y: 0,
    strategy: n,
    placement: t,
    middlewareData: {},
    isPositioned: !1
  }), [g, u] = d.useState(r);
  We(g, r) || u(r);
  const [p, v] = d.useState(null), [y, b] = d.useState(null), h = d.useCallback((M) => {
    M !== E.current && (E.current = M, v(M));
  }, []), P = d.useCallback((M) => {
    M !== w.current && (w.current = M, b(M));
  }, []), R = i || p, T = s || y, E = d.useRef(null), w = d.useRef(null), x = d.useRef(m), $ = l != null, _ = Ot(l), D = Ot(o), F = d.useCallback(() => {
    if (!E.current || !w.current)
      return;
    const M = {
      placement: t,
      strategy: n,
      middleware: g
    };
    D.current && (M.platform = D.current), jr(E.current, w.current, M).then((O) => {
      const I = {
        ...O,
        isPositioned: !0
      };
      C.current && !We(x.current, I) && (x.current = I, Yn.flushSync(() => {
        f(I);
      }));
    });
  }, [g, t, n, D]);
  Me(() => {
    a === !1 && x.current.isPositioned && (x.current.isPositioned = !1, f((M) => ({
      ...M,
      isPositioned: !1
    })));
  }, [a]);
  const C = d.useRef(!1);
  Me(() => (C.current = !0, () => {
    C.current = !1;
  }), []), Me(() => {
    if (R && (E.current = R), T && (w.current = T), R && T) {
      if (_.current)
        return _.current(R, T, F);
      F();
    }
  }, [R, T, F, _, $]);
  const S = d.useMemo(() => ({
    reference: E,
    floating: w,
    setReference: h,
    setFloating: P
  }), [h, P]), A = d.useMemo(() => ({
    reference: R,
    floating: T
  }), [R, T]), B = d.useMemo(() => {
    const M = {
      position: n,
      left: 0,
      top: 0
    };
    if (!A.floating)
      return M;
    const O = St(A.floating, m.x), I = St(A.floating, m.y);
    return c ? {
      ...M,
      transform: "translate(" + O + "px, " + I + "px)",
      ...un(A.floating) >= 1.5 && {
        willChange: "transform"
      }
    } : {
      position: n,
      left: O,
      top: I
    };
  }, [n, c, A.floating, m.x, m.y]);
  return d.useMemo(() => ({
    ...m,
    update: F,
    refs: S,
    elements: A,
    floatingStyles: B
  }), [m, F, S, A, B]);
}
const an = (e, t) => ({
  ...Vr(e),
  options: [e, t]
}), fn = (e, t) => ({
  ..._r(e),
  options: [e, t]
}), dn = (e, t) => ({
  ...Hr(e),
  options: [e, t]
});
/*!
* tabbable 6.2.0
* @license MIT, https://github.com/focus-trap/tabbable/blob/master/LICENSE
*/
var Kr = ["input:not([inert])", "select:not([inert])", "textarea:not([inert])", "a[href]:not([inert])", "button:not([inert])", "[tabindex]:not(slot):not([inert])", "audio[controls]:not([inert])", "video[controls]:not([inert])", '[contenteditable]:not([contenteditable="false"]):not([inert])', "details>summary:first-of-type:not([inert])", "details:not([inert])"], at = /* @__PURE__ */ Kr.join(","), mn = typeof Element > "u", Ce = mn ? function() {
} : Element.prototype.matches || Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector, Ve = !mn && Element.prototype.getRootNode ? function(e) {
  var t;
  return e == null || (t = e.getRootNode) === null || t === void 0 ? void 0 : t.call(e);
} : function(e) {
  return e == null ? void 0 : e.ownerDocument;
}, _e = function e(t, n) {
  var r;
  n === void 0 && (n = !0);
  var o = t == null || (r = t.getAttribute) === null || r === void 0 ? void 0 : r.call(t, "inert"), i = o === "" || o === "true", s = i || n && t && e(t.parentNode);
  return s;
}, qr = function(t) {
  var n, r = t == null || (n = t.getAttribute) === null || n === void 0 ? void 0 : n.call(t, "contenteditable");
  return r === "" || r === "true";
}, Ur = function(t, n, r) {
  if (_e(t))
    return [];
  var o = Array.prototype.slice.apply(t.querySelectorAll(at));
  return n && Ce.call(t, at) && o.unshift(t), o = o.filter(r), o;
}, Xr = function e(t, n, r) {
  for (var o = [], i = Array.from(t); i.length; ) {
    var s = i.shift();
    if (!_e(s, !1))
      if (s.tagName === "SLOT") {
        var c = s.assignedElements(), l = c.length ? c : s.children, a = e(l, !0, r);
        r.flatten ? o.push.apply(o, a) : o.push({
          scopeParent: s,
          candidates: a
        });
      } else {
        var m = Ce.call(s, at);
        m && r.filter(s) && (n || !t.includes(s)) && o.push(s);
        var f = s.shadowRoot || // check for an undisclosed shadow
        typeof r.getShadowRoot == "function" && r.getShadowRoot(s), g = !_e(f, !1) && (!r.shadowRootFilter || r.shadowRootFilter(s));
        if (f && g) {
          var u = e(f === !0 ? s.children : f.children, !0, r);
          r.flatten ? o.push.apply(o, u) : o.push({
            scopeParent: s,
            candidates: u
          });
        } else
          i.unshift.apply(i, s.children);
      }
  }
  return o;
}, pn = function(t) {
  return !isNaN(parseInt(t.getAttribute("tabindex"), 10));
}, gn = function(t) {
  if (!t)
    throw new Error("No node provided");
  return t.tabIndex < 0 && (/^(AUDIO|VIDEO|DETAILS)$/.test(t.tagName) || qr(t)) && !pn(t) ? 0 : t.tabIndex;
}, Yr = function(t, n) {
  var r = gn(t);
  return r < 0 && n && !pn(t) ? 0 : r;
}, Gr = function(t, n) {
  return t.tabIndex === n.tabIndex ? t.documentOrder - n.documentOrder : t.tabIndex - n.tabIndex;
}, vn = function(t) {
  return t.tagName === "INPUT";
}, Qr = function(t) {
  return vn(t) && t.type === "hidden";
}, Zr = function(t) {
  var n = t.tagName === "DETAILS" && Array.prototype.slice.apply(t.children).some(function(r) {
    return r.tagName === "SUMMARY";
  });
  return n;
}, Jr = function(t, n) {
  for (var r = 0; r < t.length; r++)
    if (t[r].checked && t[r].form === n)
      return t[r];
}, eo = function(t) {
  if (!t.name)
    return !0;
  var n = t.form || Ve(t), r = function(c) {
    return n.querySelectorAll('input[type="radio"][name="' + c + '"]');
  }, o;
  if (typeof window < "u" && typeof window.CSS < "u" && typeof window.CSS.escape == "function")
    o = r(window.CSS.escape(t.name));
  else
    try {
      o = r(t.name);
    } catch (s) {
      return console.error("Looks like you have a radio button with a name attribute containing invalid CSS selector characters and need the CSS.escape polyfill: %s", s.message), !1;
    }
  var i = Jr(o, t.form);
  return !i || i === t;
}, to = function(t) {
  return vn(t) && t.type === "radio";
}, no = function(t) {
  return to(t) && !eo(t);
}, ro = function(t) {
  var n, r = t && Ve(t), o = (n = r) === null || n === void 0 ? void 0 : n.host, i = !1;
  if (r && r !== t) {
    var s, c, l;
    for (i = !!((s = o) !== null && s !== void 0 && (c = s.ownerDocument) !== null && c !== void 0 && c.contains(o) || t != null && (l = t.ownerDocument) !== null && l !== void 0 && l.contains(t)); !i && o; ) {
      var a, m, f;
      r = Ve(o), o = (a = r) === null || a === void 0 ? void 0 : a.host, i = !!((m = o) !== null && m !== void 0 && (f = m.ownerDocument) !== null && f !== void 0 && f.contains(o));
    }
  }
  return i;
}, At = function(t) {
  var n = t.getBoundingClientRect(), r = n.width, o = n.height;
  return r === 0 && o === 0;
}, oo = function(t, n) {
  var r = n.displayCheck, o = n.getShadowRoot;
  if (getComputedStyle(t).visibility === "hidden")
    return !0;
  var i = Ce.call(t, "details>summary:first-of-type"), s = i ? t.parentElement : t;
  if (Ce.call(s, "details:not([open]) *"))
    return !0;
  if (!r || r === "full" || r === "legacy-full") {
    if (typeof o == "function") {
      for (var c = t; t; ) {
        var l = t.parentElement, a = Ve(t);
        if (l && !l.shadowRoot && o(l) === !0)
          return At(t);
        t.assignedSlot ? t = t.assignedSlot : !l && a !== t.ownerDocument ? t = a.host : t = l;
      }
      t = c;
    }
    if (ro(t))
      return !t.getClientRects().length;
    if (r !== "legacy-full")
      return !0;
  } else if (r === "non-zero-area")
    return At(t);
  return !1;
}, io = function(t) {
  if (/^(INPUT|BUTTON|SELECT|TEXTAREA)$/.test(t.tagName))
    for (var n = t.parentElement; n; ) {
      if (n.tagName === "FIELDSET" && n.disabled) {
        for (var r = 0; r < n.children.length; r++) {
          var o = n.children.item(r);
          if (o.tagName === "LEGEND")
            return Ce.call(n, "fieldset[disabled] *") ? !0 : !o.contains(t);
        }
        return !0;
      }
      n = n.parentElement;
    }
  return !1;
}, so = function(t, n) {
  return !(n.disabled || // we must do an inert look up to filter out any elements inside an inert ancestor
  //  because we're limited in the type of selectors we can use in JSDom (see related
  //  note related to `candidateSelectors`)
  _e(n) || Qr(n) || oo(n, t) || // For a details element with a summary, the summary element gets the focus
  Zr(n) || io(n));
}, Pt = function(t, n) {
  return !(no(n) || gn(n) < 0 || !so(t, n));
}, co = function(t) {
  var n = parseInt(t.getAttribute("tabindex"), 10);
  return !!(isNaN(n) || n >= 0);
}, lo = function e(t) {
  var n = [], r = [];
  return t.forEach(function(o, i) {
    var s = !!o.scopeParent, c = s ? o.scopeParent : o, l = Yr(c, s), a = s ? e(o.candidates) : c;
    l === 0 ? s ? n.push.apply(n, a) : n.push(c) : r.push({
      documentOrder: i,
      tabIndex: l,
      item: o,
      isScope: s,
      content: a
    });
  }), r.sort(Gr).reduce(function(o, i) {
    return i.isScope ? o.push.apply(o, i.content) : o.push(i.content), o;
  }, []).concat(n);
}, vt = function(t, n) {
  n = n || {};
  var r;
  return n.getShadowRoot ? r = Xr([t], n.includeContainer, {
    filter: Pt.bind(null, n),
    flatten: !1,
    getShadowRoot: n.getShadowRoot,
    shadowRootFilter: co
  }) : r = Ur(t, n.includeContainer, Pt.bind(null, n)), lo(r);
};
function Ye(e) {
  return d.useMemo(() => e.every((t) => t == null) ? null : (t) => {
    e.forEach((n) => {
      typeof n == "function" ? n(t) : n != null && (n.current = t);
    });
  }, e);
}
let kt = 0;
function le(e, t) {
  t === void 0 && (t = {});
  const {
    preventScroll: n = !1,
    cancelPrevious: r = !0,
    sync: o = !1
  } = t;
  r && cancelAnimationFrame(kt);
  const i = () => e == null ? void 0 : e.focus({
    preventScroll: n
  });
  o ? i() : kt = requestAnimationFrame(i);
}
var G = typeof document < "u" ? ze : he;
function He() {
  return He = Object.assign ? Object.assign.bind() : function(e) {
    for (var t = 1; t < arguments.length; t++) {
      var n = arguments[t];
      for (var r in n)
        Object.prototype.hasOwnProperty.call(n, r) && (e[r] = n[r]);
    }
    return e;
  }, He.apply(this, arguments);
}
let Je = !1, uo = 0;
const It = () => "floating-ui-" + uo++;
function ao() {
  const [e, t] = d.useState(() => Je ? It() : void 0);
  return G(() => {
    e == null && t(It());
  }, []), d.useEffect(() => {
    Je || (Je = !0);
  }, []), e;
}
const fo = d.useId, ht = fo || ao;
function mo() {
  const e = /* @__PURE__ */ new Map();
  return {
    emit(t, n) {
      var r;
      (r = e.get(t)) == null || r.forEach((o) => o(n));
    },
    on(t, n) {
      e.set(t, [...e.get(t) || [], n]);
    },
    off(t, n) {
      var r;
      e.set(t, ((r = e.get(t)) == null ? void 0 : r.filter((o) => o !== n)) || []);
    }
  };
}
const po = /* @__PURE__ */ d.createContext(null), go = /* @__PURE__ */ d.createContext(null), hn = () => {
  var e;
  return ((e = d.useContext(po)) == null ? void 0 : e.id) || null;
}, Ge = () => d.useContext(go);
function de(e) {
  return "data-floating-ui-" + e;
}
function Ee(e) {
  const t = _t(e);
  return G(() => {
    t.current = e;
  }), t;
}
const Dt = /* @__PURE__ */ de("safe-polygon");
function et(e, t, n) {
  return n && !Fe(n) ? 0 : typeof e == "number" ? e : e == null ? void 0 : e[t];
}
function vo(e, t) {
  t === void 0 && (t = {});
  const {
    open: n,
    onOpenChange: r,
    dataRef: o,
    events: i,
    elements: {
      domReference: s,
      floating: c
    },
    refs: l
  } = e, {
    enabled: a = !0,
    delay: m = 0,
    handleClose: f = null,
    mouseOnly: g = !1,
    restMs: u = 0,
    move: p = !0
  } = t, v = Ge(), y = hn(), b = Ee(f), h = Ee(m), P = d.useRef(), R = d.useRef(), T = d.useRef(), E = d.useRef(), w = d.useRef(!0), x = d.useRef(!1), $ = d.useRef(() => {
  }), _ = d.useCallback(() => {
    var S;
    const A = (S = o.current.openEvent) == null ? void 0 : S.type;
    return (A == null ? void 0 : A.includes("mouse")) && A !== "mousedown";
  }, [o]);
  d.useEffect(() => {
    if (!a)
      return;
    function S() {
      clearTimeout(R.current), clearTimeout(E.current), w.current = !0;
    }
    return i.on("dismiss", S), () => {
      i.off("dismiss", S);
    };
  }, [a, i]), d.useEffect(() => {
    if (!a || !b.current || !n)
      return;
    function S(B) {
      _() && r(!1, B);
    }
    const A = z(c).documentElement;
    return A.addEventListener("mouseleave", S), () => {
      A.removeEventListener("mouseleave", S);
    };
  }, [c, n, r, a, b, o, _]);
  const D = d.useCallback(function(S, A) {
    A === void 0 && (A = !0);
    const B = et(h.current, "close", P.current);
    B && !T.current ? (clearTimeout(R.current), R.current = setTimeout(() => r(!1, S), B)) : A && (clearTimeout(R.current), r(!1, S));
  }, [h, r]), F = d.useCallback(() => {
    $.current(), T.current = void 0;
  }, []), C = d.useCallback(() => {
    if (x.current) {
      const S = z(l.floating.current).body;
      S.style.pointerEvents = "", S.removeAttribute(Dt), x.current = !1;
    }
  }, [l]);
  return d.useEffect(() => {
    if (!a)
      return;
    function S() {
      return o.current.openEvent ? ["click", "mousedown"].includes(o.current.openEvent.type) : !1;
    }
    function A(O) {
      if (clearTimeout(R.current), w.current = !1, g && !Fe(P.current) || u > 0 && et(h.current, "open") === 0)
        return;
      const I = et(h.current, "open", P.current);
      I ? R.current = setTimeout(() => {
        r(!0, O);
      }, I) : r(!0, O);
    }
    function B(O) {
      if (S())
        return;
      $.current();
      const I = z(c);
      if (clearTimeout(E.current), b.current) {
        n || clearTimeout(R.current), T.current = b.current({
          ...e,
          tree: v,
          x: O.clientX,
          y: O.clientY,
          onClose() {
            C(), F(), D(O);
          }
        });
        const L = T.current;
        I.addEventListener("mousemove", L), $.current = () => {
          I.removeEventListener("mousemove", L);
        };
        return;
      }
      (P.current === "touch" ? !j(c, O.relatedTarget) : !0) && D(O);
    }
    function M(O) {
      S() || b.current == null || b.current({
        ...e,
        tree: v,
        x: O.clientX,
        y: O.clientY,
        onClose() {
          C(), F(), D(O);
        }
      })(O);
    }
    if (q(s)) {
      const O = s;
      return n && O.addEventListener("mouseleave", M), c == null || c.addEventListener("mouseleave", M), p && O.addEventListener("mousemove", A, {
        once: !0
      }), O.addEventListener("mouseenter", A), O.addEventListener("mouseleave", B), () => {
        n && O.removeEventListener("mouseleave", M), c == null || c.removeEventListener("mouseleave", M), p && O.removeEventListener("mousemove", A), O.removeEventListener("mouseenter", A), O.removeEventListener("mouseleave", B);
      };
    }
  }, [s, c, a, e, g, u, p, D, F, C, r, n, v, h, b, o]), G(() => {
    var S;
    if (a && n && (S = b.current) != null && S.__options.blockPointerEvents && _()) {
      const M = z(c).body;
      if (M.setAttribute(Dt, ""), M.style.pointerEvents = "none", x.current = !0, q(s) && c) {
        var A, B;
        const O = s, I = v == null || (A = v.nodesRef.current.find((H) => H.id === y)) == null || (B = A.context) == null ? void 0 : B.elements.floating;
        return I && (I.style.pointerEvents = ""), O.style.pointerEvents = "auto", c.style.pointerEvents = "auto", () => {
          O.style.pointerEvents = "", c.style.pointerEvents = "";
        };
      }
    }
  }, [a, n, y, c, s, v, b, o, _]), G(() => {
    n || (P.current = void 0, F(), C());
  }, [n, F, C]), d.useEffect(() => () => {
    F(), clearTimeout(R.current), clearTimeout(E.current), C();
  }, [a, s, F, C]), d.useMemo(() => {
    if (!a)
      return {};
    function S(A) {
      P.current = A.pointerType;
    }
    return {
      reference: {
        onPointerDown: S,
        onPointerEnter: S,
        onMouseMove(A) {
          n || u === 0 || (clearTimeout(E.current), E.current = setTimeout(() => {
            w.current || r(!0, A.nativeEvent);
          }, u));
        }
      },
      floating: {
        onMouseEnter() {
          clearTimeout(R.current);
        },
        onMouseLeave(A) {
          i.emit("dismiss", {
            type: "mouseLeave",
            data: {
              returnFocus: !1
            }
          }), D(A.nativeEvent, !1);
        }
      }
    };
  }, [i, a, u, n, r, D]);
}
function ho(e, t) {
  var n;
  let r = [], o = (n = e.find((i) => i.id === t)) == null ? void 0 : n.parentId;
  for (; o; ) {
    const i = e.find((s) => s.id === o);
    o = i == null ? void 0 : i.parentId, i && (r = r.concat(i));
  }
  return r;
}
function Re(e, t) {
  let n = e.filter((o) => {
    var i;
    return o.parentId === t && ((i = o.context) == null ? void 0 : i.open);
  }), r = n;
  for (; r.length; )
    r = e.filter((o) => {
      var i;
      return (i = r) == null ? void 0 : i.some((s) => {
        var c;
        return o.parentId === s.id && ((c = o.context) == null ? void 0 : c.open);
      });
    }), n = n.concat(r);
  return n;
}
let me = /* @__PURE__ */ new WeakMap(), Pe = /* @__PURE__ */ new WeakSet(), ke = {}, tt = 0;
const bo = () => typeof HTMLElement < "u" && "inert" in HTMLElement.prototype, bn = (e) => e && (e.host || bn(e.parentNode)), yo = (e, t) => t.map((n) => {
  if (e.contains(n))
    return n;
  const r = bn(n);
  return e.contains(r) ? r : null;
}).filter((n) => n != null);
function wo(e, t, n, r) {
  const o = "data-floating-ui-inert", i = r ? "inert" : n ? "aria-hidden" : null, s = yo(t, e), c = /* @__PURE__ */ new Set(), l = new Set(s), a = [];
  ke[o] || (ke[o] = /* @__PURE__ */ new WeakMap());
  const m = ke[o];
  s.forEach(f), g(t), c.clear();
  function f(u) {
    !u || c.has(u) || (c.add(u), u.parentNode && f(u.parentNode));
  }
  function g(u) {
    !u || l.has(u) || Array.prototype.forEach.call(u.children, (p) => {
      if (c.has(p))
        g(p);
      else {
        const v = i ? p.getAttribute(i) : null, y = v !== null && v !== "false", b = (me.get(p) || 0) + 1, h = (m.get(p) || 0) + 1;
        me.set(p, b), m.set(p, h), a.push(p), b === 1 && y && Pe.add(p), h === 1 && p.setAttribute(o, ""), !y && i && p.setAttribute(i, "true");
      }
    });
  }
  return tt++, () => {
    a.forEach((u) => {
      const p = (me.get(u) || 0) - 1, v = (m.get(u) || 0) - 1;
      me.set(u, p), m.set(u, v), p || (!Pe.has(u) && i && u.removeAttribute(i), Pe.delete(u)), v || u.removeAttribute(o);
    }), tt--, tt || (me = /* @__PURE__ */ new WeakMap(), me = /* @__PURE__ */ new WeakMap(), Pe = /* @__PURE__ */ new WeakSet(), ke = {});
  };
}
function Mt(e, t, n) {
  t === void 0 && (t = !1), n === void 0 && (n = !1);
  const r = z(e[0]).body;
  return wo(e.concat(Array.from(r.querySelectorAll("[aria-live]"))), r, t, n);
}
const bt = () => ({
  getShadowRoot: !0,
  displayCheck: (
    // JSDOM does not support the `tabbable` library. To solve this we can
    // check if `ResizeObserver` is a real function (not polyfilled), which
    // determines if the current environment is JSDOM-like.
    typeof ResizeObserver == "function" && ResizeObserver.toString().includes("[native code]") ? "full" : "none"
  )
});
function yn(e, t) {
  const n = vt(e, bt());
  t === "prev" && n.reverse();
  const r = n.indexOf(ue(z(e)));
  return n.slice(r + 1)[0];
}
function wn() {
  return yn(document.body, "next");
}
function xn() {
  return yn(document.body, "prev");
}
function Te(e, t) {
  const n = t || e.currentTarget, r = e.relatedTarget;
  return !r || !j(n, r);
}
function xo(e) {
  vt(e, bt()).forEach((n) => {
    n.dataset.tabindex = n.getAttribute("tabindex") || "", n.setAttribute("tabindex", "-1");
  });
}
function Eo(e) {
  e.querySelectorAll("[data-tabindex]").forEach((n) => {
    const r = n.dataset.tabindex;
    delete n.dataset.tabindex, r ? n.setAttribute("tabindex", r) : n.removeAttribute("tabindex");
  });
}
const yt = {
  border: 0,
  clip: "rect(0 0 0 0)",
  height: "1px",
  margin: "-1px",
  overflow: "hidden",
  padding: 0,
  position: "fixed",
  whiteSpace: "nowrap",
  width: "1px",
  top: 0,
  left: 0
};
let Ro;
function Lt(e) {
  e.key === "Tab" && (e.target, clearTimeout(Ro));
}
const je = /* @__PURE__ */ d.forwardRef(function(t, n) {
  const [r, o] = d.useState();
  G(() => (or() && o("button"), document.addEventListener("keydown", Lt), () => {
    document.removeEventListener("keydown", Lt);
  }), []);
  const i = {
    ref: n,
    tabIndex: 0,
    // Role is only for VoiceOver
    role: r,
    "aria-hidden": r ? void 0 : !0,
    [de("focus-guard")]: "",
    style: yt
  };
  return /* @__PURE__ */ d.createElement("span", He({}, t, i));
}), En = /* @__PURE__ */ d.createContext(null);
function To(e) {
  let {
    id: t,
    root: n
  } = e === void 0 ? {} : e;
  const [r, o] = d.useState(null), i = ht(), s = Tn(), c = d.useMemo(() => ({
    id: t,
    root: n,
    portalContext: s,
    uniqueId: i
  }), [t, n, s, i]), l = d.useRef();
  return G(() => () => {
    r == null || r.remove();
  }, [r, c]), G(() => {
    if (l.current === c) return;
    l.current = c;
    const {
      id: a,
      root: m,
      portalContext: f,
      uniqueId: g
    } = c, u = a ? document.getElementById(a) : null, p = de("portal");
    if (u) {
      const v = document.createElement("div");
      v.id = g, v.setAttribute(p, ""), u.appendChild(v), o(v);
    } else {
      let v = m || (f == null ? void 0 : f.portalNode);
      v && !q(v) && (v = v.current), v = v || document.body;
      let y = null;
      a && (y = document.createElement("div"), y.id = a, v.appendChild(y));
      const b = document.createElement("div");
      b.id = g, b.setAttribute(p, ""), v = y || v, v.appendChild(b), o(b);
    }
  }, [c]), r;
}
function Rn(e) {
  let {
    children: t,
    id: n,
    root: r = null,
    preserveTabOrder: o = !0
  } = e;
  const i = To({
    id: n,
    root: r
  }), [s, c] = d.useState(null), l = d.useRef(null), a = d.useRef(null), m = d.useRef(null), f = d.useRef(null), g = (
    // The FocusManager and therefore floating element are currently open/
    // rendered.
    !!s && // Guards are only for non-modal focus management.
    !s.modal && // Don't render if unmount is transitioning.
    s.open && o && !!(r || i)
  );
  return d.useEffect(() => {
    if (!i || !o || s != null && s.modal)
      return;
    function u(p) {
      i && Te(p) && (p.type === "focusin" ? Eo : xo)(i);
    }
    return i.addEventListener("focusin", u, !0), i.addEventListener("focusout", u, !0), () => {
      i.removeEventListener("focusin", u, !0), i.removeEventListener("focusout", u, !0);
    };
  }, [i, o, s == null ? void 0 : s.modal]), /* @__PURE__ */ d.createElement(En.Provider, {
    value: d.useMemo(() => ({
      preserveTabOrder: o,
      beforeOutsideRef: l,
      afterOutsideRef: a,
      beforeInsideRef: m,
      afterInsideRef: f,
      portalNode: i,
      setFocusManagerState: c
    }), [o, i])
  }, g && i && /* @__PURE__ */ d.createElement(je, {
    "data-type": "outside",
    ref: l,
    onFocus: (u) => {
      if (Te(u, i)) {
        var p;
        (p = m.current) == null || p.focus();
      } else {
        const v = xn() || (s == null ? void 0 : s.refs.domReference.current);
        v == null || v.focus();
      }
    }
  }), g && i && /* @__PURE__ */ d.createElement("span", {
    "aria-owns": i.id,
    style: yt
  }), i && /* @__PURE__ */ Kt(t, i), g && i && /* @__PURE__ */ d.createElement(je, {
    "data-type": "outside",
    ref: a,
    onFocus: (u) => {
      if (Te(u, i)) {
        var p;
        (p = f.current) == null || p.focus();
      } else {
        const v = wn() || (s == null ? void 0 : s.refs.domReference.current);
        v == null || v.focus(), s != null && s.closeOnFocusOut && (s == null || s.onOpenChange(!1, u.nativeEvent));
      }
    }
  }));
}
const Tn = () => d.useContext(En), Co = /* @__PURE__ */ d.forwardRef(function(t, n) {
  return /* @__PURE__ */ d.createElement("button", He({}, t, {
    type: "button",
    ref: n,
    tabIndex: -1,
    style: yt
  }));
});
function So(e) {
  const {
    context: t,
    children: n,
    disabled: r = !1,
    order: o = ["content"],
    guards: i = !0,
    initialFocus: s = 0,
    returnFocus: c = !0,
    modal: l = !0,
    visuallyHiddenDismiss: a = !1,
    closeOnFocusOut: m = !0
  } = e, {
    open: f,
    refs: g,
    nodeId: u,
    onOpenChange: p,
    events: v,
    dataRef: y,
    elements: {
      domReference: b,
      floating: h
    }
  } = t, P = bo() ? i : !0, R = Ee(o), T = Ee(s), E = Ee(c), w = Ge(), x = Tn(), $ = typeof s == "number" && s < 0, _ = d.useRef(null), D = d.useRef(null), F = d.useRef(!1), C = d.useRef(null), S = d.useRef(!1), A = x != null, B = b && b.getAttribute("role") === "combobox" && Yt(b) && $, M = d.useCallback(function(L) {
    return L === void 0 && (L = h), L ? vt(L, bt()) : [];
  }, [h]), O = d.useCallback((L) => {
    const W = M(L);
    return R.current.map((N) => b && N === "reference" ? b : h && N === "floating" ? h : W).filter(Boolean).flat();
  }, [b, h, R, M]);
  d.useEffect(() => {
    if (r || !l) return;
    function L(N) {
      if (N.key === "Tab") {
        j(h, ue(z(h))) && M().length === 0 && !B && Qe(N);
        const V = O(), Y = Xt(N);
        R.current[0] === "reference" && Y === b && (Qe(N), N.shiftKey ? le(V[V.length - 1]) : le(V[1])), R.current[1] === "floating" && Y === h && N.shiftKey && (Qe(N), le(V[0]));
      }
    }
    const W = z(h);
    return W.addEventListener("keydown", L), () => {
      W.removeEventListener("keydown", L);
    };
  }, [r, b, h, l, R, g, B, M, O]), d.useEffect(() => {
    if (r || !m) return;
    function L() {
      S.current = !0, setTimeout(() => {
        S.current = !1;
      });
    }
    function W(N) {
      const V = N.relatedTarget;
      queueMicrotask(() => {
        const Y = !(j(b, V) || j(h, V) || j(V, h) || j(x == null ? void 0 : x.portalNode, V) || V != null && V.hasAttribute(de("focus-guard")) || w && (Re(w.nodesRef.current, u).find((U) => {
          var J, ce;
          return j((J = U.context) == null ? void 0 : J.elements.floating, V) || j((ce = U.context) == null ? void 0 : ce.elements.domReference, V);
        }) || ho(w.nodesRef.current, u).find((U) => {
          var J, ce;
          return ((J = U.context) == null ? void 0 : J.elements.floating) === V || ((ce = U.context) == null ? void 0 : ce.elements.domReference) === V;
        })));
        V && Y && !S.current && // Fix React 18 Strict Mode returnFocus due to double rendering.
        V !== C.current && (F.current = !0, p(!1, N));
      });
    }
    if (h && be(b))
      return b.addEventListener("focusout", W), b.addEventListener("pointerdown", L), !l && h.addEventListener("focusout", W), () => {
        b.removeEventListener("focusout", W), b.removeEventListener("pointerdown", L), !l && h.removeEventListener("focusout", W);
      };
  }, [r, b, h, l, u, w, x, p, m]), d.useEffect(() => {
    var L;
    if (r) return;
    const W = Array.from((x == null || (L = x.portalNode) == null ? void 0 : L.querySelectorAll("[" + de("portal") + "]")) || []);
    if (h) {
      const N = [h, ...W, _.current, D.current, R.current.includes("reference") || B ? b : null].filter((Y) => Y != null), V = l ? Mt(N, P, !P) : Mt(N);
      return () => {
        V();
      };
    }
  }, [r, b, h, l, R, x, B, P]), G(() => {
    if (r || !h) return;
    const L = z(h), W = ue(L);
    queueMicrotask(() => {
      const N = O(h), V = T.current, Y = (typeof V == "number" ? N[V] : V.current) || h, U = j(h, W);
      !$ && !U && f && le(Y, {
        preventScroll: Y === h
      });
    });
  }, [r, f, h, $, O, T]), G(() => {
    if (r || !h) return;
    let L = !1;
    const W = z(h), N = ue(W), V = y.current;
    C.current = N;
    function Y(U) {
      if (U.type === "escapeKey" && g.domReference.current && (C.current = g.domReference.current), ["referencePress", "escapeKey"].includes(U.type))
        return;
      const J = U.data.returnFocus;
      typeof J == "object" ? (F.current = !1, L = J.preventScroll) : F.current = !J;
    }
    return v.on("dismiss", Y), () => {
      v.off("dismiss", Y);
      const U = ue(W);
      (j(h, U) || w && Re(w.nodesRef.current, u).some((ce) => {
        var wt;
        return j((wt = ce.context) == null ? void 0 : wt.elements.floating, U);
      }) || V.openEvent && ["click", "mousedown"].includes(V.openEvent.type)) && g.domReference.current && (C.current = g.domReference.current), // eslint-disable-next-line react-hooks/exhaustive-deps
      E.current && be(C.current) && !F.current && le(C.current, {
        // When dismissing nested floating elements, by the time the rAF has
        // executed, the menus will all have been unmounted. When they try
        // to get focused, the calls get ignored — leaving the root
        // reference focused as desired.
        cancelPrevious: !1,
        preventScroll: L
      });
    };
  }, [r, h, E, y, g, v, w, u]), G(() => {
    if (!(r || !x))
      return x.setFocusManagerState({
        modal: l,
        closeOnFocusOut: m,
        open: f,
        onOpenChange: p,
        refs: g
      }), () => {
        x.setFocusManagerState(null);
      };
  }, [r, x, l, f, p, g, m]), G(() => {
    if (!r && h && typeof MutationObserver == "function" && !$) {
      const L = () => {
        const N = h.getAttribute("tabindex");
        R.current.includes("floating") || ue(z(h)) !== g.domReference.current && M().length === 0 ? N !== "0" && h.setAttribute("tabindex", "0") : N !== "-1" && h.setAttribute("tabindex", "-1");
      };
      L();
      const W = new MutationObserver(L);
      return W.observe(h, {
        childList: !0,
        subtree: !0,
        attributes: !0
      }), () => {
        W.disconnect();
      };
    }
  }, [r, h, g, R, M, $]);
  function I(L) {
    return r || !a || !l ? null : /* @__PURE__ */ d.createElement(Co, {
      ref: L === "start" ? _ : D,
      onClick: (W) => p(!1, W.nativeEvent)
    }, typeof a == "string" ? a : "Dismiss");
  }
  const H = !r && P && !B && (A || l);
  return /* @__PURE__ */ d.createElement(d.Fragment, null, H && /* @__PURE__ */ d.createElement(je, {
    "data-type": "inside",
    ref: x == null ? void 0 : x.beforeInsideRef,
    onFocus: (L) => {
      if (l) {
        const N = O();
        le(o[0] === "reference" ? N[0] : N[N.length - 1]);
      } else if (x != null && x.preserveTabOrder && x.portalNode)
        if (F.current = !1, Te(L, x.portalNode)) {
          const N = wn() || b;
          N == null || N.focus();
        } else {
          var W;
          (W = x.beforeOutsideRef.current) == null || W.focus();
        }
    }
  }), !B && I("start"), n, I("end"), H && /* @__PURE__ */ d.createElement(je, {
    "data-type": "inside",
    ref: x == null ? void 0 : x.afterInsideRef,
    onFocus: (L) => {
      if (l)
        le(O()[0]);
      else if (x != null && x.preserveTabOrder && x.portalNode)
        if (m && (F.current = !0), Te(L, x.portalNode)) {
          const N = xn() || b;
          N == null || N.focus();
        } else {
          var W;
          (W = x.afterOutsideRef.current) == null || W.focus();
        }
    }
  }));
}
function Ft(e) {
  return be(e.target) && e.target.tagName === "BUTTON";
}
function Nt(e) {
  return Yt(e);
}
function Oo(e, t) {
  t === void 0 && (t = {});
  const {
    open: n,
    onOpenChange: r,
    dataRef: o,
    elements: {
      domReference: i
    }
  } = e, {
    enabled: s = !0,
    event: c = "click",
    toggle: l = !0,
    ignoreMouse: a = !1,
    keyboardHandlers: m = !0
  } = t, f = d.useRef(), g = d.useRef(!1);
  return d.useMemo(() => s ? {
    reference: {
      onPointerDown(u) {
        f.current = u.pointerType;
      },
      onMouseDown(u) {
        u.button === 0 && (Fe(f.current, !0) && a || c !== "click" && (n && l && (!o.current.openEvent || o.current.openEvent.type === "mousedown") ? r(!1, u.nativeEvent) : (u.preventDefault(), r(!0, u.nativeEvent))));
      },
      onClick(u) {
        if (c === "mousedown" && f.current) {
          f.current = void 0;
          return;
        }
        Fe(f.current, !0) && a || (n && l && (!o.current.openEvent || o.current.openEvent.type === "click") ? r(!1, u.nativeEvent) : r(!0, u.nativeEvent));
      },
      onKeyDown(u) {
        f.current = void 0, !(u.defaultPrevented || !m || Ft(u)) && (u.key === " " && !Nt(i) && (u.preventDefault(), g.current = !0), u.key === "Enter" && r(!(n && l), u.nativeEvent));
      },
      onKeyUp(u) {
        u.defaultPrevented || !m || Ft(u) || Nt(i) || u.key === " " && g.current && (g.current = !1, r(!(n && l), u.nativeEvent));
      }
    }
  } : {}, [s, o, c, a, m, i, l, n, r]);
}
const Ao = d.useInsertionEffect, Po = Ao || ((e) => e());
function Le(e) {
  const t = d.useRef(() => {
    if (process.env.NODE_ENV !== "production")
      throw new Error("Cannot call an event handler while rendering.");
  });
  return Po(() => {
    t.current = e;
  }), d.useCallback(function() {
    for (var n = arguments.length, r = new Array(n), o = 0; o < n; o++)
      r[o] = arguments[o];
    return t.current == null ? void 0 : t.current(...r);
  }, []);
}
const ko = {
  pointerdown: "onPointerDown",
  mousedown: "onMouseDown",
  click: "onClick"
}, Io = {
  pointerdown: "onPointerDownCapture",
  mousedown: "onMouseDownCapture",
  click: "onClickCapture"
}, Do = (e) => {
  var t, n;
  return {
    escapeKeyBubbles: typeof e == "boolean" ? e : (t = e == null ? void 0 : e.escapeKey) != null ? t : !1,
    outsidePressBubbles: typeof e == "boolean" ? e : (n = e == null ? void 0 : e.outsidePress) != null ? n : !0
  };
};
function Cn(e, t) {
  t === void 0 && (t = {});
  const {
    open: n,
    onOpenChange: r,
    events: o,
    nodeId: i,
    elements: {
      reference: s,
      domReference: c,
      floating: l
    },
    dataRef: a
  } = e, {
    enabled: m = !0,
    escapeKey: f = !0,
    outsidePress: g = !0,
    outsidePressEvent: u = "pointerdown",
    referencePress: p = !1,
    referencePressEvent: v = "pointerdown",
    ancestorScroll: y = !1,
    bubbles: b
  } = t, h = Ge(), P = hn() != null, R = Le(typeof g == "function" ? g : () => !1), T = typeof g == "function" ? R : g, E = d.useRef(!1), {
    escapeKeyBubbles: w,
    outsidePressBubbles: x
  } = Do(b), $ = Le((D) => {
    if (!n || !m || !f || D.key !== "Escape")
      return;
    const F = h ? Re(h.nodesRef.current, i) : [];
    if (!w && (D.stopPropagation(), F.length > 0)) {
      let C = !0;
      if (F.forEach((S) => {
        var A;
        if ((A = S.context) != null && A.open && !S.context.dataRef.current.__escapeKeyBubbles) {
          C = !1;
          return;
        }
      }), !C)
        return;
    }
    o.emit("dismiss", {
      type: "escapeKey",
      data: {
        returnFocus: {
          preventScroll: !1
        }
      }
    }), r(!1, ir(D) ? D.nativeEvent : D);
  }), _ = Le((D) => {
    const F = E.current;
    if (E.current = !1, F || typeof T == "function" && !T(D))
      return;
    const C = Xt(D), S = "[" + de("inert") + "]", A = z(l).querySelectorAll(S);
    let B = q(C) ? C : null;
    for (; B && !Qn(B); ) {
      const I = Jn(B);
      if (I === z(l).body || !q(I))
        break;
      B = I;
    }
    if (A.length && q(C) && !sr(C) && // Clicked on a direct ancestor (e.g. FloatingOverlay).
    !j(C, l) && // If the target root element contains none of the markers, then the
    // element was injected after the floating element rendered.
    Array.from(A).every((I) => !j(B, I)))
      return;
    if (be(C) && l) {
      const I = C.clientWidth > 0 && C.scrollWidth > C.clientWidth, H = C.clientHeight > 0 && C.scrollHeight > C.clientHeight;
      let L = H && D.offsetX > C.clientWidth;
      if (H && Zn(C).direction === "rtl" && (L = D.offsetX <= C.offsetWidth - C.clientWidth), L || I && D.offsetY > C.clientHeight)
        return;
    }
    const M = h && Re(h.nodesRef.current, i).some((I) => {
      var H;
      return De(D, (H = I.context) == null ? void 0 : H.elements.floating);
    });
    if (De(D, l) || De(D, c) || M)
      return;
    const O = h ? Re(h.nodesRef.current, i) : [];
    if (O.length > 0) {
      let I = !0;
      if (O.forEach((H) => {
        var L;
        if ((L = H.context) != null && L.open && !H.context.dataRef.current.__outsidePressBubbles) {
          I = !1;
          return;
        }
      }), !I)
        return;
    }
    o.emit("dismiss", {
      type: "outsidePress",
      data: {
        returnFocus: P ? {
          preventScroll: !0
        } : nr(D) || rr(D)
      }
    }), r(!1, D);
  });
  return d.useEffect(() => {
    if (!n || !m)
      return;
    a.current.__escapeKeyBubbles = w, a.current.__outsidePressBubbles = x;
    function D(S) {
      r(!1, S);
    }
    const F = z(l);
    f && F.addEventListener("keydown", $), T && F.addEventListener(u, _);
    let C = [];
    return y && (q(c) && (C = re(c)), q(l) && (C = C.concat(re(l))), !q(s) && s && s.contextElement && (C = C.concat(re(s.contextElement)))), C = C.filter((S) => {
      var A;
      return S !== ((A = F.defaultView) == null ? void 0 : A.visualViewport);
    }), C.forEach((S) => {
      S.addEventListener("scroll", D, {
        passive: !0
      });
    }), () => {
      f && F.removeEventListener("keydown", $), T && F.removeEventListener(u, _), C.forEach((S) => {
        S.removeEventListener("scroll", D);
      });
    };
  }, [a, l, c, s, f, T, u, n, r, y, m, w, x, $, _]), d.useEffect(() => {
    E.current = !1;
  }, [T, u]), d.useMemo(() => m ? {
    reference: {
      onKeyDown: $,
      [ko[v]]: (D) => {
        p && (o.emit("dismiss", {
          type: "referencePress",
          data: {
            returnFocus: !1
          }
        }), r(!1, D.nativeEvent));
      }
    },
    floating: {
      onKeyDown: $,
      [Io[u]]: () => {
        E.current = !0;
      }
    }
  } : {}, [m, o, p, u, v, r, $]);
}
let ft;
process.env.NODE_ENV !== "production" && (ft = /* @__PURE__ */ new Set());
function Sn(e) {
  var t;
  e === void 0 && (e = {});
  const {
    open: n = !1,
    onOpenChange: r,
    nodeId: o
  } = e;
  if (process.env.NODE_ENV !== "production") {
    var i;
    const w = "Floating UI: Cannot pass a virtual element to the `elements.reference` option, as it must be a real DOM element. Use `refs.setPositionReference` instead.";
    if ((i = e.elements) != null && i.reference && !q(e.elements.reference)) {
      var s;
      if (!((s = ft) != null && s.has(w))) {
        var c;
        (c = ft) == null || c.add(w), console.error(w);
      }
    }
  }
  const [l, a] = d.useState(null), m = ((t = e.elements) == null ? void 0 : t.reference) || l, f = zr(e), g = Ge(), u = Le((w, x) => {
    w && (v.current.openEvent = x), r == null || r(w, x);
  }), p = d.useRef(null), v = d.useRef({}), y = d.useState(() => mo())[0], b = ht(), h = d.useCallback((w) => {
    const x = q(w) ? {
      getBoundingClientRect: () => w.getBoundingClientRect(),
      contextElement: w
    } : w;
    f.refs.setReference(x);
  }, [f.refs]), P = d.useCallback((w) => {
    (q(w) || w === null) && (p.current = w, a(w)), (q(f.refs.reference.current) || f.refs.reference.current === null || // Don't allow setting virtual elements using the old technique back to
    // `null` to support `positionReference` + an unstable `reference`
    // callback ref.
    w !== null && !q(w)) && f.refs.setReference(w);
  }, [f.refs]), R = d.useMemo(() => ({
    ...f.refs,
    setReference: P,
    setPositionReference: h,
    domReference: p
  }), [f.refs, P, h]), T = d.useMemo(() => ({
    ...f.elements,
    domReference: m
  }), [f.elements, m]), E = d.useMemo(() => ({
    ...f,
    refs: R,
    elements: T,
    dataRef: v,
    nodeId: o,
    floatingId: b,
    events: y,
    open: n,
    onOpenChange: u
  }), [f, o, b, y, n, u, R, T]);
  return G(() => {
    const w = g == null ? void 0 : g.nodesRef.current.find((x) => x.id === o);
    w && (w.context = E);
  }), d.useMemo(() => ({
    ...f,
    context: E,
    refs: R,
    elements: T
  }), [f, R, T, E]);
}
function Mo(e, t) {
  t === void 0 && (t = {});
  const {
    open: n,
    onOpenChange: r,
    dataRef: o,
    events: i,
    refs: s,
    elements: {
      floating: c,
      domReference: l
    }
  } = e, {
    enabled: a = !0,
    keyboardOnly: m = !0
  } = t, f = d.useRef(""), g = d.useRef(!1), u = d.useRef();
  return d.useEffect(() => {
    if (!a)
      return;
    const v = z(c).defaultView || window;
    function y() {
      !n && be(l) && l === ue(z(l)) && (g.current = !0);
    }
    return v.addEventListener("blur", y), () => {
      v.removeEventListener("blur", y);
    };
  }, [c, l, n, a]), d.useEffect(() => {
    if (!a)
      return;
    function p(v) {
      (v.type === "referencePress" || v.type === "escapeKey") && (g.current = !0);
    }
    return i.on("dismiss", p), () => {
      i.off("dismiss", p);
    };
  }, [i, a]), d.useEffect(() => () => {
    clearTimeout(u.current);
  }, []), d.useMemo(() => a ? {
    reference: {
      onPointerDown(p) {
        let {
          pointerType: v
        } = p;
        f.current = v, g.current = !!(v && m);
      },
      onMouseLeave() {
        g.current = !1;
      },
      onFocus(p) {
        var v;
        g.current || p.type === "focus" && ((v = o.current.openEvent) == null ? void 0 : v.type) === "mousedown" && De(o.current.openEvent, l) || r(!0, p.nativeEvent);
      },
      onBlur(p) {
        g.current = !1;
        const v = p.relatedTarget, y = q(v) && v.hasAttribute(de("focus-guard")) && v.getAttribute("data-type") === "outside";
        u.current = setTimeout(() => {
          j(s.floating.current, v) || j(l, v) || y || r(!1, p.nativeEvent);
        });
      }
    }
  } : {}, [a, m, l, s, o, r]);
}
function nt(e, t, n) {
  const r = /* @__PURE__ */ new Map();
  return {
    ...n === "floating" && {
      tabIndex: -1
    },
    ...e,
    ...t.map((o) => o ? o[n] : null).concat(e).reduce((o, i) => (i && Object.entries(i).forEach((s) => {
      let [c, l] = s;
      if (c.indexOf("on") === 0) {
        if (r.has(c) || r.set(c, []), typeof l == "function") {
          var a;
          (a = r.get(c)) == null || a.push(l), o[c] = function() {
            for (var m, f = arguments.length, g = new Array(f), u = 0; u < f; u++)
              g[u] = arguments[u];
            return (m = r.get(c)) == null ? void 0 : m.map((p) => p(...g)).find((p) => p !== void 0);
          };
        }
      } else
        o[c] = l;
    }), o), {})
  };
}
function On(e) {
  e === void 0 && (e = []);
  const t = e, n = d.useCallback(
    (i) => nt(i, e, "reference"),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    t
  ), r = d.useCallback(
    (i) => nt(i, e, "floating"),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    t
  ), o = d.useCallback(
    (i) => nt(i, e, "item"),
    // Granularly check for `item` changes, because the `getItemProps` getter
    // should be as referentially stable as possible since it may be passed as
    // a prop to many components. All `item` key values must therefore be
    // memoized.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    e.map((i) => i == null ? void 0 : i.item)
  );
  return d.useMemo(() => ({
    getReferenceProps: n,
    getFloatingProps: r,
    getItemProps: o
  }), [n, r, o]);
}
function An(e, t) {
  t === void 0 && (t = {});
  const {
    open: n,
    floatingId: r
  } = e, {
    enabled: o = !0,
    role: i = "dialog"
  } = t, s = ht();
  return d.useMemo(() => {
    const c = {
      id: r,
      role: i
    };
    return o ? i === "tooltip" ? {
      reference: {
        "aria-describedby": n ? r : void 0
      },
      floating: c
    } : {
      reference: {
        "aria-expanded": n ? "true" : "false",
        "aria-haspopup": i === "alertdialog" ? "dialog" : i,
        "aria-controls": n ? r : void 0,
        ...i === "listbox" && {
          role: "combobox"
        },
        ...i === "menu" && {
          id: s
        }
      },
      floating: {
        ...c,
        ...i === "menu" && {
          "aria-labelledby": s
        }
      }
    } : {};
  }, [o, i, n, r, s]);
}
const Pn = qn(null);
function kn() {
  const e = Un(Pn);
  if (e === null)
    throw new Error("Popover components must be wrapped in <Popover />");
  return e;
}
function Lo({
  initialOpen: e = !1,
  modal: t,
  onOpenChange: n,
  open: r,
  placement: o = "bottom"
} = {}) {
  const [i, s] = te(e), [c, l] = te(), [a, m] = te(), f = r ?? i, g = n ?? s, u = Sn({
    middleware: [
      an(5),
      dn({
        crossAxis: o.includes("-"),
        fallbackAxisSideDirection: "end",
        padding: 5
      }),
      fn({ padding: 5 })
    ],
    onOpenChange: g,
    open: f,
    placement: o,
    whileElementsMounted: ln
  }), { context: p } = u, v = Oo(p, {
    enabled: r === null
  }), y = Cn(p), b = An(p), h = On([v, y, b]);
  return Ht(
    () => ({
      open: f,
      setOpen: g,
      ...h,
      ...u,
      descriptionId: a,
      labelId: c,
      modal: t,
      setDescriptionId: m,
      setLabelId: l
    }),
    [f, g, h, u, t, c, a]
  );
}
function In({
  children: e,
  modal: t = !1,
  ...n
}) {
  const r = Lo({ modal: t, ...n });
  return /* @__PURE__ */ k(Pn.Provider, { value: r, children: e });
}
In.Trigger = jt(
  function({ asChild: t = !1, children: n, ...r }, o) {
    const i = kn(), s = n == null ? void 0 : n.ref, c = Ye([i.refs.setReference, o, s]);
    return t && it.isValidElement(n) ? it.cloneElement(
      n,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      i.getReferenceProps({
        ref: c,
        ...r,
        ...n.props,
        "data-state": i.open ? "open" : "closed"
      })
    ) : /* @__PURE__ */ k(
      "button",
      {
        "data-state": i.open ? "open" : "closed",
        ref: c,
        type: "button",
        ...i.getReferenceProps(r),
        children: n
      }
    );
  }
);
In.Content = jt(
  // eslint-disable-next-line react/prop-types
  function({ style: t, ...n }, r) {
    const { context: o, ...i } = kn(), s = Ye([i.refs.setFloating, r]);
    return o.open ? /* @__PURE__ */ k(Rn, { children: /* @__PURE__ */ k(So, { context: o, modal: i.modal, children: /* @__PURE__ */ k(
      "div",
      {
        "aria-describedby": i.descriptionId,
        "aria-labelledby": i.labelId,
        ref: s,
        style: { ...i.floatingStyles, ...t },
        ...i.getFloatingProps(n),
        children: n.children
      }
    ) }) }) : null;
  }
);
function Dn({
  onChange: e,
  options: t,
  value: n,
  variant: r,
  ...o
}) {
  const i = window.matchMedia("(max-width: 431px"), c = i.matches ? "vertical" : o.direction ?? "vertical", [l, a] = te(c);
  return ze(() => {
    const m = (g) => {
      a(g ? "vertical" : "horizontal");
    }, f = ({ matches: g }) => {
      m(g);
    };
    return i.addEventListener("change", f), m(i.matches), () => {
      i.removeEventListener("change", f);
    };
  }, [i]), /* @__PURE__ */ k("div", { className: oe("radio-button-group", l, r), children: t.map((m) => /* @__PURE__ */ k(
    Dn.Option,
    {
      ...m,
      currentValue: n,
      onChange: e
    },
    m.id
  )) });
}
Dn.Option = function({
  currentValue: t,
  id: n,
  isDisabled: r,
  label: o,
  name: i,
  onChange: s,
  value: c
}) {
  return /* @__PURE__ */ ve(Fn, { children: [
    /* @__PURE__ */ k(
      "input",
      {
        checked: t === c,
        disabled: r,
        id: n,
        name: i,
        type: "radio",
        value: c,
        onChange: s
      }
    ),
    /* @__PURE__ */ ve("label", { htmlFor: n, children: [
      /* @__PURE__ */ k("div", { className: "checkbox-container", children: /* @__PURE__ */ k(Vn, { isChecked: t === c }) }),
      o
    ] })
  ] });
};
var Ie = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
function Fo(e) {
  return e && e.__esModule && Object.prototype.hasOwnProperty.call(e, "default") ? e.default : e;
}
var No = "Expected a function", Bt = NaN, Bo = "[object Symbol]", $o = /^\s+|\s+$/g, Wo = /^[-+]0x[0-9a-f]+$/i, Vo = /^0b[01]+$/i, _o = /^0o[0-7]+$/i, Ho = parseInt, jo = typeof Ie == "object" && Ie && Ie.Object === Object && Ie, zo = typeof self == "object" && self && self.Object === Object && self, Ko = jo || zo || Function("return this")(), qo = Object.prototype, Uo = qo.toString, Xo = Math.max, Yo = Math.min, rt = function() {
  return Ko.Date.now();
};
function Go(e, t, n) {
  var r, o, i, s, c, l, a = 0, m = !1, f = !1, g = !0;
  if (typeof e != "function")
    throw new TypeError(No);
  t = $t(t) || 0, dt(n) && (m = !!n.leading, f = "maxWait" in n, i = f ? Xo($t(n.maxWait) || 0, t) : i, g = "trailing" in n ? !!n.trailing : g);
  function u(E) {
    var w = r, x = o;
    return r = o = void 0, a = E, s = e.apply(x, w), s;
  }
  function p(E) {
    return a = E, c = setTimeout(b, t), m ? u(E) : s;
  }
  function v(E) {
    var w = E - l, x = E - a, $ = t - w;
    return f ? Yo($, i - x) : $;
  }
  function y(E) {
    var w = E - l, x = E - a;
    return l === void 0 || w >= t || w < 0 || f && x >= i;
  }
  function b() {
    var E = rt();
    if (y(E))
      return h(E);
    c = setTimeout(b, v(E));
  }
  function h(E) {
    return c = void 0, g && r ? u(E) : (r = o = void 0, s);
  }
  function P() {
    c !== void 0 && clearTimeout(c), a = 0, r = l = o = c = void 0;
  }
  function R() {
    return c === void 0 ? s : h(rt());
  }
  function T() {
    var E = rt(), w = y(E);
    if (r = arguments, o = this, l = E, w) {
      if (c === void 0)
        return p(l);
      if (f)
        return c = setTimeout(b, t), u(l);
    }
    return c === void 0 && (c = setTimeout(b, t)), s;
  }
  return T.cancel = P, T.flush = R, T;
}
function dt(e) {
  var t = typeof e;
  return !!e && (t == "object" || t == "function");
}
function Qo(e) {
  return !!e && typeof e == "object";
}
function Zo(e) {
  return typeof e == "symbol" || Qo(e) && Uo.call(e) == Bo;
}
function $t(e) {
  if (typeof e == "number")
    return e;
  if (Zo(e))
    return Bt;
  if (dt(e)) {
    var t = typeof e.valueOf == "function" ? e.valueOf() : e;
    e = dt(t) ? t + "" : t;
  }
  if (typeof e != "string")
    return e === 0 ? e : +e;
  e = e.replace($o, "");
  var n = Vo.test(e);
  return n || _o.test(e) ? Ho(e.slice(2), n ? 2 : 8) : Wo.test(e) ? Bt : +e;
}
var Jo = Go;
const ei = /* @__PURE__ */ Fo(Jo), ti = 25, gi = it.forwardRef(
  ({
    children: e,
    className: t,
    direction: n = "column",
    hideScrollbar: r = !1,
    role: o
  }, i) => {
    const s = _t(null), [c, l] = te(!1), [a, m] = te(!1), f = zt(() => {
      const u = s == null ? void 0 : s.current;
      if (!u) return !1;
      const p = n.startsWith("row"), v = n.endsWith("reverse"), { offsetHeight: y, offsetWidth: b, scrollHeight: h, scrollLeft: P, scrollTop: R, scrollWidth: T } = u, E = p ? P : R, w = Math.ceil(v ? Math.abs(E * -1) : E), x = p ? T - b : h - y, $ = p ? T > b : h > y;
      return l(w === 0 ? !1 : $), w >= x ? m(!1) : m($), $;
    }, [n, s]), g = Ht(
      () => ei(f, ti),
      [f]
    );
    return ze(() => {
      f();
    }, [e, f]), he(() => (window.addEventListener("resize", g), () => window.removeEventListener("resize", g)), [g]), Xn(
      i,
      () => ({
        checkOverflow: f,
        innerRef: s
      }),
      [f]
    ), /* @__PURE__ */ k(
      "div",
      {
        className: oe(
          "scroll-container",
          `direction-${n}`,
          { "overflowing-end": a },
          { "overflowing-start": c },
          t
        ),
        role: o,
        children: /* @__PURE__ */ k(
          "div",
          {
            className: oe(`direction-${n}`, "scroll-container-content", {
              flex: n === "row",
              "hide-scrollbar": r
            }),
            ref: s,
            onScroll: g,
            children: e
          }
        )
      }
    );
  }
);
function ni({
  initialOpen: e = !1,
  onOpenChange: t,
  open: n,
  placement: r = "top"
} = {}) {
  const [o, i] = d.useState(e), s = n ?? o, c = t ?? i, l = Sn({
    middleware: [
      an(5),
      dn({
        crossAxis: r.includes("-"),
        fallbackAxisSideDirection: "start",
        padding: 5
      }),
      fn({ padding: 5 })
    ],
    onOpenChange: c,
    open: s,
    placement: r,
    whileElementsMounted: ln
  }), { context: a } = l, m = vo(a, {
    enabled: n == null,
    move: !1
  }), f = Mo(a, {
    enabled: n == null
  }), g = Cn(a), u = An(a, { role: "tooltip" }), p = On([m, f, g, u]);
  return d.useMemo(
    () => ({
      open: s,
      setOpen: c,
      ...p,
      ...l
    }),
    [s, c, p, l]
  );
}
const Mn = d.createContext(null), Ln = () => {
  const e = d.useContext(Mn);
  if (e == null)
    throw new Error("Tooltip components must be wrapped in <Tooltip />");
  return e;
};
function ri({ children: e, ...t }) {
  const n = ni(t);
  return /* @__PURE__ */ k(Mn.Provider, { value: n, children: e });
}
const oi = d.forwardRef(function({ asChild: t = !1, children: n, ...r }, o) {
  const i = Ln(), s = n.ref, c = Ye([i.refs.setReference, o, s]);
  return t && d.isValidElement(n) ? d.cloneElement(
    n,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    i.getReferenceProps({
      ref: c,
      ...r,
      ...n.props,
      "data-state": i.open ? "open" : "closed"
    })
  ) : /* @__PURE__ */ k(
    "button",
    {
      "data-state": i.open ? "open" : "closed",
      ref: c,
      ...i.getReferenceProps(r),
      children: n
    }
  );
}), ii = d.forwardRef(
  // eslint-disable-next-line react/prop-types
  function({ style: t, ...n }, r) {
    const o = Ln(), i = Ye([o.refs.setFloating, r]);
    return o.open ? /* @__PURE__ */ k(Rn, { children: /* @__PURE__ */ k(
      "div",
      {
        ref: i,
        style: {
          ...o.floatingStyles,
          ...t
        },
        ...o.getFloatingProps(n)
      }
    ) }) : null;
  }
);
function vi({ children: e }) {
  return /* @__PURE__ */ ve(ri, { children: [
    /* @__PURE__ */ k(oi, { children: /* @__PURE__ */ k("span", { className: "inline-flex text-muted", children: /* @__PURE__ */ k(_n, { className: "size-6" }) }) }),
    /* @__PURE__ */ k(ii, { children: /* @__PURE__ */ k(
      Wt,
      {
        className: "rounded-lg border border-shade-4 bg-shade-2 p-6 text-sm leading-relaxed shadow-xl",
        size: "extra-small",
        children: e
      }
    ) })
  ] });
}
function si(e) {
  return {
    current: (e == null ? void 0 : e.currentScores) ?? [],
    previous: (e == null ? void 0 : e.previousScores) ?? []
  };
}
function hi(e) {
  const { current: t, previous: n } = si(e), r = n.length === 0 ? t : n, [o, i] = te(r);
  return he(() => {
    n.length !== 0 && setTimeout(() => {
      i(t);
    }, 2e3);
  }, [t, n.length]), {
    animatedScores: o
  };
}
function bi() {
  const [e, t] = te(!1), n = zt(async () => {
    t(!0), await Hn(1200), t(!1);
  }, []);
  return { showSuccess: e, showSuccessButtonState: n };
}
export {
  mi as ConfirmPrompt,
  K as Dialog,
  pi as ErrorDialog,
  vi as InfoTooltip,
  In as Popover,
  Pn as PopoverContext,
  Dn as RadioButtonGroup,
  gi as ScrollContainer,
  ri as Tooltip,
  ii as TooltipContent,
  oi as TooltipTrigger,
  si as formatScores,
  hi as useAnimatedScores,
  Lo as usePopover,
  kn as usePopoverContext,
  bi as useSuccessButton
};
