import { ISpotifyFeatures, Song } from '@repo/types'

import { ISpotifyTrack } from '@/store/integrations/spotify/types'

import { convertArtist } from './convertArtist'
import { convertSongTitle } from './convertSongTitle'

export function convertSong(track: ISpotifyTrack, index: number): Song {
  return {
    album: track.album.name,
    artist: convertArtist(track.artists),
    id: index,
    spotifyMeta: convertSpotifyMeta(track),
    title: convertSongTitle(track.name),
    userMeta: convertUserMeta(track),
  }
}

function convertUserMeta(track: ISpotifyTrack) {
  const { key, tempo } = track.features ?? {}

  return {
    key,
    tempo: tempo && Math.round(tempo),
  }
}

function convertSpotifyMeta(track: ISpotifyTrack) {
  return {
    duration: track.duration_ms,
    features: track.features,
    startPosition: 0,
    track: {
      id: track.id,
      uri: track.uri,
    },
  }
}

export function addFeaturesToSong(
  song: Song,
  features: ISpotifyFeatures
): Song {
  if (song.spotifyMeta === undefined) {
    throw new Error('Song does not have existing Spotify metadata')
  }

  return {
    ...song,
    spotifyMeta: {
      ...song.spotifyMeta,
      features,
    },
    userMeta: { key: features.key, tempo: Math.round(features.tempo) },
  }
}
