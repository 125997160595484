import {
  User as FirebaseUser,
  getAuth,
  onAuthStateChanged,
} from 'firebase/auth'

import { transformUser } from '@/authentication/transform-user'
import { parsePlanSelectionParams } from '@/pages/account/setup-account'

import { currentGameListener } from './CurrentGameListener'
import { hostListener } from '../../host/HostListener'
import { Store } from '../../Store'
import { createUserAccount } from '../createUserAccount'
import { fetchHostIdByCustomUrl } from '../fetchHostIdByCustomUrl'

export class AuthListener {
  public store: Store

  public constructor(store: Store) {
    this.store = store
    const auth = getAuth()

    onAuthStateChanged(
      auth,
      (firebaseUser: FirebaseUser | null) =>
        void this.handleAuthStateChanged(firebaseUser)
    )
  }

  private handleAuthStateChanged = async (
    firebaseUser: FirebaseUser | null
  ) => {
    if (!firebaseUser) {
      currentGameListener.unsubscribe()
      hostListener.unsubscribe()

      this.store.user.applySignOut()
      return
    }

    const user = await transformUser(firebaseUser)

    const planSelectionParams = parsePlanSelectionParams(
      new URLSearchParams(window.location.search)
    )
    const hasSelectedFree = planSelectionParams?.planType === 'free'

    const isPaidUser =
      Boolean(user.planType) && user.planType !== 'free' && !user.isAnonymous

    if (!user.hasAccount && (isPaidUser || hasSelectedFree)) {
      await createUserAccount(user)
      user.hasAccount = true
    }

    this.store.user.applySignIn(user)

    const hostId = user.isAnonymous
      ? await getHostIdForAnonymous()
      : user.userId

    if (!hostId) return

    currentGameListener.subscribe(hostId)
    hostListener.subscribe(hostId)
  }
}

async function getHostIdForAnonymous() {
  const hostIdOrCustomUrl = window.location.pathname.split('/')[1]
  if (hostIdOrCustomUrl === 'login') return
  return (await fetchHostIdByCustomUrl(hostIdOrCustomUrl)) ?? hostIdOrCustomUrl
}
