import { Song } from '@repo/types'

import { isDuplicateByMetadata } from './utils/isDuplicateByMetadata'
import { isDuplicateBySpotifyId } from './utils/isDuplicateBySpotifyId'

const result = {
  error: {
    message: 'This song already exists in the playlist and cannot be added',
    status: 'error',
    title: 'Duplicate Song Detected',
  } as const,
  success: {
    status: 'success',
  } as const,
  warning: {
    message:
      'A similar song already exists in the playlist. Are you sure you want to add it?',
    status: 'warning',
    title: 'Possible Duplicate Song Detected',
  } as const,
} as const

export type DuplicateCheckResult = (typeof result)[keyof typeof result]

interface Options {
  isSpotifyCompatible: boolean
}

export function checkDuplicates(
  song: Song,
  playlistSongs: Song[],
  options: Options
): DuplicateCheckResult {
  if (
    options.isSpotifyCompatible &&
    isDuplicateBySpotifyId(song, playlistSongs)
  ) {
    return result.error
  }

  for (const playlistSong of playlistSongs) {
    const { isAlbumMatch, isTitleAndArtistMatch } = isDuplicateByMetadata(
      song,
      playlistSong
    )

    if (isTitleAndArtistMatch && isAlbumMatch) {
      return result.error
    }

    if (isTitleAndArtistMatch) {
      return result.warning
    }
  }

  return result.success
}
