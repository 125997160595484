import { useDialogContext } from '@/dialogs/DialogContext'
import { useGameContext } from '@/pages/game/context/game-context'
import { updateGameLastActivity } from '@/store/game/updateGameDocument'

import { useSpotifyPlaybackError } from './useSpotifyPlaybackError'
import { useSyncExternalSpotifyPlayback } from '../queries/useSyncExternalSpotifyPlayback'
import { useSpotify } from '../useSpotify'

export function useExternalPlayback(
  setShowDeviceSelectorModal: (show: boolean) => void
) {
  const { gameId } = useGameContext()
  const { playback } = useSpotify()

  const { setConfirmPrompt } = useDialogContext()

  const { withErrorHandling } = useSpotifyPlaybackError({
    onError: () => {
      playback.refetch()
    },
    setShowDeviceSelectorModal,
  })

  const { data: playbackState, isLoading } = useSyncExternalSpotifyPlayback()

  const handleClickPrevious = () => {
    setConfirmPrompt({
      heading: 'Are you sure you want to go back to the previous song?',
      onConfirm: () => {
        withErrorHandling(async () => {
          await playback.previous()
          await updateGameLastActivity(gameId)
          playback.refetch()
        })
      },
    })
  }

  const handleClickNext = () => {
    withErrorHandling(async () => {
      await playback.next()
      await updateGameLastActivity(gameId)
      playback.refetch()
    })
  }

  const handleClickPause = () => {
    withErrorHandling(async () => {
      await playback.pause()
      await updateGameLastActivity(gameId)
      playback.refetch()
    })
  }

  const handleClickPlay = () => {
    withErrorHandling(async () => {
      await playback.play()
      await updateGameLastActivity(gameId)
      playback.refetch()
    })
  }

  return {
    handleClickNext,
    handleClickPause,
    handleClickPlay,
    handleClickPrevious,
    isLoading,
    isPlaying: Boolean(playbackState?.isPlaying),
  }
}
