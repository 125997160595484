import { observer } from 'mobx-react-lite'
import React from 'react'

import { useQueryUserPlaylists } from '@/pages/playlists/playlist-queries/user-playlists/useQueryUserPlaylists'

import { ManagePlaylistsContainer } from './manage-playlists-container'
import { useDeleteUserPlaylist } from '../playlist-queries/user-playlists/useDeleteUserPlaylist'

interface ManagePlaylistsRouteProps {
  baseUrl?: string
}

export const ManagePlaylistsRoute = observer(function ManagePlaylistsRoute({
  baseUrl,
}: ManagePlaylistsRouteProps): React.ReactElement | null {
  const { data = [], isLoading: isLoadingPlaylists } = useQueryUserPlaylists()
  const { isLoading: isMutating, mutate } = useDeleteUserPlaylist()

  const isLoading = isLoadingPlaylists || isMutating

  const handleDeletePlaylist = (playlistId: string) => {
    mutate(playlistId)
  }

  return (
    <ManagePlaylistsContainer
      baseUrl={baseUrl}
      isLoading={isLoading}
      playlists={data}
      onDeletePlaylist={handleDeletePlaylist}
    />
  )
})
