import { Playlist } from '@repo/types'

import { apiClient } from '@/api/api-client'
import StoreService from '@/store/StoreService'

import { useUpdatePlaylist } from '../useUpdatePlaylist'

export function useUpdateSystemPlaylist({
  playlistId,
}: {
  playlistId: string
}) {
  const { playlistService } = StoreService.getStore()

  const { queryFn: itemQueryFn, queryKey: itemQueryKey } =
    playlistService.queries.systemPlaylist

  const { queryFn: listQueryFn, queryKey: listQueryKey } =
    playlistService.queries.systemPlaylists

  const mutationFn = (playlist: Playlist) =>
    apiClient.adminUpdateSystemPlaylist(playlist)

  return useUpdatePlaylist(
    mutationFn,
    itemQueryKey(playlistId),
    () => itemQueryFn(playlistId),
    listQueryKey,
    listQueryFn
  )
}
