import { Spinner } from '@repo/ui'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'

import { LogoHeader } from '@/components/LogoHeader'
import { Main } from '@/components/Main'
import { Page } from '@/components/Page'
import { TermsOfService } from '@/components/TermsOfService'

export const PRICING_URL = `${import.meta.env.VITE_PRIMARY_URL}/pricing`

export const RedirectPricing = observer(function RedirectPricing() {
  useEffect(() => {
    window.location.assign(PRICING_URL)
  }, [])

  return (
    <Page title='Redirecting to Pricing Page'>
      <Main>
        <LogoHeader />

        <Spinner label='Redirecting to pricing page...' />

        <TermsOfService />
      </Main>
    </Page>
  )
})
