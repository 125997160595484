import type { Scoreboard } from '@repo/types'
import { Dialog } from '@repo/ui/client'

import { AnimatedScoreboard } from '@/components/Scoreboard'

interface ScoreboardDialogProps {
  scores: Scoreboard
}

export function ScoreboardDialog({ scores }: ScoreboardDialogProps) {
  return (
    <Dialog>
      <Dialog.Body>
        <AnimatedScoreboard scores={scores} />
      </Dialog.Body>
    </Dialog>
  )
}
