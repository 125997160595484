import { createPortalLink } from './createPortalLink'
import { PRICING_URL } from '../pages/account/redirect-pricing'
import StoreService from '../store/StoreService'

export function createUpgradeLink(): Promise<void> | undefined {
  const { user } = StoreService.getStore()

  if (user.isPro) return

  if (user.isFree) {
    window.location.assign(PRICING_URL)
    return
  }

  return createPortalLink()
}
