import { User as FirebaseUser } from 'firebase/auth'

import { SpotifyAuthClient } from './SpotifyAuthClient'
import { ISpotifyTokenResponse } from '../../types'

export interface FirebaseTokenResponse {
  email?: string
  error?: string
  firebaseToken: string
  hasMigrated: boolean
  spotify: ISpotifyTokenResponse
  user?: FirebaseUser
}

export enum FetchMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
}

export class SpotifyTokenError extends Error {
  public error?: string
  public email?: string

  public constructor(error?: string, email?: string) {
    super()
    this.error = error
    this.email = email
  }
}

export function isProperty(
  value: unknown,
  obj: SpotifyAuthClient
): value is keyof SpotifyAuthClient {
  if (typeof value !== 'string') return false
  return value in obj
}
