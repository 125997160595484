import { MessageType } from '@repo/types'
import { MessageBanner } from '@repo/ui'
import { observer } from 'mobx-react-lite'
import React from 'react'

import { useGameContext } from '../pages/game/context/game-context'
import { useGameControlsContext } from '../pages/game/context/game-controls-context'

export const BingoResultsBanner = observer(
  function BingoResultsBanner(): React.ReactElement | null {
    const { currentMessage } = useGameContext()
    const { isVerifying } = useGameControlsContext()

    const messageType = currentMessage?.message.type

    if (messageType && messageType === MessageType.DENIED_BINGO)
      return (
        <MessageBanner color='red'>
          <span>
            {currentMessage.message.data.playerName}
            {"'"}s Bingo DENIED!
          </span>
        </MessageBanner>
      )

    if (messageType && messageType === MessageType.CONFIRMED_BINGO)
      return (
        <MessageBanner color='green'>
          <span>
            {currentMessage.message.data.playerName}
            {"'"}s Bingo CONFIRMED!
          </span>
        </MessageBanner>
      )

    if (isVerifying) {
      return <MessageBanner color='orange'>Verifying...</MessageBanner>
    }

    return null
  }
)
