import { motion } from 'framer-motion'
import { observer } from 'mobx-react-lite'
import React from 'react'

const parentVariants = {
  hidden: { transition: { staggerChildren: 0.0334 } },
  visible: { transition: { staggerChildren: 0.0334 } },
}

const childVariants = {
  hidden: {
    opacity: 0,
    transition: { duration: 0.3 },
  },
  visible: {
    opacity: 1,
    transition: { duration: 0.3 },
  },
}

interface IAnimationWrapperProps {
  children: React.ReactElement
  className?: string
  keyId?: string
  propToAnimate?: boolean
  shouldAnimate?: boolean
}

export const AnimatedStaggerParent = observer(function AnimatedStaggerParent({
  children,
  className,
  keyId,
  shouldAnimate = true,
}: IAnimationWrapperProps): React.ReactElement {
  if (!shouldAnimate) return children

  return (
    <motion.div
      animate='visible'
      className={className}
      initial='hidden'
      key={keyId ? keyId : undefined}
      variants={parentVariants}
    >
      {children}
    </motion.div>
  )
})

export const AnimatedStaggerChild = observer(function AnimatedStaggerChild({
  children,
  className,
  keyId,
  shouldAnimate = true,
}: IAnimationWrapperProps & { keyId: string }): React.ReactElement {
  if (!shouldAnimate) return children

  return (
    <motion.div
      className={className}
      exit='hidden'
      key={keyId}
      variants={childVariants}
    >
      {children}
    </motion.div>
  )
})
