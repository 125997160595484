import { Collections, Game } from '@repo/types'
import { ToggleSwitch } from '@repo/ui'
import { doc, updateDoc } from 'firebase/firestore'
import { observer } from 'mobx-react-lite'
import React, { SyntheticEvent } from 'react'

import { Timestamp, databaseRef, setServerTimestamp } from '@/firebase/firebase'

import { SettingsRow } from './SettingsRow'
import { useGameContext } from '../../game/context/game-context'

export const RequestContactDetailsSettings = observer(
  function RequestContactDetailsSettings(): React.ReactElement {
    const { gameData, gameId, tracker } = useGameContext()

    const handleChange = (event: SyntheticEvent) => {
      const { checked } = event.target as HTMLInputElement

      const update: Pick<Game, 'requestContactDetails' | 'lastActivity'> = {
        lastActivity: setServerTimestamp() as Timestamp,
        requestContactDetails: checked,
      }

      void updateDoc(doc(databaseRef, Collections.GAMES, gameId), update)
      tracker.changeToggle('Request Winner Contact Details', checked)
    }

    return (
      <SettingsRow
        description='Collect contact information from winning guests.'
        heading='Winner Contact Details'
      >
        <ToggleSwitch
          id='requestContactDetails'
          isChecked={gameData.requestContactDetails}
          onChange={handleChange}
        />
      </SettingsRow>
    )
  }
)
