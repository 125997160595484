import { observer } from 'mobx-react-lite'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { Route, useRouteMatch } from 'react-router-dom'

import { AnonymousGameRoute } from '@/pages/game/context/anonymous-game-route'
import { SidekickSettingsRoute } from '@/pages/settings/pages/sidekick-settings'

import { SidekickLobbyRoute } from './sidekick-lobby'
import { SidekickLyrics } from './sidekick-lyrics'

export const SidekickRoute = observer(
  function SidekickRoute(): React.ReactElement {
    const { path } = useRouteMatch()

    return (
      <AnonymousGameRoute>
        <Helmet>
          <title>Sidekick | Rockstar Bingo</title>
        </Helmet>

        <Route path={`${path}/lobby/:setup?`}>
          <SidekickLobbyRoute />
        </Route>

        <Route path={`${path}/settings`}>
          <SidekickSettingsRoute />
        </Route>

        <Route path={path} exact>
          <SidekickLyrics />
        </Route>
      </AnonymousGameRoute>
    )
  }
)
