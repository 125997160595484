import { Game } from '@repo/types'
import { ToggleSwitch } from '@repo/ui'
import { observer } from 'mobx-react-lite'
import React from 'react'

import { useGameContext } from '@/pages/game/context/game-context'
import { updateGameSetting } from '@/store/game/updateGameDocument'

import { SettingsRow } from '../SettingsRow'

const SETTING_NAME = 'Current Letter'
const SETTING_ID = 'jumbotron.enableCurrentLetter' as const

export const EnableJumbotronCurrentLetter = observer(
  function EnableJumbotronCurrentLetter(): React.ReactElement {
    const { gameData, gameId, tracker } = useGameContext()

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target

      const update: Pick<Game, typeof SETTING_ID> = {
        [SETTING_ID]: checked,
      }

      void updateGameSetting(gameId, update)
      tracker.changeToggle(`Jumbotron ${SETTING_NAME}`, checked)
    }

    return (
      <SettingsRow
        description='Show the current column letter on screen.'
        heading={SETTING_NAME}
      >
        <ToggleSwitch
          id={SETTING_ID}
          isChecked={gameData.jumbotron.enableCurrentLetter}
          onChange={handleChange}
        />
      </SettingsRow>
    )
  }
)
