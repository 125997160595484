import { Spinner } from '@repo/ui'
import * as Sentry from '@sentry/react'
import { getAuth, signInAnonymously } from 'firebase/auth'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'

import { firebaseApp } from '@/firebase/firebase'

import StoreService from '../store/StoreService'

interface IAnonymousRouteProps {
  component: React.ElementType
  exact?: boolean
  path: string | string[]
}

export const AnonymousRoute = observer(function AnonymousRoute({
  component: Component,
  ...rest
}: IAnonymousRouteProps): React.ReactElement | null {
  const store = StoreService.getStore()
  const { hasCheckedAuth, isLoggedIn } = store.user

  useEffect(() => {
    if (!hasCheckedAuth || isLoggedIn) return

    const auth = getAuth(firebaseApp)
    if (auth.currentUser) return

    signInAnonymously(auth).catch((error) => {
      console.error(error)
      Sentry.captureException(error)
    })
  }, [isLoggedIn, hasCheckedAuth])

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!hasCheckedAuth || !isLoggedIn) return <Spinner />

        return <Component {...props} />
      }}
    />
  )
})
