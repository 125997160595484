import { useQuery } from 'react-query'

import { useSpotify } from '../useSpotify'

export function useQuerySpotifyPlaylists() {
  const { auth, playlistService } = useSpotify()

  const { fn, key } = playlistService.queries.all

  return useQuery(key, fn, {
    // 24 hours
cacheTime: 1000 * 60 * 60 * 24 * 7,
    
enabled: auth.isAuthenticated, 
    staleTime: 1000 * 60 * 60 * 24, // 7 days
  })
}
