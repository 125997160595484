import { observer } from 'mobx-react-lite'
import React from 'react'

interface FormErrorProps {
  error: string
}

export const FormError = observer(function FormError({
  error,
}: FormErrorProps): React.ReactElement | null {
  if (!error) return null

  return (
    <div className='flex flex-col gap-2 rounded-lg border-red-500 bg-red-500 px-6 py-4 text-red-100'>
      {error}
    </div>
  )
})
