import { Badge } from '@repo/ui'

export function WarningBadge() {
  return (
    <Badge
      className='mr-auto w-auto text-2xs text-muted sm:text-2xs'
      size='small'
    >
      Warning
    </Badge>
  )
}
