import { Device, Playlist, Song } from '@repo/types'

import { FirebaseTokenResponse } from './spotify/auth/auth-client/types'

export const INTEGRATIONS = {
  SPOTIFY: 'spotify',
} as const

export interface IntegrationOptions {
  enableExternalDevices: boolean
  supportsExternalDevices: boolean
}

export interface IntegrationDeviceService {
  init: () => void
  queryDevices: () => Promise<Device[]>
  transferPlayback: (deviceId: string) => Promise<void>
}

export interface IntegrationDevicesStore {
  activeDevice?: Device
  enableExternalDevices: boolean
  setActiveDevice: (device: Device) => Promise<void>
  setEnableExternalDevices: (enableExternal: boolean) => void
  storedDeviceIdLocal?: string
  storedDeviceIdRemote?: string
  supportsExternalDevices: boolean
}

export interface IntegrationPlayback {
  isLoading: boolean
  isPlaying: boolean
  next: () => Promise<void>
  pause: () => Promise<void>
  play: (song?: Song) => Promise<void>
  previous: () => Promise<void>
  selectedTrack: Song | undefined
}

export interface IntegrationPlayerStore {
  activateElement: () => void
  currentPlaybackDuration: number
  currentPlaybackTime: number
  currentTrackId?: string
  deviceId?: string
  isPlaying: boolean
  progressPercent: string
}

export interface IntegrationPlayerService {
  init: () => Promise<void>
  isReady: boolean
}

export interface IntegrationModel {
  auth: IntegrationAuthClient
  client: IntegrationClient
  connect: (options?: { currentUrl?: string; showDialog?: boolean }) => void
  deviceService: IntegrationDeviceService
  devices: IntegrationDevicesStore
  disconnect: () => Promise<void>
  id: string
  init: () => void
  playback: IntegrationPlayback
  player: IntegrationPlayerStore
  playerService: IntegrationPlayerService
  playlistService: IntegrationPlaylistService
}

export interface IntegrationClient {
  getDevices: () => Promise<Device[]>
  getPlaylistById: (playlistId: string) => Promise<Playlist>
  getPlaylistByUrl: (playlistUrl: string) => Promise<Playlist>
  getPlaylists: () => Promise<Playlist[]>
}

export interface IntegrationAuthClient {
  accessToken?: string
  authRedirectUrl?: string
  connect: (options?: { currentUrl?: string; showDialog?: boolean }) => void
  disconnect: () => void
  email?: string
  handleAuthCallback(): Promise<void | FirebaseTokenResponse>
  isAuthenticated: boolean
  refreshAccessToken(): Promise<unknown>
  refreshToken?: string
}

export interface IntegrationPlaylistService {
  queryPlaylistById(playlistId: string): Promise<Playlist | undefined>
  queryPlaylistByUrl(playlistUrl: string): Promise<Playlist | undefined>
  queryPlaylists(): Promise<Playlist[]>
}

export interface IntegrationProfile {
  displayName: string
  email: string
  id: string
  imageUrl?: string
}

export interface IntegrationProfileService {
  queryProfile(): Promise<IntegrationProfile>
}
