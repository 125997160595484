import * as Sentry from '@sentry/react'
import { getFunctions, httpsCallable } from 'firebase/functions'

import { firebaseApp } from '@/firebase/firebase'

export async function createPortalLink(onComplete?: () => void): Promise<void> {
  const name = import.meta.env.VITE_STRIPE_PORTAL_FUNCTION_NAME
  const region = import.meta.env.VITE_STRIPE_PORTAL_FUNCTION_REGION

  if (!name || !region) {
    throw new Error('Invalid portal function configuration')
  }

  const functions = getFunctions(firebaseApp, region)
  const functionRef = httpsCallable(functions, name)

  try {
    const result = await functionRef({ returnUrl: window.location.origin })
    const { data } = result as { data: { url: string } }
    window.location.assign(data.url)
    onComplete?.()
  } catch (error) {
    console.error(error)
    Sentry.captureException(error)
  }
}
