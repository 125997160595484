import { Playlist } from '@repo/types'
import { observer } from 'mobx-react-lite'
import React from 'react'

import { List } from '@/components/List'

import { PlaylistTrack } from './PlaylistTrack'
import { ReorderPlaylist } from './ReorderPlaylist'

interface PlaylistTracksProps {
  canReorder?: boolean
  isCompact?: boolean
  onClick: (id: number) => void
  onClickDelete: (id: number) => void
  onUpdate: (values: { id: number }[]) => void
  playlist: Playlist
}

export const PlaylistTracks = observer(function PlaylistTracks({
  canReorder = false,
  isCompact = false,
  onClick,
  onClickDelete,
  onUpdate,
  playlist,
}: PlaylistTracksProps): React.ReactElement {
  if (canReorder) {
    return (
      <ReorderPlaylist
        isCompact={isCompact}
        playlist={playlist}
        onClick={onClick}
        onClickDelete={onClickDelete}
        onUpdate={onUpdate}
      />
    )
  }

  return (
    <List className='overflow-hidden'>
      {playlist.songs.map((song, index) => (
        <List.Item className='overflow-hidden' key={song.id}>
          <PlaylistTrack
            canReorder={canReorder}
            index={index}
            isCompact={isCompact}
            song={song}
            onClick={onClick}
            onClickDelete={onClickDelete}
          />
        </List.Item>
      ))}
    </List>
  )
})
