import { z } from 'zod'

export type ErrorWithMessage = {
  message: string
}

export type ErrorWithCode = {
  code: string
}

function hasMessageProperty(input: unknown): input is { message: string } {
  return (
    typeof input === 'object' &&
    input !== null &&
    'message' in input &&
    typeof (input as { message: string }).message === 'string'
  )
}

const JsonErrorSchema = z.object({
  error: z.string()
})

export function isErrorWithCode(error: unknown): error is ErrorWithCode {
  return error instanceof Error && 'code' in error
}

export function toErrorWithMessage(maybeError: unknown): ErrorWithMessage {
  if (hasMessageProperty(maybeError)) return maybeError

  try {
    const parsed = JsonErrorSchema.parse(maybeError)
    return new Error(parsed.error)
  } catch {
    try {
      return new Error(JSON.stringify(maybeError))
    } catch {
      return new Error(String(maybeError))
    }
  }
}

export function getErrorMessage(error: unknown): string {
  return toErrorWithMessage(error).message
}
