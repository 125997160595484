import {
  Panel,
  Badge,
  Heading,
  Checkbox,
  Container,
  Description,
} from '@repo/ui'
import * as Sentry from '@sentry/react'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { AppHeader } from '@/components/AppHeader'
import { Button } from '@/components/Button'
import { BackButton, CloseButton } from '@/components/icon-buttons/IconButtons'
import { Main } from '@/components/Main'
import { Page } from '@/components/Page'
import { PageHeading } from '@/components/PageHeading'
import { useGameContext } from '@/pages/game/context/game-context'
import { ColourSettings } from '@/pages/settings/components/ColourSettings'

const COHOST_SHOW_FEATURES = 'show_features'

interface ShowFeaturesSettings {
  bpm: boolean
  info: boolean
  key: boolean
  spotifyTrackLink: boolean
}

export const SidekickSettingsRoute = observer(
  function SidekickSettingsRoute(): React.ReactElement {
    return <SidekickSettings />
  }
)

export const getShowFeaturesSettings = (): ShowFeaturesSettings => {
  const storedSettings = localStorage.getItem(COHOST_SHOW_FEATURES)

  const defaultSettings = {
    bpm: false,
    info: false,
    key: false,
    spotifyTrackLink: false,
  }

  try {
    return ((storedSettings && JSON.parse(storedSettings)) ||
      defaultSettings) as ShowFeaturesSettings
  } catch (error) {
    Sentry.captureException(error)

    return defaultSettings
  }
}

const SidekickSettings = observer(
  function SidekickSettings(): React.ReactElement {
    const { gameId } = useGameContext()
    const history = useHistory()
    const [showFeatures, setShowFeatures] = useState<ShowFeaturesSettings>(
      getShowFeaturesSettings()
    )

    useEffect(
      () =>
        localStorage.setItem(
          COHOST_SHOW_FEATURES,
          JSON.stringify(showFeatures)
        ),
      [showFeatures]
    )

    const handleCloseClick = () => {
      if (gameId) {
        history.push(`.`)
      }
    }

    return (
      <Page title='Sidekick Settings'>
        <AppHeader
          actionsLeft={<BackButton url={`.`} />}
          actionsRight={<CloseButton onClick={handleCloseClick} />}
          header={<PageHeading>Sidekick Settings</PageHeading>}
        />

        <Main>
          <Container size='medium'>
            <ColourSettings />

            <Panel className='mt-6'>
              <Heading className='mb-2'>Audio Features</Heading>
              <Description>
                Show selected audio features about the current and upcoming
                songs. This feature is only available for playlists imported
                from Spotify.
              </Description>

              <div className='mt-6'>
                <Checkbox
                  className='mb-5'
                  id='show_features_bpm'
                  isChecked={showFeatures.bpm}
                  onChange={(event) =>
                    setShowFeatures({
                      ...showFeatures,
                      bpm: event.target.checked,
                    })
                  }
                >
                  <>
                    Show the{' '}
                    <Badge className='mx-2' colour='purple' size='small'>
                      BPM
                    </Badge>
                  </>
                </Checkbox>

                <Checkbox
                  className='mb-5'
                  id='show_features_key'
                  isChecked={showFeatures.key}
                  onChange={(event) =>
                    setShowFeatures({
                      ...showFeatures,
                      key: event.target.checked,
                    })
                  }
                >
                  <>
                    Show the standard{' '}
                    <Badge className='mx-2' colour='pink' size='small'>
                      Key
                    </Badge>
                  </>
                </Checkbox>

                <Checkbox
                  className='mb-5'
                  id='show_features_info'
                  isChecked={showFeatures.info}
                  onChange={(event) =>
                    setShowFeatures({
                      ...showFeatures,
                      info: event.target.checked,
                    })
                  }
                >
                  <>
                    Show more{' '}
                    <Badge className='mx-2' colour='blue' size='small'>
                      Info
                    </Badge>
                    about the current song
                  </>
                </Checkbox>
                <Checkbox
                  id='show_features_spotify_track_link'
                  isChecked={showFeatures.spotifyTrackLink}
                  onChange={(event) =>
                    setShowFeatures({
                      ...showFeatures,
                      spotifyTrackLink: event.target.checked,
                    })
                  }
                >
                  Show Spotify track link
                </Checkbox>
              </div>
            </Panel>
          </Container>

          <Container className='mt-8' size='medium'>
            <Button text='Done' onClick={() => history.push(`.`)} />
          </Container>
        </Main>
      </Page>
    )
  }
)
