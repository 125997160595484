import { merge } from '@repo/ui'
import { observer } from 'mobx-react-lite'
import { toCanvas } from 'qrcode'
import React, { useEffect, useRef } from 'react'

import { useGameContext } from '../../context/game-context'

interface DisplayQrCodeProps {
  className?: string
  isFullscreen?: boolean
}

function createHostUrl(hostId: string) {
  const appUrl = import.meta.env.VITE_PRIMARY_URL

  const url = new URL(appUrl)
  url.pathname = `join/${hostId}`

  return url.toString()
}

export const DisplayQrCode = observer(function DisplayQrCode({
  className,
  isFullscreen = false,
}: DisplayQrCodeProps): React.ReactElement {
  const ref = useRef<HTMLCanvasElement>(null)
  const game = useGameContext()

  useEffect(() => {
    if (!ref.current) return

    const url = createHostUrl(game.gameData.hostId)

    const fullscreenSize =
      Math.min(window.innerHeight, window.innerWidth) - 48 * 2

    const width = isFullscreen ? fullscreenSize : 128

    toCanvas(ref.current, url, { width }, (error) => {
      if (error) console.error(error)
    })
  }, [game.gameData.hostId, isFullscreen])

  return (
    <canvas
      className={merge(
        { 'absolute inset-0 m-auto rounded-2xl': isFullscreen },
        className
      )}
      ref={ref}
    />
  )
})
