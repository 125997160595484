import { observer } from 'mobx-react-lite'

import { PlaybackControls } from './PlaybackControls'
import { DeviceSelectorModal } from '../DeviceSelectorModal'
import { useDeviceSelectorModal } from '../useDeviceSelectorModal'

export const SpotifyPlaybackControls = observer(
  function PlaybackControlsContainer() {
    const { setShowDeviceSelectorModal, showDeviceSelectorModal } =
      useDeviceSelectorModal()

    return (
      <>
        <DeviceSelectorModal
          setShowDeviceSelectorModal={setShowDeviceSelectorModal}
          showDeviceSelectorModal={showDeviceSelectorModal}
        />

        <PlaybackControls
          setShowDeviceSelectorModal={setShowDeviceSelectorModal}
        />
      </>
    )
  }
)
