import { merge } from '@repo/ui'

interface NumbersItemProps {
  id: number
  isCurrent?: boolean
  isDrawn?: boolean
  size?: 'small' | 'large'
}

export function NumbersItem({
  id,
  isCurrent = false,
  isDrawn = false,
}: NumbersItemProps) {
  return (
    <div
      className={merge(
        'font-bold rounded-sm sm:rounded-md md:rounded-xl bg-shade-2 flex items-center justify-center duration-300 transition-opacity',
        'opacity-25 md:aspect-square',
        'px-[0.5em]',
        {
          'bg-blue-500 text-white': isCurrent,
          'opacity-100': isDrawn,
        }
      )}
    >
      <span
        style={{
          fontSize: `clamp(0.5rem, 2.66vw, 2rem)`,
          marginTop: `calc(clamp(0.0025rem, 2.66vw, 0.25rem) * -1)`,
        }}
      >
        {id}
      </span>
    </div>
  )
}
