import {
  ButtonGroup,
  Container,
  Description,
  Heading,
  Panel,
  Spinner,
} from '@repo/ui'
import { observer } from 'mobx-react-lite'
import { Redirect } from 'react-router-dom'

import { Button } from '@/components/Button'
import { LogoHeader } from '@/components/LogoHeader'
import { Page } from '@/components/Page'
import { useSpotifyAuthCallback } from '@/store/integrations/spotify/auth/useSpotifyAuthCallback'
import StoreService from '@/store/StoreService'

import { SpotifyAuthButton } from '../components/SpotifyAuthButton'
import { useSpotify } from '../useSpotify'

export const AuthSpotify = observer(function AuthSpotify() {
  const { user } = StoreService.getStore()
  const spotify = useSpotify()
  const { authRedirectUrl, isAuthenticated } = spotify.auth

  const { hasError, hasHandledAuthCallback } = useSpotifyAuthCallback()

  if (hasError) {
    return (
      <Container size='medium'>
        <LogoHeader />
        <Panel className='flex flex-col gap-6'>
          <div className='flex flex-col gap-2'>
            <Heading>Something went wrong</Heading>
            <Description>
              We ran into a problem connecting to Spotify. Please try again.
            </Description>
          </div>

          <ButtonGroup direction='column'>
            <SpotifyAuthButton />
            <Button text='Back' to='/' variant='outlined' />
          </ButtonGroup>
        </Panel>
      </Container>
    )
  }

  if (
    authRedirectUrl &&
    authRedirectUrl !== '/auth-spotify' &&
    isAuthenticated &&
    user.hasCheckedAuth &&
    hasHandledAuthCallback
  ) {
    return <Redirect to={authRedirectUrl} />
  } else if (isAuthenticated) {
    return <Redirect to='/' />
  }

  return (
    <Page title='Connecting to Spotify'>
      <Spinner label='Connecting to Spotify...' />
    </Page>
  )
})
