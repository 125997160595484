import {
  ColourPalette,
  PlaybackOption,
  Playlist,
  PlaylistType,
} from '@repo/types'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router-dom'

import { PlaylistEditorContainer } from './PlaylistEditorContainer'
import { useQuerySystemPlaylist } from '../playlist-queries/system-playlists/useQuerySystemPlaylist'
import { useUpdateSystemPlaylist } from '../playlist-queries/system-playlists/useUpdateSystemPlaylist'

interface IEditPlaylistRouteParams {
  playlistId: string
}

export const EditPlaylistRouteAdmin = observer(
  function EditPlaylistRouteAdmin(): React.ReactElement | null {
    const { playlistId } = useParams<IEditPlaylistRouteParams>()
    const {
      data: playlist,
      error,
      isLoading,
    } = useQuerySystemPlaylist(playlistId)

    const { mutate: updateSystemPlaylist } = useUpdateSystemPlaylist({
      playlistId,
    })

    const handleSubmit = async (values: Playlist) => {
      await updateSystemPlaylist({
        ...values,
        colour: values.colour || ColourPalette.PURPLE,
        id: playlistId,
        playbackOptions: [PlaybackOption.SPOTIFY],
        type: PlaylistType.SYSTEM_PLAYLISTS,
      })
    }

    return (
      <>
        <Helmet>
          <title>Admin: Edit Playlist | Rockstar Bingo</title>
        </Helmet>

        <PlaylistEditorContainer
          baseUrl='/admin/'
          error={error as Error | null}
          isLoading={isLoading}
          playlist={playlist}
          onSubmit={handleSubmit}
        />
      </>
    )
  }
)
