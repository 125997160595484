import { Collections, PlanType, User, UserBrandingSettings, UserSettings } from '@repo/types'
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithRedirect,
  signOut
} from 'firebase/auth'
import { deleteDoc, doc, setDoc, updateDoc } from 'firebase/firestore'
import { makeAutoObservable } from 'mobx'

import { Subscription } from '@/billing/getSubscription'
import { databaseRef } from '@/firebase/firebase'

import { MixpanelService } from '../../mixpanel'
import StoreService from '../StoreService'
import { applyProperties } from '../utils'

export class UserStore {
  [key: string]: unknown

  public hasCheckedAuth = false

  public email = ''
  public hasAccount = false
  public isAnonymous = false
  public maxPlayers = 0
  public name = ''
  public planType = PlanType.FREE
  public settings?: UserSettings
  public signInProvider?: string
  public userId = ''
  public subscriptions: Subscription[] = []

  public parentId = ''
  public inherit: Array<'playlists'> = []

  public constructor() {
    makeAutoObservable(this)
  }

  private get store() {
    return StoreService.getStore()
  }

  public get isLoggedIn(): boolean {
    return Boolean(this.userId)
  }

  public get isFree(): boolean {
    return this.planType === PlanType.FREE
  }

  public get isBasic(): boolean {
    return this.planType === PlanType.BASIC
  }

  public get isPremium(): boolean {
    return this.planType === PlanType.PREMIUM
  }

  public get isPro(): boolean {
    return this.planType === PlanType.PRO
  }

  public get subscription() {
    return this.subscriptions[0]
  }

  public get hasSubscription() {
    return Boolean(this.subscription)
  }

  public get isSubscriptionStatusValid() {
    return ['active', 'trialing'].includes(this.subscription?.status)
  }

  public get isSubscriptionActionRequired() {
    return this.hasSubscription && !this.isSubscriptionStatusValid
  }

  public get hasParent(): boolean {
    return Boolean(this.parentId)
  }

  public get canEditPlaylists(): boolean {
    if (this.hasParent) {
      return !this.inherit.includes('playlists')
    }
    return !this.hasParent
  }

  public get canManageSubscription(): boolean {
    return !this.hasParent
  }

  public apply(updates: Partial<this>): void {
    applyProperties(this, updates)
  }

  public applySignIn(user: User): void {
    applyProperties(this, user)

    this.hasCheckedAuth = true

    MixpanelService.identify(user)
  }

  public applySignOut(): void {
    this.email = ''
    this.hasAccount = false
    this.isAnonymous = false
    this.maxPlayers = 0
    this.name = ''
    this.planType = PlanType.FREE
    this.settings = undefined
    this.signInProvider = undefined
    this.userId = ''
    this.subscriptions = []

    this.hasCheckedAuth = true

    MixpanelService.reset()
  }

  public signInGoogle(): void {
    const auth = getAuth()
    const provider = new GoogleAuthProvider()

    if (import.meta.env.VITE_ENV === 'local') {
      void signInWithPopup(auth, provider)
    } else {
      void signInWithRedirect(auth, provider)
    }
  }

  public async signOut(): Promise<void> {
    const { spotify } = this.store
    spotify.disconnect()

    this.applySignOut()

    const auth = getAuth()
    await signOut(auth)

    window.localStorage.clear()
    window.sessionStorage.clear()
  }

  public updateBrandingSettings(values: UserBrandingSettings): Promise<void> {
    this.settings = { ...this.settings, branding: { ...values } }

    const update = { [`settings.branding`]: { ...values } }
    return updateDoc(doc(databaseRef, Collections.USERS, this.userId), update)
  }

  public async updateJoinScreenUrl(url: string): Promise<void> {
    if (this.settings?.joinScreen?.url) {
      const existingDoc = doc(
        databaseRef,
        Collections.CUSTOM_JOIN_SCREEN_URLS,
        this.settings.joinScreen.url
      )

      await deleteDoc(existingDoc)
    }

    const docRef = doc(databaseRef, Collections.CUSTOM_JOIN_SCREEN_URLS, url)
    await setDoc(docRef, { hostId: this.userId })

    this.settings = { ...this.settings, joinScreen: { url } }

    const update = { [`settings.joinScreen`]: { url } }
    return updateDoc(doc(databaseRef, Collections.USERS, this.userId), update)
  }
}
