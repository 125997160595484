import { observer } from 'mobx-react-lite'

import StoreService from '../store/StoreService'

export const PlanRestrictions = observer(function PlanRestrictions() {
  const { user } = StoreService.getStore()

  return (
    <>
      You can host up to <strong>{user.maxPlayers} guests</strong> per game
    </>
  )
})
