import { useSuccessButton } from '@repo/ui/client'
import * as Sentry from '@sentry/react'
import { Form, Formik } from 'formik'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import * as yup from 'yup'

import { getFirebaseAuthErrorMessage } from '@/authentication/utils'
import { Button } from '@/components/Button'
import { FormError } from '@/components/forms/FormError'
import { TextField } from '@/components/forms/TextField'

export interface JumbotronDefaultVideoFormValues {
  video: string
  videoPreGame: string
}

interface JumbotronDefaultVideoProps {
  onSubmit: (values: JumbotronDefaultVideoFormValues) => Promise<unknown>
  onSuccess?: () => void
  video?: string
  videoPreGame?: string
}

export const videoSchema = yup
  .string()
  .url('Please enter a valid URL')
  .required('Please enter a valid URL')

export const JumbotronDefaultVideoForm = observer(
  function JumbotronDefaultVideoForm({
    onSubmit,
    onSuccess,
    video,
    videoPreGame,
  }: JumbotronDefaultVideoProps): React.ReactElement {
    const [formError, setFormError] = useState<string | undefined>()
    const { showSuccess, showSuccessButtonState } = useSuccessButton()

    const validationSchema = yup.object({
      video: videoSchema,
    })

    const handleSubmit = async (values: JumbotronDefaultVideoFormValues) => {
      try {
        await onSubmit(values)

        await showSuccessButtonState()
        onSuccess?.()
      } catch (error) {
        console.error(error)
        Sentry.captureException(error)

        const errorMessage = getFirebaseAuthErrorMessage(error)
        setFormError(errorMessage)
      }
    }

    return (
      <Formik
        initialValues={{
          video: video ?? '',
          videoPreGame: videoPreGame ?? '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, isValid }) => (
          <Form
            className='flex flex-col gap-8'
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          >
            <div className='flex flex-col gap-6'>
              <TextField label='Default Video URL' name='video' />
            </div>

            <div className='flex flex-col gap-6'>
              <TextField label='Pre-game Video URL' name='videoPreGame' />
            </div>

            <Button
              disabled={isSubmitting || !isValid}
              isLoading={isSubmitting}
              loadingText='Saving...'
              showSuccess={showSuccess}
              text='Save'
              type='submit'
            />

            {formError && <FormError error={formError} />}
          </Form>
        )}
      </Formik>
    )
  }
)
