import {
  PlanSelection,
  PlanType,
  isBillingInterval,
  isPlanTypeV2,
} from '@repo/types'
import {
  Panel,
  ButtonGroup,
  Heading,
  Container,
  Label,
  Description,
} from '@repo/ui'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { Button } from '@/components/Button'
import { LogoHeader } from '@/components/LogoHeader'
import { Logout } from '@/components/Logout'
import { Main } from '@/components/Main'
import { Page } from '@/components/Page'
import { SubscriptionDetails } from '@/components/SubscriptionDetails'
import { TermsOfService } from '@/components/TermsOfService'
import StoreService from '@/store/StoreService'
import { createUserAccount } from '@/store/user/createUserAccount'
import { useSearchParams } from '@/utils/use-search-params'

import { RedirectCheckout } from './redirect-checkout'
import { RedirectFree } from './redirect-free'
import { PRICING_URL, RedirectPricing } from './redirect-pricing'

export function parsePlanSelectionParams(
  searchParams: URLSearchParams
): PlanSelection | undefined {
  const planType = searchParams.get('plan')
  if (!isPlanTypeV2(planType) && planType !== PlanType.FREE) return

  const getInterval = () => {
    const intervalParam = searchParams.get('interval')
    if (!isBillingInterval(intervalParam)) return
    return intervalParam
  }

  const getQuantity = () => {
    const quantityParam = searchParams.get('quantity')
    if (!quantityParam) return
    return parseInt(quantityParam, 10)
  }

  return {
    interval: getInterval(),
    planType,
    quantity: getQuantity(),
  }
}

function usePlanSelection(): PlanSelection | undefined {
  const searchParams = useSearchParams()
  return parsePlanSelectionParams(searchParams)
}

interface SetupAccountParams {
  checkoutCancelled?: string
}

export const SetupAccountRoute = observer(
  function SetupAccountRoute(): React.ReactElement {
    return <SetupAccount />
  }
)

const SetupAccount = observer(function SetupAccount(): React.ReactElement {
  const { checkoutCancelled } = useParams<SetupAccountParams>()

  const planSelection = usePlanSelection()

  const { user } = StoreService.getStore()

  if (user.hasAccount && !user.isFree && !checkoutCancelled) {
    return <SubscriptionDetails />
  }

  if (planSelection?.planType === 'free') {
    return <RedirectFree showWelcomeModal />
  }

  if (planSelection) {
    return <RedirectCheckout planSelection={planSelection} />
  }

  if (checkoutCancelled) {
    return <RedirectPricing />
  }

  return (
    <Page title='Choose a Plan Type'>
      <Main>
        <LogoHeader />

        <Container size='small'>
          <Panel className='flex flex-col gap-8'>
            <div>
              <Heading size='large'>Choose a Plan Type</Heading>

              <Description className='mt-4'>
                Take the app for a spin for free with limited features, or
                become a subscriber to host professional events.
              </Description>
            </div>

            <div>
              <div className='flex flex-col gap-4'>
                <div>
                  <Label>Logged in as:</Label>
                  {user.email}
                </div>
                <div className='flex gap-2 text-sm text-muted'>
                  <div>Not your account?</div>
                  <Logout variant='anchor' />
                </div>
              </div>
            </div>

            <ButtonGroup direction='column'>
              <TryFreeButton />
              <Button
                text='Purchase Subscription'
                to={PRICING_URL}
                isExternal
              />
            </ButtonGroup>
          </Panel>
        </Container>

        <TermsOfService />
      </Main>
    </Page>
  )
})

const TryFreeButton = observer(function TryFreeButton() {
  const history = useHistory()
  const { user } = StoreService.getStore()

  const handleClick = async () => {
    await createUserAccount(user)

    user.apply({ hasAccount: true })

    history.push('/?welcome=true')
  }

  return <Button text='Try Free' variant='outlined' onClick={handleClick} />
})
