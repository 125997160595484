import { toErrorWithMessage } from '@repo/lib'
import { Collections, Game, GameStatus } from '@repo/types'
import { ButtonGroup, Container, Panel, Spinner } from '@repo/ui'
import { collection, getDocs, limit, orderBy, query, startAfter, where } from 'firebase/firestore'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'

import { GameListByDate } from '@/admin/components/game-list-by-date'
import { AppHeader } from '@/components/AppHeader'
import { Button } from '@/components/Button'
import { BackButton } from '@/components/icon-buttons/IconButtons'
import { Main } from '@/components/Main'
import { Page } from '@/components/Page'
import { PageHeading } from '@/components/PageHeading'
import { databaseRef } from '@/firebase/firebase'
import StoreService from '@/store/StoreService'

export const GameHistoryRoute = observer(function GameHistoryRoute(): React.ReactElement {
  return <GameHistory />
})

const GameHistory = observer(function GameHistory(): React.ReactElement {
  return (
    <Page title='Game History'>
      <AppHeader
        actionsLeft={<BackButton url='/music' />}
        header={<PageHeading>Game History</PageHeading>}
      />
      <Main>
        <Container size='large'>
          <GameHistoryAll />
        </Container>
      </Main>
    </Page>
  )
})

const QUERY_PAGE_SIZE_LIMIT = 50

const fetchGamesPage = async (
  userId: string,
  direction: 'desc' | 'asc',
  itemLimit: number,
  timestamp: number
) => {
  const gamesCollection = collection(databaseRef, Collections.GAMES)

  let gamesQuery = query(
    gamesCollection,
    where('gameStatus', '==', GameStatus.GAME_COMPLETED),
    where('hostId', '==', userId),
    orderBy('timestamp', direction),
    limit(itemLimit)
  )

  if (timestamp) {
    gamesQuery = query(gamesQuery, startAfter(new Date(timestamp)))
  }

  const snapshot = await getDocs(gamesQuery)

  if (snapshot.empty) {
    console.log('No matching documents.')
    return { games: [], lastVisibleTimestamp: 0 }
  }

  const games: Game[] = snapshot.docs.map(doc => doc.data() as Game)

  const firstVisible = games[0]
  const lastVisible = games[games.length - 1]

  const firstVisibleTimestamp = firstVisible.timestamp.toMillis()
  const lastVisibleTimestamp = lastVisible.timestamp.toMillis()

  return { firstVisibleTimestamp, games, lastVisibleTimestamp }
}

function createParams(direction: 'desc' | 'asc', lastTimestamp: number) {
  const params = new URLSearchParams()
  params.set('direction', direction)
  params.set('last', lastTimestamp.toString())

  return params.toString()
}

function parseTimestampParam(param?: string | null) {
  const parsed = parseInt(param || '0', 10)
  return isNaN(parsed) ? 0 : parsed
}

function parseDirectionParam(param?: string | null): 'asc' | 'desc' {
  return param === 'asc' ? 'asc' : 'desc'
}

function parseParams(params: URLSearchParams) {
  const direction = parseDirectionParam(params.get('direction'))
  const lastTimestamp = parseTimestampParam(params.get('last'))

  return { direction, lastTimestamp }
}

const GameHistoryAll = observer(() => {
  const { user } = StoreService.getStore()

  const history = useHistory()
  const location = useLocation()
  const { direction, lastTimestamp } = parseParams(new URLSearchParams(location.search))

  const queryKey = ['game-history', `userId:${user.userId}`, direction, QUERY_PAGE_SIZE_LIMIT]

  const queryClient = useQueryClient()
  const queryCache = queryClient.getQueryCache()
  const queries = queryCache.getAll()
  const cachedQueryKeys = queries
    .map(query => query.queryKey)
    .filter(queryKey => queryKey[0] === 'game-history' && queryKey[1] === `userId:${user.userId}`)

  const { data, error, isError, isFetching, isLoading } = useQuery(
    [...queryKey, lastTimestamp],
    () => fetchGamesPage(user.userId, direction, QUERY_PAGE_SIZE_LIMIT, lastTimestamp),
    {
      // 24 hours
      cacheTime: 1000 * 60 * 60 * 24 * 7,

      // 7 days
      keepPreviousData: true,

      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 60 * 24
    }
  )

  const checkCache = () => {
    const currentQueryIndex = cachedQueryKeys.findIndex(
      keyArray => keyArray.at(-1) === lastTimestamp
    )
    const previousQuery = cachedQueryKeys[currentQueryIndex - 1]
    const timestamp = previousQuery?.at(-1)

    if (typeof timestamp !== 'number') return
    return timestamp
  }

  const updateUrl = (direction: 'desc' | 'asc', timestamp: number) => {
    const params = createParams(direction, timestamp)
    history.push(`?${params}`)

    window.scrollTo(0, 0)
  }

  const navigate = (updatedDirection: 'desc' | 'asc') => {
    let timestamp: number

    if (updatedDirection !== direction) {
      const previousTimestamp = checkCache()

      if (typeof previousTimestamp === 'number') {
        updateUrl(direction, previousTimestamp)
        return
      }

      timestamp = data?.firstVisibleTimestamp ?? 0
    } else {
      timestamp = data?.lastVisibleTimestamp ?? 0
    }

    updateUrl(updatedDirection, timestamp)
  }

  const handleNext = () => {
    navigate('desc')
  }

  const handlePrevious = () => {
    navigate('asc')
  }

  if (isLoading) return <Spinner label='Loading games...' />

  if (isError) return <Panel>{toErrorWithMessage(error).message}</Panel>

  if (isFetching) return <Spinner label='Loading more games...' />

  if (!data || !data.games.length) {
    return (
      <Panel className='flex flex-col gap-6'>
        {`No results matched your query`}
        <Button text='Reset' to='/game-history' />
      </Panel>
    )
  }

  return (
    <Container size='large'>
      <GameListByDate games={data.games} showHostHeader={false} />

      <ButtonGroup className='mt-8'>
        <Button disabled={!lastTimestamp} text='Newer' onClick={handlePrevious} />
        <Button disabled={!data?.lastVisibleTimestamp} text='Older' onClick={handleNext} />
      </ButtonGroup>
    </Container>
  )
})
