import * as Sentry from '@sentry/browser'
import { makeAutoObservable, reaction, when } from 'mobx'

import StoreService from '@/store/StoreService'
import { applyProperties } from '@/store/utils'
import { backoff } from '@/utils/backoff'

import { toSeconds } from './utils'
import { IntegrationPlayerService } from '../..'
import { SESSION_STORAGE_INTERNAL_PLAYER_KEY } from '../SpotifyDevicesStore'

export const SPOTIFY_PLAYER_DEVICE_NAME = 'Rockstar Bingo Player'
const SPOTIFY_SDK = 'https://sdk.scdn.co/spotify-player.js'
const SPOTIFY_SCRIPT_ID = 'spotify-player-sdk'

export class SpotifyPlayerService implements IntegrationPlayerService {
  isEnabled = false
  isReady = false

  constructor() {
    makeAutoObservable(this)
  }

  public async init() {
    console.log('Initializing...')
    this.appendScript()

    this.spotify.player._playerElement = await this.configure()
    await this.connect()

    this.subscribe()
  }

  public connect() {
    console.log('Connecting web player...')
    return this.playerElement.connect()
  }

  public disconnect() {
    if (!this.isEnabled) return

    console.log('Disconnecting web player...')
    return this.playerElement.disconnect()
  }

  private get store() {
    return StoreService.getStore()
  }

  private get spotify() {
    return this.store.spotify
  }

  private get auth() {
    return this.spotify.auth
  }

  private get token() {
    return this.auth.accessToken ?? ''
  }

  private get player() {
    return this.spotify.player
  }

  private get playerElement() {
    return this.player.playerElement
  }

  private configure(): Promise<Spotify.Player> {
    return new Promise((resolve) => {
      window.onSpotifyWebPlaybackSDKReady = () => {
        console.log('Spotify Web Playback SDK is ready. Creating player...')

        const player = new Spotify.Player({
          getOAuthToken: (callback: (token: string) => void) =>
            callback(this.token),
          name: SPOTIFY_PLAYER_DEVICE_NAME,
          volume: 1,
        })

        resolve(player)
      }
    })
  }

  private appendScript() {
    const script = document.createElement('script')
    script.id = SPOTIFY_SCRIPT_ID
    script.src = SPOTIFY_SDK
    script.async = true
    document.head.appendChild(script)
  }

  private subscribe(): void {
    const { playerElement } = this

    playerElement.addListener('ready', this.handleReady)
    playerElement.addListener('not_ready', this.handleNotReady)
    playerElement.addListener(
      'player_state_changed',
      this.handlePlayerStateChanged
    )
    playerElement.addListener('account_error', this.handleAccountError)
    playerElement.addListener(
      'authentication_error',
      this.handleAuthenticationError
    )
    playerElement.addListener(
      'initialization_error',
      this.handleInitializationError
    )
    playerElement.addListener('playback_error', this.handlePlaybackError)

    reaction(
      () => ({
        currentTrackId: this.player.currentTrack?.id,
        isPlaying: this.player.isPlaying,
      }),
      ({ currentTrackId, isPlaying }, previous) => {
        if (
          !isPlaying ||
          (currentTrackId && currentTrackId !== previous.currentTrackId)
        ) {
          this.player.stopTimer()
          this.player.apply({ currentPlaybackTime: 0 })
        }

        if (isPlaying && this.player.timer === undefined) {
          this.player.startTimer()
        }
      }
    )
  }

  private handleReady = ({ device_id }: Spotify.WebPlaybackInstance) => {
    this.player.deviceId = device_id

    console.log('Internal player device ID:', device_id)

    sessionStorage.setItem(SESSION_STORAGE_INTERNAL_PLAYER_KEY, device_id)

    this.playerElement.setVolume(1).catch(console.error)

    this.apply({ isReady: true })
  }

  private handleNotReady = ({ device_id }: Spotify.WebPlaybackInstance) => {
    console.log('Device ID has gone offline', device_id)
    Sentry.captureException(
      new Error(`Device ID has gone offline: ${device_id}`)
    )
  }

  private handlePlayerStateChanged = (state: Spotify.PlaybackState | null) => {
    const paused = Boolean(state?.paused)
    const currentPlaybackDuration = toSeconds('duration', state)
    const currentPlaybackTime = toSeconds('position', state)

    this.player.apply({
      currentPlaybackDuration,
      currentPlaybackTime,
      currentTrack: state?.track_window?.current_track ?? undefined,
      isLoading: Boolean(state?.loading),
      isPaused: paused,
      isPlaying: !paused,
    })
  }

  private handleAccountError = (error: Spotify.Error) => {
    console.error('handleAccountError', error)
    Sentry.captureException(error)
    this.spotify.disconnect()
  }

  private handleAuthenticationError = (error: Spotify.Error) => {
    console.error('handleAuthenticationError', error)
    Sentry.captureException(error)

    this.auth.refreshAccessToken().catch(console.error)
  }

  private handlePlaybackError = async (error: Spotify.Error) => {
    try {
      console.log('handlePlaybackError', error)

      this.spotify.playback.apply({ isLoading: true })

      await when(() => this.player.isPaused && !this.player.isLoading)
      await this.retryPlayback()
    } catch (error) {
      console.error('handlePlaybackError', error)
      Sentry.captureException(error)
    } finally {
      this.spotify.playback.apply({ isLoading: false })
    }
  }

  private handleInitializationError = async (error: Spotify.Error) => {
    console.error('handleInitializationError', error)
    Sentry.captureException(error)
  }

  private retryPlayback = async () => {
    let attempts = 0
    const maxAttempts = 10

    while (!this.player.isPlaying && attempts < maxAttempts) {
      // Check again after the delay, in case playback has started
      if (this.player.isPlaying) {
        break
      }

      console.log(
        `Attempting to resume playback... Attempt ${
          attempts + 1
        } of ${maxAttempts}`
      )
      await this.playerElement.togglePlay()
      attempts++

      await backoff(attempts, { baseDelay: 10, maxDelay: 1000 })
    }

    if (this.player.isPlaying) {
      console.log('Playback resumed successfully.')
    } else {
      throw new Error(
        `Failed to resume playback after ${maxAttempts} attempts.`
      )
    }
  }

  apply(values: Partial<SpotifyPlayerService>) {
    applyProperties(this, values)
  }
}
