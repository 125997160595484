import { AuthProvider } from '@repo/types'
import { SpotifyIcon } from '@repo/ui'
import { observer } from 'mobx-react-lite'
import React from 'react'
import styled from 'styled-components'

import { focusOutlineStyles } from '../components/StyledComponents'

export enum AuthType {
  LOGIN = 'login',
  SIGNUP = 'sign-up',
  CONNECT = 'connect',
}

interface AuthButtonProps {
  authType?: AuthType
  onClick: () => void
  provider: AuthProvider
}

export const AuthButton = observer(function AuthButton({
  authType,
  provider,
  ...props
}: AuthButtonProps): React.ReactElement {
  return (
    <AuthButtonStyled {...props}>
      <ContainerStyled>
        <IconContainerStyled>
          {provider === AuthProvider.GOOGLE && <GoogleIcon />}
          {provider === AuthProvider.SPOTIFY && <SpotifyIcon />}
          {provider === AuthProvider.EMAIL && <EmailIcon />}
        </IconContainerStyled>
        <TextContainerStyled>
          {authType === AuthType.SIGNUP && `Sign up with ${provider}`}
          {authType === AuthType.LOGIN && `Login with ${provider}`}
          {authType === AuthType.CONNECT && `Connect to ${provider}`}
        </TextContainerStyled>
      </ContainerStyled>
    </AuthButtonStyled>
  )
})

const GoogleIcon = observer(function GoogleIcon(): React.ReactElement {
  return (
    <svg viewBox='0 0 533.5 544.3' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M533.5 278.4c0-18.5-1.5-37.1-4.7-55.3H272.1v104.8h147c-6.1 33.8-25.7 63.7-54.4 82.7v68h87.7c51.5-47.4 81.1-117.4 81.1-200.2z'
        fill='#4285f4'
      />
      <path
        d='M272.1 544.3c73.4 0 135.3-24.1 180.4-65.7l-87.7-68c-24.4 16.6-55.9 26-92.6 26-71 0-131.2-47.9-152.8-112.3H28.9v70.1c46.2 91.9 140.3 149.9 243.2 149.9z'
        fill='#34a853'
      />
      <path
        d='M119.3 324.3c-11.4-33.8-11.4-70.4 0-104.2V150H28.9c-38.6 76.9-38.6 167.5 0 244.4l90.4-70.1z'
        fill='#fbbc04'
      />
      <path
        d='M272.1 107.7c38.8-.6 76.3 14 104.4 40.8l77.7-77.7C405 24.6 339.7-.8 272.1 0 169.2 0 75.1 58 28.9 150l90.4 70.1c21.5-64.5 81.8-112.4 152.8-112.4z'
        fill='#ea4335'
      />
    </svg>
  )
})

const EmailIcon = observer(function EmailIcon(): React.ReactElement {
  return (
    <svg
      aria-hidden='true'
      data-prefix='far'
      focusable='false'
      height='24'
      role='img'
      viewBox='0 0 512 512'
      width='24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z'
        fill='currentColor'
      ></path>
    </svg>
  )
})

const AuthButtonStyled = styled.button`
  .light & {
    --auth-button: var(--bg-00);
    --auth-button-border: var(--bg-04);
    --auth-button-text: var(--text);
    --auth-button-hover: var(--bg-00);
    --auth-button-hover-shadow: rgba(117, 117, 117, 0.5);
  }

  .dark & {
    --auth-button: var(--bg-03);
    --auth-button-border: var(--bg-03);
    --auth-button-text: var(--text);
    --auth-button-hover: var(--bg-04);
    --auth-button-hover-shadow: transparent;
  }

  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 184px;
  max-width: 100%;
  width: 100%;
  height: 48px;
  border: 1px solid var(--auth-button-border);
  background-color: var(--auth-button);
  border-radius: 6px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.12);
  cursor: pointer;
  cursor: hand;
  align-self: center;
  user-select: none;
  transition: all 400ms ease 0s;
  padding-right: 10px;
  color: var(--auth-button-text);
  font-size: 14px;
  letter-spacing: 0.2px;
  user-select: none;
  text-transform: uppercase;

  &:hover {
    box-shadow: 0 3px 8px var(--auth-button-hover-shadow);
    background: var(--auth-button-hover);
    user-select: none;
  }

  ${focusOutlineStyles}
`

const ContainerStyled = styled.div`
  margin: auto;
  height: 36px;
  max-width: 220px;
  min-width: 208px;
  display: flex;
  align-items: center;
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

const IconContainerStyled = styled.div`
  width: 18px;
  min-height: 48px;
  border-radius: 2px;
  user-select: none;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 15px;

  svg {
    width: 20px;
    height: 18px;
    user-select: none;
  }
`

const TextContainerStyled = styled.span`
  min-width: 16ch;
  text-align: left;
`
