import { DrawnItem } from '@repo/types'

export const Columns = {
  0: 'B',
  1: 'I',
  2: 'N',
  3: 'G',
  4: 'O',
} as const

const mins = {
  B: 1,
  G: 46,
  I: 16,
  N: 31,
  O: 61,
} as const

type ColumnNumber = keyof typeof Columns
type ColumnName = (typeof Columns)[ColumnNumber]

type BingoNumber = {
  id: number
  isCurrent: boolean
  isDrawn: boolean
}

type BingoColumn = BingoNumber[]
type BingoBoard = { [column in ColumnName]: BingoColumn }

export function generateBingoBoard(
  drawnItems: DrawnItem[],
  currentDrawnItem?: DrawnItem
): BingoBoard {
  const board: BingoBoard = {
    B: [],
    G: [],
    I: [],
    N: [],
    O: [],
  }

  const drawnSet = new Set(drawnItems.map((item) => item.id))

  for (const columnName of Object.values(Columns)) {
    const min = mins[columnName]
    const max = min + 14

    for (let i = min; i <= max; i++) {
      const isDrawn = drawnSet.has(i)
      const isCurrent = currentDrawnItem?.id === i

      const num: BingoNumber = { id: i, isCurrent: isCurrent, isDrawn: isDrawn }
      board[columnName].push(num)
    }
  }

  return board
}
