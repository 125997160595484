import { DrawnItem, DrawnSong } from '@repo/types'

export function isDrawnSong(item?: DrawnItem | DrawnSong): item is DrawnSong {
  if (!item) return false
  return 'song' in item
}

export function isNonNullish<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined
}
