import { useLayoutEffect, useRef } from 'react'

type RectResult = Pick<
  DOMRect,
  'width' | 'height' | 'top' | 'right' | 'bottom' | 'left'
>

function getBoundingClientRect<T extends HTMLElement>(element?: T): RectResult {
  let rect: RectResult = {
    bottom: 0,
    height: 0,
    left: 0,
    right: 0,
    top: 0,
    width: 0,
  }
  if (element) rect = element.getBoundingClientRect()
  return rect
}

/**
 * Calls the callback with the current size of the element. Will ALWAYS
 * call the callback immediately when invoked, and then after any
 * detected sizing change.
 *
 * Returns a function to remove the listeners
 * @param element
 * @param callback
 */
function measureElement<T extends HTMLElement>(
  element: T,
  callback: (result: RectResult) => void
): () => void {
  let existingObserver: ResizeObserver

  function handleResize(): void {
    requestAnimationFrame(() => {
      if (!element) {
        return
      }
      const output = getBoundingClientRect(element)
      callback(output)
    })
  }

  window.addEventListener('resize-synthetic', handleResize)

  if (typeof ResizeObserver === 'function') {
    existingObserver = new ResizeObserver(() => handleResize())
    existingObserver.observe(element)
  } else {
    // fallback if ResizeObserver isn't available
    window.addEventListener('resize', handleResize)
    handleResize()
  }

  return () => {
    if (existingObserver) {
      existingObserver.disconnect()
    } else {
      window.removeEventListener('resize', handleResize)
    }
    window.removeEventListener('resize-synthetic', handleResize)
  }
}

export function useMeasureElement(
  callback: (result: RectResult) => void
): (node: HTMLElement | null) => void {
  const element = useRef<HTMLElement | null>()
  useLayoutEffect(() => {
    if (element.current) {
      return measureElement(element.current, callback)
    } else {
      return () => undefined
    }
  }, [element, callback])

  return (node: HTMLElement | null) => {
    element.current = node
  }
}
